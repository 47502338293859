angular.module('LeasePilot').directive('rentTable', function() {
  return {
    restrict: 'E',
    replace: true,
    template: function(element, attrs) {
      const leaseIf = window.isLeaseIfDeprecated ? 'lease-if-deprecated' : 'lease-if';

      var columns_arr = attrs.columns.split(',');
      var column = [];

      // Fill columns based on arguments
      for (var i = 0; i < columns_arr.length; i++) {
        column[i] = { title: columns_arr[i].replace('\\n', '<br>') };
      }

      var tableId = attrs.tableId ? attrs.tableId : '';
      var rentTableType = attrs.rentTableType
        ? attrs.rentTableType
        : 'rentTable';
      var rowStyle = attrs.rowStyle;
      var headRowStyle = attrs.headRowStyle
        ? attrs.headRowStyle
        : attrs.rowStyle;
      var headCellStyle = attrs.headCellStyle
        ? attrs.headCellStyle
        : attrs.cellStyle;
      var headTextStyle = attrs.headTextStyle ? attrs.headTextStyle : '';
      var tableClass = attrs.tableClass;
      var cellStyle = attrs.cellStyle;
      var termText = attrs.termText
        ? attrs.termText
        : '{{ $index + 1 | ordinal }} Full 12-Month Period';
      var firstTermText = attrs.firstTermText
        ? attrs.firstTermText
        : attrs.termText
        ? attrs.termText
        : 'Commencement Date - Last Day of 1st Full 12-Month Period';
      var lastTermText = attrs.lastTermText
        ? attrs.lastTermText
        : attrs.termText
        ? attrs.termText
        : 'Balance of Term';
      var term1stValue = attrs.term1stValue
        ? attrs.term1stValue
        : "{{ rentPeriod.rentPerSqFoot * lease.premiseAreaSize | currency:'$':2 }}";
      var term2ndValue = attrs.term2ndValue
        ? attrs.term2ndValue
        : "{{ (rentPeriod.rentPerSqFoot * lease.premiseAreaSize)/12 | currency:'$':2 }}";
      var term3rdValue = attrs.term3rdValue;
      var term4thValue = attrs.term4thValue;
      var hlTag = attrs.hlTag ? attrs.hlTag : 'rentValues';
      var terms = attrs.terms ? attrs.terms : 'lease.rentPeriods';
      var years = attrs.years ? attrs.years : 'lease.leaseYears';
      var months = attrs.months ? attrs.months : 'lease.leaseMonths';
      var consolidatedRenewalTerm = attrs.consolidatedRenewalTerm
        ? attrs.consolidatedRenewalTerm
        : false;
      var consolidatedRentYears = attrs.consolidatedRentYears
        ? attrs.consolidatedRentYears
        : false;
      var consolidatedTermMultipleYearsText = attrs.consolidatedTermMultipleYearsText
        ? attrs.consolidatedTermMultipleYearsText
        : '{{ rentPeriod.startYear }} - {{ rentPeriod.endYear }}';
      var consolidatedTermSingleYearText = attrs.consolidatedTermSingleYearText
        ? attrs.consolidatedTermSingleYearText
        : '{{ rentPeriod.startYear }}';
      var consolidatedRenewalTermsMultipleYearsText = attrs.consolidatedRenewalTermsMultipleYearsText
        ? attrs.consolidatedRenewalTermsMultipleYearsText
        : '{{ lease.leaseYears + ($parent.$parent.$index || $parent.$index) * lease.renewalTerm + renewalRentPeriod.startYear }} - {{ lease.leaseYears + ($parent.$parent.$index || $parent.$index) * lease.renewalTerm + renewalRentPeriod.endYear }}';
      var consolidatedRenewalTermsSingleYearText = attrs.consolidatedRenewalTermsSingleYearText
        ? attrs.consolidatedRenewalTermsSingleYearText
        : '{{ lease.leaseYears + ($parent.$parent.$index || $parent.$index) * lease.renewalTerm + renewalRentPeriod.startYear }}';
      var showOnlyCpi = attrs.showOnlyCpi
        ? attrs.showOnlyCpi === 'true'
        : false;
      var addMarkForFreeRent = attrs.addMarkForFreeRent !== 'false';
      var addSingleMarkForFreeRent = attrs.addSingleMarkForFreeRent === 'true';
      var supportPartialYear = attrs.supportPartialYear
        ? attrs.supportPartialYear === 'true'
        : false;
      var excludeFirstTerm = attrs.excludeFirstTerm === 'true';
      var addMonthsRowAtEnd =
        attrs.addMonthsRowAtEnd || attrs.addMonthsRowAtEnd === 'true';
      var specificRenewalTerm = attrs.specificRenewalTerm
        ? attrs.specificRenewalTerm
        : false;
      /* Related to adding free rent row */
      var addFreeRentRow = attrs.addFreeRentRow ? attrs.addFreeRentRow : false;
      var isHardDate = attrs.isHardDate;
      var freeRentDatesRange = attrs.freeRentDatesRange;
      var addFreeRentRows = attrs.addFreeRentRows === 'true';
      /* /Related to adding free rent row */

      var result =
        "<table table-id='" +
        tableId +
        "' generated-directive='" +
        rentTableType +
        "' ng-click='inline.openInlineDirective($event)' class=\"fs-hide " +
        tableClass +
        '" ng-class="(\'' +
        years +
        "'==currentlyChanging) ? 'adding-para' : ''\"" +
        (attrs.style ? 'style="' + attrs.style + '">' : '>') +
        /*-------------------------------------------------------
         Headers
         -------------------------------------------------------*/
        '<tbody>' +
        '<tr' +
        (headRowStyle ? " style='" + headRowStyle + "'>" : '>') +
        '<td' +
        (headCellStyle ? " style='" + headCellStyle + "'>" : '>') +
        (attrs.msWordStyle
          ? "<span style='display:none;user-select:none;'>!!" +
            attrs.msWordStyle +
            '!!</span>'
          : '') +
        (headTextStyle ? '<span style ="' + headTextStyle + '">' : '') +
        column[0].title +
        (headTextStyle ? '</span>' : '') +
        '</td>' +
        '<td' +
        (headCellStyle ? " style='" + headCellStyle + "'>" : '>') +
        (headTextStyle ? '<span style ="' + headTextStyle + '">' : '') +
        column[1].title +
        (headTextStyle ? '</span>' : '') +
        '</td>' +
        (column.length > 2
          ? '<td' +
            (headCellStyle ? " style='" + headCellStyle + "'>" : '>') +
            (headTextStyle ? '<span style ="' + headTextStyle + '">' : '') +
            column[2].title +
            (headTextStyle ? '</span>' : '') +
            '</td>'
          : '') +
        (column.length > 3
          ? '<td' +
            (headCellStyle ? " style='" + headCellStyle + "'>" : '>') +
            (headTextStyle ? '<span style ="' + headTextStyle + '">' : '') +
            column[3].title +
            (headTextStyle ? '</span>' : '') +
            '</td>'
          : '') +
        (column.length > 4
          ? '<td' +
            (headCellStyle ? " style='" + headCellStyle + "'>" : '>') +
            (headTextStyle ? '<span style ="' + headTextStyle + '">' : '') +
            column[4].title +
            (headTextStyle ? '</span>' : '') +
            '</td>'
          : '') +
        '</tr>' +
        (!consolidatedRenewalTerm
          ? /*-------------------------------------------------------
           Single Term rent table
           -------------------------------------------------------*/

            consolidatedRentYears
            ? /*-------------------------------------------------------
             With consolidated rent years
             -------------------------------------------------------*/
              "<tbody hl='" +
              terms +
              ".length'" +
              (addFreeRentRows ? "hl2='lease.rentRows'" : '') +
              ' ng-class="(\'' +
              hlTag +
              "'==currentlyChanging) ? 'adding-para' : ''\">" +
              "<tr ng-repeat='rentPeriod in lease." +
              (addFreeRentRows ? 'rentRows' : 'consolidatedRentPeriods') +
              " track by $index' class='added' " +
              (excludeFirstTerm ? "ng-if='!$first'" : '') +
              '>' +
              "<td "+leaseIf+"='rentPeriod.startYear !== rentPeriod.endYear'" +
              (cellStyle ? " style='" + cellStyle + "'>" : '>') +
              consolidatedTermMultipleYearsText +
              (addMarkForFreeRent || addSingleMarkForFreeRent
                ? "<span ng-if='$index == 0'><span "+leaseIf+"='lease.hasFreeRent && lease.freeRent > 0' hl='lease.hasFreeRent' hl2='lease.freeRent' >*</span></span>"
                : '') +
              '</td>' +
              "<td "+leaseIf+"='rentPeriod.startYear === rentPeriod.endYear'" +
              (cellStyle ? " style='" + cellStyle + "'>" : '>') +
              consolidatedTermSingleYearText +
              (addMarkForFreeRent || addSingleMarkForFreeRent
                ? "<span ng-if='$index == 0'><span "+leaseIf+"='lease.hasFreeRent && lease.freeRent > 0' hl='lease.hasFreeRent' hl2='lease.freeRent' >*</span></span>"
                : '') +
              '</td>' +
              '<td' +
              (cellStyle ? " style='" + cellStyle + "'>" : '>') +
              term1stValue +
              '</td>' +
              (column.length > 2
                ? '<td' +
                  (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                  term2ndValue +
                  (addMarkForFreeRent
                    ? "<span ng-if='$index == 0'><span "+leaseIf+"='lease.hasFreeRent && lease.freeRent > 0' hl='lease.hasFreeRent' hl2='lease.freeRent' >**</span></span>"
                    : '') +
                  '</td>'
                : '') +
              (column.length > 3
                ? '<td' +
                  (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                  "{{ rentPeriod.rentPerSqFoot | currency:'$':2 }}" +
                  "<span "+leaseIf+"='rentPeriod.isFree && rentPeriod.isFirst' hl='lease.hasFreeRent' hl2='lease.freeRent' >*</span>" +
                  '</td>'
                : '') +
              (column.length > 4
                ? '<td' +
                  (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                  term4thValue +
                  '</td>'
                : '') +
              '</tr>' +
              '</tbody>'
            : /* OR */

              /*-------------------------------------------------------
             Without consolidated rent years
             -------------------------------------------------------*/
              '<tbody class="added" "+leaseIf+"=\'' +
              addFreeRentRow +
              "' ng-class=\"('" +
              hlTag +
              "'==currentlyChanging) ? 'adding-para' : ''\">" +
              '<tr ng-if="' +
              isHardDate +
              '">' +
              '<td' +
              (cellStyle ? " style='" + cellStyle + "'" : '') +
              '>' +
              freeRentDatesRange +
              '</td>' +
              '<td' +
              (cellStyle ? " style='" + cellStyle + "'" : '') +
              '>$0 {{' +
              (addMarkForFreeRent || addSingleMarkForFreeRent) +
              "? '*' : ''}}</td>" +
              '<td' +
              (cellStyle ? " style='" + cellStyle + "'" : '') +
              '>N/A {{' +
              (addMarkForFreeRent || addSingleMarkForFreeRent) +
              "? '*' : ''}}</td>" +
              '</tr>' +
              '</tbody>' +
              "<tbody hl='" +
              terms +
              '.length\' class="added" "+leaseIf+"=\'' +
              years +
              "' ng-class=\"('" +
              hlTag +
              "'==currentlyChanging) ? 'adding-para' : ''\">" +
              (supportPartialYear
                ? '<tr "+leaseIf+"=\'lease.rentInputType === "manual" && ' +
                  months +
                  " > 0'>" +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>{{ formatDateAsText(lease.rentCommenceAt) }} - {{ formatDateAsText(addDays(addMonths(lease.rentCommenceAt, lease.leaseMonths), -1)) }}</td>' +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>N/A</td>' +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>{{ (' +
                  terms +
                  "[0].rentPerSqFoot * lease.premiseAreaSize)/12 | currency:'$':2 }}</td>" +
                  '</tr>' +
                  '<tr "+leaseIf+"=\'lease.rentInputType === "automatic" && ' +
                  months +
                  " > 0'>" +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>{{ formatDateAsText(lease.rentCommenceAt) }} - {{ formatDateAsText(addDays(addMonths(lease.rentCommenceAt, lease.leaseMonths), -1)) }}</td>' +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>N/A</td>' +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>{{ (' +
                  terms +
                  "[0].totalRent)/12 | currency:'$':2 }}</td>" +
                  '</tr>' +
                  '<tr "+leaseIf+"=\'lease.rentInputType === "fixed" && ' +
                  months +
                  " > 0'>" +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>{{ formatDateAsText(lease.rentCommenceAt) }} - {{ formatDateAsText(addDays(addMonths(lease.rentCommenceAt, lease.leaseMonths), -1)) }}</td>' +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>N/A</td>' +
                  '<td' +
                  (cellStyle ? " style='" + cellStyle + "'" : '') +
                  '>{{ (' +
                  terms +
                  "[0].totalRent) | currency:'$':2 }}</td>" +
                  '</tr>'
                : '') +
              (supportPartialYear
                ? "<tr ng-repeat='rentPeriod in " +
                  terms +
                  ' | limitTo: ' +
                  terms +
                  ".length - (lease.leaseMonths && lease.leaseMonths > 0 ? 1 : 0) : (lease.leaseMonths && lease.leaseMonths > 0 ? 1 : 0)' " +
                  (rowStyle ? " style='" + rowStyle + "'>" : '>')
                : "<tr ng-repeat='rentPeriod in " +
                  terms +
                  "' " +
                  (rowStyle ? " style='" + rowStyle + "'>" : '>')) +
              "<td ng-if='$index == 0'" +
              (cellStyle ? " style='" + cellStyle + "'>" : '>') +
              firstTermText +
              '</td>' +
              "<td ng-if='$index > 0 && $index + 1 < " +
              years +
              "'" +
              (cellStyle ? " style='" + cellStyle + "'>" : '>') +
              termText +
              '</td>' +
              "<td ng-if='$index + 1 == " +
              years +
              " && $index != 0'" +
              (cellStyle ? " style='" + cellStyle + "'>" : '>') +
              lastTermText +
              '</td>' +
              (addMonthsRowAtEnd
                ? "<td ng-if='$index == " +
                  years +
                  "'" +
                  (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                  termText +
                  '</td>'
                : '') +
              '<td ' +
              (cellStyle ? " style='" + cellStyle + "'>" : '>') +
              term1stValue +
              '</td>' +
              (column.length > 2
                ? '<td ' +
                  (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                  term2ndValue +
                  '</td>'
                : '') +
              (column.length > 3
                ? '<td ' +
                  (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                  term3rdValue +
                  '</td>'
                : '') +
              (column.length > 4
                ? '<td ' +
                  (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                  term4thValue +
                  '</td>'
                : '') +
              '</tr>' +
              '</tbody>'
          : /* OR */

            /*-------------------------------------------------------
           Multiple or single consolidated renewal term rent table
           -------------------------------------------------------*/
            "<tbody ng-repeat='renewal in lease.consolidatedRenewalInfo" +
            (showOnlyCpi
              ? ' | filter: { hasCpiRent: true } | limitTo:renewal.cpiAnchorYears'
              : '') +
            " track by $index' " +
            (specificRenewalTerm
              ? "ng-if='$index == " + specificRenewalTerm + "'"
              : '') +
            ' class="added" hl=\'' +
            terms +
            ".length' hl2='renewal.cpiAnchorYears' hl3='renewalValues' exclude='{{$index>0}}'>" +
            "<tr ng-repeat='renewalRentPeriod in renewal.consolidatedRenewalRentPeriods track by $index'>" +
            "<td "+leaseIf+"='renewalRentPeriod.startYear !== renewalRentPeriod.endYear'" +
            (cellStyle ? " style='" + cellStyle + "'>" : '>') +
            consolidatedRenewalTermsMultipleYearsText +
            '</td>' +
            "<td "+leaseIf+"='renewalRentPeriod.startYear === renewalRentPeriod.endYear'" +
            (cellStyle ? " style='" + cellStyle + "'>" : '>') +
            consolidatedRenewalTermsSingleYearText +
            '</td>' +
            '<td' +
            (cellStyle ? " style='" + cellStyle + "'>" : '>') +
            term1stValue +
            '</td>' +
            (column.length > 2
              ? '<td' +
                (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                term2ndValue +
                '</td>'
              : '') +
            (column.length > 3
              ? '<td' +
                (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                term3rdValue +
                '</td>'
              : '') +
            (column.length > 4
              ? '<td' +
                (cellStyle ? " style='" + cellStyle + "'>" : '>') +
                term4thValue +
                '</td>'
              : '') +
            '</tr>' +
            '</tbody>') +
        /*-------------------------------------------------------
         Empty table row
         -------------------------------------------------------*/
        "<tbody "+leaseIf+"='!" +
        years +
        "' hl='" +
        hlTag +
        "'>" +
        '<tr ' +
        (rowStyle ? " style='" + rowStyle + "'>" : '>') +
        '<td ' +
        (cellStyle ? "style='" + cellStyle + "'>" : '>') +
        '&nbsp;</td>' +
        '<td ' +
        (cellStyle ? "style='" + cellStyle + "'>" : '>') +
        '&nbsp;</td>' +
        (column.length > 2
          ? '<td' +
            (cellStyle ? " style='" + cellStyle + "'>" : '>') +
            '&nbsp;</td>'
          : '') +
        (column.length > 3
          ? '<td' +
            (cellStyle ? " style='" + cellStyle + "'>" : '>') +
            '&nbsp;</td>'
          : '') +
        (column.length > 4
          ? '<td' +
            (cellStyle ? " style='" + cellStyle + "'>" : '>') +
            '&nbsp;</td>'
          : '') +
        '</tr>' +
        '</tbody>' +
        '</table>';

      return result;
    },
    link: function($scope, element, attrs) {
      $scope.addFreeRentRows =
        $scope.addFreeRentRows || attrs.addFreeRentRows === 'true';
      $scope.addMonthsRowAtEnd =
        $scope.addMonthsRowAtEnd || attrs.addMonthsRowAtEnd === 'true';

      $scope.$on('rentValuesChanged', function(event, data) {
        if ($scope.addFreeRentRows) {
          $scope.insertFreeRows();
        }
        if ($scope.addMonthsRowAtEnd) {
          $scope.insertMonthRow();
        }
      });

      /*
       * Insert Free Rent Rows into rentRows
       */
      $scope.insertFreeRows = function() {
        var lease = $scope.lease;
        if (!lease.rentPeriods) return;

        lease.rentRows = [];
        for (var i = 0; i < lease.rentPeriods.length; i++) {
          var period = lease.rentPeriods[i];
          var startDate = moment(lease.existingLeaseExpirationDate)
            .add(i, 'year')
            .add(1, 'day');
          var endYearDate = moment(lease.existingLeaseExpirationDate).add(
            i + 1,
            'year',
          );

          //extended lease year at beginnung of term
          if (lease.hasRentAbatement && lease.hasFreeRentAddedToTerm)
            if (lease.freeRentExtendedAt == 'beginning') {
              endYearDate = moment(endYearDate.toDate()).add(
                lease.abatementDates.length,
                'month',
              );
              if (i > 0)
                startDate = moment(startDate.toDate()).add(
                  lease.abatementDates.length,
                  'month',
                );
            } else if (lease.freeRentExtendedAt == 'end') {
              if (i == lease.leaseYears - 1) {
                endYearDate = moment(endYearDate.toDate()).add(
                  lease.abatementDates.length,
                  'month',
                );
              }
            }

          var freeDates = $scope.lease.hasRentAbatement
            ? _.sortBy(
                _.filter(lease.abatementDates, function(date) {
                  return (
                    moment(date.date).isSame(startDate) ||
                    moment(date.date).isBetween(startDate, endYearDate)
                  );
                }),
                'date',
              )
            : [];

          for (var j = 0; j < freeDates.length; j++) {
            var freeMonth = freeDates[j].date;
            //push period of year
            if (startDate.isBefore(freeMonth)) {
              lease.rentRows.push({
                startDate: startDate.toDate(),
                endDate: moment(freeMonth)
                  .add(-1, 'day')
                  .toDate(),
                isFree: false,
                rentPerSqFoot: period.rentPerSqFoot,
                totalRent:
                  lease.hasRentAbatement && lease.hasFreeRentAddedToTerm
                    ? period.totalRent
                    : (period.totalRent / 12) * (12 - freeDates.length),
              });
            }

            //add month to free-rent period
            if (
              lease.rentRows.length > 1 &&
              lease.rentRows[lease.rentRows.length - 1].isFree
            ) {
              lease.rentRows[lease.rentRows.length - 1].endDate = moment(
                freeMonth,
              )
                .endOf('month')
                .startOf('day')
                .toDate();
            } else {
              //push free rent period
              lease.rentRows.push({
                startDate: moment(freeMonth).toDate(),
                endDate: moment(freeMonth)
                  .endOf('month')
                  .startOf('day')
                  .toDate(),
                isFree: true,
                isFirst: !_.some(lease.rentRows, function(e) {
                  return e.isFirst == true;
                }),
                rentPerSqFoot: 0,
                totalRent: 0,
              });
            }
            startDate = moment(freeMonth)
              .endOf('month')
              .startOf('day')
              .add(1, 'day');
          }

          //push the last period of year
          if (startDate.isBefore(endYearDate) || !startDate.isValid()) {
            lease.rentRows.push({
              startDate: startDate.toDate(),
              endDate: endYearDate.toDate(),
              isFree: false,
              rentPerSqFoot: period.rentPerSqFoot,
              totalRent:
                lease.hasRentAbatement && lease.hasFreeRentAddedToTerm
                  ? period.totalRent
                  : (period.totalRent / 12) * (12 - freeDates.length),
            });
          }
        }

        //consolidate rent rows
        for (var i = lease.rentRows.length - 1; i > 0; i--) {
          var period = lease.rentRows[i];
          var prevPeriod = lease.rentRows[i - 1];
          if (
            period.rentPerSqFoot == prevPeriod.rentPerSqFoot &&
            (period.totalRent == prevPeriod.totalRent ||
              (isNaN(period.totalRent) && isNaN(prevPeriod.totalRent)))
          ) {
            prevPeriod.endDate = period.endDate;
            lease.rentRows.splice(i, 1);
          }
        }
      };

      $scope.insertMonthRow = function() {
        if (
          $scope.lease.rentPeriods &&
          $scope.lease.rentPeriods.length === $scope.lease.leaseYears &&
          $scope.lease.leaseMonths
        ) {
          $scope.lease.rentPeriods.push({});
        } else if (
          $scope.lease.rentPeriods &&
          $scope.lease.rentPeriods.length > $scope.lease.leaseYears &&
          !$scope.lease.leaseMonths
        ) {
          $scope.lease.rentPeriods.splice(-1, 1);
        }
      };

      if (!$scope.lease || !$scope.lease.rentPeriods) return;
      $scope.init = (function() {
        if ($scope.addFreeRentRows) {
          $scope.insertFreeRows();
        }
        if ($scope.addMonthsRowAtEnd) {
          $scope.insertMonthRow();
        }
      })();
    },
  };
});
