angular.module('LeasePilot').directive('printPdf', [
  '$rootScope',
  'ApiService',
  'ProcessStatusService',
  function($rootScope, ApiService, ProcessStatusService) {
    return {
      restrict: 'E',
      templateUrl: 'print_pdf.html',
      link: function($scope, element, ettrs) {
        $scope.processing = false;
        $scope.eventName = 'printPDF';
        $scope.printErrorMessage =
          'Oops... something went wrong with the print process, please wait a couple of seconds and try again.';

        var iframe = angular.element('<iframe/>', {
          name: 'downloadPDF',
          style:
            'position: absolute; top: 0; left: 0; width: 1px; height: 1px; z-index: -1; opacity: 0;',
          'event-name': $scope.eventName,
        });

        $scope.$on($scope.eventName, function(event, data) {
          console.log(new Date().getTime() + ' on event: ', $scope.eventName);
          console.log(new Date().getTime() + ' data ', data);
          ApiService.htmlToPdf(data.file, window.ASSETS_DESIRED_STYLES).then(
            function(response) {
              $scope.processing = false;
              ProcessStatusService.end('print');
              $scope.safeApply();
              console.log(
                new Date().getTime() + ' convert to PDF finished successfully',
              );
              openPrintWizard(response.data);
            },
            function(error) {
              $scope.processing = false;
              ProcessStatusService.end('print');
              $scope.safeApply();
              alert($scope.printErrorMessage);
            },
            function() {},
          );
        });

        $scope.printPDF = function() {
          window.track.event(new PrintDocumentEvent({
            context: $rootScope.getContext(),
          }));

          ProcessStatusService.start("print");
          $scope.processing = true;

          if (window.lease) {
            downloadManager.prepareDownload("printPDF");
          } else {
            var src =
              "/" +
              pluralize($rootScope.lease.type).toLowerCase() +
              "/" +
              $rootScope.lease.id;
            angular
              .element('iframe[event-name="' + $scope.eventName + '"]')
              .remove();
            angular.element(iframe).attr("src", src);
            angular.element("body").append(iframe);
          }
        };

        function openPrintWizard(data) {
          var file = new Blob([data], { type: 'application/pdf' });
          var url = URL.createObjectURL(file);
          var iframe = document.createElement('iframe');
          document.body.appendChild(iframe);
          iframe.style.display = 'none';
          iframe.onload = function() {
            setTimeout(function() {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
          iframe.src = url;
        }
      },
    };
  },
]);
