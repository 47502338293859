angular.module('LeasePilot').filter('toVowelA', function() {
  return function(input) {
    if (input) {
      if (input.toLowerCase() == 'utah') {
        return 'a';
      } else if (isVowel(input.charAt(0))) {
        return 'an';
      } else {
        return 'a';
      }
    } else {
      return 'a';
    }
  };
});
