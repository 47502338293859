angular.module("LeasePilot").service("BuildingHelperService", [
  "$http",
  function($http) {
    async function available(name) {
      const result = await $http({
        method: "post",
        url: `/api/buildings/available`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          name
        }
      });

      if (result.status === 200) {
        return result.data.available;
      } else {
        return false;
      }
    }

    function validateName(name) {
      const nameRegex = /^[A-Za-z0-9_@&'(),./ -]+$/;

      const isValid = {
        pattern: nameRegex.test(name),
        length: name.length >= 1 && name.length <= 255,
      }
      
      isValid.all = isValid.pattern && isValid.length;

      return isValid;
    }

    async function validate({
      type = null,
      name = null,
      dashboardName = null,
      landlordName = null,
      landlordEntityType = null,
      landlordStateOfFormation = null,
      streetAddress = null,
      city = null,
      county = null,
      state = null,
      zipCode = null,
      municipalityType = null,
      buildingOfficeArea = null,
    }) {
      const isValid = {
        all: true
      };

      if (name !== null) {
        isValid.name = validateName(name);
        isValid.all &&= isValid.name.all;

        if (type === "create") {
          isValid.nameAvailable = await available(name);
          isValid.all &&= isValid.nameAvailable;
        }
      }

      if (dashboardName !== null) {
        isValid.dashboardName = validateName(dashboardName);
        isValid.all &&= isValid.dashboardName.all;
      }

      isValid.landlordName = true;
      isValid.landlordEntityType = true;
      isValid.landlordStateOfFormation = true;
      isValid.streetAddress = true;
      isValid.city = true;
      isValid.county = true;
      isValid.state = true;
      isValid.zipCode = true;
      isValid.municipalityType = true;
      isValid.buildingOfficeArea = true;

      return isValid;
    }

    function create({
      name,
      dashboardName,
      landlordName,
      landlordEntityType,
      landlordStateOfFormation,
      streetAddress,
      city,
      county,
      state,
      zipCode,
      municipalityType,
      buildingOfficeArea,
    }) {
      return $http({
        method: "post",
        url: `/api/buildings`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          name,
          dashboardName,
          landlordName,
          landlordEntityType,
          landlordStateOfFormation,
          streetAddress,
          city,
          county,
          state,
          zipCode,
          municipalityType,
          buildingOfficeArea,
        }
      });
    }

    function update({
      id,
      name,
      dashboardName,
      landlordName,
      landlordEntityType,
      landlordStateOfFormation,
      streetAddress,
      city,
      county,
      state,
      zipCode,
      municipalityType,
      buildingOfficeArea,
    }) {
      return $http({
        method: "put",
        url: `/api/buildings/${id}`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          id,
          name,
          dashboardName,
          landlordName,
          landlordEntityType,
          landlordStateOfFormation,
          streetAddress,
          city,
          county,
          state,
          zipCode,
          municipalityType,
          buildingOfficeArea,
        }
      });
    }

    return {
      available,
      validate,
      create,
      update
    };
  }
]);
