const hiddenClassName = ".lp-hide";
const windowParamName = "applyFilterVisibleState";

const documentQuerySelectorAllOriginal = Object.getOwnPropertyDescriptor(
  Document.prototype,
  "querySelectorAll"
);
Object.defineProperty(Document.prototype, "querySelectorAll", {
  ...documentQuerySelectorAllOriginal,
  value: function(query, onlyVisibleElements = false) {
    onlyVisibleElements = onlyVisibleElements || window[windowParamName];
    let list = documentQuerySelectorAllOriginal.value.call(this, query);

    if (onlyVisibleElements) {
      return filterVisible(list);
    } else {
      return list;
    }
  }
});

const documentQuerySelectorOriginal = Object.getOwnPropertyDescriptor(
  Document.prototype,
  "querySelector"
);
Object.defineProperty(Document.prototype, "querySelector", {
  ...documentQuerySelectorOriginal,
  value: function(query, onlyVisibleElements = false) {
    onlyVisibleElements = onlyVisibleElements || window[windowParamName];
    if (onlyVisibleElements) {
      let list = document.querySelectorAll(query, true);
      if (list.length !== 0) {
        return list[0];
      } else {
        return null;
      }
    } else {
      return documentQuerySelectorOriginal.value.call(this, query);
    }
  }
});


//CharacterData.prototype.remove


// const CharacterDataOriginal = Object.getOwnPropertyDescriptor(
//   CharacterData.prototype,
//   "remove"
// );
// Object.defineProperty(CharacterData.prototype, "remove", {
//   ...CharacterDataOriginal,
//   value: function(arg) {
//       console.log(this.textContent);
    
//       return CharacterDataOriginal.value.call(this,arg);
    
//   }
// });

// //Node.prototype.removeChild

// const removeChildOriginal = Object.getOwnPropertyDescriptor(
//   Node.prototype,
//   "removeChild"
// );
// Object.defineProperty(Node.prototype, "removeChild", {
//   ...removeChildOriginal,
//   value: function(arg) {
//     if(window.isDebug){
//       if(arg.textContent.indexOf(', an') !== -1){
//         debugger;
//       }
//       console.log(arg);
//     }
    
//       return removeChildOriginal.value.call(this,arg);
    
//   }
// });

Document.prototype.querySelectorWithInstance = function(query,instance){
  if (!instance) {
    return this.querySelector.call(this,[query]);
  }
  else{
    const list = this.querySelectorAll.call(this,[query]);
    return list[instance];
  }
}


async function waitForSingleFreeTextToCompile(freeText,element){
  if(!element){
    element = document;
  }
  const waitForFreeTextCompile = function (resolve, reject) {
    let testCounter = 0;

    const isReady = () => {
      const freeTextReady = element.querySelector(`[free-text="${freeText}"] .EOCE`);
      
      if (freeTextReady) {
        return true;
      }
    }

    const reTest = () => {
      testCounter++;
      if (isReady()) {
        resolve(true);
      } else if (testCounter < 100) {
        setTimeout(reTest, 20);
      } else {
        resolve(false);
      }
    };

    if (isReady()) {
      resolve(true);
    } else {
      setTimeout(reTest, 20);
    }
  }
  return new Promise(waitForFreeTextCompile);
  
}

document.waitForFreeTextCompile = async function(freeTextList,element){
  if( Array.isArray(freeTextList))
  {
    const arr = [];
    freeTextList.forEach((ft)=>{
      arr.push(waitForSingleFreeTextToCompile(ft,element));
    })
    return Promise.all(arr);

  }
  else{
    return waitForSingleFreeTextToCompile(freeTextList,element);

  }
 
}
