angular.module('LeasePilot').directive('showExportErrorsModal', [
  '$mdDialog',
  function($mdDialog) {
    return {
      restrict: 'E',
      scope: {
        targetStatus: '@',
        errorType: '@',
        errors: '=',
      },
      templateUrl: 'show_export_errors.html',
      link: function(scope) {
        scope.showDetails = false;

        scope.cancel = function() {
          $mdDialog.cancel();
        };
        scope.toggleDetails = function() {
          scope.showDetails = !scope.showDetails;
        };

        scope.userError = function() {
          return _.includes(
            ['export_failed', 'conversion_failed'],
            scope.errorType,
          );
        };
      },
    };
  },
]);
