angular.module('LeasePilot').directive('existingGuarantors', [
  function() {
    return {
      restrict: 'E',
      scope: {
        lease: '=',
        changing: '=',
        changed: '=',
      },
      link: function(scope, element, attrs) {
        function getIndex(guarantorType, item) {
          return _.indexOf(
            scope.lease.existingGuarantorInfo[guarantorType],
            item,
          );
        }

        function getPlainText(scope, model, text) {
          if (!text) {
            return '__________';
          } else {
            return (
              '<span class=' +
              (model == scope.changing ? '"adding"' : '"added"') +
              '>' +
              text +
              '</span>'
            );
          }
        }

        function getDate(scope, model, date) {
          if (!date) {
            return '__________';
          } else {
            return (
              '<span class=' +
              (model == scope.changing ? '"adding"' : '"added"') +
              '>' +
              moment(date).format('MMMM D, YYYY') +
              '</span>'
            );
          }
        }

        function createJoindArray(individuals, marriedCouples, entities) {
          return _.filter(_.union(individuals, marriedCouples, entities), {
            hasBeenReleased: false,
          });
        }

        function createLanguageByType(scope, item) {
          var result, itemModel;

          if (item.type === 'individual') {
            itemModel =
              'lease.existingGuarantorInfo.individuals[' +
              getIndex('individuals', item) +
              ']';
            result = _.string.sprintf(
              '%s guaranteed and continues to guarantee Tenant’s obligations under the Lease pursuant to that certain Guaranty of Lease, dated %s (the “%s Guaranty”)',
              getPlainText(scope, itemModel + '.name', item.name),
              getDate(scope, itemModel + '.guarantyDate', item.guarantyDate),
              getPlainText(scope, itemModel + '.nickname', item.nickname),
            );
          } else if (item.type === 'married') {
            itemModel =
              'lease.existingGuarantorInfo.marriedCouples[' +
              getIndex('marriedCouples', item) +
              ']';
            result = _.string.sprintf(
              '%s and %s, husband and wife, guaranteed and continue to guarantee Tenant’s obligations under the Lease pursuant to that certain Guaranty of Lease, dated %s (the “%s Guaranty”)',
              getPlainText(scope, itemModel + '.name1', item.name1),
              getPlainText(scope, itemModel + '.name2', item.name2),
              getDate(scope, itemModel + '.guarantyDate', item.guarantyDate),
              getPlainText(scope, itemModel + '.nickname', item.nickname),
            );
          } else if (item.type === 'entity') {
            itemModel =
              'lease.existingGuarantorInfo.entities[' +
              getIndex('entities', item) +
              ']';
            result = _.string.sprintf(
              '%s guaranteed and continues to guarantee Tenant’s obligations under the Lease pursuant to that certain Guaranty of Lease, dated %s (the “%s Guaranty”)',
              getPlainText(scope, itemModel + '.name', item.name),
              getDate(scope, itemModel + '.guarantyDate', item.guarantyDate),
              getPlainText(scope, itemModel + '.nickname', item.nickname),
            );
          }

          return result;
        }

        scope.$watch(
          '[changed, changing, lease.existingGuarantorInfo]',
          function(newValue, oldValue) {
            var lease = scope.lease;
            var result = '______________________________';
            var list;
            var sentences = [];

            if (!lease) {
              return;
            }

            var hasExistingIndividualGuarantors =
              lease.existingGuarantorInfo.individuals.length > 0;
            var hasExistingMarriedCouplesGuarantors =
              lease.existingGuarantorInfo.marriedCouples.length > 0;
            var hasExistingEntitiesGuarantors =
              lease.existingGuarantorInfo.entities.length > 0;
            var hasExistingGuarantors =
              hasExistingIndividualGuarantors ||
              hasExistingMarriedCouplesGuarantors ||
              hasExistingEntitiesGuarantors;
            var individuals = lease.existingGuarantorInfo.individuals;
            var marriedCouples = lease.existingGuarantorInfo.marriedCouples;
            var entities = lease.existingGuarantorInfo.entities;

            if (!hasExistingGuarantors) {
              element.html(result);
              return;
            }

            // Before concatenating the  two arrays, lets indicate where each item came from
            _.forEach(individuals, function(item) {
              item.type = 'individual';
            });
            _.forEach(marriedCouples, function(item) {
              item.type = 'married';
            });
            _.forEach(entities, function(item) {
              item.type = 'entity';
            });

            // Create the joined array
            list = createJoindArray(individuals, marriedCouples, entities);

            if (list.length === 0) {
              element.html(result);
              return;
            }

            // Start building each sentence seperately
            var curr;
            for (var i = 0; i < list.length; i++) {
              curr = list[i];
              sentences.push(createLanguageByType(scope, curr));
            }

            // This is the result language
            result = _.string.sprintf(
              '%s.',
              _.string.toSentence(sentences, ', ', ' and '),
            );

            // Wrap the result with the contenteditable markup
            result = _.string.sprintf(
              "<span contenteditable='false'>%s</span>",
              result,
            );
            element.html(result);
          },
          true,
        );
      },
    };
  },
]);
