/* eslint-disable */

(function() {
  function ClausebookManager() {
    let $scope = null;
    let $mdDialog = null;
    
    this.init = function(_scope, _mdDialog) {
      $scope = _scope;
      $mdDialog = _mdDialog;

      $scope.$on('downloadSelectionResult', captureClauseComplete);

      try {
        if (!window.lease) {
          return;
        }
        
        const formConfig = JSON.parse(window.lease.form.config);

        if (formConfig && formConfig.clausebook) {
          const clausebookConfig = formConfig.clausebook;
          const clausebookFrame = document.getElementById('clausebook');
          const workspaceId = clausebookConfig.workspaceId;
          const clausebookId = clausebookConfig.clausebookId;
          const email = clausebookConfig.email || window.user.email;
          clausebookFrame.setAttribute("data-src", `${window._clausebook_base_url}/leasepilot.html?email=${email}&workspace=${workspaceId}&clausebook=${clausebookId}`);

          window.addEventListener("message", (event) => {
            if (event.origin !== window._clausebook_base_url) {
              return;
            }
          
            switch (event.data.type) {
              case "SIGN_UP": {
                this.signup(event);
                break;
              }
              case "CAPTURE": {
                this.captureClause(event);
                break;
              }
              case "INSERT": {
                this.insertClause(event);
                break;
              }
              case "PREVIEW": {
                this.previewClause(event);
                break;
              }
              case "PREVIEW_UNSAVED": {
                this.previewNewClause(event);
                break;
              }
            }
          }, false);
        }
      } catch(e) {
        // Do nothing.
      }
    }

    function captureClauseComplete(event, data){
      const iframe = document.getElementById('clausebook');

      const message = {
        type: "CAPTURE_COMPLETE",
        payload: {
          html: null,
          ooxml: null,
        }
      };

      if (data.data) {
        message.payload.html = data.data.html;
        message.payload.ooxml = data.data.rtf;
      }

      iframe.contentWindow.postMessage(message, window._clausebook_base_url);
    }

    this.signup = function(event) {
      window.track.event(new SignUpToClausebook({}));
      
      const email = window.user.email;
      const page = window.open(`/clausebook/signup?email=${email}`, "_blank");

      const bc = new BroadcastChannel("clausebook_channel");
      bc.onmessage = (event) => {
        if (event.data.type === "SIGN_UP_COMPLETE") {
          page.close();
          
          const iframe = document.getElementById('clausebook');

          const message = {
            type: "SIGN_UP_COMPLETE",
            payload: {}
          };

          iframe.contentWindow.postMessage(message, window._clausebook_base_url);
        }
      };
    }

    this.captureClause = function(event) {
      window.track.event(new CaptureClause({}));
      
      downloadManager.downloadSelection();
    }

    this.insertClause = function(event) {
      window.track.event(new InsertClause({}));
      
      const { html, ooxml } = event.data.payload;

      clipboardManager.insertClause(html, ooxml, null);
    }

    this.previewClause = function(event) {
      window.track.event(new PreviewClause({
        saved: true,
      }));
      
      window.__CLAUSE_PREVIEW__ = {
        saved: true,
        ...event.data.payload,
      };

      $mdDialog.show({
        template:
          `<md-dialog><preview-clause></preview-clause></md-dialog>`,
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
      });
    }

    this.previewNewClause = function(event) {
      window.track.event(new PreviewClause({
        saved: false,
      }));
      
      window.__CLAUSE_PREVIEW__ = {
        saved: false,
        ...event.data.payload,
      };

      $mdDialog.show({
        template:
          `<md-dialog><preview-clause></preview-clause></md-dialog>`,
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
      });
    }
  }

  window.clausebookManager = new ClausebookManager();
})();
  