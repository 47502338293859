angular.module('LeasePilot').directive('deleteUserConfirmationModal', [
  '$rootScope',
  '$mdDialog',
  '$http',
  'UsersService',
  function(
    $rootScope,
    $mdDialog,
    $http,
    UsersService,
  ) {
    return {
      restrict: 'E',
      templateUrl: 'delete_user_confirmation_modal.html',
      scope: {
        id: "@",
        email: "@",
      },
      link: function($scope) {
        // 
        $scope.delete = function() {
          new UsersService({ id: $scope.id }).delete().then(
            function() {
              $mdDialog.cancel();
              window.location.reload();
            },
            function(error) {
              $scope.error = error.data.error;
              throw error;
            },
          );
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
        };
      },
    };
  },
]);
