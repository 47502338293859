angular.module('LeasePilot').directive('editBuildingLanguageModal', [
  '$rootScope',
  '$mdDialog',
  '$compile',
  '$sce',
  'BuildingService',
  function($rootScope, $mdDialog, $compile, $sce, BuildingService) {
    return {
      restrict: 'E',
      templateUrl: 'edit_building_language_modal.html',
      scope: {
        bsName: '@',
        resourceUrl: '@',
      },
      link: function(scope, element) {
        var appScope = $rootScope.findAppScope(scope);
        scope.firstLineData = '{{ firstLineData }}';
        scope.secondLineData = '{{ secondLineData }}';
        scope.title = _.startCase(scope.bsName);
        initBuildings();
        var currBuilding;

        function initBuildings() {
          BuildingService.query({
            companyId: $rootScope.editedCompanyId,
            includeBlanks: true,
          }).then(function(response) {
            scope.buildings = response;
            currBuilding = scope.building = response.find(function(building) {
              return building.id == $rootScope.buildingId;
            });
          });
        }

        scope.changeBuilding = async function(newBuilding) {
          if (scope.changed) {
            // prevent change building
            scope.saveChangesAlert = true;
            scope.building = currBuilding;
          } else {
            currBuilding = scope.building = newBuilding;

            let updatedAt = null;
            
            if (scopeVar.lease && scopeVar.lease.form) {
              updatedAt = new Date(scopeVar.lease.form.updatedAt).getTime();
            }
            
            const specificUrl = scope.building.specificUrl;
            const parts = specificUrl.split('/');

            if (parts.length == 2) {
              const buildingFileUrl = parts[1];

              const newSpecificUrl =
                scopeVar.ASSETS_NEW_BUILDING_DIR_URL +
                '/' +
                buildingFileUrl +
                '?v=' +
                updatedAt;

              $rootScope.isBuildingLanguageNeeded = true;

              try {
                const response = await fetch(newSpecificUrl);

                if (!response.ok) {
                  window.isBuildingLanguageNewPath = false;
                } else {
                  window.isBuildingLanguageNewPath = true;
                }
              } catch (error) {
                window.isBuildingLanguageNewPath = false;
              }
            }
            
            scope.resourceUrl = appScope.setBuildingUrl(
              scope.building.specificUrl,
            );
          }
        };

        scope.changed = false;

        scope.getOverrideText = function() {
          return $sce.trustAsHtml(
            $rootScope.buildingOverrides[scope.building.id][scope.bsName].text,
          );
        };

        scope.hasOverride = function() {
          if (!$rootScope.buildingOverrides || !scope.building) {
            return false;
          }

          var building = $rootScope.buildingOverrides[scope.building.id];
          return building && building[scope.bsName] ? true : false;
        };

        function getProcessedHtml() {
          var text = '';
          var className = 'override-color';
          var color = 'rgb(0, 156, 33)';
          var innerNode;

          $('span[style*="color: ' + color + '"]')
            .addClass(className)
            .css('color', '');

          innerNode = $('#edit-building-language .override-container');
          if (innerNode) {
            text = $(innerNode).html();
          }

          return text;
        }

        function addBuildingOverride(data) {
          $rootScope.hasNewBuildingOverride = true;
          $rootScope.buildingOverrides = $rootScope.buildingOverrides || {};
          $rootScope.buildingOverrides[data.buildingId] =
            $rootScope.buildingOverrides[data.buildingId] || {};
          $rootScope.buildingOverrides[data.buildingId][data.bsName] = data;
        }

        scope.change = function(event) {
          // TODO - check if new text is different from original (same mechanism as
          // contenteditable directive - 'defaultProvision')
          scope.changed = true;
          var targetElement = event.currentTarget;
          var node = $(targetElement)
            .children()
            .filter(function() {
              // We skip the markers all-together
              return !$(this).is('.SOCE, .PLACEHOLDER, .EOCE');
            });

          if ($(targetElement).is('ng-include') && node.length)
            node.addClass('override-container');
        };

        scope.discard = function() {
          $compile(element)(scope);
        };

        scope.save = function() {
          var text = getProcessedHtml();
          var data = {
            text: text,
            formId: $rootScope.lease.form.id,
            buildingId: scope.building.id,
            bsName: scope.bsName,
          };

          addBuildingOverride(data);
          scope.changed = false;
          scope.saveChangesAlert = false;
        };

        function confirmLeaveWithoutSaving() {
          var confirm = $mdDialog.confirm({
            skipHide: true,
            template:
              '<div class="confirm-modal md-dialog-content">' +
              '<p class="title" >Are you sure you want to leave without saving changes?</p>' +
              '<div class="modal-actions">' +
              '<button class="btn blue-outline" ng-click="dialog.hide()">Yes</button>' +
              '<button class="btn blue active" ng-click="dialog.abort()">No</button>' +
              '</div>' +
              '</div>',
          });

          $mdDialog.show(confirm).then(
            function confirmLeaving() {
              $mdDialog.cancel();
            },
            function preventLeaving() {
              // Do nothing. leave the edit dialog open.
            },
          );
        }

        scope.cancel = function() {
          if (scope.changed) {
            confirmLeaveWithoutSaving();
          } else {
            $mdDialog.cancel();
          }
        };
      },
    };
  },
]);
