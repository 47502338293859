const hiddenClassName = ".lp-hide-element";
const visibleElementClass = ".lp-show-element";
const windowParamName = "applyFilterVisibleState";

$.prototype.__find = $.prototype.find;
$.prototype.find = function(selector) {
  let onlyVisibleElements = window[windowParamName];
  let result = this.__find(selector);

  if (onlyVisibleElements) {
    result = result.filter((index, node) => {
      return node.closest(hiddenClassName) === null;
    });
  }

  return result;
};
