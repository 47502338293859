angular.module('LeasePilot').factory('OverridesService', [
  'railsResourceFactory',
  'railsSerializer',
  function(railsResourceFactory, railsSerializer) {
    return railsResourceFactory({
      url: '/api/overrides',
      name: 'override',
    });
  },
]);
