angular.module('LeasePilot').filter('toWords', function() {
  const commonFractions = [];
  commonFractions[0.25] = 'one quarter';
  commonFractions[0.5] = 'one half';
  commonFractions[0.75] = 'three quarters';

  return function(input, humanizeCommonFractions) {
    if (input === null || input === undefined) {
      return null;
    }

    if (input == 0) {
      return 'zero';
    }

    if (humanizeCommonFractions !== 'true') {
      return toWords(input).trim();
    }

    let number = Math.trunc(input);
    let fraction = Math.abs(input - number);
    let result;

    if (!fraction) {
      result = toWords(number);
    } else {
      if (Math.abs(number) < 1) {
        if (commonFractions[input]) {
          result = commonFractions[input];
        } else {
          result = toWords(input);
        }
      } else {
        if (commonFractions[fraction]) {
          result = toWords(number);
          result = `${result.trim()} and ${commonFractions[fraction]}`;
        } else {
          result = toWords(input);
        }
      }
    }

    result = result.trim();

    return result;
  };
});
