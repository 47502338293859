angular.module('LeasePilot').filter('noFraction', [
  '$filter',
  '$locale',
  function(filter, locale) {
    var currencyFilter = filter('currency');
    var formats = locale.NUMBER_FORMATS;
    return function(amount, currencySymbol) {
      var value = currencyFilter(amount, currencySymbol);
      var sep = value.indexOf(formats.DECIMAL_SEP);
      // remove leading currency sign
      if (amount >= 1) {
        return value.substring(1, sep);
      } else if (amount >= 0 && amount < 1) {
        return '0';
      }
      return '-';
    };
  },
]);
