angular.module('LeasePilot').directive('leaseMenu', [
  '$rootScope',
  function($rootScope) {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'lease_menu.html',
      link: function(scope, element, attrs) {},
    };
  },
]);
