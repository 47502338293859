angular.module('LeasePilot').factory('SalesforceOpportunitiesService', [
  'railsResourceFactory',
  function(railsResourceFactory) {
    var resource = railsResourceFactory({
      url: '/api/salesforce_opportunities',
      name: 'salesforceOpportunity', // NOTE: why is this parameter needed?
    });

    return resource;
  },
]);
