angular.module('LeasePilot').directive('autocomplete', [
  function() {
    return {
      restrict: 'A',
      scope: {
        autocomplete: '=',
      },
      link: function(scope, element, attrs) {
        if (!scope.autocomplete) {
          const field = attrs.ngModel;
          $(element).attr('autocomplete', field);
        }
      },
    };
  },
]);
