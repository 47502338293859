angular.module('LeasePilot').controller('TopBarController', [
  '$scope',
  'CurrentUserService',
  'UsersService',
  '$rootScope',
  '$mdDialog',
  function($scope, CurrentUserService, UsersService, $rootScope, $mdDialog) {
    $scope.userAction = {};

    $rootScope.user = window.user;
    $rootScope.users = window.users;
    $rootScope.partners = window.partners;
    $rootScope.accounts = window.accounts;
    $rootScope.buildings = window.buildings;

    if ($rootScope.accounts.length > 1) {
      $rootScope.account = $rootScope.accounts.filter(acc => acc.id == window.user.company.id)[0].id;
    }

    if (window.user.company.companyProfile.config?.warning) {
      $scope.warning = window.user.company.companyProfile.config.warning;

      $scope.showWarning = function() {
        alert(window.user.company.companyProfile.config.warning.message);
      };

      $scope.closeWarning = function() {
        $scope.safeApply(() => {
          $scope.warning = null;
        });
      };
    }

    $scope.selectAccount = function(accountId) {
      document.cookie = `company_id=${accountId}; path=/;`;
      localStorage.removeItem("dashboard_filter");
      window.location.href = '/';
    };
    
    $scope.perform = function() {
      if ($scope.userAction) {
        switch ($scope.userAction) {
          case 'ChangePassword':
            window.location.href = '/users/edit';
            break;
          case 'TwoFactor':
              window.location.href = '/two_factor_settings/new';
              break;

          case 'AdminArea':
            window.location.href = '/manage/companies';
            break;

          case 'formManagement':
            window.location.href = '/manage_forms/forms';
            break;

          case 'usersManagement':
            window.location.href = '/manage_forms/users';
            break;

          case 'buildingsManagement':
            window.location.href = '/manage_forms/buildings';
            break;

          case 'SignOut':
            $.ajax({
              method: 'DELETE',
              url: '/users/sign_out',
            }).done(function() {
              window.track.event(new LogoutEvent());
              sessionStorage.clear();
              document.cookie = "company_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              window.location.href = '/users/sign_in';
            });
        }

        $scope.userAction = undefined;
      }
    };

    $scope.showCreateVersionDialog = function(event) {
      $mdDialog.show({
        template:
          '<md-dialog><new-version-modal></new-version-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
      });
    };
  },
]);
