angular.module('LeasePilot').directive('quote', [
  '$compile',
  function($compile) {
    return {
      scope: {
        text: '=',
      },
      link: function(scope, element, attrs) {
        element.append($(scope.text));
      },
    };
  },
]);
