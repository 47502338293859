angular.module('LeasePilot').directive('connectivityNotice', [
  '$window',
  function($window) {
    return {
      restrict: 'E',
      scope: {
        serverRunning: '=',
      },
      templateUrl: 'connectivity_notice_directive.html',
      link: function(scope) {
        scope.isConnected = $window.navigator.onLine;
        scope.serverRunning = true;

        $window.addEventListener(
          'offline',
          function() {
            scope.isConnected = false;
            scope.$digest();
          },
          false,
        );

        $window.addEventListener(
          'online',
          function() {
            scope.isConnected = true;
            scope.$digest();
          },
          false,
        );
      },
    };
  },
]);
