/* eslint-disable */

(function() {
  function CustomEditorManager() {
    let $scope = null;
    let $filter = null;
    let $compile = null;

    function createDOMElement(container, item) {
      let element = null;
      let prefix = "";
      let suffix = "";
      let ref = item.ref;
      let label = item.label;

      if (ref && ref.indexOf("$index") !== -1) {
        ref = ref
          .replace("[$parent.$index]", "[{{$parent.$index}}]")
          .replace("[$index]", "[{{$index}}]");
      }

      if (label.indexOf("$index") !== -1) {
        label = `{{ ${label} }}`;
      }

      // Create element based on type
      switch (item.type) {
        case "card":
          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <div class="collapse-card" data-id="${$filter("titleize")(label)}">
                <div class="collapse-card__heading">
                  <div class="collapse-card__title">
                    <span>
                      ${label}
                    </span>
                  </div>
                </div>
                <div class="collapse-card__body">
                </div>
              </div>
            </div>
          `)[0];

          container = element.querySelector(".collapse-card__body");
          break;
        case "text":
          if (item.prefix) {
            prefix = `<span class="unit">${item.prefix}</span>`;
          }

          if (item.suffix) {
            suffix = `<span class="unit">${item.suffix}</span>`;
          }

          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <editor-control for="${item.ref}" class="editor-control">
                <div class="input-container">
                  <label for="${item.ref}">${label}</label>
                  <div class="input-with-unit">
                    ${prefix}
                    <input type="text" autocomplete="${item.ref}" id="${item.ref}" ng-change="update(); updateCurrent('${ref}');" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 50, 'blur': 0 }, timezone: 'UTC' }" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                    ${suffix}
                  </div>
                </div>
              </editor-control>
            </div>
          `)[0];
          break;
        case "textarea":
            if (item.prefix) {
              prefix = `<span class="unit">${item.prefix}</span>`;
            }
  
            if (item.suffix) {
              suffix = `<span class="unit">${item.suffix}</span>`;
            }
  
            element = $(`
              <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
                <editor-control for="${item.ref}" class="editor-control">
                  <div class="input-container">
                    <label for="${item.ref}">${label}</label>
                    <div class="input-with-unit">
                      ${prefix}
                      <textarea id="${item.ref}" style="height: 85px;" ng-change="update(); updateCurrent('${ref}');" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 50, 'blur': 0 }, timezone: 'UTC' }" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                      ${suffix}
                    </div>
                  </div>
                </editor-control>
              </div>
            `)[0];
            break;
        case "list":
          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <div class="row remove-space">
                <div class="input-container">
                  <div class="row">
                    <label class="title col m10"><b>${label}</b></label>
                    <md-icon class="col m1 add-item remove-angular-border"
                      ng-click="${item.ref}.push({}); update(); updateCurrent('${item.ref}.length');" ng-hide="${item.disabled || false}">
                    </md-icon>
                  </div>
                </div>
                <div class="background col m12">
                  <div class="repeat-container" ng-repeat="record in ${item.ref} track by $index">
                    <div class="row">
                      <div class="col m1 delete-container ng-show">
                        <md-icon class="delete-item remove-angular-border material-icons" ng-click="${item.ref.replace("$index", "$parent.$index")}.splice($index, 1); update(); updateCurrent('${item.ref.replace("$index", "$parent.$index")}.length');" ng-hide="${item.disabled || false}">
                        </md-icon>
                      </div>
                      <div class="col m10 repeat-list"></div>
                    </div>
                    <hr class="list-divider" ng-show="!$last"/>
                  </div>
                </div>
              </div>
            </div>
          `)[0];

          container = element.querySelector(".repeat-container .repeat-list");
          break;
        case "number":
          if (item.prefix) {
            prefix = `<span class="unit">${item.prefix}</span>`;
          }

          if (item.suffix) {
            suffix = `<span class="unit">${item.suffix}</span>`;
          }

          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <editor-control for="${item.ref}" class="editor-control">
                <div class="input-container">
                  <label for="${item.ref}">${label}</label>
                  <div class="input-with-unit">
                    ${prefix}
                    <input type="number" autocomplete="${item.ref}" id="${item.ref}" ng-change="update(); updateCurrent('${ref}');" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 50, 'blur': 0 }, timezone: 'UTC' }" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                    ${suffix}
                  </div>
                </div>
              </editor-control>
            </div>
          `)[0];
          break;
        case "date":
          if (item.prefix) {
            prefix = `<span class="unit">${item.prefix}</span>`;
          }

          if (item.suffix) {
            suffix = `<span class="unit">${item.suffix}</span>`;
          }

          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <editor-control for="${item.ref}">
                <div class="input-container">
                  <label for="${item.ref}">${label}</label>
                  <div class="input-with-unit">
                    ${prefix}
                    <input type="date" autocomplete="${item.ref}" id="${item.ref}" ng-change="${ref} = fixDate('${ref}'); update(); updateCurrent('${ref}');" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 50, 'blur': 0 }, timezone: 'UTC' }" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                    ${suffix}
                  </div>
                </div>
              </editor-control>
            </div>
          `)[0];
          break;
        case "datetime":
          if (item.prefix) {
            prefix = `<span class="unit">${item.prefix}</span>`;
          }

          if (item.suffix) {
            suffix = `<span class="unit">${item.suffix}</span>`;
          }

          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <editor-control for="${item.ref}">
                <div class="input-container">
                  <label for="${item.ref}">${label}</label>
                  <div class="input-with-unit">
                    ${prefix}
                    <input type="datetime-local" autocomplete="${item.ref}" id="${item.ref}" ng-change="update(); updateCurrent('${ref}');" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 50, 'blur': 0 }, timezone: 'UTC' }" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                    ${suffix}
                  </div>
                </div>
              </editor-control>
            </div>
          `)[0];
          break;
        case "dropdown":
          if (item.prefix) {
            prefix = `<span class="unit">${item.prefix}</span>`;
          }

          if (item.suffix) {
            suffix = `<span class="unit">${item.suffix}</span>`;
          }

          if (item.source) {
            item.options = window.sources[item.source.list].map(option => ({
              key: option[item.source.key],
              value: option[item.source.value],
            }));
          }

          if (typeof item.options[0].value == "number") {
            element = $(`
              <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
                <editor-control for="${item.ref}" class=" editor-control">
                  <div class="input-container">
                    <label for="${item.ref}">${label}</label>
                    <div class="input-with-unit">
                      ${prefix}
                      <select id="${item.ref}" ng-change="update(); updateCurrent('${ref}');" ng-model="${item.ref}" ng-disabled="${item.disabled || false}" ng-options="number for number in [${item.options.map(x => x.value)}]">
                      </select>
                      ${suffix}
                    </div>
                  </div>
                </editor-control>
              </div>
            `)[0];
          } else {
            element = $(`
              <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
                <editor-control for="${item.ref}" class=" editor-control">
                  <div class="input-container">
                    <label for="${item.ref}">${label}</label>
                    <div class="input-with-unit">
                      ${prefix}
                      <select id="${item.ref}" ng-change="update(); updateCurrent('${ref}');" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                        ${item.options.map(option => `<option value="${option.key}">${option.value}</option>`).join("")}
                      </select>
                      ${suffix}
                    </div>
                  </div>
                </editor-control>
              </div>
            `)[0];
          }

          break;
        case "checkbox":
          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <editor-control for="${item.ref}" class="editor-control">
                <div class="input-container">
                  <md-checkbox ng-change="update(); updateCurrent('${ref}');" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                    <label for="${item.ref}">${label}</label>
                  </md-checkbox>
                </div>
              </editor-control>
            </div>
          `)[0];
          break;
        case "radio":         
          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <editor-control for="${item.ref}" class="editor-control">
                <div class="input-container">
                  <label for="${item.ref}">${label}</label>
                  <md-radio-group ng-change="update(); updateCurrent('${ref}');" ng-model="${item.ref}" ng-disabled="${item.disabled || false}">
                    ${item.options.map(option => `
                      <md-radio-button class="col m6" id="${$filter("titleize")(`${label} ${option.value}`)}" value="${option.key}">
                        <label for="${$filter("titleize")(`${label} ${option.value}`)}">${option.value}</label>
                      </md-radio-button>
                    `).join("")}
                  </md-radio-group>
                </div>
              </editor-control>
            </div>
          `)[0];
          break;
        case "file":
          element = $(`
            <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
              <editor-control for="${item.ref}" class="editor-control">
                <div>
                  <div class='upload-file' ng-class='{ uploading: uploading }' ng-controller='CustomFileUploadController'>
                    <div class='input-container'>
                      <label for="${item.ref}">${label}</label>
                      <div class='browse-section'>
                        <div class='word-doc-name'>
                          <input type="text" class='file-input' ng-model="${item.ref}.filename" readonly/>
                        </div>
                        <div>
                          <label class='inputfile btn blue' for='${ref}'>Choose File</label>
                          <div class='upload-message'>
                            <span class='blue-loader'>
                              <img src="/assets/blue-loader.gif" />
                            </span>
                            <span>Loading</span>
                          </div>
                          <input accept='{{::supportedFiles}}' autocomplete='false' class='inputfile'
                            id='${ref}' ngf-max-size='20MB'
                            ngf-select='uploadFile("${ref}", $file)' type='file'>
                        </div>
                      </div>
                      <div class='remove-attachment'>
                        <a ng-click='removeFile("${ref}", $event)'>Remove Attachment</a>
                      </div>
                    </div>
                  </div>
                </div>
              </editor-control>
            </div>
          `)[0];
          break;
        case "group":
          let groupLabel = "";

          if (label) {
            groupLabel = 
            `<div class="col m12">
              <div class="category-header">
                <div class="input-container">
                  <b>${label}</b>
                </div>
              </div>
            </div>`;
          }

          element = $(`
              <div data-type="${item.type}" editor-show="${item.conditions ? item.conditions : true}">
                <div class="row remove-space">
                  ${groupLabel}
                  <div class="background col m12">
                    <div class="row">
                      <div class="col m12"></div>
                    </div>
                  </div>
                </div>
              </div>
            `)[0];

          container = element.querySelector(".background .row .col");
          break;
        default:
          element = document.createElement("div");
      }

      if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
          container.appendChild(createDOMElement(container, child));
        });
      }

      return element;
    }

    function createDOMElements(jsonSpec) {
      const element = $(`
        <div class="cards-section custom-editor">
          <div class="editor-cards-container">
          </div>
        </div>
      `)[0];

      const container = element.querySelector(".editor-cards-container");

      jsonSpec.forEach(item => {
        container.appendChild(createDOMElement(container, item));
      });

      return element;
    }

    this.init = function(_scope, _filter, _compile) {
      $scope = _scope;
      $filter = _filter;
      $compile = _compile;

      try {
        if (!window.lease) {
          return;
        }

        const jsonSpec = window.lease.form.customEditor;
        const domElements = createDOMElements(jsonSpec);
        const container = document.querySelector('[data-tab="terms"]');
        $compile(domElements)($scope);
        container.appendChild(domElements);
      } catch (e) {
        console.log(e);
      }
    };
  }

  window.customEditorManager = new CustomEditorManager();
})();
