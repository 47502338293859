angular.module('LeasePilot').controller('ManageBuildingController', [
  '$scope',
  'BuildingService',
  function($scope, BuildingService) {
    $scope.saveBuildingDefaults = function(path) {
      var defaults = _.pickBy($scope.lease, function(value) {
        return !(value === null || value === undefined || value === '');
      });

      new BuildingService({ id: $scope.buildingId, defaults: defaults })
        .update()
        .then(function() {
          window.location = path;
        });
    };

    $scope.discard = function(path) {
      window.location = path;
    };
  },
]);
