angular.module('LeasePilot').directive("editorSearch", ['$rootScope',
  function ($rootScope) {
    var editorSearch = function (scope) {

      scope.currentIndex = 0;
      scope.isOpen = true;
      scope.list = [];
      scope.count = 0;
      scope.fieldName = '';
      scope.fieldNotVisible = false;
      scope.notVisibleMessage = "";
      scope.showNotVisibleMessage = false;

      const input = document.querySelector('editor-search input');
      const button = document.querySelector(".editor-search__show-interface");

      document.addEventListener("keydown", function(e) {
        if ((e.ctrlKey || e.metaKey) && e.keyCode === window.ENUMS.KEYS.KEY_E) {
          e.preventDefault();
          button.click();
        }
      });

      var search = _.debounce( () => {
        if(scope.searchText.length < 2){
          scope.list =[];
          scope.currentIndex = 0;
          scope.fieldNotVisible = false;
          return;
        }
        scope.list = leftSideEditorSearch.searchByName(scope.searchText);
        scope.count = scope.list.length;
        scope.currentIndex = 0;
        if(scope.list.length!== 0){
          scope.moveTo();
        }
      },200);

      scope.handleKeyUp = (e) => {
        if (e.keyCode === window.ENUMS.KEYS.ENTER) {
          scope.moveToNext();
        } else if (e.keyCode === window.ENUMS.KEYS.ESCAPE) { // ESC
          scope.hideSearch();
        }
      };
      scope.createNotVisibleMessage = ()=>{
        //Field isn't currently visible based on current selections.
        scope.notVisibleMessage = `The field "${scope.list[scope.currentIndex].name}" in card "${scope.list[scope.currentIndex].cardName}" isn't currently visible based on current selections.`;
      }
      scope.moveTo = async () => {
        scope.showNotVisibleMessage = false;
        if(scope.list.length !== 0){
          scope.fieldName = scope.list[scope.currentIndex].displayName;
          await scope.list[scope.currentIndex].goTo();
          scope.fieldNotVisible = scope.list[scope.currentIndex].isVisible();
          if(!scope.fieldNotVisible){
            scope.createNotVisibleMessage();
            scope.showNotVisibleMessage = true;
            scope.safeApply();
          }

        }
        else{
          scope.fieldNotVisible = false;
          scope.fieldName = '';
        }
      };

      scope.showSearch = () => {
        scope.isOpen = true;

        setTimeout(() => {
          input.focus();
        });

        window.track.event(new OpenLeftHandEditorSearchEvent({
          context: $rootScope.getContext(),
        }));
      }

      scope.hideSearch = () => {
        scope.isOpen = true;
        scope.searchText = "";

        setTimeout(() => {
          input.value = "";
        });

        window.track.event(new HideLeftHandEditorSearchEvent({
          context: $rootScope.getContext(),
        }));
      }

      scope.moveToPrev = () => {
        scope.currentIndex = scope.currentIndex === 0 ? scope.list.length - 1 : scope.currentIndex - 1;
        scope.moveTo();

        window.track.event(new MoveToThePreviousEditorSearchResultEvent({
          context: $rootScope.getContext(),
        }));
      };

      scope.moveToNext = () => {
        scope.currentIndex = scope.currentIndex + 1 >= scope.list.length ? 0 : scope.currentIndex + 1;
        scope.moveTo();

        window.track.event(new MoveToTheNextEditorSearchResultEvent({
          context: $rootScope.getContext(),
        }));
      }

      scope.search = search;
    }
    return {
      restrict: "E",
      scope: {

      },
      templateUrl: 'editor_search.html',
      link: editorSearch,
    };
  },
]);
