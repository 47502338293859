angular.module('LeasePilot').directive('deleteCompanyModal', [
  '$mdDialog',
  'CompanyService',
  function($mdDialog, CompanyService) {
    return {
      restrict: 'E',
      templateUrl: 'delete_company.html',
      link: function($scope, element, attrs) {
        document.getElementById('company-name').focus();

        const companyId = attrs.companyId;
        const companyName = attrs.companyName;

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.disabled = function() {
          return (
            !$scope.name || $scope.name != companyName || $scope.actionStarted
          );
        };

        $scope.delete = function() {
          if ($scope.name == companyName) {
            $scope.actionStarted = true;
            new CompanyService({ id: companyId }).delete().then(
              function() {
                window.location.reload();
              },
              function(error) {
                $scope.error = error.data.error;
              },
            );
          } else {
            $scope.error =
              'The company name inserted does not match the company selected for deletion!';
          }
        };
      },
    };
  },
]);
