angular.module('LeasePilot').directive('editorTemplateConfigurations', [
  '$rootScope',
  '$timeout',
  function($rootScope, $timeout) {
    return {
      restrict: 'E',
      transclude: false,
      scope: {
        templateId: '=',
        template: '=',
      },
      templateUrl: 'editor_template_configurations.html',
      link($scope) {
        $scope.template.defaults.orderFields =
          $scope.template.defaults.orderFields || {};

        $scope.$watch(
          '[$root.lease, templateId, template]',
          () => {
            $timeout(function() {
              load();
            }, 10);
          },
          true,
        );

        function getGroupControls(obj, categoryName) {
          let controls = [];
          var ctrlKey;
          var order;

          if (!$scope.template.defaults.orderFields) {
            $scope.template.defaults.orderFields = {};
          }
          if (!$scope.template.defaults.orderFields[categoryName]) {
            $scope.template.defaults.orderFields[categoryName] = {};
          }

          let category = $scope.template.defaults.orderFields[categoryName];

          obj.each((index, elem) => {
            ctrlKey = $(elem).attr('order');

            // Due to the move from ng-if to editor-if/editor-show (which will cause elements to be on DOM but hidden), we need to be able to ignore elements that are supposedly hidden. So if we encounter parent element (with '.order') that has hidden children (editor-show) or no children at all (editor-if), skip them.
            if (!$(elem).is(':visible')) {
              return;
            }

            order =
              category && category[ctrlKey] >= 0 ? category[ctrlKey] : 0;
            controls.push({
              key: ctrlKey,
              order: order,
            });
          });

          return _.unionBy(_.sortBy(controls, e => e.order), 'key');
        }

        function load() {
          $scope.container = [];
          $(
            `.editor [data-template-id="${$scope.templateId}"] .group-control`,
          ).each(function(_, element) {
            let categoryName = element.getAttribute('config-name') || 'main';
            $scope.container.push({
              name: categoryName,
              groupControls: getGroupControls(
                $(element).children('.order'),
                categoryName,
              ),
            });
          });
          $scope.container = _.unionBy($scope.container, 'name');
        }

        $scope.reorder = function(categoryIndex) {
          $scope.container[categoryIndex].groupControls.forEach(
            (control, index) => {
              var categoryName = $scope.container[categoryIndex].name;

              $(
                '[order="' +
                  control.key +
                  '"][category="' +
                  categoryName +
                  '"]',
              ).css('order', index);

              if (!$scope.template.defaults.orderFields[categoryName]) {
                $scope.template.defaults.orderFields[categoryName] = {};
              }
              control.order = index;
              $scope.template.defaults.orderFields[categoryName][
                control.key
              ] = index;
            },
          );
        };
      },
    };
  },
]);
