angular.module('LeasePilot').controller('newEditorController', [
  '$scope',
  'FormService',
  'EditorService',
  function($scope, FormService, EditorService) {
    $scope.documentType = 'Lease';
    $scope.dealType = 'retail';
    $scope.availableEditors = [];

    $scope.init = function(formId) {
      $scope.formId = formId;
      FormService.query({
        allCompaniesEditors: true,
      }).then(
        function(response) {
          $scope.forms = response;
          $scope.filterEditors();
        },
        function(error) {},
      );
    };

    $scope.filterEditors = function() {
      $scope.documentTypes = _.chain($scope.forms)
        .map(function(form) {
          return form.name;
        })
        .uniq()
        .value();

      $scope.dealTypes = _.chain($scope.forms)
        .filter(function(form) {
          return form.name === $scope.documentType;
        })
        .map(function(form) {
          return form.dealType;
        })
        .uniq()
        .value();

      $scope.baseEditor = null;

      $scope.availableEditors = _.filter($scope.forms, function(form) {
        return (
          form.name === $scope.documentType && form.dealType === $scope.dealType
        );
      });
    };

    $scope.resetDealType = function() {
      $scope.dealType = $scope.dealTypes[0];
    };

    $scope.submit = function(path) {
      new EditorService({
        formId: $scope.formId,
        baseEditor: $scope.baseEditor,
      })
        .create()
        .then(function(editor) {
          window.location = path;
        });
    };
  },
]);
