angular.module('LeasePilot').directive('crb', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      id: '@',
    },
    link: function(scope, element) {
      element.html("<a id='cross_ref_" + scope.id + "'></a>");
    },
  };
});
