const FreeTextFunctions = function() {
  this.generateAdvancedEdit = (isAdvancedEdit, sectionId) => {
    let output = "";

    if (isAdvancedEdit) {
      output =
        "<md-button " +
        'aria-label="TinyMCE Edit" ' +
        'class="md-icon-button edit-btn advanced-edit remove-on-download" ' +
        'ng-if="editingEnabled && !download" ' +
        'ng-click="openTinyMceDialog(' +
        sectionId +
        ');">' +
        '<i class="material-icons">mode_edit</i>' +
        "<md-tooltip>Advanced edit</md-tooltip>" +
        "</md-button>";
    }
    return output;
  };

  this.generateRevert = sectionId => {
    let output = "";

    if (!window.isDownload && window.isDebug) {
      output =
        "<md-button " +
        'aria-label="Reset Changes" ' +
        'class="md-icon-button edit-btn remove-on-download" ' +
        'ng-if="dataScope.editingEnabled && ((dataScope.leaseFreeTexts[' +
        sectionId +
        "] != null && !(dataScope.leaseFreeTexts[" +
        sectionId +
        "].hasOwnProperty('type') && dataScope.leaseFreeTexts[" +
        sectionId +
        "].type === 'override')) || dataScope.lease.restyle[" +
        sectionId +
        ']) && dataScope.showRevertChanges" ' +
        'ng-click="dataScope.deleteFreeText(' +
        sectionId +
        ', true);">' +
        '<i class="material-icons">undo</i>' +
        "<md-tooltip>Revert changes in this section</md-tooltip>" +
        "</md-button>";
    }
    return output;
  };

  let freeTextCouter = 0;

  this.generateText = (sectionId, isBullet, isDeletable, isAdmin,element) => {
    let output = "";
    freeTextCouter++;
    const isInNewListItem = element[0].closest('.inserted-list-item') ? true : false;

    

    if (isAdmin && !isInNewListItem) {      
      element[0].setAttribute('data-uniq-number',freeTextCouter);
      
      
      output =
        "<span ng-if=\"!lockedFreeText["+freeTextCouter+"]\" class=\"editable\" contenteditable=\"true\" " +
        'ng-model="dataScope.leaseFreeTexts[' +
        sectionId +
        ']" ' +
        'bullet="' +
        isBullet +
        '" ' +
        (!isDeletable ? 'deletable="false" ' : "") +
        'section-id="{{::' +
        sectionId +
        '}}">' +
        "<ng-transclude></ng-transclude>" +
        "</span>" +
        "<span ng-if=\"lockedFreeText["+freeTextCouter+"]\" class='locked-free-text'>" +
        "<span ng-if='dataScope.leaseFreeTexts[" +
        sectionId +
        "].text' bind-html-compile='dataScope.leaseFreeTexts[" +
        sectionId +
        "].text' >" +
        "</span>" +
        "<span ng-if='!dataScope.leaseFreeTexts[" +
        sectionId +
        "].text' >" +
        "<ng-transclude></ng-transclude>" +
        "</span>" +
        "</span>";
    } else {
      if (window.isDownload) {
        // Render `ng-model` only if necessary for `sectionId`
        // 1. There's a free-text (normal)
        // 2. There's a free-text for an override
        // 3. There's a free-text for a building override

        const freeText =
          sectionId.indexOf("+") !== -1 ||
          _.find(
            window.lease.freeTexts,
            freeText =>
              freeText.sectionId == sectionId ||
              sectionId.startsWith(freeText.sectionId)
          ) ||
          _.find(
            window.lease.form.overrides,
            freeText =>
              freeText.sectionId == sectionId ||
              sectionId.startsWith(freeText.sectionId)
          ) ||
          _.find(
            window.lease.form.buildingOverrides,
            freeText =>
              freeText.sectionId == sectionId ||
              sectionId.startsWith(freeText.sectionId)
          );

        if (freeText) {
          output =
            '<span contenteditable="false" ' +
            'ng-model="dataScope.leaseFreeTexts[' +
            sectionId +
            ']" ' +
            'bullet="' +
            isBullet +
            '" ' +
            'section-id="{{::' +
            sectionId +
            '}}">' +
            "<ng-transclude></ng-transclude>" +
            "</span>";
        } else {
          output =
            '<span contenteditable="false" ' +
            'bullet="' +
            isBullet +
            '" ' +
            'section-id="{{::' +
            sectionId +
            '}}">' +
            "<ng-transclude></ng-transclude>" +
            "</span>";
        }
      } else {
        output =
          '<span class="editable" contenteditable="true" ' +
          'ng-model="dataScope.leaseFreeTexts[' +
          sectionId +
          ']" ' +
          'bullet="' +
          isBullet +
          '" ' +
          (!isDeletable ? 'deletable="false" ' : "") +
          'section-id="{{::' +
          sectionId +
          '}}">' +
          "<ng-transclude></ng-transclude>" +
          "</span>";
      }
    }

    return output;
  };
};

const freeTextFunctions = new FreeTextFunctions();

function getUniqNumberFromFreeTextElement(element){

  return element.getAttribute('data-uniq-number');

}

angular.module("LeasePilot").directive("freeText", [
  "RolesService",
  "$mdToast",
  "$rootScope",  
  function(RolesService, $mdToast,$rootScope) {
    const freeTextLink = function(scope, element, attrs) {

      const isAdmin = window.isAdmin;
    
      
      scope.dataScope = $rootScope.findAppScope();
      if(window.location.href.indexOf('smlView')!== -1){
        if(!window.originalFreeText){
          window.originalFreeText ={};
        }
        window.originalFreeText[element[0].getAttribute('free-text')] = element[0].textContent;
      }
      
      let elScope = angular.element(element).scope();
      scope.shouldBeBlocked = false;
    
      // get free text uniq number 
    
      
      const uniqNumber = getUniqNumberFromFreeTextElement(element[0]);
      
    
    
      // get closest lease-if
      let isInComplicatedCondition = false;
      const closestLeaseIf = element[0].closest("[lease-if]");
      if (closestLeaseIf) {
        const ifId = closestLeaseIf.getAttribute("data-if-id");
        if (ifId) {
          isInComplicatedCondition = leaseIfManager
            .getElement(ifId)
            .getIsComplicatedCondition();
        }
      }
      
      if(!scope.lockedFreeTextInfo){
        scope.lockedFreeTextInfo ={};
      }
    
      if(isInComplicatedCondition){
    
        scope.lockedFreeTextInfo[uniqNumber] = isInComplicatedCondition;
        
      }
    
      if (isAdmin) {
        const isDynamicFreeText = isNaN(attrs.freeText);
    
        
        
    
        var showMessageForBlockedText = function(element) {
          $mdToast.cancel();
          var template =
            "<md-toast>" +
            '<div class="md-toast-content">' +
            '<div tabindex="0" class="close remove-angular-border">' +
            '<span ng-click="cancel()" aria-hidden="true" class="remove-angular-border">×</span>' +
            "</div>" +
            '<span class="md-toast-text">This text involves document logic, please contact LeasePilot to make changes in this text</span>' +
            "</div>" +
            '<div class="bottom-arrow"></div>' +
            "</md-toast>";
    
          $mdToast.show({
            hideDelay: 5000,
            position: "top left",
            template: template,
            parent: element,
            controller:
              ("toastController",
              [
                "$scope",
                "$mdToast",
                function($scope, $mdToast) {
                  $scope.cancel = function() {
                    $mdToast.cancel();
                  };
                }
              ])
          });
        };
    
        function isBlockedForUser(item) {
          let isInComplicatedCondition = false;
    
          if(item){
            if(scopeVar.lockedFreeTextInfo[item]){
              isInComplicatedCondition = true;
            }
          }
          
          return (
            (RolesService.isFormAdmin() && (scope.isComplicatedCondition || isInComplicatedCondition ) ) ||
            ((RolesService.isFormAdmin() || RolesService.isAdmin()) &&
              isDynamicFreeText)
          );
        }
    
        function isTempBlocked() {
          return RolesService.isFormAdmin() && scope.assetsLanguageMode;
        }
    
        scope.isLocked = function(item) {
          scope.shouldBeBlocked = !scope.editingEnabled || isBlockedForUser(item) || isTempBlocked() ? true : false;            
          
          return scope.shouldBeBlocked;
        };
    
        element.on("click", function(e) {
          const uniqNumber = getUniqNumberFromFreeTextElement(e.currentTarget);;
          
          if (isBlockedForUser(uniqNumber)) {
            showMessageForBlockedText(element);
          }
        });
    
    
        if(isAdmin){
          if(!scope.lockedFreeText){
            scope.lockedFreeText = {};
          }
          scope.isLocked(uniqNumber);
          if(scope.shouldBeBlocked){
            element[0].setAttribute('data-locked',true);
            scope.lockedFreeText[uniqNumber] = true;
          }
        }
      }
    };
    
    return {
      restrict: "A",
      transclude: true, // so we can keep the inner html
      template: function(element, attrs) {
        const isAdmin = window.isAdmin;
        const sectionId = attrs.freeText;
        const isBullet = !!attrs.bullet;
        const isAdvancedEdit = !!attrs.advancedEdit;
        const isDeletable = !(isBullet || isAdvancedEdit);

        const result =
          "" +
          freeTextFunctions.generateAdvancedEdit(
            isAdvancedEdit,
            sectionId
          ) +
          freeTextFunctions.generateRevert(sectionId) +
          freeTextFunctions.generateText(
            sectionId,
            isBullet,
            isDeletable,
            isAdmin,
            element
          );

        return result;
      },
      link: freeTextLink,
    };
  }
]);
