const hiddenClassName = ".lp-hide-element,.deleted-element";
const visibleElementClass = ".lp-show-element";
const windowParamName = "applyFilterVisibleState";
window[windowParamName] = false;

const _getObjectTypeName = function(object) {
  var funcNameRegex = /function (.{1,})\(/;
  var results = funcNameRegex.exec(object.constructor.toString());
  return results && results.length > 1 ? results[1] : "";
};

window.filterVisible = function(collection) {
  let workCollection;

  if (_getObjectTypeName(collection) !== "Array") {
    workCollection = Array.from(collection);
  } else {
    workCollection = collection;
  }

  let retValue = workCollection.filter(node => {
    return node.closest(hiddenClassName) === null;
  });

  retValue.item = function(index) {
    return this[index];
  };

  return retValue;
};

window.applyFilterVisible = function(func, args) {
  const orgWindowSetting = window[windowParamName];
  window[windowParamName] = true;

  let result;
  try {
    result = func.apply(this, args);
  } finally {
    window[windowParamName] = orgWindowSetting;
  }

  return result;
};

window.applyShowAll = function(func, args) {
  const orgWindowSetting = window[windowParamName];
  window[windowParamName] = false;

  let result;
  try {
    result = func.apply(this, args);
  } finally {
    window[windowParamName] = orgWindowSetting;
  }

  return result;
};
