angular.module('LeasePilot').directive('processStatus', [
  'ProcessStatusService',
  function(ProcessStatusService) {
    return {
      restrict: 'E',
      link: function($scope, element, attrs) {
        var processes = {
          save: {
            placeholder: 'saving...',
            spinner: false,
          },
          print: {
            placeholder: 'preparing to print...',
            spinner: true,
          },
          download: {
            placeholder: 'downloading...',
            spinner: true,
          },
          downloadAbstract: {
            placeholder: 'downloading abstract...',
            spinner: true,
          },
          saved: {
            placeholder: 'all changes saved',
            spinner: false,
          },
        };

        var noChangesMade = true;

        $scope.process = function() {
          var processName = ProcessStatusService.getCurrent().name;
          if (processName === 'save') {
            if (noChangesMade) ProcessStatusService.startDefault('saved');
            noChangesMade = false;
          }
          return processes[processName];
        };
      },
    };
  },
]);
