angular.module('LeasePilot').directive('editorTemplateConfig', function() {
  return {
    restrict: 'E',
    transclude: false,
    scope: {
      template: '=',
      label: '@',
      config: '@',
      onChange: '&',
      configName: '@config',
      disabled: '=',
    },
    templateUrl: 'editor_template_config.html',
    link: function($scope, element, attrs) {
      if (!$scope.label) {
        $scope.label = _.startCase($scope.configName);
      }
    },
  };
});
