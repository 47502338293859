angular.module('LeasePilot').directive('infoMessage', [
  '$timeout',
  function($timeout) {
    return {
      restrict: 'E',
      scope: {
        isSticky: '=',
        infoMessage: '@',
      },
      template: "<div ng-if='showMessage && infoMessage && infoMessage.length !== 0'>{{infoMessage}}</div>",
      link: function(scope) {
        scope.showMessage = false;
        scope.$watch('infoMessage', function(newVal, oldVal) {
          if (newVal) {
            scope.showMessage = true;
            if (!scope.isSticky) {
              $timeout(function() {
                scope.showMessage = false;
                scope.infoMessage = '';
              }, 5000);
            }
          }
        });
      },
    };
  },
]);
