/* global JSZip */
angular.module('LeasePilot').directive('importChangesModal', [
  '$rootScope',
  '$mdDialog',
  'BlankLeaseService',
  'DocumentService',
  'ApiService',
  'LeaseEditorService',
  function(
    $rootScope,
    $mdDialog,
    BlankLeaseService,
    DocumentService,
    ApiService,
    LeaseEditorService,
  ) {
    return {
      restrict: 'E',
      templateUrl: 'import_changes_modal.html',
      scope: {
        download: '=',
      },
      link: function($scope, element, attrs) {
        $rootScope.$on('updateImportChangesDialogStatus', function(
          event,
          data,
        ) {
          if (data.noChanges) {
            $scope.showWarning = true;

            $scope.processFileError = false;
            versionControlManager.exitVCView();
            $scope.processFileError = false;            

            $scope.$apply();
          } else if (data.close) {
              $scope.processFileError = false;
              $scope.cancel();
              } else {
                $scope.processFileError = true;
                $scope.clearFileSelection();
              }
        });

        var getLeaseDisplayName = () => {
          const tenantName = $scope.$root.lease.customFields?.fileName || $scope.$root.lease.tenantInfo?.tradeName || $scope.$root.lease.tenantInfo?.name;

          if (!$rootScope.lease.isConformedDeal) {
            let name = `${$scope.$root.lease.building.dashboardName} - `;
            if (tenantName) {
              name += `${tenantName} - `;
            }
            name += `${$scope.$root.lease.type} - Version-${$scope.$root.lease.instanceVersion}`;
            return name;
          } else {
            let name = `[Conformed Deal] - `;
            if (tenantName) {
              name += `${tenantName} - `;
            }
            name += `${$scope.$root.lease.type} - Version-${$scope.$root.lease.instanceVersion}`;
            return name;
          }
        };

        $scope.processFileError = false;
        $scope.netWorkError = false;
        $scope.processing = false;
        $scope.showMask = false;
        $scope.isImporting = false;
        $scope.wordDoc = null;
        $scope.selectedFile = false;
        $scope.leaseDisplayName = getLeaseDisplayName();
        $scope.errors = {
          wordDoc: 'No file selected',
        };

        $scope.clearFileSelection = () => {
          $scope.selectedFile = false;
          $scope.showWarning = false;
          $scope.processFileError = false;
          $scope.netWorkError = false;
        };

        $scope.downloadErrorMessage =
          'Oops... something went wrong with the download process, please wait a couple of seconds and try again.';
        $scope.eventName1 = 'diffgramOriginal';
        var type =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.uploadFile = function(file) {
          $scope.showWarning = false;
          $scope.isImporting = false;
          $scope.wordDoc = file;
          $scope.selectedFile = true;
          delete $scope.errors.wordDoc;
        };

        function downloadFile(data) {
          var a = document.createElement('a');
          var file = new Blob([data], {
            type: type,
          });
          var url = URL.createObjectURL(file);
          a.href = url;
          a.download = 'diffgram.zip';
          document.body.appendChild(a);
          a.click();
        }

        function getDiffgram(original, modified) {
          $scope.processFileError = false;
          $scope.netWorkError = false;

          /////////////////////////////////////////////////////////////////////////////////////////////
          // Kept for debugging purposes uncomment to get the zip file before it's sent to the service
          // The Zip file is downloaded as a *.docx file, hence, needs to be renamed
          // downloadFile(zipFile);
          /////////////////////////////////////////////////////////////////////////////////////////////

          window._version_control_tenant_requests_document = {
            document: modified,
          };

          const serviceFunction =  ApiService.diffgram;

          serviceFunction(original, modified, window.ASSETS_DESIRED_STYLES).then(
            function(response) {
              $scope.showMask = false;
              $scope.processing = false;

              $rootScope.$emit('showDiffgram', {
                diffgram: response.data,
              });

              console.log(
                new Date().getTime() +
                  ' download redline doc finished successfully',
              );
            },
            function(error) {
              $scope.showMask = false;
              $scope.processing = false;
              $scope.netWorkError = true;
              console.log('error ', error);
            },
          );
        }

        function setOriginalFile() {
          setTimeout(()=>{
            var originalIframe = $('iframe[name="original"]');
            var originalIframeSrc;

            originalIframeSrc = window.location.href.replace('/edit', '');
            originalIframeSrc += "/?deprecated=true";
            originalIframe.attr('src', originalIframeSrc);
          });
        }

        function setModifiedFile() {
          $scope.modifiedFile = $scope.wordDoc;
        }

        function singleFileConverted() {
          if ($scope.originalFile && $scope.modifiedFile) {
            getDiffgram($scope.originalFile, $scope.modifiedFile);
          }
        }


        $rootScope.$on($scope.eventName1, function(event, data) {
          console.log(new Date().getTime() + ' on event: ', $scope.eventName1);
          console.log(new Date().getTime() + ' data ', data);
          var file = new Blob([data.file], {
            type: 'html/text',
          });
          $scope.originalFile = file;
          singleFileConverted(file);
        });

       

        $scope.import = function() {
          
          $scope.showMask = true;
          $scope.processing = true;
          $scope.isImporting = true;
          window.downloadForVersionControl = true;
          setModifiedFile();
          $scope.safeApply();

          setTimeout(()=>{
            versionControlManager.setIdsAndDownload();
            window.track.event(new ImportTenantRequestsEvent({
              context: $rootScope.getContext(),
            }));
          }, 500);
          
        };
      },
    };
  },
]);
