angular.module('LeasePilot').directive('tinyMceEditor', [
  '$rootScope',
  '$mdDialog',
  'LeaseEditorService',
  function($rootScope, $mdDialog, LeaseEditorService) {
    return {
      restrict: 'E',
      templateUrl: 'tiny_mce_editor_modal.html',
      link: function(scope, element, attrs) {
        scope.sectionId = element.attr('section-id');

        (function initTinyMCE() {
          var clonedElement = $(
            '[section-id="' + scope.sectionId + '"]',
          ).clone();
          LeaseEditorService.removeMarkers(clonedElement);
          $('#editable-div').html(clonedElement.html());
          tinymce.init({
            selector: '#editable-div',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks fullscreen',
              'insertdatetime media table contextmenu',
              'textcolor colorpicker',
            ],
            invalid_styles: 'color',
            branding: false,
            auto_focus: 'editable-div',
            invalid_elements: 'a',
            toolbar: [
              {
                name: 'history', items: [ 'undo', 'redo' ]
              },
              {
                name: 'styles', items: [ 'styleselect' ]
              },
              {
                name: 'fontsize', items: [ 'fontsizeselect' ]
              },
              {
                name: 'formatting', items: [ 'bold', 'italic', 'underline']
              },
              {
                name : 'color', items: ['forecolor', 'backcolor']
              },
              {
                name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ]
              },
              {
                name: 'indentation', items: [ 'outdent', 'indent' ]
              }
            ],
            fontsize_formats : '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt',
            // paste_postprocess: function(editor, fragment) {
            //   // Fragment is a DocumentFragment node containing the DOM structure of the pasted content,
            //   // after it has been filtered by the PowerPaste plugin.
            //   $(fragment.node)
            //     .find('span:has(span)')
            //     .each(function() {
            //       var decoration = $(this).css('text-decoration');

            //       // move decoration style to children nodes
            //       $(this)
            //         .children()
            //         .each(function(i, element) {
            //           var hasDecoration =
            //             element.style.textDecoration.length > 0;
            //           if (!hasDecoration)
            //             $(element).css('text-decoration', decoration);
            //         });
            //       // remove decoration style from parent element
            //       $(this).css('text-decoration', '');
            //     });

            //   $(fragment.node)
            //     .find('h1, h2, h3, h4, h5, h6')
            //     .each(function() {
            //       // create a <p> with the same style
            //       $(this).replaceWith(
            //         $(
            //           "<p style='" +
            //             $(this).attr('style') +
            //             "'>" +
            //             $(this).html() +
            //             '</p>',
            //         ),
            //       );
            //     });
            // },
          });
        })();

        scope.cancel = function() {
          tinymce.remove('#editable-div');
          $mdDialog.cancel();
        };

        function prepare(section, content) {
          const container = LeaseEditorService.findClosestContainer(section);
          const style = container.get(0).getAttribute('style');
          const dummy = document.createElement('dummy');
          dummy.innerHTML = content;

          const paragraphs = dummy.querySelectorAll('p');
          paragraphs.forEach(p => {
            p.setAttribute('style', `${style}; ${p.getAttribute('style')}`);
            p.style.marginLeft = 0;
            p.style.textIndent = 0;

            p.setAttribute('data-paragraph-indent', 0);
            p.setAttribute('data-text-indent', 0);
            p.setAttribute('data-text-align', 'start');
            p.setAttribute('data-original-paragraph-indent', '0pt');
            p.setAttribute('data-original-text-indent', p.style.textIndent || '0pt');
            p.setAttribute('data-original-text-align', 'start');
            p.classList.add('new-paragraph');
          });

          return dummy.innerHTML;
        }

        scope.saveHTML = function() {
          var editedContent = tinymce.get('editable-div').getContent();
          var editedSection = $(
            '[contenteditable][section-id="' + scope.sectionId + '"]',
          );

          editedContent = editedContent
            .replace(/\n/g, "")
            .replace(/\r/g, "")
            .replace(/\t/g, "");

          editedSection.html(prepare(editedSection, editedContent));
          LeaseEditorService.addMarkers(editedSection);
          editedSection.trigger('change');
          scope.cancel();

          window.track.event(new SaveChangesUsingTheAdvancedEditEvent({
            context: $rootScope.getContext(),
          }));
        };
      },
    };
  },
]);
