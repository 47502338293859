angular.module('LeasePilot').service('FreeTextToSmlService', [
  function() {
    var SML_TAGS = [
      {
        smlTagName: 'lease',
        htmlTagName: 'lease-var',
        attributesMap: {
          var: 'ng-attr-name',
          calc: 'ng-attr-calc',
          line: 'll',
          ev: 'ev',
          f1: 'f1',
          f2: 'f2',
          f3: 'f3',
          'f1-options': 'f1-options',
          'f2-options': 'f2-options',
          'f3-options': 'f3-options',
        },
      },
      {
        smlTagName: 'building-variable',
        htmlTagName: 'building-variable',
        attributesMap: {
          name: 'bs-name',
          'first-line-data': 'ng-attr-first-line-data',
          'first-line-class': 'first-line-class',
          'second-line-data': 'ng-attr-second-line-data',
          'second-line-class': 'second-line-class',
        },
      },
      {
        smlTagName: 'unlocked',
        htmlTagName: 'unlocked-var',
        attributesMap: {
          var: 'ng-attr-name',
          calc: 'ng-attr-calc',
          line: 'll',
          ev: 'ev',
          f1: 'f1',
          f2: 'f2',
          f3: 'f3',
          'f1-options': 'f1-options',
          'f2-options': 'f2-options',
          'f3-options': 'f3-options',
        },
      },
      {
        smlTagName: 'crr',
        htmlTagName: 'crr',
      },
    ];

    function getTagAttributes(attrs, el) {
      //get exsisting attributes
      var attrsList = _.chain(attrs)
        .mapValues(function(attr) {
          return $(el).attr(attr);
        })
        .pickBy(function(val) {
          return val;
        })
        .value();

      //get attributes as string
      attrsList = _.chain(attrsList)
        .map(function(val, attr) {
          return isNaN(parseInt(val))
            ? attr + '="' + val + '"'
            : attr + '=' + val;
        })
        .join(' ')
        .value();

      return attrsList;
    }

    function getTagText(tag, el) {
      var str = '';
      switch (el[0].tagName) {
        case 'CRR':
          str = '<<' + tag.smlTagName + '-' + el.attr('id') + '>>';
          break;
        case 'BUILDING-VARIABLE':
          el.attr('bs-name', el.attr('bs-name').replace(/\'/g, ''));
        default:
          str =
            '<<' +
            tag.smlTagName +
            ' ' +
            getTagAttributes(tag.attributesMap, el) +
            '>>';
          break;
      }

      return str;
    }

    function convert(freeText) {
      freeText = freeText.wrapAll('<p/>').parent();

      _.each(SML_TAGS, function(tag) {
        freeText.find(tag.htmlTagName).each(function() {
          var textSpan = $('<span />');
          textSpan.text(getTagText(tag, $(this)));
          $(this).replaceWith(textSpan);
        });
      });

      var html = freeText.html().replace(/﻿/g, '');
      return $(html);
    }

    return {
      convert: convert,
    };
  },
]);
