angular.module('LeasePilot').directive('deleteGuarantor', [
  '$rootScope',
  function($rootScope) {
    return {
      restrict: 'AE',
      template:
        '<md-icon class="inline-delete-guarantor material-icons" title="delete entity" ng-click="removeGuarantorConfirm(index, type, typeObj);" ></md-icon>',
      link: function(scope, element, attrs) {
        ['individuals', 'marriedCouples', 'entities'].forEach(function(
          data,
          index,
        ) {
          if (attrs.id.indexOf(data) >= 0) {
            scope.type = data;
            scope.typeObj = attrs.id.split('individuals')[0] + data;
            scope.index = attrs.index;
            return;
          }
        });
      },
    };
  },
]);
