angular.module('LeasePilot').factory('ImportTaskService', [
  'railsResourceFactory',
  function(railsResourceFactory) {
    var resource = railsResourceFactory({
      url: '/api/vts_import_tasks',
      name: 'import_task',
    });

    resource.prototype.getCachedDeals = function() {
      var self = this;

      return resource.$get(self.$url('cached_deals')).then(function(response) {
        self.id = response.id;
        self.status = response.status;
        self.exportedAt = response.exportedAt;
        self.deals = response.deals;

        return self;
      });
    };

    return resource;
  },
]);
