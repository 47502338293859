angular.module('LeasePilot').directive('editorIf', [
  '$animate',
  '$compile',
  '$location',
  function($animate, $compile, $location) {
    const editorIfLink = function($scope, $element, $attr, ctrl, $transclude) {
      var block, childScope, previousElements;
      var editorMode = $location.search().editor_mode;
    
      /**
       * Copied from Angular source code.
       * We cannot access the version from the Angular source code from here.
       */
    
      var getBlockNodes = function(nodes) {
        var node = nodes[0];
        var endNode = nodes[nodes.length - 1];
        var blockNodes = [node];
    
        do {
          node = node.nextSibling;
          if (!node) break;
          blockNodes.push(node);
        } while (node !== endNode);
    
        return $(blockNodes);
      };
    
      $scope.$watch($attr.editorIf, function editorIfWatchAction(value) {
        if (value || editorMode === 'testing') {
          if (!childScope) {
            $transclude(function(clone, newScope) {
              childScope = newScope;
              // clone[clone.length++] = $compile.$$createComment('end editorIf', $attr.editorIf);
              clone[clone.length++] = document.createComment(
                'end editorIf: ' + $attr.editorIf,
              );
              // Note: We only need the first/last node of the cloned nodes.
              // However, we need to keep the reference to the jqlite wrapper as it might be changed later
              // by a directive with templateUrl when its template arrives.
              block = {
                clone: clone,
              };
              $animate.enter(clone, $element.parent(), $element);
            });
          }
        } else {
          if (previousElements) {
            previousElements.remove();
            previousElements = null;
          }
          if (childScope) {
            childScope.$destroy();
            childScope = null;
          }
          if (block) {
            previousElements = getBlockNodes(block.clone);
            $animate.leave(previousElements).done(function(response) {
              if (response !== false) previousElements = null;
            });
            block = null;
          }
        }
      });
    };
    
    return {
      multiElement: true,
      transclude: 'element',
      priority: 600,
      terminal: true,
      restrict: 'A',
      $$tlb: true,
      link: editorIfLink,
    };
  },
]);
