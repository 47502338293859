angular.module('LeasePilot').filter('titleize', function() {
  return function(input) {
    return !!input
      ? input
          .replace(/(\w+)/g, _.capitalize)
          .replace(/[^\w\/\s]/g, ' ')
          .trim()
      : '';
  };
});
