angular.module('LeasePilot').filter('formatDate', function() {
  return function(input, format, formatInput) {
    try {
      if (formatInput) {
        return moment(input, formatInput).format(format);
      } else {
        return moment(input).format(format);
      }
    } catch (e) {
      console.log(
        `Error formatting date with input ${input} and format : ${format}`,
      );
      return input;
    }
  };
});
