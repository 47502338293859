angular.module('LeasePilot').directive('inlineEditor', [
  '$compile',
  '$rootScope',
  'LabelsService',
  function($compile, $rootScope, LabelsService) {
    return {
      restrict: 'E',
      scope: {
        inlineRefreshStep: '=', // InlineRefreshStep is used so we update the inline editor at each click/open.
        pinned: '=', // Pinned tracks if the inline editor should be pinned or not.
        hlActive: '=', // HlActive tracks if the highlighting bar is being shown or not.
      },
      link: function(scope, element) {
        var scopeVar = $rootScope.findAppScope(scope);

        // Keep track of all related fields and the index in the list of related fields.
        scope.relatedFields = null;
        scope.relatedFieldsIdx = -1;

        /**
         * Set the location of the inline editor.
         *
         * If the vertical distance is further than the lease preview height, the movement will not be animated.
         * Otherwise, the movement will be animated.
         *
         * @param {number} x - X coordinate on the window to move the inline editor to.
         * @param {number} y - Y coordinate on the window to move the inline editor to.
         * @param {boolean} unpinned - True if the inline editor should be unpinned, false otherwise.
         */
        scope.setLoc = function(x, y, unpinned) {
          // If we are unpinning, we find a new location to fix the scrolling to.
          if (unpinned) {
            scope.lastFixPos = $('.preview').scrollTop();
            scope.fixedLoc = false;
          }

          // Set animation duration to 250 unless the vertical distance is more than the lease preview height.
          var duration = 250;
          if (
            Math.abs(parseInt(element.css('top'), 10) - y) >
            window.innerHeight - 112
          ) {
            // window height minus top bars
            duration = 0;
          }
          $(element).animate(
            { top: y + 'px', left: x + 'px' },
            { duration: duration, queue: false },
          );
        };

        /**
         * Hide both arrows and make sure that the inline editor sits on top of the navigation bars.
         */
        scope.hideArrows = function() {
          element.find('.top-arrow').hide();
          element.find('.bottom-arrow').hide();
          element.css('z-index', '100');
        };

        /**
         * Set the active field to a given index in the list of related fields.
         *
         * @param {number} idx - Index in the list of related fields of the field we wish to show.
         */
        scope.setActiveField = function(idx) {
          scope.relatedFieldsIdx = idx;

          // Reset the highlight mechanism every time we switch to a different field in the inline editor
          scopeVar.resetHighlighting();
          scope.showActiveField();
        };

        /**
         * Show the currently active field (as indicated by scope.relatedFieldsIdx) in the editor, and update
         * the list of related fields at the bottom.
         */
        scope.showActiveField = function() {
          // Get active field, control object for that field, and highlight it.
          var activeField = scope.relatedFields[scope.relatedFieldsIdx];
          scopeVar.inline.currentField = activeField;
          var controlObj = scopeVar.inline.indexedControls[activeField];

          // If we can find the control in the editor, show it in the inline editor.
          if (controlObj) {
            // Set breadcrumb text.
            element.find('.breadcrumb').text(controlObj.breadcrumb);

            scope.editorRefCurrent = controlObj.ref;

            var node;
            if (controlObj.type === 'group-control') {
              node = $('<div ng-include="\'' + controlObj.ref + '\'"></div>');
            } else {
              node = controlObj.ref.clone(); // clone so we don't modify existing nodes
            }

            var index = node[0].getAttribute('index');

            node = $(node[0]).html(
              $(node[0])
                .html()
                .replace(/\$index/g, index),
            );

            // We must recompile cloned nodes so they are linked to Angular.
            // -> If controls require modification before recompile, we do that here.
            node
              .find2('md-checkbox, md-switch, md-radio-button, lp-text-area')
              .each(function() {
                var txt = $(this).text();
                $(this).empty();
                $(this).text(txt);
              });

            var compiledNode = $compile(node)(scopeVar);
            var addTo = element.find('.inline-boxer');
            addTo.empty();
            addTo.append(compiledNode);

            if (
              controlObj.type === 'control' &&
              node[0].getAttribute('inline-directive')
            ) {
              var ele = node[0].getAttribute('inline-directive');
              var ele =
                '<' + ele + ' id=' + controlObj.id + ' index=' + index + ' />';
              var compiledDirective = $compile(ele)(scopeVar);
              addTo.append(compiledDirective);
            }
          }

          // Add information on the additional related fields.
          element.find('.inline-more').empty();
          if (scope.relatedFields.length > 1) {
            // Note: we can't easily remove duplicates from the list
            // cause next to the list, we're maintaining the index of the field - both should be in sync
            // That's why, instead, we won't render (skip) anything that's already been rendered
            var renderedFields = [];
            for (var i = 0; i < scope.relatedFields.length; i++) {
              if (renderedFields.indexOf(scope.relatedFields[i]) === -1) {
                // Mark that field as rendered
                renderedFields.push(scope.relatedFields[i]);
                element.find('.side-wrapper').show();
                var moreLink = angular.element('<div/>', {
                  class: 'inline-more-link',
                  'ng-click': 'setActiveField(' + i + ')',
                  'ng-class': '{active: (relatedFieldsIdx === ' + i + ')}',
                  title:
                    $rootScope.labels[
                      LabelsService.normalize(scope.relatedFields[i])
                    ],
                });
                moreLink.append(
                  '<span>' +
                    $rootScope.labels[
                      LabelsService.normalize(scope.relatedFields[i])
                    ] +
                    '</span>',
                );
                element.find('.inline-more').append($compile(moreLink)(scope));
              }
            }
            if (renderedFields.length == 1)
              element.find('.side-wrapper').hide();
          } else {
            element.find('.side-wrapper').hide();
          }

          if (scope.pinned) {
            // Pinned, check if we need to shift upwards to accommodate a longer element.
            var elementBotLoc = element.offset().top + element.height();
            if (elementBotLoc > window.innerHeight) {
              // bottom will be outside of height, move the xPos upwards
              var yPos =
                parseInt(element.css('top'), 10) -
                (elementBotLoc - window.innerHeight + 20);
              scope.setLoc(element.offset().left, yPos, false);
            }
          } else {
            // Not pinned, must move to new location.
            setTimeout(function() {
              pinInlineToActiveElement();
            }, 10);
          }
        };

        /**
         * On scroll, track the scroll distance and if needed, move the inline editor.
         */
        $('.preview').on('scroll', function() {
          var previewScrollTop = $('.preview').scrollTop();
          var diff = previewScrollTop - scope.lastFixPos;

          var oldTop = parseInt(element.css('top'), 10);
          if (!scope.fixedLoc) {
            element.css('top', oldTop - diff);
          }
          scopeVar.inline.mouseY -= diff;

          scope.lastFixPos = previewScrollTop;
        });

        /**
         * Use jQuery draggable to make the inline editor draggable.
         *
         * On drag, the arrows are hidden and the inline editor no longer moves along with the lease preview scrolling.
         */
        element.draggable({
          handle: '.breadcrumb',
          // containment: ".preview"
        });
        element.on('dragstart', function() {
          scope.hideArrows();
          scope.fixedLoc = true;
        });

        /**
         * When clicking somewhere in the lease, hide the inline editor.
         */
        $('.preview').on('click', function() {
          if (!scope.pinned) {
            scopeVar.inline.hideInline();
            scopeVar.$digest();
          }
        });

        /**
         * Update the inline editor at every update of inlineRefreshStep, indicating that it has been newly opened.
         */
        scope.$watch('inlineRefreshStep', function() {
          if (!scopeVar) {
            return;
          }

          var activeFields = scopeVar.inline.activeFields;
          if (!activeFields) {
            return;
          }

          // Only get fields which currently have a control element available.
          activeFields = activeFields.filter(function(field) {
            return !!scopeVar.inline.indexedControls[field];
          });

          if (activeFields.length === 0) {
            return;
          }

          /*
           * Reset to the first field in the list of active fields.  The array should have been organized in advance
           * so that the first index of the list is the field which we clicked on or want to have as the active one.
           */
          scope.relatedFields = activeFields;
          scope.relatedFieldsIdx = 0;

          // We get here from different places, some needs to reset the highlighting, some done so -
          // Only reset the highlighting mechanism if the currentField is different than the previousField
          if (
            scopeVar.inline.currentField &&
            scopeVar.inline.previousField &&
            scope.relatedFields[scope.relatedFieldsIdx] !==
              scopeVar.inline.previousField
          ) {
            scopeVar.resetHighlighting();
          }
          scopeVar.inline.previousField =
            scope.relatedFields[scope.relatedFieldsIdx];
          scope.showActiveField(scopeVar);
        });

        /**
         * Watch pin button.  On pin, move to the upper left hand corner.
         */
        scope.$watch('pinned', function(pinned) {
          if (pinned) {
            scope.hideArrows();
            scope.fixedLoc = true;
            scope.setLoc(19, 122, false);
          } else {
            setTimeout(function() {
              pinInlineToActiveElement();
            }, 10);
          }
        });

        /**
         * Watch the highlighting bar, and re-adjust the location of the inline editor depending on if it is being
         * shown or not (the lease location will change when the highlighting bar is shown or not shown).
         */
        scope.$watch('hlActive', function(active, oldActive) {
          if (!scope.fixedLoc) {
            if (active && !oldActive) {
              scope.setLoc(
                element.attr('left'),
                parseInt(element.css('top'), 10) + 37,
                true,
              );
            } else if (!active && oldActive) {
              scope.setLoc(
                element.attr('left'),
                parseInt(element.css('top'), 10) - 37,
                true,
              );
            }
          }
        });

        function pinInlineToActiveElement() {
          // Set to be centered over click.
          var xPos, yPos;
          var $arrow = $('.arrow');
          var $preview = $('.preview');
          if (
            scopeVar.inline.mouseX + element.width() / 2 + 20 >
            window.innerWidth
          ) {
            // too far to the right
            xPos = window.innerWidth - element.width() - 20;
            $arrow.css('left', scopeVar.inline.mouseX - xPos);
          } else if (
            scopeVar.inline.mouseX - element.width() / 2 <
            $preview.offset().left
          ) {
            // too far to the left
            xPos = $preview.offset().left + 20;
            $arrow.css('left', scopeVar.inline.mouseX - xPos);
          } else {
            // set arrow to be centered over click
            xPos = scopeVar.inline.mouseX - element.width() / 2;
            $arrow.css('left', element.width() / 2);
          }

          if (scopeVar.inline.mouseY < 200 + element.height()) {
            yPos = scopeVar.inline.mouseY + 20;
            // arrow on top
            element.find('.top-arrow').show();
            element.find('.bottom-arrow').hide();
            element.css('z-index', '2');
          } else {
            yPos = scopeVar.inline.mouseY - element.height() - 20;
            // arrow on bottom
            element.find('.top-arrow').hide();
            element.find('.bottom-arrow').show();
            element.css('z-index', '2');
          }

          scope.setLoc(xPos, yPos, true);
        }
      },
    };
  },
]);
