/* global rangy */

/* ====================================================================================== */


/* ====================================================================================== */

angular.module('LeasePilot').directive('contenteditable', [
  '$compile',
  'FreeTextService',
  '$rootScope',
  'LeaseEditorService',
  '$mdDialog',
  '$q',
  '$timeout',
  'CleanFreeTextService',
  'ApiService',
  function(
    $compile,
    FreeTextService,
    $rootScope,
    LeaseEditorService,
    $mdDialog,
    $q,
    $timeout,
    CleanFreeTextService,
    ApiService,
  ) {
    const contentEditableLink = function(scope, element, attrs, ngModel) {
      if (contentEditableManager && !contentEditableManager.inited) {
        contentEditableManager.init(
          $compile,
          FreeTextService,
          $rootScope,
          LeaseEditorService,
          $q,
          $timeout,
          CleanFreeTextService,
          ApiService
        );
      }
    
      contentEditableManager.contentEditableLink(scope, element, attrs, ngModel);
    }
    
    return {
      restrict: 'A',
      require: '?ngModel',
      link: contentEditableLink
    }
  },
]);
