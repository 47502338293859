angular.module("LeasePilot").controller("ManagePartnersController", [
  "$scope",
  "PartnerHelperService",
  function($scope, PartnerHelperService) {
    $scope.init = async function(partnerId) {
      if ($scope.partners[partnerId]) {
        $scope.selectedBuildings = [];

        $scope.partnerId = partnerId;
        $scope.partners[partnerId].buildings.forEach(function(buildingId) { 
          $scope.selectedBuildings[buildingId] = true;
        });

        $scope.showAll = true;

        $scope.safeApply();
      }
    };

    $scope.update = async function() {
      $scope.safeApply(() => {
        $scope.isProcessing = true;
      });

      const promise = PartnerHelperService.update({
        companyId: window.user.company.id,
        partnerId: $scope.partnerId,
        assets: Object.keys($scope.selectedBuildings).filter(buildingId => $scope.selectedBuildings[buildingId]),
      });
      
      promise.then(
        response => {
          window.location.href = "/manage_forms/users";
        },
        error => {
          console.log(error);
          // TODO:
        }
      );
    };

    $scope.cancel = function() {
      window.location.href = "/manage_forms/users";
    };
  }
]);
