import DOMPurify from 'dompurify';

function getDocumentText() {
  if (typeof lease !== undefined) {
    return;
  }

  const root = document.querySelector(".document");

  if (root) {
    const text = root.innerText;
  
    return text
      .replace(/\u200B/g, "") // ZERO WIDTH SPACE
      .replace(/\u200D/g, "") // ZERO WIDTH JOINER
      .replace(/\ufeff/g, ""); // ZERO WIDTH NO-BREAK SPACE;
  }
}

function getContext() {
  const selection = rangy.getSelection();

  if (selection) {
    return selection.toString();
  }

  return null;
}

(function() {
  function AutopilotManager() {
    let $scope = null;

    this.initialized = false;

    this.init = function(_scope) {
      $scope = _scope;

      if (this.initialized) {
        return;
      }

      try {
        if (!lease) {
          return;
        }

        if (window?.user?.company?.companyProfile?.hasCoPilot) {
          const autopilotFrame = document.getElementById("autopilot");
          const params = new URLSearchParams();
          const workspaceId = window.user.company.name;
          params.append("workspaceId", workspaceId);
          const userId = window.user.email;
          params.append("userId", userId);
          const reference = document.location.href;
          params.append("reference", reference);
          const name = document.querySelector(".document-info");
          params.append("name", name?.innerText);

          autopilotFrame.setAttribute(
            "src",
            `${window._autopilot_base_url}/?${params.toString()}`
          );

          window.addEventListener(
            "message",
            event => {
              if (event.origin !== window._autopilot_base_url) {
                return;
              }

              switch (event.data.type) {
                case "INSERT": {
                  this.insert(event);
                  break;
                }
                case "COPY": {
                  this.copy(event);
                  break;
                }
              }
            },
            false
          );

          this.initialized = true;

          setTimeout(() => {
            this.updateDocumentContext();
          }, 3000);
        }
      } catch (e) {
        console.log(e);
        // Do nothing.
      }
    };

    this.updateDocumentContext = function() {
      try {
        const iframe = document.getElementById("autopilot");

        if (iframe) {
          const doc = getDocumentText();
  
          const message = {
            type: "UPDATE_DOCUMENT_CONTEXT",
            payload: {
              doc,
            }
          };
  
          iframe.contentWindow.postMessage(message, window._autopilot_base_url);
        }
      } catch(ex) {
        Raven.captureException(ex);
      }
    };

    this.updatePromptContext = function() {
      try {
        const iframe = document.getElementById("autopilot");

        if (iframe) {
          const context = getContext();
  
          const message = {
            type: "UPDATE_PROMPT_CONTEXT",
            payload: {
              context,
            }
          };
  
          iframe.contentWindow.postMessage(message, window._autopilot_base_url);
        }
      } catch(ex) {
        Raven.captureException(ex);
      }
    }

    this.insert = function(event) {
      const { text } = event.data.payload;

      if (text) {
        let cleanText = text;
        
        cleanText = cleanText.replace(/{{.*?}}/g, '');

        cleanText = DOMPurify.sanitize(cleanText, {
          ALLOWED_TAGS: [],
          ALLOWED_ATTR: [],
        });

        clipboardManager.insertText(cleanText);
      }
    };

    this.copy = function(event) {
      const { text } = event.data.payload;

      if (text) {
        const ClipboardItemData = {
          "text/plain": new Blob([text], { type: "text/plain" })
        };
        const data = [new ClipboardItem(ClipboardItemData)];

        navigator.clipboard.write(data);
      }
    };

    this.getDocumentText = function() {
      return getDocumentText();
    }

    this.getContext = function() {
      return getContext();
    }
  }

  window.autopilotManager = new AutopilotManager();
})();
