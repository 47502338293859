angular.module('LeasePilot').directive('newVersionModal', [
  '$rootScope',
  '$mdDialog',
  '$http',
  'LeaseEditorService',
  function($rootScope, $mdDialog, $http, LeaseEditorService) {
    return {
      restrict: 'E',
      templateUrl: 'new_version_modal.html',
      link: function(scope, element, attrs) {
        scope.ErrorMessage =
          'Oops... something went wrong with the version creation, please wait a couple of seconds and try again.';

        scope.cancel = function() {
          $mdDialog.cancel();
        };

        scope.createNewVersion = function(desc) {
          $http({
            method: 'POST',
            url: '/create_lease_version',
            data: {
              lease_id: $rootScope.getLeaseId(),
              desc: desc || '',
            },
          }).then(
            function(response) {
              const appScope = $rootScope.findAppScope(scope);
              appScope.lease.instanceVersion = response.data.version;
              appScope.lease.instanceDesc = response.data.description;
              appScope.lease.iterationNumber = 0;
              LeaseEditorService.setLeaseTitle();
              scope.cancel();

              window.track.event(new CreateNewVersionEvent({
                version: appScope.lease.instanceVersion,
                description: appScope.lease.instanceDesc,
              }));
            },
            function(error) {
              alert(scope.ErrorMessage);
              scope.cancel();
            },
          );
        };
      },
    };
  },
]);
