angular.module('LeasePilot').directive('guarantors', function() {
  return {
    restrict: 'E',
    scope: {
      guarantorInfo: '=',
      changing: '=',
      changed: '=',
      namesOnly: '=',
    },
    link: function(scope, element) {
      scope.$watch('[changed, changing]', function(newVal, oldVal) {
        var guarantorInfo = scope.guarantorInfo;

        if (!guarantorInfo) {
          return;
        }

        var guarantors = [];
        if (
          typeof guarantorInfo.individuals !== 'undefined' ||
          typeof guarantorInfo.marriedCouples !== 'undefined' ||
          typeof guarantorInfo.entities !== 'undefined'
        ) {
          // This using the new structure of guarantors
          for (
            var x = 0;
            guarantorInfo.individuals && x < guarantorInfo.individuals.length;
            x++
          ) {
            if (scope.namesOnly) {
              guarantors.push(
                '<span class=' +
                  ('lease.guarantorInfo.individuals[' + x + '].name' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.individuals[x].name || '_____________') +
                  '</span>',
              );
            } else {
              // TODO: liorkedmi - we'll probably need to complete it in the future, for now that's all we need.
            }
          }
          for (
            var y = 0;
            guarantorInfo.marriedCouples &&
            y < guarantorInfo.marriedCouples.length;
            y++
          ) {
            if (scope.namesOnly) {
              guarantors.push(
                '<span class=' +
                  ('lease.guarantorInfo.marriedCouples[' + y + '].name1' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.marriedCouples[y].name1 || '_____________') +
                  '</span>',
              );
              guarantors.push(
                '<span class=' +
                  ('lease.guarantorInfo.marriedCouples[' + y + '].name2' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.marriedCouples[y].name2 || '_____________') +
                  '</span>',
              );
            } else {
              // TODO: liorkedmi - we'll probably need to complete it in the future, for now that's all we need.
            }
          }
          for (
            var z = 0;
            guarantorInfo.entities && z < guarantorInfo.entities.length;
            z++
          ) {
            if (scope.namesOnly) {
              guarantors.push(
                '<span class=' +
                  ('lease.guarantorInfo.entities[' + z + '].name' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.entities[z].name || '_____________') +
                  '</span>',
              );
            } else {
              // TODO: liorkedmi - we'll probably need to complete it in the future, for now that's all we need.
            }
          }
        } else {
          // This using the old structure of guarantors
          // We keep this to support former clients
          for (var i = 0; i < guarantorInfo.guarantors.length; i++) {
            if (scope.namesOnly) {
              guarantors.push(
                '<span class=' +
                  ('lease.guarantorInfo.guarantors[' + i + '].name' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.guarantors[i].name
                    ? guarantorInfo.guarantors[i].name
                    : '_____________') +
                  '</span>',
              );
            } else {
              guarantors.push(
                '<span class=' +
                  ('lease.guarantorInfo.guarantors[' + i + '].name' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.guarantors[i].name
                    ? guarantorInfo.guarantors[i].name
                    : '_____________') +
                  '</span>' +
                  (guarantorInfo.type === 'entity'
                    ? ', a ' +
                      '<span class=' +
                      ('lease.guarantorInfo.stateOfFormation' == scope.changing
                        ? '"adding"'
                        : '"added"') +
                      '>' +
                      (guarantorInfo.stateOfFormation
                        ? guarantorInfo.stateOfFormation
                        : '_____________') +
                      '</span> ' +
                      '<span class=' +
                      ('lease.guarantorInfo.entityTypeCombined' ==
                      scope.changing
                        ? '"adding"'
                        : '"added"') +
                      '>' +
                      (guarantorInfo.entityTypeCombined
                        ? guarantorInfo.entityTypeCombined
                        : '_____________') +
                      '</span>'
                    : ', an individual') +
                  ', with an address of ' +
                  '<span class=' +
                  ('lease.guarantorInfo.guarantors[' + i + '].address' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.guarantors[i].address
                    ? guarantorInfo.guarantors[i].address
                    : '_____________') +
                  '</span>' +
                  ', ' +
                  '<span class=' +
                  ('lease.guarantorInfo.guarantors[' + i + '].city' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.guarantors[i].city
                    ? guarantorInfo.guarantors[i].city
                    : '_____________') +
                  '</span>' +
                  ', ' +
                  '<span class=' +
                  ('lease.guarantorInfo.guarantors[' + i + '].state' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.guarantors[i].state
                    ? guarantorInfo.guarantors[i].state
                    : '_____________') +
                  '</span> ' +
                  '<span class=' +
                  ('lease.guarantorInfo.guarantors[' + i + '].zip' ==
                  scope.changing
                    ? '"adding"'
                    : '"added"') +
                  '>' +
                  (guarantorInfo.guarantors[i].zip
                    ? guarantorInfo.guarantors[i].zip
                    : '_____________') +
                  '</span>',
              );
            }
          }
        }

        var result = '';
        for (var i = 0; i < guarantors.length; i++) {
          var isFirst = i === 0;
          var isLast = i === guarantors.length - 1;

          if (isFirst) {
            result += guarantors[i];
          } else if (isLast) {
            result += ' and ' + guarantors[i];
          } else {
            result += ', ' + guarantors[i];
          }
        }

        if (!scope.namesOnly) {
          if (guarantors.length === 1) {
            result += ' (the “Guarantor”) ';
          } else {
            result += ' (jointly and severally, the “Guarantor”) ';
          }
        }

        element.html("<span contenteditable='false'>" + result + '</span>');
      });
    },
  };
});
