angular.module("LeasePilot").directive("resetPasswordModal", [
  "$mdDialog",
  "UserHelperService",
  function($mdDialog, UserHelperService) {
    return {
      restrict: "E",
      templateUrl: "reset_password_modal.html",
      scope: {
        id: "@",
        email: "@"
      },
      link: function($scope) {
        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.resetPassword = function() {
          UserHelperService.resetPassword($scope.id, $scope.password).then(
            response => {
              $mdDialog.cancel();
              window.location.reload();
            },
            error => {
              throw error;
            }
          );
        };

        $scope.generatePassword = function() {
          $scope.password = UserHelperService.generatePassword();
          $scope.validatePassword($scope.password);

          setTimeout(() => {
            document.querySelector("#password").select();
          });
        };

        $scope.validatePassword = function(password) {
          const isValid = UserHelperService.validatePassword(password);
          $scope.isValid = isValid;
        };
      }
    };
  }
]);
