const COLORS_PALETTE = [
  '#8770ec',
  '#f18d8d',
  '#7ecb8e',
  '#fb9162',
  '#c192e8',
  '#eec472',
  '#7ecbc8',
  '#62bcfb',
  '#e66888',
  '#b3d774',
];

// TODO: rename the file to a more generic name
angular.module('LeasePilot').directive('vtsNotes', [
  '$rootScope',
  function($rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'vts_notes.html',
      scope: {
        leaseId: '=',
      },
      link: function($scope) {
        scopeVar = $rootScope.findAppScope($scope);
        $scope.isListOpened = false;
        $scope.openedPanel = 'notes';
        var userColors = [];
        getCorrespondence(scopeVar.lease.externalComments);
        getNotes(scopeVar.lease.notes);

        function getUserColor(userName) {
          // get individual color for every user's avatar
          if (!_.has(userColors, userName)) {
            const userColor = COLORS_PALETTE[Object.keys(userColors).length];
            userColors[userName] = userColor;
          }

          return userColors[userName];
        }

        function getCorrespondence(comments) {
          $scope.correspondence = comments.reduce((filtered, note) => {
            let userName =
              _.get(note, 'rawText.DealComment.User.FirstName', '') +
              ' ' +
              _.get(note, 'rawText.DealComment.User.LastName', '');
            let userColor = getUserColor(userName);
            let dateComments;

            var correspondenceItem = {
              name: userName,
              title: _.get(note, 'rawText.DealComment.User.Title', ''),
              email: _.get(note, 'rawText.DealComment.User.Email', ''),
              abbreviation:
                _.get(note, 'rawText.DealComment.User.FirstName', '').charAt(
                  0,
                ) +
                _.get(note, 'rawText.DealComment.User.LastName', '').charAt(0),
              text: note.text,
              date: moment(note.publishedAt).format('dddd, MMMM Do'),
              time: moment(note.publishedAt).format('h:m A'),
              dateTime: moment(note.publishedAt),
              color: userColor,
            };

            if (
              (dateComments = _.find(filtered, {
                date: correspondenceItem.date,
              }))
            ) {
              dateComments.comments.push(correspondenceItem);
              dateComments.comments.sort((a, b) => {
                return a.dateTime - b.dateTime;
              });
            } else {
              dateComments = {
                date: correspondenceItem.date,
                comments: [],
              };
              dateComments.comments.push(correspondenceItem);
              dateComments.comments.sort((a, b) => a.dateTime - b.dateTime);

              filtered.push(dateComments);
              filtered.sort(
                (a, b) => a.comments[0].dateTime - b.comments[0].dateTime,
              );
            }

            return filtered;
          }, []);
        }

        function getNoteSectionName(note) {
          var sectionName = _.get(note, 'parentAttribute', '')
            .split(/(?=[A-Z])/)
            .join(' ');

          if (note.parent_attribute === 'Right') {
            sectionName = _.get(note, 'rawText.Right.Name', '') + ' right';
          }

          return sectionName;
        }

        function getNotes(notes) {
          let notesSection;

          $scope.notes = notes.reduce((filtered, note) => {
            if (note.parentAttribute === null) {
              return filtered;
            }
            const newNote = {
              section: getNoteSectionName(note),
              text: note.text.replace(/<br\/>/g, ' '),
            };

            if (
              (notesSection = _.find(filtered, {
                sectionName: newNote.section,
              }))
            ) {
              notesSection.notes.push(newNote);
            } else {
              notesSection = {
                sectionName: newNote.section,
                notes: [],
              };

              notesSection.notes.push(newNote);
              filtered.push(notesSection);
            }

            return filtered;
          }, []);
        }

        $scope.toggleNotesList = function() {
          $scope.isListOpened = !$scope.isListOpened;
        };
      },
    };
  },
]);
