const clientListLevels = ["paragraph", "sub-paragraph", "ul-paragraph","normal"];
function ListLevel(data, clientLevelName) {
  this.format = null;
  this.level = null;
  this.listHeader = null;
  this.name = null;
  this.outerTag = null;
  this.outlineDisplayName = null;
  this.previewText = null;
  this.outline = null;

  if (data) {
    Object.keys(data).forEach(key => {
      if (key in this) {
        this[key] = data[key];
      } else {
        if (key !== "$$hashKey") {
          console.error(
            `Error creating ListLevel could not find key ${key} in object`
          );
        }
      }
    });
  } else if (
    clientLevelName &&
    clientListLevels.indexOf(clientLevelName) !== -1
  ) {
    this.level = clientLevelName;
    this.outerTag = "P";
  }

  this.getTemplateText = () => {
    return listItemsManager.$templateCache.get(`${this.level}.html`);
  };

  this.getTemplate = (itemObject, compile) => {
    return listItemsManager.createListItemFromTemplate(
      this.level,
      this.format,
      this.name,
      itemObject,
      compile ? true : false,
      false
    );
  };

  this.getNumberOfFreeTextsInLevel = () => {
    let templateText = this.getTemplateText();
    let count = (templateText.match(/free-text/g) || []).length;
    return count;
  };

  this.getLevelIndexInOutline = () => {
    let indexToReturn = -1;
    let outlineName = this.getOutlineName();

    if (outlineName && listItemsManager.listLevelsMap[outlineName]) {
      let outlineListLevels = listItemsManager.listLevelsMap[outlineName];
      let counter = 0;
      while (counter < outlineListLevels.length && indexToReturn === -1) {
        if (outlineListLevels[counter].level === this.level) {
          indexToReturn = counter;
        }
        counter++;
      }
    }
    return indexToReturn;
  };

  this.getLevelByName = function(name) {
    if (clientListLevels.indexOf(name) !== -1) {
      return new ListLevel(null, name);
    } else {
      const outlineName = this.getOutlineName();
      if (
        outlineName &&
        listItemsManager &&
        listItemsManager.listLevelsMap[outlineName]
      ) {
        const levelList = listItemsManager.listLevelsMap[outlineName];
        let index = 0;
        while (index < levelList.length) {
          if (levelList[index].level === name) {
            return new ListLevel(levelList[index]);
          }
          index++;
        }
      }
    }
    return null;
  };

  this.getNextLevel = () => {
    let outlineName = this.getOutlineName();
    let nextLevelBaseInfo = null;

    if (outlineName && listItemsManager.listLevelsMap[outlineName]) {
      let currentLevelIndex = this.getLevelIndexInOutline();

      if (currentLevelIndex === -1) {
        return null;
      }

      nextLevelBaseInfo =
        listItemsManager.listLevelsMap[outlineName][currentLevelIndex + 1];
    }
    if (nextLevelBaseInfo) {
      return new ListLevel(nextLevelBaseInfo);
    } else {
      return null;
    }
  };

  this.getPreviousLevel = () => {
    let outlineName = this.getOutlineName();
    let nextLevelBaseInfo = null;

    if (outlineName && listItemsManager.listLevelsMap[outlineName]) {
      let currentLevelIndex = this.getLevelIndexInOutline();

      if (currentLevelIndex === -1) {
        return null;
      }

      nextLevelBaseInfo =
        listItemsManager.listLevelsMap[outlineName][currentLevelIndex - 1];
    }
    if (nextLevelBaseInfo) {
      return new ListLevel(nextLevelBaseInfo);
    } else {
      return null;
    }
  };
}

ListLevel.prototype.getOutlineName = function() {
  if (this.outline && this.outline !== "") {
    return this.outline;
  } else {
    return "main";
  }
};

ListLevel.getLevelByName = function(outlineName, name) {
  if (clientListLevels.indexOf(name) !== -1) {
    return new ListLevel(null, name);
  } else {
    if (
      outlineName &&
      listItemsManager &&
      listItemsManager.listLevelsMap[outlineName]
    ) {
      const levelList = listItemsManager.listLevelsMap[outlineName];
      let index = 0;
      while (index < levelList.length) {
        if (levelList[index].level === name) {
          return new ListLevel(levelList[index]);
        }
        index++;
      }
    }
  }
  return null;
};

window.ListLevel = ListLevel;
