angular.module('LeasePilot').filter('ordinalWords', [
  '$filter',
  function($filter) {
    return function(input) {
      if (isNaN(input)) {
        return '[not a number: ' + input + ']';
      }

      if (input < 0) {
        return input;
      }

      input = parseInt(input);

      switch (true) {
        case input == 0:
          return 'zero';
        case !input:
          return null;
        case input == 1:
          return 'first';
        case input == 2:
          return 'second';
        case input == 3:
          return 'third';
        case input == 4:
          return 'fourth';
        case input == 5:
          return 'fifth';
        case input == 6:
          return 'sixth';
        case input == 7:
          return 'seventh';
        case input == 8:
          return 'eighth';
        case input == 9:
          return 'ninth';
        case input == 10:
          return 'tenth';
        case input == 11:
          return 'eleventh';
        case input == 12:
          return 'twelfth';
        case input == 13:
          return 'thirteenth';
        case input == 14:
          return 'fourteenth';
        case input == 15:
          return 'fifteenth';
        case input == 16:
          return 'sixteenth';
        case input == 17:
          return 'seventeenth';
        case input == 18:
          return 'eighteenth';
        case input == 19:
          return 'nineteenth';
        case input == 20:
          return 'twentieth';
        case input < 30:
          return 'twenty ' + $filter('ordinalWords')(input % 10);
        case input == 30:
          return 'thirtieth';
        case input < 40:
          return 'thirty ' + $filter('ordinalWords')(input % 10);
        case input == 40:
          return 'fortieth';
        case input < 50:
          return 'forty ' + $filter('ordinalWords')(input % 10);
        case input == 50:
          return 'fiftieth';
        case input < 60:
          return 'fifty ' + $filter('ordinalWords')(input % 10);
        case input == 60:
          return 'sixtieth';
        case input < 70:
          return 'sixty ' + $filter('ordinalWords')(input % 10);
        case input == 70:
          return 'seventieth';
        case input < 80:
          return 'seventy ' + $filter('ordinalWords')(input % 10);
        case input == 80:
          return 'eightieth';
        case input < 90:
          return 'eighty ' + $filter('ordinalWords')(input % 10);
        case input == 90:
          return 'ninetieth';
        case input < 100:
          return 'ninety ' + $filter('ordinalWords')(input % 10);
        case input == 100:
          return 'one hundredth';
        case input < 200:
          return 'one hundred ' + $filter('ordinalWords')(input % 100);
        case input == 200:
          return 'two hundredth';
        case input < 300:
          return 'two hundred ' + $filter('ordinalWords')(input % 100);
        case input == 300:
          return 'three hundredth';
        case input < 400:
          return 'three hundred ' + $filter('ordinalWords')(input % 100);
        case input == 400:
          return 'four hundredth';
        case input < 500:
          return 'four hundred ' + $filter('ordinalWords')(input % 100);
        case input == 500:
          return 'five hundredth';
        case input < 600:
          return 'five hundred ' + $filter('ordinalWords')(input % 100);
        case input == 600:
          return 'six hundredth';
        case input < 700:
          return 'six hundred ' + $filter('ordinalWords')(input % 100);
        case input == 700:
          return 'seven hundredth';
        case input < 800:
          return 'seven hundred ' + $filter('ordinalWords')(input % 100);
        case input == 800:
          return 'eight hundredth';
        case input < 900:
          return 'eight hundred ' + $filter('ordinalWords')(input % 100);
        case input == 900:
          return 'nine hundredth';
        case input < 1000:
          return 'nine hundred ' + $filter('ordinalWords')(input % 100);
        default:
          return '[number too long: ' + input + ']';
      }
    };
  },
]);
