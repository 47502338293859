angular.module('LeasePilot').directive('buildingVariable', [
  '$rootScope',
  '$mdDialog',
  '$sce',
  'RolesService',
  function($rootScope, $mdDialog, $sce, RolesService) {
    const buildingVariableLink = function(scope, element, attrs) {
      scopeVar = $rootScope.findAppScope(scope);
    
      scope.openDialog = function(event) {
        if (RolesService.isFormAdmin() && !$rootScope.assetsLanguageMode) {
          return;
        }
        scopeVar.inline.hideInline();
        $mdDialog.show({
          template:
            '<md-dialog><edit-building-language-modal ' +
            "bs-name='" +
            scope.bsName +
            "' " +
            'resource-url=' +
            scope.resourceUrl +
            '/></md-dialog>',
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: false,
          fullscreen: false,
        });
      };
    
      scope.hasOverride = function() {
        if (!$rootScope.buildingOverrides || !$rootScope.buildingId)
          return false;
        var buildingData =
          $rootScope.buildingOverrides[$rootScope.buildingId];
        return buildingData && buildingData[scope.bsName] ? true : false;
      };
    
      scope.isFormAdmin = RolesService.isFormAdmin();
    };
    
    return {
      restrict: 'E',
      scope: {
        bsName: '=',
        resourceUrl: '=',
        firstLineData: '=',
        secondLineData: '=',
        firstLineClass: '=',
        secondLineClass: '=',
        firstLineIsChanged: '=',
        secondLineIsChanged: '=',
      },
      templateUrl: 'building_variable.html',
      link: buildingVariableLink,
    };
  },
]);
