if (!Text.prototype.getTopAnchor) {
  Text.prototype.getTopAnchor = function() {
    let container = this;
    let parent;

    while (
      container.parentElement &&
      (parent = container.parentElement.closest("[pid]")) &&
      parent &&
      (parent.tagName !== "DIV" ||
        (parent.tagName === "DIV" &&
          parent.firstElementChild.hasAttribute("advanced-edit")))
    ) {
      container = parent;
    }

    return container;
  };

  Text.prototype.getPreviousTextNodeInBlock = function() {
    const block = this.parentElement.getBlockData();
    const containter = block.containingNode;
    const textNodes = containter.getAllTextNodes();
    return textNodes[textNodes.indexOf(this) - 1];
  };

  Text.prototype.getNextTextNodeInBlock = function() {
    const block = this.parentElement.getBlockData();
    const containter = block.containingNode;
    const textNodes = containter.getAllTextNodes();
    return textNodes[textNodes.indexOf(this) + 1];
  };

  Object.defineProperty(Text.prototype, "getBlockData", {
    value: function() {
      return listItemsManager.getListItemObjectFromNode(this.parentElement);
    },
    enumerable: false
  });

  Text.prototype.closest = function(param) {
    return this.parentElement.closest(param);
  };
}
