(function() {
  "use strict";

  document.addEventListener("DOMContentLoaded", () => {
    const POSITION_RIGHT = "right";
    const POSITION_LEFT = "left";
    const menus = document.querySelectorAll(".lp-menu");

    const styles = document.querySelectorAll(".lp-menu [data-menu-width], .lp-menu[data-menu-position]");
    styles.forEach(element => {
      const width = element.dataset.menuWidth;
      const position = element.dataset.menuPosition;

      if (width) {
        element.style.minWidth = `${width}px`;
      }

      if (position === POSITION_RIGHT) {
        element.style.right = "0";
      } else if (position === POSITION_LEFT) {
        element.style.left = "0";
      }
    });
  
    menus.forEach(menu => {
      const position = menu.dataset.menuPosition || POSITION_LEFT;
      const button = menu.querySelector(".lp-menu-button");
      const content = menu.querySelector(".lp-menu-content");
  
  
      button.addEventListener("click", (event) => {
        event.stopPropagation();

        const opened = document.querySelector(".lp-menu--open");
        const menu = event.target.closest(".lp-menu");
        const menuItem = document.querySelector(".lp-menu-item.open");

        if (opened) {
          opened.classList.toggle("lp-menu--open");
        }

        if (menu) {
          menu.classList.toggle("lp-menu--open");
        }

        if (menuItem) {
          menuItem.classList.toggle("open");
        }
      });
    });

    document.addEventListener("click", (event) => {
      event.stopPropagation();

      const menu = document.querySelector(".lp-menu--open");
      const menuItem = document.querySelector(".lp-menu-item.open");
      let autoClose = true;

      if (event.target.closest("[data-auto-close]")) {
        autoClose = event.target.closest("[data-auto-close]").getAttribute("data-auto-close") !== "false";
      }
      
      // We want to keep the menu open when clicking on an item which opens a sub-menu
      const subMenu = event.target.closest(".lp-menu-item");
      if (subMenu && subMenu.classList.contains("lp-menu-item--submenu")) {
        return;
      }

      if (autoClose) {
        if (menu) {
          menu.classList.toggle("lp-menu--open");
        }
      }

      if (menuItem) {
        menuItem.classList.toggle("open");
      }
    });
  });
})();
