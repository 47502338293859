angular.module('LeasePilot').directive('deleteBlankModal', [
  '$mdDialog',
  'BlankLeaseService',
  function($mdDialog, BlankLeaseService) {
    return {
      restrict: 'E',
      templateUrl: 'delete_blank.html',
      scope: {
        blankId: '@',
        blankName: '@',
      },
      link: function($scope) {
        $scope.name;

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.disabled = function() {
          return !$scope.name || $scope.name != $scope.blankName;
        };

        $scope.delete = function() {
          new BlankLeaseService({ id: $scope.blankId }).delete().then(
            function() {
              window.location.reload();
            },
            function(error) {
              $scope.error = error.data.error;
            },
          );
        };
      },
    };
  },
]);
