angular.module('LeasePilot').directive('downloadSml', [
  'ApiService',
  'DownloadService',
  'ProcessStatusService',
  'FreeTextToSmlService',
  function(
    ApiService,
    DownloadService,
    ProcessStatusService,
    FreeTextToSmlService,
  ) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'download_sml.html',
      scope: {
        company: '=',
        insertChanges: '=',
        changes: '=',
      },
      link: function($scope) {
        $scope.changes = $scope.changes || {};

        $scope.downloadSML = function($file) {
          var type =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          ProcessStatusService.start('download');
          var apiOpertaion;
          if ($scope.insertChanges) {
            var changes = {};
            // initialize freeTexts changes.
            changes.freeTexts = _.chain($scope.changes.overrides)
              .map(function(override) {
                var SML = FreeTextToSmlService.convert($(override.text))
                  .wrapAll('<p/>')
                  .parent();
                return {
                  sectionId: override.sectionId,
                  html: SML.html(),
                };
              })
              .value();

            changes.listItems = $scope.changes.listItems;

            apiOpertaion = ApiService.SMLToMSWordWithChanges($file, changes);
          } else {
            apiOpertaion = ApiService.SMLToMSWord($file, $scope.company);
          }

          apiOpertaion.then(
            function successCallback(response) {
              ProcessStatusService.end('download');
              $scope.safeApply();
              DownloadService.download(response.data, type, fileName($file));
            },
            function errorCallback(response) {
              console.error(response);
              ProcessStatusService.end('download');
              $scope.safeApply();
            },
          );
        };

        var fileName = function(file) {
          if ($scope.insertChanges) {
            return file.name.replace('.docx', ' - With Changes.docx');
          } else {
            return file.name.replace('.docx', ' - SecID.docx');
          }
        };
      },
    };
  },
]);
