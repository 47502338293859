angular.module('LeasePilot').directive('share', [
  'ShareService',
  function (ShareService) {
    return {
      restrict: 'E',
      templateUrl: function (tElement, tAttrs) {
        if (tAttrs) {
          if (tAttrs.mode === 'menu') {
            return 'share_menu_item.html';
          } else if (tAttrs.mode === 'dashboard') {
            return 'share_dashboard.html';
          } else {
            return 'share.html';
          }
        }
      },
      scope: {
        leaseId: '=',
        type: '@',
        mode: '@',
      },
      link: function ($scope) {
        $scope.showShareDialog = function (event) {
          const leaseData = {
            leaseId: $scope.leaseId,
            type: $scope.type,
          }

          ShareService.showShareDialog(event, leaseData);
        };

      },
    };
  },
]);
