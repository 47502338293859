angular.module('LeasePilot').directive('generateTag', [
  '$compile',
  function($compile) {
    return {
      transclude: true,
      template: '<ng-transclude></ng-transclude>',
      link: function(scope, element, attr) {},
    };
  },
]);
