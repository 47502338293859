var brDirective = [
  "$animate",
  "$compile",
  function($animate, $compile) {
    return {
      restrict: "E",
      link: function(scope, element, attr) {
        if (window.isDownload) {
          return;
        }

        let domElement = element[0];
        let style = domElement.getAttribute("style");

        if (
          style &&
          style.indexOf("section-break") === -1 &&
          (style.indexOf("page-break") !== -1 ||
            style.indexOf("break-before: page") !== -1)
        ) {
          domElement.style.position = "relative";
          pageLayersManager.addRemoveBtnToPageBreak(domElement);
        }
      }
    };
  }
];

angular.module("LeasePilot").directive("br", brDirective);
