angular.module('LeasePilot').directive('exportLeaseModal', [
  '$document',
  '$http',
  '$rootScope',
  '$timeout',
  '$mdDialog',
  'ApiService',
  function($document, $http, $rootScope, $timeout, $mdDialog, ApiService) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'export_lease_modal.html',
      link: function(scope, element) {
        scope.status = 'pending';
        scope.cancelled = false;
        scope.eventName = 'export';

        $rootScope.$on('exportLeaseDialogClosed', function() {
          scope.cancelled = true;
        });

        scope.doneStatus = function() {
          return scope.status === 'exported';
        };

        scope.errorStatus = function() {
          return _.includes(
            [
              'task_creation_failed',
              'conversion_failed',
              'export_failed',
              'html_to_word_conversion_failed',
            ],
            scope.status,
          );
        };

        scope.pendingStatus = function() {
          return !(scope.doneStatus() || scope.errorStatus());
        };

        scope.cancel = function() {
          $mdDialog.cancel();
        };
      },
    };
  },
]);
