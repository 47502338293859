const NG_HIDE_CLASS = "lp-hide";
const NG_SHOW_CLASS = "lp-show";
const NG_HIDE_ElEMENT_CLASS = "lp-hide-element";
const NG_SHOW_ElEMENT_CLASS = "lp-show-element";
const DATA_IF_ATTRIBUTE_NAME = "data-if-id";

const colorArray = ["red","blue","green","gray","blueviolet","burlywood","cyan"];


function LeaseIfItem(element, id, manager,appScope) {
  this.element = element;
  this.id = id;
  this.currentValue = false;
  this.parentIfId = null;
  this.parentIfObject = null;
  this.children = [];
  this.manager = manager;
  this.startMarker = null;
  this.endMarker = null;
  this.isComplicatedCondition = false;


  let relateds = null;
  if (appScope) {
    relateds = appScope.inline.util.checkRelateds(
      splitVariable(element.getAttribute('lease-if')),
      element,
    );
  }
  
  var isComplicatedCondition =
  _.uniq(
    _.union(relateds, appScope.inline.findNestedControls(element)),
  ).length > 1;

  if(isComplicatedCondition){
    this.isComplicatedCondition  = true;
    if(this.isComplicatedCondition && leaseIfManager.isInFormManager){
      element.setAttribute('data-Complicated-Condition',true);
    }
    
  }



  this.element.setAttribute(DATA_IF_ATTRIBUTE_NAME, this.id);

  if (this.element.parentElement) {
    const parentIfElement = this.element.parentElement.closest(
      `[${DATA_IF_ATTRIBUTE_NAME}]`
    );
    if (parentIfElement) {
      this.parentIfId = parentIfElement.getAttribute(DATA_IF_ATTRIBUTE_NAME);
      this.parentIfObject = this.manager.getElement(this.parentIfId);
      this.parentIfObject.children.push(this);
    }
  }

  this.updateValue = value => {
    this.currentValue = value;
    this.updateElementStyle();
  };

  this.addRemoveIfMarkers = ()=>{
    
    if(window.leaseIfManager.forceShowAllFlage){
      let color =  colorArray[ this.id % colorArray.length ];
      if(!color){
        debugger;
      }
      if(!this.startMarker){
        this.startMarker = document.createElement('span');
        this.startMarker.textContent = `[[[${this.element.tagName== "SPAN" ? 'ifi' : 'if'} cn="${this.element.getAttribute('lease-if')}"]]]`;
        this.startMarker.setAttribute('style',`background-color:${color};opacity:0.7;`);
      }
      if(!this.endMarker){
        this.endMarker = document.createElement('span');
        this.endMarker.textContent = `[[[end-if]]]`;
        this.endMarker.setAttribute('style',`background-color:${color};opacity:0.7;`);
      }
    }

    if(window.leaseIfManager.forceShowAllFlage){
      if(!this.startMarker.parentElement){
        this.element.parentElement.insertBefore(this.startMarker,this.element);
      }
      if(!this.endMarker.parentElement){
        this.element.parentElement.insertBefore(this.endMarker,this.element.nextElementSibling);
      }
    }
    else{
      this.startMarker.remove();
      this.endMarker.remove();
    }
  }

  this.getIsComplicatedCondition = ()=>{
    if(this.isComplicatedCondition) {
      return true;
    }
    else{
      let parentObj = this.parentIfObject;  
      while (parentObj) {
        if(parentObj.isComplicatedCondition){
          return true;
        }
        parentObj = parentObj.parentIfObject;
      }
      return false;
    }
  }

  this.getValue = () => {
    if(window.leaseIfManager.forceShowAllFlage){
      return window.leaseIfManager.forceShowAllFlage;
    }
    let value = this.currentValue;
    let parentObj = this.parentIfObject;
    while (parentObj) {
      value = value && parentObj.currentValue;
      parentObj = parentObj.parentIfObject;
    }
    return value;
  };

  this.updateElementStyle = () => {
    const value = this.getValue();
    let wrapperClassToRemove = value ? NG_HIDE_CLASS : NG_SHOW_CLASS;
    let wrapperClassToAppend = value ? NG_SHOW_CLASS : NG_HIDE_CLASS;
    let elementClassToRemove = value
      ? NG_HIDE_ElEMENT_CLASS
      : NG_SHOW_ElEMENT_CLASS;
    let elementClassToAppend = value
      ? NG_SHOW_ElEMENT_CLASS
      : NG_HIDE_ElEMENT_CLASS;

    this.element.classList.add(wrapperClassToAppend);
    this.element.classList.remove(wrapperClassToRemove);

    if (this.element.tagName !== "DIV") {
      this.element.classList.add(elementClassToAppend);
      this.element.classList.remove(elementClassToRemove);
    }

    for (
      let childrenIndex = 0;
      childrenIndex < this.children.length;
      childrenIndex++
    ) {
      this.children[childrenIndex].updateElementStyle();
    }

    this.updateChildElementsStyle(
      this.element,
      elementClassToAppend,
      elementClassToRemove
    );
  };

  this.updateChildElementsStyle = (
    element,
    elementClassToAppend,
    elementClassToRemove
  ) => {
    for (let index = 0; index < element.childNodes.length; index++) {
      let node = element.childNodes[index];
      if(node.classList  && node.classList.contains('diff-item')){
        continue;
      }
      else if (node.tagName && node.tagName.startsWith("CONCEPT")) {
        this.updateChildElementsStyle(
          node,
          elementClassToAppend,
          elementClassToRemove
        );
      } else if (node.firstElementChild && node.firstElementChild.hasAttribute("advanced-edit")) {
        if (node.firstElementChild.classList) {
          node.firstElementChild.classList.add(elementClassToAppend);
          node.firstElementChild.classList.remove(elementClassToRemove);

          this.updateChildElementsStyle(
            node.firstElementChild,
            elementClassToAppend,
            elementClassToRemove
          );
        }
      } else {
        if (node.classList) {
          let ifId = node.getAttribute(DATA_IF_ATTRIBUTE_NAME);
          let ignoreLeaseIf = node.classList.contains("lp-if-ignore");

          if (!ifId && !ignoreLeaseIf) {
            node.classList.add(elementClassToAppend);
            node.classList.remove(elementClassToRemove);
          }
        }
      }
    }
  };
}

function LeaseIfManager() {
  this.LEASE_IF_COUNTER = 1;
  this.leaseIfItems = {};
  this.forceShowAllFlage = false;

  this.leaseChangesElementList = null;
  this.changeIndex = 0;



  this.tryGetArrayForNgRepeat = (name)=>{
    if(name.indexOf('.') == -1 ){
      return null;
    }
    const parts = name.split('.');
    let startPoint = scopeVar[parts[0]];
    
    for(let i=1; i< parts.length; i++){
      if(!startPoint[parts[i]]){
        return null;
      }
      else{
        startPoint = startPoint[parts[i]];
      }
    }

    return startPoint;

    // try{
    //   // return eval(name);

    // }
    // catch(e){
    //   return null;
    // }
  }

  this.tryShowAllNgRepeat = ()=>{

    const documentEl = document.querySelector('.document');
    let comments = documentEl.getAllCommentNodes().filter((com)=>{
      return com.textContent.indexOf('ngRepeat') !== -1;
    });

    this.ngRepeatElements = [];
    const reg = new RegExp(/in (.*?) /);
    comments.forEach((com)=>{
      let regSearch = reg.exec(com.textContent);
      if(regSearch && regSearch[1]){
        let arr = this.tryGetArrayForNgRepeat(regSearch[1]);
        if(arr && arr.length == 0 ){
          arr.push({});
        }
      }
    });
    scopeVar.safeApply();

  }




  this.changeItemTextDisplay = target => {
    const textDispyEl = document.querySelector(".originalText");
    const textDispyElCurr = document.querySelector(".currentText");
    
    textDispyEl.textContent = "";
    textDispyElCurr.textContent = ""; 

    if (target.tagName === "SPAN" && !target.closest('.deleted-container')) {
      target = target.hasAttribute("free-text")
        ? target
        : target.closest("[free-text]");

      const orgText = window.originalFreeText[target.getAttribute("free-text")];

      if (orgText && orgText.length !== 0) {
        textDispyEl.innerHTML = `<b>Original Text:</b><span> ${orgText} </span>`;
        textDispyElCurr.innerHTML = `<b>Currnt Text:</b><span> ${target.textContent} </span>` ;
      }
    }
    else if(!target.closest('.deleted-container')) {
      textDispyElCurr.innerHTML = `<b>Currnt Text:</b><span> ${target.textContent} </span>` ;
    }
  };

  this.toggleShowAllView = ()=>{

    if(window.location.href.indexOf('smlView') == -1){
      window.location.href = window.location.href + '?smlView=1';
      return;
    }

    if(this.forceShowAllFlage){
      return;
    }

    this.tryShowAllNgRepeat();

    this.forceShowAllFlage = !this.forceShowAllFlage;
    this.updateAllIfDisplay();
    scopeVar.compileVisibleFreeTexts();

    setTimeout(() => {

      scopeVar.overrideOrderedListitem.forEach((item)=>{
        let el = null;
        if(item.jsonID){
          el = document.querySelector(`[data-json-id="${item.jsonID}"]`);
        }
        if(el){
          el.classList.add('override-color');
        }
      });

      scopeVar.overrides.forEach((item)=>{
        if(item.sectionId){
          const el = document.querySelector(`[section-id="${item.sectionId}"]`);
          if(el ){ //&& !el.querySelector('.override-color') && !el.closest('.deleted-container') && !el.closest('.override-color') ){
            el.classList.add('override-color-changes');
          }
        }
      })

      document.querySelectorAll('.deleted-container [free-text]').forEach((freeText)=>{
        const span = document.createElement('span');        
        span.textContent = window.originalFreeText[freeText.getAttribute('free-text')];
        freeText.appendChild(span);

      });

      this.leaseChangesElementList = Array.from(
        document.querySelectorAll(
          ".override-color,.deleted-container,.override-color-changes"
        )
      ).filter(item => {
        return !item.parentElement.closest(".override-color") && !item.parentElement.closest(".override-color-changes");
      });

      

      this.changeIndex = 0;

      
      const leaseWrapperElement = document.querySelector('.lease-wrapper');
      const leaseElement = document.querySelector('.lease');
      if(this.forceShowAllFlage){
        leaseWrapperElement.classList.add('manage');
        leaseElement.classList.add('showAll');
      }
      else{
        leaseWrapperElement.classList.remove('manage');
        leaseElement.classList.remove('showAll');
      }
      
      this.showChangesBox();
      this.updateChangeLocationText();
      
    },500);

    
   
  }


  this.changesBox = null;
  this.nextChange = null;
  this.prevChange = null;
  this.dataChange = null;
  this.totalChanges = null;
  this.createChangesBox = ()=>{
    if(!this.changesBox){
      this.changesBox = document.createElement('div');
      this.changesBox.classList.add('chages-wrapper');
      this.changesBox.innerHTML = `<div class="title">Changes In Document: <span class="totalChanges"> </span> </div> <div class="btns">
                                    <div id="showText" > Show : 
                                    <input type="checkbox" name="showOrg" id="showOrg" value="Original" checked=true>
                                    <label for="showOrg"> Original</label>
                                    <input type="checkbox" name="showCrr" id="showCrr" value="Current" checked=true>
                                    <label for="showCrr"> Current</label>
                                    </div>                                    
                                    <div class="prev btn blue"> <- </div>
                                    <div class="data"> </div>
                                    <div class="next btn blue"> -> </div>                                   
                                    </div>
                                    <div class="originalText" > </div>
                                    <div class="currentText" > </div>
                                    `;

      this.nextChange = this.changesBox.querySelector('.next');
      this.prevChange = this.changesBox.querySelector('.prev');
      this.dataChange = this.changesBox.querySelector('.data');
      this.totalChanges = this.changesBox.querySelector('.totalChanges');
      this.changesBox.querySelector("#showOrg").addEventListener('change',this.updateTextDisplay);      
      this.changesBox.querySelector("#showCrr").addEventListener('change',this.updateTextDisplay);

      this.nextChange.addEventListener('click',this.goToNextChange);
      this.prevChange.addEventListener('click',this.goToPrevChange);
    }
  }
  
  this.updateTextDisplay = (e)=>{
    document.querySelector(".originalText").style.display = this.changesBox.querySelector("#showOrg").checked ? 'block' : 'none' ;
    document.querySelector(".currentText").style.display = this.changesBox.querySelector("#showCrr").checked ? 'block' : 'none' ;
  }

  this.goToNextChange = ()=>{
    this.leaseChangesElementList[this.changeIndex].classList.remove('changeMarkup');
    if(this.changeIndex + 1 < this.leaseChangesElementList.length){
      this.changeIndex++;
    }
    else{
      this.changeIndex = 0;
    }
    this.updateChangeLocationText();
  };
  this.goToPrevChange = ()=>{
    this.leaseChangesElementList[this.changeIndex].classList.remove('changeMarkup');
    this.changeIndex--;
    if(this.changeIndex<0){
      this.changeIndex = this.leaseChangesElementList.length-1;
    }
    this.updateChangeLocationText();

  };  

  this.updateChangeLocationText = ()=>{
    this.leaseChangesElementList[this.changeIndex].scrollIntoViewIfNeeded();
    this.changeItemTextDisplay(this.leaseChangesElementList[this.changeIndex]);
    this.leaseChangesElementList[this.changeIndex].classList.add('changeMarkup');
    
    this.dataChange.textContent = `${this.changeIndex} / ${this.leaseChangesElementList.length -1}`;
  };

  this.showChangesBox = ()=>{
    const leaseElement = document.querySelector('.lease-layout');
    this.createChangesBox();
    leaseElement.appendChild(this.changesBox);
    this.totalChanges.textContent = `${this.leaseChangesElementList.length -1}`;
    this.dataChange.textContent = `${this.changeIndex} / ${this.leaseChangesElementList.length -1}`;
  };
  this.hideChangesBox = ()=>{
    this.changesBox.remove();
  };

  this.forceShowAll = ()=>{
    this.forceShowAllFlage = true; 
    this.updateAllIfDisplay();
  }

  this.showRealValues = ()=>{
    this.forceShowAllFlage = false; 
    this.updateAllIfDisplay();
  }

  this.updateAllIfDisplay = ()=>{
    Object.keys( leaseIfManager.leaseIfItems).forEach((key)=>{
      this.leaseIfItems[key].updateElementStyle();
      this.leaseIfItems[key].addRemoveIfMarkers();
    });
  }

  this.isInFormManager = document.querySelector('body.forms-edit') ? true : false;

  this.addIfElementToManager = (element,appScope) => {
    if (!element.hasAttribute(DATA_IF_ATTRIBUTE_NAME)) {
      this.LEASE_IF_COUNTER++;
      const ifObject = new LeaseIfItem(element, this.LEASE_IF_COUNTER, this,appScope);
      this.leaseIfItems[ifObject.id] = ifObject;
      return ifObject.id;
    } else {
      return this.leaseIfItems[element.getAttribute(DATA_IF_ATTRIBUTE_NAME)];
    }
  };

  this.updateIfValue = (id, value) => {
    if (id) {
      leaseIfManager.leaseIfItems[id].updateValue(value);
    }
  };

  this.getElement = index => {
    return this.leaseIfItems[index];
  };

  this.registerAllIfObjects = (container,appScope) => {
    const workElement = container || document;
    const ifElements = workElement.querySelectorAll("[lease-if]");
    for (var index = 0; index < ifElements.length; index++) {
      this.addIfElementToManager(ifElements[index],appScope);
    }
  };
}

window.leaseIfManager = new LeaseIfManager();

var leaseIfDirective = [
  "$animate",
  "$compile",
  "$rootScope",
  function($animate, $compile, $rootScope) {
    const leaseIfLink = function(scope, element, attr) {
      const appScope = $rootScope.findAppScope();
      let ifID = element[0].getAttribute(DATA_IF_ATTRIBUTE_NAME);
      const container = element[0].getTopAnchor();
    
      
    
      if (!ifID) {
        if(container && container.closest('body')){
          leaseIfManager.registerAllIfObjects(null,appScope);
        }
        else{            
          leaseIfManager.registerAllIfObjects(container,appScope);
        }
        ifID = element[0].getAttribute(DATA_IF_ATTRIBUTE_NAME);
      }
    
      if (attr.listLevel) {
        let keys = Object.keys(attr.$attr);
        let sectionEndElement = document.createElement("section-end");
        keys.forEach(key => {
          if (key === "leaseIfDeprecated") {
            sectionEndElement.setAttribute(
              "data-leaseIf",
              attr["leaseIfDeprecated"]
            );
          } if(key === 'fts' && attr[key].indexOf('+') !== -1)  {
            sectionEndElement.setAttribute("data-" + key, attr[key]);
          } else {
            sectionEndElement.setAttribute("data-" + key, attr[key]);
          }
        });
        element[0].parentElement.insertBefore(sectionEndElement, element[0]);
      }
    
      scope.$watch(attr.leaseIf, function leaseIfDeprecatedWatchAction(
        value
      ) {
        value = !!value;
    
        if (!ifID) {
          setTimeout(() => {
            leaseIfManager.registerAllIfObjects(null,appScope);
            ifID = element[0].getAttribute(DATA_IF_ATTRIBUTE_NAME);
    
            leaseIfManager.updateIfValue(ifID, value);
            if (value) {
              if (!appScope.isLoading) {
                appScope.compileVisibleFreeTexts(element[0]);
              }
            }
          });
        } else {
          leaseIfManager.updateIfValue(ifID, value);
          if (value) {
            if (!appScope.isLoading) {
              appScope.compileVisibleFreeTexts(element[0]);
              if (element[0].tagName === "DIV") {
                setTimeout(() => {
                  if(element[0].querySelector('[list]')){
                    if (appScope && appScope.listsRefresh) {
                      appScope.listsRefresh();
                    }
                  }
                });
              }
            }
          }
        }
      });
    };
    
    return {
      restrict: "A",
      multiElement: true,
      link: leaseIfLink,
    };
  }
];

angular.module("LeasePilot").directive("leaseIf", leaseIfDirective);
