angular.module('LeasePilot').directive('lpTextArea', [
  '$rootScope',
  function($rootScope) {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'lp_text_area.html',
      scope: {
        model: '@',
      },
      link: function(scope, element, attrs) {
        scope.scopeVar = $rootScope.findAppScope(scope);
        scope.model = 'scopeVar.' + scope.model;
      },
    };
  },
]);
