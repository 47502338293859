angular.module('LeasePilot').factory('CleanFreeTextService', function () {
  const CleanFreeTextService = {
    cleanVersionControlTags: function (data) {
      if (!data) return data;
      let element = document.querySelector('[section-id="'+data.section_id+'"]');
      if(element  && element.closest('ins,del,.deleted-element')){
        if (data.action === "delete") {
          data.action = "update";
        }
        data.text = this.cleanElementBySectionId(data.section_id);
      }
      else{
        data.text = this.cleanHTMLText(data.text);
      }
      return data;
    },
    cleanBulk: function (data) {
      if (!data) return data;
      let returnData = {};
      Object.keys(data).forEach((key) => {
        let item = data[key];
        item = this.cleanVersionControlTags(item);
        returnData[key] = item;
      });
      return returnData;
    },
    cleanHTMLText : function(html){
      let el = document.createElement('el');
      el.innerHTML = html;
      this.cleanHtmlForAcceptReject(el);
      return el.innerHTML;
    },
    // update the content of the element by the status of the
    // containing  ins / del tag
    cleanHtmlForAcceptRejectBlocks: function (element) {
      let delElement = element.closest('del');
      let insElement = element.closest('ins');
      let isDeletedElement = element.closest('.deleted-element') ? true : false;
      if ((delElement && delElement.classList.contains('diff-item--accepted')) ||
        (insElement && !insElement.classList.contains('diff-item--accepted')) || isDeletedElement ) {
        let wElement = document.createElement(element.tagName);
        wElement.innerHTML = element.innerHTML;
        let eocElements = wElement.querySelectorAll('.EOCE');
        eocElements.forEach((node) => {
          node.parentElement.innerHTML = '<span style="display:none;user-select:none;" class="deleted-paragraph"></span>';
        });
        return wElement;
      }
      return element;
    },
    // sanitize the html (remove ins /del tags ) according to the
    // marker status
    cleanHtmlForAcceptReject: function (element, fromExitReview) {

      if (fromExitReview) {
        element.querySelectorAll('ins[list-header]').forEach((node) => {
          if (node.querySelector('.diff-item--accepted')) {
            Array.from(node.children).reverse().forEach((el) => {
              el.remove();
              node.parentElement.insertBefore(el, node);
            });
          }
        });
      }


      element.querySelectorAll('[data-vc-id]').forEach((i)=> {i.removeAttribute('data-vc-id');})
      
      const list = element.querySelectorAll('.diff-item');
      list.forEach(node => {
        node.querySelectorAll('.data-diff-wrap').forEach(el => {
          el.classList.remove('data-diff-wrap');
        });
        if (node.tagName === 'DEL') {
          if (!node.classList.contains('diff-item--accepted')) {
            var childNodesCount = node.childNodes.length;
            for (var i = 0; i < childNodesCount; i++) {
              // `insertBefore` removes the node from the DOM,
              // so the first element is deleted from childNodes array.
              node.parentElement.insertBefore(node.childNodes[0], node);
            }
          }
          node.remove();
        } else if (node.tagName === 'INS') {
          node.querySelectorAll('[pid]').forEach(el => {
            el.removeAttribute('pid');
          });
          if (node.classList.contains('diff-item--accepted')) {
            var childNodesCount = node.childNodes.length;
            for (var i = 0; i < childNodesCount; i++) {
              // `insertBefore` removes the node from the DOM,
              // so the first element is deleted from childNodes array.
              node.parentElement.insertBefore(node.childNodes[0], node);
            }
          }
          node.remove();
        }
        node.classList.remove(
          'diff-item',
          'diff-item--static',
          'diff-item--deletion',
        );
      });

      const highlight = element.querySelectorAll('.highlight');
      highlight.forEach(node => {
        node.classList.remove('highlight');
      });
    },

    cleanElementBySectionId: function (sectionId, keepVersionControlTags = false) {
      let element = document.querySelector('[section-id="'+sectionId+'"]');
      return this.cleanHTMLElement(element,keepVersionControlTags);
    },

    cleanHTMLElement: function (element, keepVersionControlTags = false) {
      if(!element) return '';
      // update saved element (no DOM changes) for version control if we are in BLOCK change
      let workElementInitial = keepVersionControlTags ? element : this.cleanHtmlForAcceptRejectBlocks(element);

      let workElement = document.createElement('div');
      workElement.appendChild(workElementInitial.cloneNode(true));

      const added = workElement.querySelectorAll('.added');
      added.forEach(node => {
        node.classList.remove('added');
      });

      const ngScope = workElement.querySelectorAll('.ng-scope');
      ngScope.forEach(node => {
        node.classList.remove('ng-scope');
      });

      const ngBinding = workElement.querySelectorAll('.ng-binding');
      ngBinding.forEach(node => {
        node.classList.remove('ng-binding');
      });

      const rangySelectionBoundaries = Array.from(workElement.querySelectorAll('.rangySelectionBoundary'));
      rangySelectionBoundaries.forEach(node => {
        node.remove();
      });

      const imageControls = workElement.querySelectorAll('image-controls');
      imageControls.forEach(node => {
        node.remove();
      });

      const imageClasses = workElement.querySelectorAll('img.active, .image-container--active');
      imageClasses.forEach(node => {
        node.classList.remove('active');
        node.classList.remove('image-container--active');
      });

      if (!keepVersionControlTags) {
        this.cleanHtmlForAcceptReject(workElement);
      }

      // Clean control-characters
      const spans = workElement.querySelectorAll('span');
      spans.forEach(span => {
        span.style.fontFamily = span.style.fontFamily.replace('lp, ', '');
      });

      return workElement.childNodes[0].innerHTML;
    },
  };

  return CleanFreeTextService;
});
