angular.module('LeasePilot').directive('uploadSmlErrorsModal', [
  '$mdDialog',
  function($mdDialog) {
    return {
      restrict: 'E',
      scope: {
        smlErrorMsg: '@',
        buildingErrorMsg: '@',
      },
      templateUrl: 'upload_sml_errors.html',
      link: function(scope) {
        scope.showDetails = false;
        scope.cancel = function() {
          $mdDialog.cancel();
        };
        scope.toggleDetails = function() {
          scope.showDetails = !scope.showDetails;
        };
      },
    };
  },
]);
