angular.module('LeasePilot').directive('removedGuarantors', [
  '$compile',
  function($compile) {
    return {
      restrict: 'E',
      scope: {
        lease: '=',
        changing: '=',
        changed: '=',
      },
      link: function(scope, element, attrs) {
        function getIndex(guarantorType, item) {
          return _.indexOf(
            scope.lease.existingGuarantorInfo[guarantorType],
            item,
          );
        }

        function getPlainText(scope, model, text) {
          if (!text) {
            return '__________';
          } else {
            return (
              '<span class=' +
              (model == scope.changing ? '"adding"' : '"added"') +
              '>' +
              text +
              '</span>'
            );
          }
        }

        function getDate(scope, model, date) {
          if (!date) {
            return '__________';
          } else {
            return (
              '<span class=' +
              (model == scope.changing ? '"adding"' : '"added"') +
              '>' +
              moment(date).format('MMMM D, YYYY') +
              '</span>'
            );
          }
        }

        function createJoindArray(individuals, marriedCouples, entities) {
          return _.filter(_.union(individuals, marriedCouples, entities), {
            hasBeenReleased: true,
          });
        }

        function createFirstSectionLanguage(guarantors) {
          if (guarantors.length === 1) {
            return '';
          } else {
            return 's';
          }
        }

        function createSecondSectionLanguage(guarantors) {
          var list = [];
          var result;

          for (var i = 0; i < guarantors.length; i++) {
            var guarantor = guarantors[i];
            if (guarantor.type === 'individual') {
              list.push(
                _.string.sprintf(
                  '%s Guaranty',
                  getPlainText(
                    scope,
                    'lease.existingGuarantorInfo.individuals[' +
                      getIndex('individuals', guarantor) +
                      '].nickname',
                    guarantor.nickname,
                  ),
                ),
              );
            } else if (guarantor.type === 'married') {
              list.push(
                _.string.sprintf(
                  '%s Guaranty',
                  getPlainText(
                    scope,
                    'lease.existingGuarantorInfo.marriedCouples[' +
                      getIndex('marriedCouples', guarantor) +
                      '].nickname',
                    guarantor.nickname,
                  ),
                ),
              );
            } else if (guarantor.type === 'entity') {
              list.push(
                _.string.sprintf(
                  '%s Guaranty',
                  getPlainText(
                    scope,
                    'lease.existingGuarantorInfo.entities[' +
                      getIndex('entities', guarantor) +
                      '].nickname',
                    guarantor.nickname,
                  ),
                ),
              );
            }
          }

          result = _.string.toSentence(list, ' or the ', ' or the ');

          if (list.length === 1) {
            result = _.string.sprintf(' and the %s', result);
          } else {
            result = _.string.sprintf(
              ', the %s (the “Released Guaranties”)',
              result,
            );
          }

          return result;
        }

        function createThirdSectionLanguage(guarantors) {
          var list = [];
          var result;
          var hasMarriedCouple = false;

          for (var i = 0; i < guarantors.length; i++) {
            var guarantor = guarantors[i];
            if (guarantor.type === 'individual') {
              list.push(
                _.string.sprintf(
                  '%s',
                  getPlainText(
                    scope,
                    'lease.existingGuarantorInfo.individuals[' +
                      getIndex('individuals', guarantor) +
                      '].nickname',
                    guarantor.nickname,
                  ),
                ),
              );
            } else if (guarantor.type === 'married') {
              hasMarriedCouple = true;
              list.push(
                _.string.sprintf(
                  '%s',
                  getPlainText(
                    scope,
                    'lease.existingGuarantorInfo.marriedCouples[' +
                      getIndex('marriedCouples', guarantor) +
                      '].nickname',
                    guarantor.nickname,
                  ),
                ),
              );
            } else if (guarantor.type === 'entity') {
              list.push(
                _.string.sprintf(
                  '%s',
                  getPlainText(
                    scope,
                    'lease.existingGuarantorInfo.entities[' +
                      getIndex('entities', guarantor) +
                      '].nickname',
                    guarantor.nickname,
                  ),
                ),
              );
            }
          }

          result = _.string.toSentence(list, ', ', ' and ');

          if (list.length === 1) {
            if (!hasMarriedCouple) {
              result = _.string.sprintf('%s is', result);
            } else {
              result = _.string.sprintf('the %s Guarantors are', result);
            }
          } else {
            result = _.string.sprintf(
              '%s Guarantors (collectively, the “Released Guarantors”) are',
              result,
            );
          }

          return result;
        }

        function createFourthSectionLanguage(guarantors) {
          var result;

          if (guarantors.length === 1) {
            var guarantor = guarantors[0];
            if (guarantor.type === 'individual') {
              result = _.string.sprintf(
                '%s Guaranty',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.individuals[' +
                    getIndex('individuals', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'married') {
              result = _.string.sprintf(
                '%s Guaranty',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.marriedCouples[' +
                    getIndex('marriedCouples', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'entity') {
              result = _.string.sprintf(
                '%s Guaranty',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.entities[' +
                    getIndex('entities', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            }
          } else {
            result = 'Released Guaranties';
          }

          return result;
        }

        function createFifthSectionLanguage(guarantors) {
          var result;
          var guarantor = guarantors[0];

          if (guarantor.type === 'individual') {
            result = getDate(
              scope,
              'lease.existingGuarantorInfo.individuals[' +
                getIndex('individuals', guarantor) +
                '].releaseDate',
              guarantor.releaseDate,
            );
          } else if (guarantor.type === 'married') {
            result = getDate(
              scope,
              'lease.existingGuarantorInfo.marriedCouples[' +
                getIndex('marriedCouples', guarantor) +
                '].releaseDate',
              guarantor.releaseDate,
            );
          } else if (guarantor.type === 'entity') {
            result = getDate(
              scope,
              'lease.existingGuarantorInfo.entities[' +
                getIndex('entities', guarantor) +
                '].releaseDate',
              guarantor.releaseDate,
            );
          }

          return result;
        }

        function createSixthSectionLanguage(guarantors) {
          var result;

          if (guarantors.length === 1) {
            var guarantor = guarantors[0];
            if (guarantor.type === 'individual') {
              result = _.string.sprintf(
                '%s',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.individuals[' +
                    getIndex('individuals', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'married') {
              result = _.string.sprintf(
                'the %s Guarantors',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.marriedCouples[' +
                    getIndex('marriedCouples', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'entity') {
              result = _.string.sprintf(
                '%s',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.entities[' +
                    getIndex('entities', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            }
          } else {
            result = 'Released Guarantors';
          }

          return result;
        }

        function createSeventhSectionLanguage(guarantors) {
          var result;

          if (guarantors.length === 1) {
            var guarantor = guarantors[0];
            if (guarantor.type === 'individual') {
              result = _.string.sprintf(
                '%s Guaranty',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.individuals[' +
                    getIndex('individuals', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'married') {
              result = _.string.sprintf(
                '%s Guarantors',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.marriedCouples[' +
                    getIndex('marriedCouples', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'entity') {
              result = _.string.sprintf(
                '%s',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.entities[' +
                    getIndex('entities', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            }
          } else {
            result = 'the Released Guarantors';
          }

          return result;
        }

        function createEighthSectionLanguage(guarantors) {
          var result;

          if (guarantors.length === 1) {
            var guarantor = guarantors[0];
            if (guarantor.type === 'individual') {
              result = _.string.sprintf(
                '%s Guaranty',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.individuals[' +
                    getIndex('individuals', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'married') {
              result = _.string.sprintf(
                '%s Guaranty',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.marriedCouples[' +
                    getIndex('marriedCouples', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            } else if (guarantor.type === 'entity') {
              result = _.string.sprintf(
                '%s Guaranty',
                getPlainText(
                  scope,
                  'lease.existingGuarantorInfo.entities[' +
                    getIndex('entities', guarantor) +
                    '].nickname',
                  guarantor.nickname,
                ),
              );
            }
          } else {
            result = 'Released Guaranties';
          }

          return result;
        }

        scope.$watch(
          '[changed, changing, lease.existingGuarantorInfo]',
          function(newValue, oldValue) {
            if (!scope.lease) {
              return;
            }

            var lease = scope.lease;
            var result = '';
            var list;
            var list = [];

            var hasExistingIndividualGuarantors =
              lease.existingGuarantorInfo.individuals.length > 0;
            var hasExistingMarriedCouplesGuarantors =
              lease.existingGuarantorInfo.marriedCouples.length > 0;
            var hasExistingEntitiesGuarantors =
              lease.existingGuarantorInfo.entities.length > 0;
            var hasExistingGuarantors =
              hasExistingIndividualGuarantors ||
              hasExistingMarriedCouplesGuarantors ||
              hasExistingEntitiesGuarantors;
            var individuals = lease.existingGuarantorInfo.individuals;
            var marriedCouples = lease.existingGuarantorInfo.marriedCouples;
            var entities = lease.existingGuarantorInfo.entities;

            if (!hasExistingGuarantors) {
              element.html(result);
              return;
            }

            // Before concatenating the  two arrays, lets indicate where each item came from
            _.forEach(individuals, function(item) {
              item.type = 'individual';
            });
            _.forEach(marriedCouples, function(item) {
              item.type = 'married';
            });
            _.forEach(entities, function(item) {
              item.type = 'entity';
            });

            // Create the joined array
            list = createJoindArray(individuals, marriedCouples, entities);

            // Since we filtered out the guarantors that has not been released, the list may be empty
            if (list.length === 0) {
              element.html(result);
              return;
            }

            // This is the result language
            result = _.string.sprintf(
              "<crb id='Guarantor' is-lower-case='true'></crb><u>Release of Guarantor%s</u>. Notwithstanding anything to the contrary contained in the Lease%s, %s hereby released from the obligations set forth in the %s as of %s (the “Release Date”). For purposes of clarification, %s shall remain liable for any and all obligations of %s incurred under the %s prior to the Release Date.",
              createFirstSectionLanguage(list),
              createSecondSectionLanguage(list),
              createThirdSectionLanguage(list),
              createFourthSectionLanguage(list),
              createFifthSectionLanguage(list),
              createSixthSectionLanguage(list),
              createSeventhSectionLanguage(list),
              createEighthSectionLanguage(list),
            );

            // Wrap the result with the contenteditable markup
            result = _.string.sprintf(
              "<span contenteditable='false'>%s</span>",
              result,
            );
            result = $compile(result)(scope);
            element.html(result);
          },
          true,
        );
      },
    };
  },
]);
