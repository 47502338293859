import './Search.scss';

import React, { Component } from 'react';

class Search extends Component {
  state = {
    isOpen: false,
    isPaused: false,
    term: '',
    currentResult: 0,
    totalResults: 0,
    results: [],
  };

  constructor(props) {
    super(props);

    this.init();
    this.registerEvents();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.isOpen !== this.state.isOpen)) {
      if (nextProps.isOpen) {
        this.open();
      } else {
        this.hide();
      }
    } else if ((nextProps.isPaused !== this.state.isPaused)) {
      this.pause();
    }
  }

  init() {
    const { scope } = this.props;

    // Throttle search to only trigger once every 500ms
    this.search = _.debounce(this.search, 500);

    // Instance for MarkJS
    this.instance = new Mark(scope);

    // Define the mark options for MarkJS instance
    this.options = {
      acrossElements: true,
      ignoreJoiners: true,
      separateWordSearch: false,
      synonyms: {"’": "'", '“': '"', '”': '"'},
      filter: this.filter.bind(this),
    };
  }

  filter(node) {
    if ($(node.parentElement).is(":hidden")) {
      return false;
    }

    return true;
  }

  done(callback) {
    function cleanText(text) {
      text = text.replace(/﻿/g, "");
      text = text.replace(/undo/g, "");
      text = text.replace(/mode_edit/g, "");
      text = text.replace(/\r/g, "");
      text = text.replace(/\n/g, "");
      text = text.replace(/\n/g, "");
      text = text.replace(/ /g, " ");
      // "’": "'", '“': '"', '”': '"'
      text = text.replace(/’/g, "'");
      text = text.replace(/“/g, "\"");
      text = text.replace(/”/g, "\"");
      return text;
    }

    const results = document.getElementsByTagName("mark");
    const normalizedResults = [];
    let term = this.state.term;
    let current;
    let currentText = "";
    let currentPartialText = "";
    let parts = [];

    term = cleanText(term);
    for (let i=0; i<results.length; i++) {
      current = results[i];
      currentText = cleanText(current.textContent);

      if (currentText.toLowerCase() === term.toLowerCase()) {
        normalizedResults.push(current);
        currentText = "";
        currentPartialText = "";
        parts = [];
      } else {
        currentPartialText = cleanText(currentPartialText + current.textContent);
        parts.push(current);

        if (currentPartialText.toLowerCase() === term.toLowerCase()) {
          normalizedResults.push(parts);
          currentText = "";
          currentPartialText = "";
          parts = [];
        }
      }
    }

    let currentResult = 0;
    if (callback) {
      currentResult = this.state.currentResult;
    }

    this.setState({
      currentResult,
      totalResults: normalizedResults.length,
      results: normalizedResults,
    }, () => {
      // TODO: check if at-least one of the results is currently showing on the screen
      //       if so, don't scroll away and highlight the first result that's showing
      //       otherwise, highlight the closest result
      if (true) {
        this.highlight(currentResult);
      } else {
        this.highlight(currentResult);
      }

      if (callback) {
        callback();
      }
    });
  }

  registerEvents() {
    $(window).bind("keydown", (e) => {
      if ((e.ctrlKey || e.metaKey) && (e.keyCode === window.ENUMS.KEYS.KEY_F)) { // CTRL+F
        e.preventDefault();
        this.open();
      }
    });
  }

  open() {
    this.setState({
      isOpen: true,
      isPaused: false,
      term: '',
      currentResult: 0,
      totalResults: 0,
      results: [],
    }, () => {
      // Reflect the change in the angular app
      this.props.toggleSearchFn(true);

      // Focus on the search input
      this.searchInput.focus();
    });
  }

  hide() {
    this.setState({
      isOpen: false,
      isPaused: false,
      term: '',
      currentResult: 0,
      totalResults: 0,
      results: [],
    });

    // Reflect the change in the angular app
    this.props.toggleSearchFn(false);

    // Clear the results
    this.clear();
  }

  pause() {
    this.setState({
      ...this.state,
      isPaused: true,
    });

    // Clear the results
    this.clear();
  }

  clear(callback) {
    this.instance.unmark({
      done: () => {
        if (callback) {
          callback();
        }
      },
    })
  }

  search(term, callbackFn) {
    const options = {
      ...this.options,
      done: () => {
        this.done(callbackFn);
      },
    };

    if (term) {
      this.setState({
        term,
        isPaused: false,
      }, () => {
        // Clear existing markers from the previous search before performing the new search
        this.clear(() => {
          // Preform the new search
          this.instance.mark(term, options);
        });
      });
    }
  }

  searchKeyUp(e) {
    let term = this.searchInput.value;

    if (e.keyCode === window.ENUMS.KEYS.ESCAPE) { // ESC
      this.hide();
    } else if (e.keyCode === window.ENUMS.KEYS.ENTER) { // ENTER
      if (this.searchInput.value.length === 1) {
        this.search(term);
      } else if (this.searchInput.value.length > 1) {
        this.next();
      }
    } else {
      if (this.searchInput.value.length > 1) {
        this.search(term);
      }
    }
  }

  highlight(index) {
    const { results } = this.state;

    // Remove the highlight from the currently highlighted element
    $(".current-result").removeClass("current-result");

    // Highlight the `index` result item
    const current = $(results[index]).addClass("current-result");

    // scroll to the highlighted result
    this.props.scrollFn(current);

    this.setState({
      currentResult: index,
    });
  }

  move(direction) {
    const { currentResult, totalResults } = this.state;

    if (direction === 'next') {
      let nextResult = currentResult;

      if (currentResult+1 === totalResults) {
        nextResult = 0;
      } else {
        nextResult++;
      }

      this.highlight(nextResult);
    } else if (direction === 'previous') {
      let previousResult = currentResult;

      if (currentResult === 0) {
        previousResult = totalResults - 1;
      } else {
        previousResult--;
      }

      this.highlight(previousResult);
    }
  }

  next() {
    const { isPaused } = this.state;

    if (isPaused) {
      const { term } = this.state;

      this.search(term, () => this.move('next'));
    } else {
      this.move('next');
    }
  }

  prev() {
    const { isPaused } = this.state;

    if (isPaused) {
      const { term } = this.state;

      this.search(term, () => this.move('previous'));
    } else {
      this.move('previous');
    }
  }

  render() {
    const { isOpen, currentResult, totalResults } = this.state;

    if (isOpen) {
      return (
        <div className="search  search--opened">
          <div className="search__box">
            <div className="search__box__input">
              <input
                id="search-input"
                type="search"
                placeholder="Find in document"
                onKeyUp={(event) => this.searchKeyUp(event)}
                ref={(input) => {
                  this.searchInput = input;
                }}
              />
            </div>
            <div className="search__box__results">
              <span><span id="search-current-result">{totalResults > 0 ? (currentResult+1) : 0}</span> of <span id="search-total-results">{totalResults}</span></span>
            </div>
          </div>
          <div className="search__navigation">
            <div
              className="prev"
              role="button"
              onClick={() => this.prev()}
            >
              <div className="icon  icon-prev">
                <svg width="7" height="11">
                  <use xlinkHref="#previous"/>
                </svg>
              </div>
            </div>
            <div
              className="next"
              role="button"
              onClick={() => this.next()}
            >
              <div className="icon  icon-next">
                <svg width="7" height="11">
                  <use xlinkHref="#next"/>
                </svg>
              </div>
            </div>
          </div>
          <div
            className="search__close"
            role="button"
            onClick={() => this.hide()}
          >
            <div className="icon  icon-close">
              <svg width="10" height="10">
                <use xlinkHref="#inline_close"/>
              </svg>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="search  search--closed">
          <div
            className="icon  icon-search"
            role="button"
            onClick={() => this.open()}
          >
            <svg width="16" height="16">
              <use xlinkHref="#magnifying_glass"/>
            </svg>
          </div>
        </div>
      );
    }
  }
}

export default Search;
