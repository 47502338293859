(function() {
  "use strict";

  function DatesHelper() {
    this.isDate = function(date) {
      return moment(date).isValid();
    };

    this.addYears = function(date, years, defaultPlaceholder) {
      const placeholder = defaultPlaceholder || "________";

      if (!date || years === undefined || years === null) {
        return placeholder;
      }

      if (date.indexOf && date.indexOf("________") !== -1) {
        return null;
      }

      if (!moment(date).isValid()) {
        return placeholder;
      }

      const futureDate = moment(date).add(years, "years");

      // If the date is the last day of the month, we want to stay on it,
      // even after adding years. this is an edge-case to handle adding years to February
      // 28th that takes you to a leap year where the end of February is the 29th
      if (
        moment(date).date() ===
        moment(date)
          .endOf("month")
          .date()
      ) {
        futureDate.endOf("month").startOf("day");
      }

      return futureDate.format();
    };

    this.addMonths = function(date, months, defaultPlaceholder) {
      const placeholder = defaultPlaceholder || "________";

      if (!date || months === undefined || months === null) {
        return placeholder;
      }

      if (date.indexOf && date.indexOf("________") !== -1) {
        return null;
      }

      if (!moment(date).isValid()) {
        return placeholder;
      }

      const futureDate = moment(date).add(months, "months");

      // If the date is the last day of the month, we want to stay on it,
      // even after adding months.
      if (
        moment(date).date() ===
        moment(date)
          .endOf("month")
          .date()
      ) {
        futureDate.endOf("month").startOf("day");
      }

      return futureDate.format();
    };

    this.addDays = function(date, days, defaultPlaceholder) {
      const placeholder = defaultPlaceholder || "________";

      if (!date || days === undefined || days === null) {
        return placeholder;
      }

      if (date.indexOf && date.indexOf("________") !== -1) {
        return null;
      }

      if (!moment(date).isValid()) {
        return placeholder;
      }

      const calculatedDate = moment(date)
        .add(days, "days")
        .format();

      return calculatedDate;
    };

    this.firstDayOfMonth = function(date, defaultPlaceholder) {
      const placeholder = defaultPlaceholder || "________";

      if (!date) {
        return placeholder;
      }

      if (date.indexOf && date.indexOf("________") !== -1) {
        return null;
      }

      if (!moment(date).isValid()) {
        return placeholder;
      }

      const calculatedDate = moment(date)
        .startOf("month")
        .startOf("day")
        .format();

      return calculatedDate;
    };

    this.isFirstDayOfMonth = function(date) {
      return (moment(date).isSame(moment(this.firstDayOfMonth(date))));
    };

    this.lastDayOfMonth = function(date, defaultPlaceholder) {
      const placeholder = defaultPlaceholder || "________";

      if (!date) {
        return placeholder;
      }

      if (date.indexOf && date.indexOf("________") !== -1) {
        return null;
      }

      if (!moment(date).isValid()) {
        return placeholder;
      }

      const calculatedDate = moment(date)
        .endOf("month")
        .startOf("day")
        .format();

      return calculatedDate;
    };

    this.isLastDayOfMonth = function(date) {
      return (moment(date).isSame(moment(this.lastDayOfMonth(date))));
    };

    this.formatDateAsMonthDayYear = function(date) {
      if (!date) {
        return "________";
      }

      if (date === "________") {
        return null;
      }

      if (!moment(date).isValid()) {
        return "________";
      }

      return moment(date).format("MM/DD/YYYY");
    };

    this.formatDateAsText = function(date) {
      if (!date) {
        return "________";
      }

      if (date === "________") {
        return null;
      }

      if (!moment(date).isValid()) {
        return "________";
      }

      return moment(date).format("MMMM D, YYYY");
    };

    this.formatDateAs = function(date, format) {
      if (!date) {
        return "________";
      }

      if (date === "________") {
        return null;
      }

      if (!moment(date).isValid()) {
        return "________";
      }

      return moment(date).format(format);
    };

    this.fixDate = function(ref) {
      const element = document.querySelector("[ng-model='" + ref + "']");
      const value = element.value;
      const date = moment(value).toDate();
      return date;
    }
  }

  window.datesHelper = new DatesHelper();
})();
