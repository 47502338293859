angular.module('LeasePilot').service('LeaseEditorService', [
  'DocumentService',
  '$rootScope',
  '$q',
  '$compile',
  '$timeout',
  '$filter',
  '$interpolate',
  function(DocumentService, $rootScope, $q, $compile, $timeout, $filter, $interpolate) {
    return {
      setLeaseTitle: setLeaseTitle,
      setLease: setLease,
      getLeaseTenantName: getLeaseTenantName,
      getFileName: getFileName,
      hideEmptyParagraph: hideEmptyParagraph,
      setDeletedParagraphVisibility: setDeletedParagraphVisibility,
      isHidden: isHidden,
      isSelecting: isSelecting,
      isMeaningfulSelection: isMeaningfulSelection,
      isBlinkingCaret: isBlinkingCaret,
      isEmpty: isEmpty,
      isNewListItem: isNewListItem,
      closestNewListItemId: closestNewListItemId,
      isIndented: isIndented,
      shouldDeleteNewListItem: shouldDeleteNewListItem,
      deleteNonWidthChars: deleteNonWidthChars,
      shouldHideEmptyParagraph: shouldHideEmptyParagraph,
      isBlockElement: isBlockElement,
      findClosestContainerAbove: findClosestContainerAbove,
      findClosestContainer: findClosestContainer,
      findAllContentEditableInSelection: findAllContentEditableInSelection,
      findClosestContentEditable: findClosestContentEditable,
      findClosestContentEditableInDirection: findClosestContentEditableInDirection,
      findAllContentEditable: findAllContentEditable,
      findAllFreeTexts: findAllFreeTexts,
      findPreviousContentEditable: findPreviousContentEditable,
      findNextContentEditable: findNextContentEditable,
      setControlCharacters: setControlCharacters,
      removeMarkers: removeMarkers,
      addMarkers: addMarkers,
      skipMarkers: skipMarkers,
      skipEmptyNodes: skipEmptyNodes,
      isHeading: isHeading,
      getHeading: getHeading,
      prepareForComparison: prepareForComparison,
      setEditingModeForSegment: setEditingModeForSegment,
      enableEditingForSegment: enableEditingForSegment,
      disableEditingForSegment: disableEditingForSegment,
      setContentEditableColor: setContentEditableColor,
      flattenSpecialVariables: flattenSpecialVariables,
      skipNonWidthChar: skipNonWidthChar,
      getCurrentEditedElement: getCurrentEditedElement,
      adjustBulletsColor: adjustBulletsColor,
      isFirstBullet: isFirstBullet,
      getStyle: getStyle,
      bindImgEvents: bindImgEvents,
      deselectImage: deselectImage,
      resizeBase64Image: resizeBase64Image,
      deleteImg: deleteImg,
      skipPageBreak: skipPageBreak,
      getLeaseId: getLeaseId,
      isSelfClosing: isSelfClosing,
      isNodeWordStyle: isNodeWordStyle,
      isCaretInStartParagraph:  isCaretInStartParagraph,
      createRemoveTable: createRemoveTable,
      getCaretNode: getCaretNode,
      copySelection: copySelection,
    };

    // Remove Table ------------------------------------------------------------ Start
    function redoRemoveTable(table){
      return function(){
        const deferred = $q.defer();
        table.classList.add('deleted-element');
        deferred.resolve();
        return deferred.promise;
      }
    }

    function undoRemoveTable(table){
      return function(){
        const deferred = $q.defer();
        table.classList.remove('deleted-element');
        deferred.resolve();
        return deferred.promise;
      }
    }

    async function removeTable(event){
      var $scope = $rootScope.findAppScope();
      let tableToRemove = null;
      if($scope.lease.isLocked){
        return;
      }

      const gTable =event.target.closest('generate-table.in-edit');
      tableToRemove = gTable.querySelector('table');
      
      if(tableToRemove){

        

        let currentUndoIndex = $scope.histIndex;
        const freeTexts =  tableToRemove.querySelectorAll('[section-id]');

        await $scope.addToHistory({
          id: "remove table",
          desc: "remove table: ",
          redo: redoRemoveTable(
            tableToRemove
          ),
          undo: undoRemoveTable(
            tableToRemove
          )
        });

        $scope.createBulk(freeTexts);

        $scope.histRegisterBatch(
          $scope.histStack.length - 1,
          $scope.histIndex - currentUndoIndex
        );
        
      }
    }

    function createRemoveTable(){
      const generatedTablesList = document.querySelectorAll('generate-table');
      generatedTablesList.forEach((tbl)=>{
        if(!tbl.querySelector('.remove-tbl-btn')){
          var span = document.createElement('span');
          span.classList.add('remove-tbl-btn','remove-on-download');
          span.textContent = 'Remove Table';
          span.addEventListener('click',removeTable);
          tbl.querySelector('table').appendChild(span);
        }
      });

      
    } 

    

    // Remove Table ------------------------------------------------------------ End

    function deleteImg(img) {
      deselectImage();
      var CE = findClosestContentEditable(img);
      CE.html("");
      CE.trigger('change');
    }

    function prepareCanvas(canvas, img, ctx, width, height) {
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
    }

    function resizeBase64Image(base64Image, width, height, callback) {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var img = new Image();
      img.src = base64Image;

      if (callback) {
        img.onload = function() {
          prepareCanvas(canvas, img, ctx, width, height);
          callback(canvas.toDataURL("image/png"));
        };
      } else {
        prepareCanvas(canvas, img, ctx, width, height);
        return canvas.toDataURL("image/png");
      }
    }

    function bindImgEvents(img, scope) {
      var addImageMenu = function(img, scope) {
        img.each(function(i, el){
          var imageMenu = el.parentElement.querySelector('image-controls');
          if (!imageMenu) {
            imageMenu = document.createElement('image-controls');
            el.parentElement.prepend(imageMenu);
            $compile(imageMenu)(scope);
          }
          var size = el.getAttribute('data-size');
          if (size) {
            $timeout(function(){
              // wait for compile to finish, so we have the button
              if (size !== 'original') {
                imageMenu.querySelector('.selected').classList.remove('selected');
              }

              imageMenu.querySelector(`[data-size="${size}"]`).classList.add('selected');
            });
          }
        });
      };

      $(document).on('click', '.image-container img', function(e) {
        var sel = rangy.getSelection();
        sel.removeAllRanges();
        var currImg = e.target;
        var isActive = currImg.classList.contains('active');
        if (!isActive) {
          deselectImage();
          currImg.classList.add('active');
          currImg.closest('.image-container').classList.add('image-container--active');
          addImageMenu($(currImg), scope);
        }
      });
    }

    function deselectImage() {
      $('img.active').removeClass('active');
      $('.image-container--active').removeClass('image-container--active');
    }

    function setLeaseTitle() {
      $rootScope.title = {};

      $rootScope.safeApply(() => {
        if ($rootScope.lease) {
          $rootScope.title.version = {
            major: $rootScope.lease.instanceVersion || 1,
            minor: $rootScope.lease.iterationNumber || 0,
          };
        }
 
        if ($rootScope.lease?.form?.flavor === "rewind")  {
          const companyName = $rootScope.lease.company.displayName;
          $rootScope.title.tenant = `${companyName} 2024 Year in Review Report`
        } else {
          if ($rootScope.lease?.customFields?.fileName) {
            $rootScope.title.tenant = $rootScope.lease.customFields?.fileName;
          } else {
            if ($rootScope.lease?.tenantInfo) {
              $rootScope.title.tenant = getLeaseTenantName().toUpperCase();
            }
          }

          if ($rootScope.lease?.building) {
            $rootScope.title.building = (
              $rootScope.lease.building.dashboardName ||
              $rootScope.lease.building.displayName ||
              $rootScope.lease.building.name ||
              $rootScope.lease.building.internalName
            ).toUpperCase();
          }
        }
      });
    }

    function getCaretNode(){
      let sel = rangy.getSelection();
      let focusNode = sel.focusNode;

      if (focusNode.nodeType === Node.TEXT_NODE) {
        focusNode = focusNode.parentElement;
      }

      return focusNode;
      
    }

    function isCaretInStartParagraph() {
      let sel = rangy.getSelection();
      let focusNode = sel.focusNode;

      if (focusNode.nodeType === Node.TEXT_NODE) {
        focusNode = focusNode.parentElement;
      }

      if (!isBlockElement(focusNode)) {
        focusNode = findClosestContainer(focusNode).get(0);
      }

      let focusOffset = sel.getRangeAt(0).toCharacterRange(focusNode).start;

      if (focusNode.classList.contains("new-paragraph") && focusOffset <= 1) {
        return true;
      }

      return focusOffset === 0;
    }

    function setLease(id, type) {
      var deferred = $q.defer();
      DocumentService.get({ id: id, type: type }).then(
        function(lease) {
          $rootScope.lease = lease;
          deferred.resolve(lease);
        },
        function(error) {
          deferred.reject(error);
        },
      );
      return deferred.promise;
    }

    function getLeaseTenantName() {
      return (
        $rootScope.lease.customFields?.fileName ||
        $rootScope.lease.tenantInfo?.tradeName ||
        $rootScope.lease.tenantInfo?.name ||
        ''
      );
    }

    function getFileName(pattern = null) {
      if (!pattern) {
        pattern = "{{BUILDING_NAME}} - {{TENANT_NAME}} ({{DOCUMENT_TYPE}}) v{{VERSION}}.docx";
        
        if (window.user.company.companyProfile.downloadFilenamePattern) {
          pattern = window.user.company.companyProfile.downloadFilenamePattern;
        }

        if (window.lease.form.customFilenamePattern) {
          let interpolateFn = $interpolate(window.lease.form.customFilenamePattern.replace(/&lcub;/g, "{").replace(/&rcub;/g, "}"));
          pattern = interpolateFn(scopeVar);
        }
      }

      if (!pattern.endsWith(".docx")) {
        pattern += ".docx";
      }
      
      const id = $rootScope.lease.id;
      const companyName = $rootScope.lease.company.displayName;
      const version = $rootScope.lease.instanceVersion;
      const dealType = $rootScope.lease.form.dealType;
      const documentType = $rootScope.lease.type.toLowerCase();
      const flavor = $rootScope.lease.form.flavor;
      const tenantName = getLeaseTenantName();
      const status = $rootScope.lease.status.description;
      const createdAt = $rootScope.lease.createdAt;
      const modifiedAt = $rootScope.lease.modifiedAt;
      const amendmentNumber = documentType.toLowerCase() === "amendment" 
        ? $rootScope.lease.previousAmendment.length + 1
        : "";

      if ($rootScope.lease?.form?.flavor === "rewind")  {
        return `${companyName} 2024 Year in Review Report.docx`;
      }

      let filename = pattern;
      
      filename = filename
        .replace(/{{ID}}/g, id)
        .replace(/{{VERSION}}/g, version)
        .replace(/{{CREATED_AT}}/g, Intl.DateTimeFormat("en-US").format(createdAt))
        .replace(/{{MODIFIED_AT}}/g, Intl.DateTimeFormat("en-US").format(modifiedAt))
        .replace(/{{DEAL_TYPE__CAPITALIZE}}/g, dealType.capitalize())
        .replace(/{{DEAL_TYPE__UPPERCASE}}/g, dealType.toUpperCase())
        .replace(/{{DEAL_TYPE__LOWERCASE}}/g, dealType.toLowerCase())
        .replace(/{{DEAL_TYPE}}/g, dealType)
        .replace(/{{DOCUMENT_TYPE__CAPITALIZE}}/g, documentType.capitalize())
        .replace(/{{DOCUMENT_TYPE__UPPERCASE}}/g, documentType.toUpperCase())
        .replace(/{{DOCUMENT_TYPE__LOWERCASE}}/g, documentType.toLowerCase())
        .replace(/{{DOCUMENT_TYPE}}/g, documentType)
        .replace(/{{FORM_NAME__CAPITALIZE}}/g, flavor.capitalize())
        .replace(/{{FORM_NAME__UPPERCASE}}/g, flavor.toUpperCase())
        .replace(/{{FORM_NAME__LOWERCASE}}/g, flavor.toLowerCase())
        .replace(/{{FORM_NAME}}/g, flavor)
        .replace(/{{STATUS__CAPITALIZE}}/g, status.capitalize())
        .replace(/{{STATUS__UPPERCASE}}/g, status.toUpperCase())
        .replace(/{{STATUS__LOWERCASE}}/g, status.toLowerCase())
        .replace(/{{STATUS}}/g, status)
        .replace(/\.(?=.*\.)/g, "");

      if (!$rootScope.lease.isConformedDeal) {
        const buildingName = $rootScope.lease.building?.dashboardName;
        if (buildingName) {
          filename = filename
          .replace(/{{BUILDING_NAME__CAPITALIZE}}/g, buildingName.capitalize())
          .replace(/{{BUILDING_NAME__UPPERCASE}}/g, buildingName.toUpperCase())
          .replace(/{{BUILDING_NAME__LOWERCASE}}/g, buildingName.toLowerCase())
          .replace(/{{BUILDING_NAME}}/g, buildingName);
        } else {
          filename = filename
          .replace(/{{BUILDING_NAME__CAPITALIZE}}/g, "")
          .replace(/{{BUILDING_NAME__UPPERCASE}}/g, "")
          .replace(/{{BUILDING_NAME__LOWERCASE}}/g, "")
          .replace(/{{BUILDING_NAME}}/g, "");  
        }
        
        const buildingInternalName = $rootScope.lease.building?.internalName || "";
        if (buildingInternalName) {
          filename = filename
          .replace(/{{BUILDING_INTERNAL_NAME__CAPITALIZE}}/g, buildingInternalName.capitalize())
          .replace(/{{BUILDING_INTERNAL_NAME__UPPERCASE}}/g, buildingInternalName.toUpperCase())
          .replace(/{{BUILDING_INTERNAL_NAME__LOWERCASE}}/g, buildingInternalName.toLowerCase())
          .replace(/{{BUILDING_INTERNAL_NAME}}/g, buildingInternalName);
        } else {
          filename = filename
          .replace(/{{BUILDING_INTERNAL_NAME__CAPITALIZE}}/g, "")
          .replace(/{{BUILDING_INTERNAL_NAME__UPPERCASE}}/g, "")
          .replace(/{{BUILDING_INTERNAL_NAME__LOWERCASE}}/g, "")
          .replace(/{{BUILDING_INTERNAL_NAME}}/g, "");
        }
        
        const landlordName = $rootScope.lease.building?.landlordName || "";
        if (landlordName) {
          filename = filename
            .replace(/{{LANDLORD_NAME__CAPITALIZE}}/g, landlordName.capitalize())
            .replace(/{{LANDLORD_NAME__UPPERCASE}}/g, landlordName.toUpperCase())
            .replace(/{{LANDLORD_NAME__LOWERCASE}}/g, landlordName.toLowerCase())
            .replace(/{{LANDLORD_NAME}}/g, landlordName);
        } else {
          filename = filename
            .replace(/{{LANDLORD_NAME__CAPITALIZE}}/g, "")
            .replace(/{{LANDLORD_NAME__UPPERCASE}}/g, "")
            .replace(/{{LANDLORD_NAME__LOWERCASE}}/g, "")
            .replace(/{{LANDLORD_NAME}}/g, "");
        }
      } else {
        filename = filename
          .replace(/{{BUILDING_NAME__CAPITALIZE}}/g, "")
          .replace(/{{BUILDING_NAME__UPPERCASE}}/g, "")
          .replace(/{{BUILDING_NAME__LOWERCASE}}/g, "")
          .replace(/{{BUILDING_NAME}}/g, "")
          .replace(/{{BUILDING_INTERNAL_NAME__CAPITALIZE}}/g, "")
          .replace(/{{BUILDING_INTERNAL_NAME__UPPERCASE}}/g, "")
          .replace(/{{BUILDING_INTERNAL_NAME__LOWERCASE}}/g, "")
          .replace(/{{BUILDING_INTERNAL_NAME}}/g, "")
          .replace(/{{LANDLORD_NAME__CAPITALIZE}}/g, "")
          .replace(/{{LANDLORD_NAME__UPPERCASE}}/g, "")
          .replace(/{{LANDLORD_NAME__LOWERCASE}}/g, "")
          .replace(/{{LANDLORD_NAME}}/g, "");

        filename = "[Conformed Deal] " + filename
      }

      if (amendmentNumber) {
        filename = filename
          .replace(/{{AMENDMENT_NUMBER}}/g, amendmentNumber)
          .replace(/{{AMENDMENT_NUMBER__ORDINAL}}/g, $filter('ordinal')(amendmentNumber))
          .replace(/{{AMENDMENT_NUMBER__ORDINAL__CAPITALIZE}}/g, $filter('ordinal')(amendmentNumber).capitalize())
          .replace(/{{AMENDMENT_NUMBER__ORDINAL__UPPERCASE}}/g, $filter('ordinal')(amendmentNumber).toUpperCase())
          .replace(/{{AMENDMENT_NUMBER__ORDINAL__LOWERCASE}}/g, $filter('ordinal')(amendmentNumber).toLowerCase())
          .replace(/{{AMENDMENT_NUMBER__WORDS}}/g, $filter('toWords')(amendmentNumber))
          .replace(/{{AMENDMENT_NUMBER__WORDS__CAPITALIZE}}/g, $filter('toWords')(amendmentNumber).capitalize())
          .replace(/{{AMENDMENT_NUMBER__WORDS__UPPERCASE}}/g, $filter('toWords')(amendmentNumber).toUpperCase())
          .replace(/{{AMENDMENT_NUMBER__WORDS__LOWERCASE}}/g, $filter('toWords')(amendmentNumber).toLowerCase())
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS}}/g, $filter('ordinalWords')(amendmentNumber))
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS__CAPITALIZE}}/g, $filter('ordinalWords')(amendmentNumber).capitalize())
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS__UPPERCASE}}/g, $filter('ordinalWords')(amendmentNumber).toUpperCase())
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS__LOWERCASE}}/g, $filter('ordinalWords')(amendmentNumber).toLowerCase());
      } else {
        filename = filename
          .replace(/{{AMENDMENT_NUMBER}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL__CAPITALIZE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL__UPPERCASE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL__LOWERCASE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__WORDS}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__WORDS__CAPITALIZE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__WORDS__UPPERCASE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__WORDS__LOWERCASE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS__CAPITALIZE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS__UPPERCASE}}/g, "")
          .replace(/{{AMENDMENT_NUMBER__ORDINAL_WORDS__LOWERCASE}}/g, "");
      }

      if (tenantName) {
        filename = filename
          .replace(/{{TENANT_NAME__CAPITALIZE}}/g, tenantName.capitalize())
          .replace(/{{TENANT_NAME__UPPERCASE}}/g, tenantName.toUpperCase())
          .replace(/{{TENANT_NAME__LOWERCASE}}/g, tenantName.toLowerCase())
          .replace(/{{TENANT_NAME}}/g, tenantName);
      } else {
        filename = filename
          .replace(/{{TENANT_NAME__CAPITALIZE}}/g, "")
          .replace(/{{TENANT_NAME__UPPERCASE}}/g, "")
          .replace(/{{TENANT_NAME__LOWERCASE}}/g, "")
          .replace(/{{TENANT_NAME}}/g, "");
      }

      return filename;
    }

    function getLeaseId() {
      return (
        $rootScope.lease.id ||
        ''
      );
    }

    function hideEmptyParagraph(element, container, reloadStructure = false) {
      var nonVisibleSpan =
        '<span style="display:none;user-select:none;" class="deleted-paragraph"></span>';

      // Hide the container entirely
      $(container).addClass('deleted-container');

      if ($rootScope.isListItem(container)) {
        $(container).prev().addClass('deleted-container');
      }

      // Empty the content-editable element and append the `deleted-paragraph` span
      // this allows us hide the paragraph again after reload
      $(element)
        .empty()
        .append(nonVisibleSpan);

      if (reloadStructure) {
        $rootScope.findAppScope().structureChanged();
      }

      window.track.event(new DeleteParagraphEvent({
        context: $rootScope.getContext(),
      }));
    }

    function setDeletedParagraphVisibility(element) {
      const $container = $(findClosestContainer(element));
      const container = $container.get(0);

      if (container) {
        var isEmptyNode = container.querySelectorAll('.deleted-paragraph').length > 0;
        var isInVisible = container.classList.contains('deleted-container');
  
        if (isEmptyNode && !isInVisible) {
          $container.addClass('deleted-container');
          $container.find('[list]').text('');
  
          if ($rootScope.isListItem($container)) {
            $container.prev().addClass('deleted-container');
          }
        } else if (!isEmptyNode && isInVisible) {
          $container.removeClass('deleted-container');
  
          if ($rootScope.isListItem($container)) {
            $container.prev().removeClass('deleted-container');
          }
        }
      }
    }

    function isHidden(container) {
      return (
        $(container).is(':hidden') ||
        $(container).hasClass('deleted-container') ||
        $(container).find('.deleted-paragraph').length > 0
      );
    }

    function isSelecting() {
      if(!rangy && rangy.getSelection) {
        return false;
      }

      var sel = rangy.getSelection();

      if (sel) {
        return !sel.isCollapsed && sel.rangeCount > 0;
      }

      return false;
    }

    function isMeaningfulSelection() {
      var sel = rangy.getSelection();

      var selectionText = sel.toString();
      selectionText = selectionText.replace(/﻿/g, ''); //ignore non-width-chars
      selectionText = selectionText.replace(/\n/g, ''); //ignore line-break
      selectionText = selectionText.trim();

      if (
        _.startsWith(selectionText, '!!') &&
        _.endsWith(selectionText, '!!')
      ) {
        return false;
      }

      return true;
    }

    function isBlinkingCaret() {
      var sel = rangy.getSelection();

      if (sel) {
        return sel.isCollapsed && sel.nativeSelection.type === 'Caret';
      }

      return false;
    }

    function isEmpty(
      element,
      ignoreSpaces = false,
      ignoreLists = false,
    ) {
      var clone = $(element).clone();

      let text;

      if (!clone.get(0)) {
        return;
      }

      applyShowAll(() => {
        if(clone[0] && clone[0].querySelectorAll ){
          clone[0].querySelectorAll(".lp-hide").forEach(node => {
            node.remove();
          });
        }
      });

      if ($rootScope.isDummyListItem($(element))) {
        clone.children('span')[0].remove();
      }

      if (!ignoreLists) {
        clone.find('[list]').remove();
      }

      if (ignoreSpaces) {
        clone.find('br').remove();
      }

      if ($(clone).get(0).nodeType === Node.TEXT_NODE) {
        text = $(clone).get(0).textContent;
      } else {
        text = $(clone).get(0).innerText;
      }

      if ($(clone).get(0).classList?.contains("SKIP")) {
        return true;
      }

      if (!text) {
        return true;
      }

      // Remove special characters and white-space
      // Note: A node the contains actual spaces isn't empty!
      text = text.replace(/﻿/g, '');
      text = text.replace(/undo/g, '');
      text = text.replace(/mode_edit/g, '');
      text = text.replace(/\r/g, '');
      text = text.replace(/\n/g, '');

      if (ignoreSpaces) {
        text = text.trim();
      }

      return text.length === 0 && clone.find('br').length === 0;
    }

    function isNewListItem(element) {
      return $(element).closest('.inserted-list-item').length > 0;
    }

    function closestNewListItemId(element) {
      return $(element)
        .closest('[inserted-list-item-id]')
        .attr('inserted-list-item-id');
    }

    function isIndented(container = null) {
      if (!container) {
        const sel = rangy.getSelection();
        const element = findClosestContentEditable(sel.anchorNode);
        container = findClosestContainer(element);
      }

      if (!container) {
        return null;
      }

      const blockData = container.get(0).getBlockData();
      const textIndent = 1 * container.attr("data-text-indent");
      const paragraphIndent = 1 * container.attr("data-paragraph-indent");

      return (
        (
          blockData.blockType === BLOCK_TYPE.PARAGRAPH ||
          blockData.blockType === BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH
        ) &&
        (
          (!isNaN(textIndent) && textIndent > 0) || 
          (!isNaN(paragraphIndent) && paragraphIndent > 0)
        )
      );
    }

    function shouldDeleteNewListItem() {
      const sel = rangy.getSelection();

      if (sel) {
        const element = findClosestContentEditable(sel.anchorNode);
        const container = findClosestContainer(element);
        const listItemId = closestNewListItemId(element);
        const insertedListItem = $(
          "[inserted-list-item-id='" + listItemId + "'] [list]",
        ).closest("[inserted-list-item-id='" + listItemId + "']");

        return (
          isNewListItem(element) &&
          $rootScope.listItemIsEmpty(insertedListItem)
        );
      }

      return false;
    }

    function deleteNonWidthChars() {
      var sel = rangy.getSelection();
      var anchorNode = sel.anchorNode;
      var anchorOffset = sel.anchorOffset;
      var shouldExtend = false;

      if (anchorNode.nodeType === Node.ELEMENT_NODE && anchorOffset) {
        anchorNode = sel.anchorNode.childNodes[anchorOffset - 1];
        anchorOffset = sel.getRangeAt(0).toCharacterRange(anchorNode).start;
      }

      var removingTab = anchorNode.previousSibling &&
        anchorNode.previousSibling.classList &&
        anchorNode.previousSibling.classList.contains('word-indent');

      if (removingTab) {
        sel.nativeSelection.modify('extend', 'backward', 'character');
        sel.nativeSelection.modify('extend', 'backward', 'character');
        return;
      }

      var focusNodeAfterChange;

      do {
        var focusNodeBeforeChange = focusNodeAfterChange;
        sel.nativeSelection.modify('extend', 'backward', 'character');
        sel.refresh();
        focusNodeAfterChange = sel.focusNode;
        var isChanged = focusNodeBeforeChange != focusNodeAfterChange;
        shouldExtend = 
          _.startsWith(
            sel.text(),
            window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE,
          ) || 
          _.startsWith(
            sel.text(),
            window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE,
          ) || 
          (focusNodeAfterChange.nodeType === Node.ELEMENT_NODE && focusNodeAfterChange.classList.contains("SKIP"));
      } while (shouldExtend && isChanged);

      if ($(anchorNode.previousSibling).hasClass('word-indent')) {
        sel.nativeSelection.modify('extend', 'forward', 'character');
      }
    }

    function isContainNewParagraphs(element) {
      let container = findClosestContainer(element).get(0);
      return container.querySelectorAll('.new-paragraph').length > 0;
    }

    function shouldHideEmptyParagraph(element) {
      var container = findClosestContainer(element);
      if ($rootScope.isListItem(container) || $rootScope.isDummyListItem(element)) {
        return $rootScope.listItemIsEmpty(container);
      } else if (isContainNewParagraphs(element)) {
        return false;
      } else {
        return isEmpty(container, true);
      }
    }

    function isBlockElement(element) {
      var blockElement = $(element);

      if (!blockElement[0]) {
        return;
      }

      if (blockElement[0].nodeType === Node.TEXT_NODE) {
        blockElement = $(blockElement[0].parentElement);
      }

      let display = $(blockElement).css("display");
      let isInline =
        display === "inline" ||
        display === "inline-block" ||
        display === "list-item" ||
        (display === "none" && !blockElement[0].classList.contains('deleted-container') && !blockElement[0].classList.contains('deleted-element'));

      return !isInline;
    }

    function findClosestContainerAbove(element) {
      let container = findClosestContainer(element);

      if (element.get) {
        element = element.get(0);
      }

      if (container.get(0) === element) {
        container = findClosestContainer(element.parentElement);
      }

      return container;
    }

    function findClosestContainer(element) {
      // Find the first block ancestor element
      var blockElement = $(element);

      if (!blockElement[0]) {
        return null;
      }

      if (blockElement[0].nodeType === Node.TEXT_NODE) {
        blockElement = $(blockElement[0].parentElement);
      }

      let isBlock = isBlockElement(blockElement);

      if (!isBlock) {
        blockElement = $(blockElement).parent();
        isBlock = isBlockElement(blockElement);

        while (!isBlock) {
          blockElement = blockElement.parent();
          isBlock = isBlockElement(blockElement);

          if (!blockElement || $(blockElement).hasClass("lease")) {
            break;
          }
        }
      }

      return blockElement;
    }

    function findAllContentEditableInSelection() {
      return window.applyFilterVisible(() => {
        var sel = rangy.getSelection();
        var range = sel.getRangeAt(0);
        var contentEditables = range.getNodes([1], function(node) {
          return (
            node.hasAttribute('contenteditable') &&
            node.hasAttribute('section-id') &&
            !node.closest("ins.diff-item--rejected")
          );
        });

        if (isSelecting()) {
          var anchor = findClosestContentEditable(sel.anchorNode);
          var focus = findClosestContentEditable(sel.focusNode);

          if (anchor.length > 0) {
            contentEditables.push(anchor);
          }

          if (sel.anchorNode !== sel.focusNode) {
            if (focus.length > 0) {
              contentEditables.push(focus);
            }
          }
        }

        return _.uniqBy(contentEditables, function(e) {
          return $(e).attr('section-id');
        });
      });
    }

    function findClosestContentEditable(element) {
      if ($(element).hasClass('editable')) {
        return $(element);
      }

      let contentEditable = $(element).closest('.editable');

      if (contentEditable.attr('contenteditable-type') === 'building-variable') {
        contentEditable = undefined;
      }

      return contentEditable;
    }

    function findClosestContentEditableInDirection(
      contentEditable,
      isNext,
    ) {
      var currentElement = contentEditable;
      var previousElement;
      var canContinue;
      var contentEditableList;
      var currentContainer;

      if (
        contentEditable &&
        contentEditable.get &&
        contentEditable.get(0) &&
        contentEditable.get(0).tagName === 'INS'
      ) {
        contentEditable = $(contentEditable.get(0).firstElementChild);
      }

      // We do this since content-editable is wrapped inside a free-text
      currentElement = $(contentEditable).parent();

      currentContainer = findClosestContainer(currentElement);

      do {
        // Traverse the DOM tree until reaching the correct container
        do {
          previousElement = currentElement;
          canContinue = true;

          if ($(currentElement).hasClass('lease')) {
            return;
          }

          if (isNext) {
            currentElement = currentElement.next();
          } else {
            currentElement = currentElement.prev();
          }

          if (currentElement.length === 0) {
            let parent = previousElement.parent();

            if (parent.get(0) && parent.get(0).nodeName.toLowerCase() === 'concept') {

              currentElement = parent;
            } else {
              currentElement = findClosestContainerAbove(previousElement);
            }

            canContinue = false;
          }
        } while (
          currentElement.get(0) &&
          (!canContinue ||
          $(currentElement).find('.editable:visible').length === 0 ||
          isHidden(currentElement))
        );

        // Go through the containers in the direction to find the most suitable one
        if (currentElement.get(0)) {
          while ($(currentElement).find('.editable:visible').length === 0) {
            if (isNext) {
              currentElement = currentElement.next();
            } else {
              currentElement = currentElement.prev();
            }
          }

          // Get the correct content-editable according to the direction
          contentEditableList = findAllContentEditable(currentElement)
            .filter(':visible');

          if (isNext) {
            currentElement = $(contentEditableList).first();
          } else {
            currentElement = $(contentEditableList).last();
          }
        }
      } while (
        findClosestContainer(currentElement).get(0) ===
        currentContainer.get(0)
      );

      return currentElement;
    }

    function findAllFreeTexts(element) {
      return window.applyShowAll(() => {
        return $(element).find("[free-text], [free-text-placeholder]");
      });
    }

    function findAllContentEditable(element) {
      return window.applyFilterVisible(() => {
        return $(element).find(".editable");
      });
    }

    function findPreviousContentEditable(element) {
      element = $(element);

      if (!element.hasClass('editable')) {
        element = findClosestContentEditable(element);
      }

      return window.applyFilterVisible(() => {

        const contentEditableList = Array.from(document.querySelectorAll('.editable'));
        const anchor = $(element).get(0);
        const anchorIndex = contentEditableList.indexOf(anchor);
        let currentIndex = anchorIndex;
        let result;

        if (anchorIndex !== -1) {
          while (currentIndex - 1 >= 0) {
            currentIndex--;

            if (!contentEditableList[currentIndex].closest(
              ".lp-hide, .deleted-container"
            )) {
              break;
            }

          }
        }

        result = contentEditableList[currentIndex];

        return $(result);
      });
    }

    function findNextContentEditable(element) {
      element = $(element);

      if (!element.hasClass('editable')) {
        element = findClosestContentEditable(element);
      }

      return window.applyFilterVisible(() => {

        const contentEditableList = Array.from(document.querySelectorAll('.editable'));
        const anchor = $(element).get(0);
        const anchorIndex = contentEditableList.indexOf(anchor);
        let currentIndex = anchorIndex;
        let result;

        if (anchorIndex !== -1) {
          while (currentIndex + 1 < contentEditableList.length) {
            currentIndex++;

            if (!contentEditableList[currentIndex].closest(
              ".lp-hide, .deleted-container"
            )) {
              break;
            }

          }
        }

        result = contentEditableList[currentIndex];

        return $(result);
      });
    }

    function findPreviousContainer(element) {
      if (!$(element).get(0)) {
        return element;
      }

      const blockData = $(element).get(0).getBlockData();
      const previousBlockData = blockData.getPreviousVisibleBlock();

      if (previousBlockData) {
        return previousBlockData.containingNode;
      }
      
      return element;
    }

    function findNextContainer(element) {
      if (!$(element).get(0)) {
        return element;
      }
      
      const blockData = $(element).get(0).getBlockData();
      const nextBlockData = blockData.getNextVisibleBlock();
      
      if (nextBlockData) {
        return nextBlockData.containingNode;
      }

      return element;
    }

    function setControlCharacters(element) {
      const scopeVar = $rootScope.findAppScope();
      const el = $(element).get(0);

      if (!el) {
        return;
      }

      // Toggle control characters on/off for all spans, even after undo/redo etc'
      el
        .querySelectorAll('span:not(.SOCE):not(.EOCE)')
        .forEach(span => {
          if (scopeVar.isControlCharacters) {
            if (!span.style.fontFamily.startsWith('lp')) {
              span.setAttribute('data-original-font-family', span.style.fontFamily);
              span.style.fontFamily = `lp, ${span.style.fontFamily}`;
            }
          } else {
            if (span.hasAttribute('data-original-font-family')) {
              span.style.fontFamily = span.getAttribute('data-original-font-family');
              span.removeAttribute('data-original-font-family');
            }
          }
        });
    }

    function removeMarkers(element) {
      element.find('.SOCE, .EOCE').remove();
    }

    function addMarkers(element) {
      if (!(element instanceof jQuery)) {
        element = $(element);
      }

      // Add markers at the beginning and end of each content-editable to help
      // navigating the text with the keyboard
      if (
        element.attr('skip-markers') ||
        element.attr('contenteditable') === 'false'
      ) {
        return;
      }

      let container;

      if (element.find('ng-transclude').length > 0) {
        container = element.find('ng-transclude');
      } else {
        container = element;
      }

      if ($(container).find('.SOCE').length === 0) {
        const startMarker = document.createElement('span');
        startMarker.classList.add('SOCE');
        startMarker.contentEditable = false;

        $(container).prepend(startMarker);
      }

      //add PLACEHOLDER class to exists span.
      const placeholder = document.createElement('span');
      placeholder.classList.add('PLACEHOLDER');
      let containerStyleData  =  container.get(0).getAttribute('data-font-info');
      let containerStyleDataJSON = containerStyleData ? JSON.parse(containerStyleData) : null;
      if (containerStyleDataJSON) {
        Object.keys(containerStyleDataJSON).forEach(key => {
          if (
            containerStyleDataJSON[key] &&
            containerStyleDataJSON[key] !== ""
          ) {
            placeholder.style[key] = containerStyleDataJSON[key];
          }
        });
      }
      
      placeholder.innerText = window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE;

      const firstSpan = $(container)
        .find('.SOCE')
        .next('span')
        .get(0)

        if(firstSpan){
          const styleData = {
            fontFamily: firstSpan.style.fontFamily,
            fontWeight: firstSpan.style.fontWeight,            
            fontStyle: firstSpan.style.fontStyle,            
            textDecoration: firstSpan.style.textDecoration,
          }
          container.get(0).setAttribute('data-font-info',JSON.stringify(styleData));
        }

      if (
        firstSpan &&
        $(firstSpan).attr('contenteditable') !== 'false' &&
        !firstSpan.hasAttribute('list')
      ) {
        $(firstSpan).addClass('PLACEHOLDER');
      }

      if ($(container).find('.PLACEHOLDER').length === 0) {
        const isContainTable = $(container)
            .get(0)
            .querySelector("table");

        if (isContainTable) {
          $(placeholder).text('').addClass("PLACEHOLDER--EMPTY");
        }

        $(placeholder).insertAfter($(container).find('.SOCE'));
      } else {
        var currentPlaceholder = $(container).find('.PLACEHOLDER:not(.PLACEHOLDER--EMPTY)');
        if (currentPlaceholder.get(0) && currentPlaceholder.text().length === 0) {
          let emptyListItemNumber = currentPlaceholder
            .get(0)
            .querySelector("[list]");

          let isContainLeaseVar = currentPlaceholder
            .get(0)
            .querySelector("lease-var");

          let isContainUnlockedVar = currentPlaceholder
            .get(0)
            .querySelector("unlocked-var");

          let isContainBuildingVar = currentPlaceholder
            .get(0)
            .querySelector("building-variable");

          let isContainImages = currentPlaceholder
            .get(0)
            .querySelector("img");

          let isContainTable = currentPlaceholder
            .get(0)
            .querySelector("table");

          let isContainLeaseUpload = currentPlaceholder
            .get(0)
            .querySelector("lease-upload");

          if (emptyListItemNumber) {
            $(emptyListItemNumber).text(".");
          } else if (isContainLeaseVar || isContainUnlockedVar || isContainImages || isContainBuildingVar || isContainTable || isContainLeaseUpload) {
            // Skip
          } else {
            $(currentPlaceholder).text(
              window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE
            );
          }
        }
      }

      const placehoderRef = $(container).find('.PLACEHOLDER:not(.PLACEHOLDER--EMPTY)').get(0);

      if (placehoderRef && placehoderRef.textContent.length === 0) {
        let isContainLeaseVar = currentPlaceholder
            .get(0)
            .querySelector("lease-var");

        let isContainUnlockedVar = currentPlaceholder
            .get(0)
            .querySelector("unlocked-var");

        let isContainBuildingVar = currentPlaceholder
            .get(0)
            .querySelector("building-variable");

        let isContainImages = currentPlaceholder
            .get(0)
            .querySelector("img");

        let isContainTable = currentPlaceholder
            .get(0)
            .querySelector("table");

        let isContainLeaseUpload = currentPlaceholder
            .get(0)
            .querySelector("lease-upload");

        if (!isContainLeaseVar && !isContainUnlockedVar && !isContainImages && !isContainBuildingVar && !isContainTable && !isContainLeaseUpload) {
          $(container)
            .find('.PLACEHOLDER')
            .replaceWith(placeholder);
        }
      }

      if ($(container).find('.EOCE').length === 0) {
        const endMarker = document.createElement('span');
        endMarker.classList.add('EOCE');
        endMarker.contentEditable = false;

        $(container).append(endMarker);
      }

      // Set `white-space: pre-wrap` to allow for spans with only white space to exist
      element
        .find('span:not(.SOCE):not(.EOCE)')
        .css('white-space', 'pre-wrap');

      setControlCharacters(element);
    }

    function skipMarkers() {
      var sel = rangy.getSelection();
      var anchorNode = sel.anchorNode;
      if (isSelecting()) {
        return;
      }

      if (!anchorNode) {
        return;
      }

      if (anchorNode.nodeType === Node.TEXT_NODE) {
        if ($(anchorNode).closest('.SOCE').length > 0) {
          anchorNode = $(anchorNode)
            .closest('.SOCE')
            .get(0);
        }
        if ($(anchorNode).closest('.EOCE').length > 0) {
          anchorNode = $(anchorNode)
            .closest('.EOCE')
            .get(0);
        }
      }

      //
      if (
        anchorNode.nextElementSibling &&
        anchorNode.nextElementSibling.nodeName.toLowerCase() === 'hard-return' &&
        !anchorNode.parentElement.classList.contains('new-paragraph')
      ) {
        const element = anchorNode.previousElementSibling;
        const contentEditable = $(element).find('.editable').get(0);

        if (contentEditable) {
          const spans = contentEditable.querySelectorAll('span');
          $rootScope.placeCaretAtEnd(spans[spans.length - 1]);
          return;
        }
      }

      var span;
      var $anchorNode = $(anchorNode);
      const isBullets = $anchorNode.closest('[bullet="true"]').get(0);

      if (isBullets) {
        const bullet = $(isBullets).find('li').get(0);

        if (bullet && bullet.innerHTML.length === 0) {
          bullet.innerHTML = '<br>';
        }
      }

      // Handle selection before SOCE / after EOCE
      if ($anchorNode.hasClass('editable')) {
        if (sel.anchorOffset === 0) {
          span = anchorNode.childNodes[0];
          span = skipEmptyNodes(span, true);
          if (span) {
            $rootScope.placeCaretAtStart(span.childNodes[0], false);
          }
        } else if (
          sel.anchorOffset > 0 &&
          sel.anchorOffset < anchorNode.childNodes.length - 1
        ) {
          // Don't do anything, the caret is already placed at the correct location
        } else {
          span = anchorNode.childNodes[anchorNode.childNodes.length - 1];
          span = skipEmptyNodes(span, false);
          $rootScope.placeCaretAtEnd(
            span.childNodes[span.childNodes.length - 1],
            false,
          );
        }
      } else if ($anchorNode.hasClass('SOCE')) {
        span = anchorNode;
        span = skipEmptyNodes(span, true);
        $rootScope.placeCaretAtStart(span.childNodes[0], false);
      } else if ($anchorNode.hasClass('EOCE')) {
        span = anchorNode;
        span = skipEmptyNodes(span, false);
        $rootScope.placeCaretAtEnd(
          span.childNodes[span.childNodes.length - 1],
          false,
        );
      }
    }

    function skipEmptyNodes(anchorNode, isForward) {
      var direction;
      var marker;
      var isEmptyNode, isEditable;

      if (
        (!isForward && $(anchorNode).hasClass('SOCE')) ||
        (isForward && $(anchorNode).hasClass('EOCE'))
      ) {
        return anchorNode;
      }

      if ($(anchorNode).hasClass('deleted-paragraph')) {
        return anchorNode;
      }

      if (
        !isEmpty(anchorNode) &&
        $(anchorNode).attr('contenteditable') !== 'false'
      ) {
        return anchorNode;
      }

      if (isForward) {
        direction = 'nextSibling';
        marker = '.EOCE';
      } else {
        direction = 'previousSibling';
        marker = '.SOCE';
      }

      var nextNode = anchorNode;

      do {
        nextNode = nextNode[direction];
        isEmptyNode = isEmpty(nextNode);
        isEditable = $(nextNode).attr('contenteditable') !== 'false';

        // We want to allow the caret to be placed in an <li> even if it's empty
        if (
          $(nextNode).closest("[bullet='true']").length > 0 &&
          nextNode.nodeName.toLowerCase() === 'li'
        ) {
          return nextNode;
        }

        if (isEditable) {
          anchorNode = nextNode;
        }
      } while (
        (isEmptyNode || !isEditable) &&
        nextNode[direction] &&
        !$(nextNode[direction]).is(marker)
      );

      return anchorNode;
    }

    function isHeading(element) {
      var scope = angular.element(element);
      var scopeVar = $rootScope.findAppScope(scope);
      var heading;

      // This is useful when there are no dynamic lists defined in the lease
      if (
        !scopeVar.listLevels ||
        (scopeVar.listLevels && scopeVar.listLevels.length === 0)
      ) {
        return false;
      }

      let levelInfo = _.find(scopeVar.listLevels, { level: "section" });
      if (levelInfo && levelInfo.outerTag) {
        heading = levelInfo.outerTag;
      } else {
        return false;
      }

      return $(element).closest(heading).length > 0;
    }

    function getHeading(element) {
      var scope = angular.element(element);
      var scopeVar = $rootScope.findAppScope(scope);
      var listLevels = _.map(scopeVar.listLevels, 'outerTag').join();
      return $(element)
        .closest(listLevels)
        .get(0);
    }

    function prepareForComparison(html) {
      if (html) {
        let dummy = document.createElement('dummy');
        dummy.innerHTML = html;

        let placeholder = dummy.querySelector('.PLACEHOLDER');
        if (placeholder) {
          const newParagraphs = placeholder.querySelectorAll('.new-paragraph');
          let paragraph;

          for (let i = 0; i < newParagraphs.length; i++) {
            paragraph = newParagraphs[i];
            paragraph.innerHTML = paragraph.innerHTML
              .replace(/﻿/g, '&nbsp;');
          }
        }

        html = dummy.innerHTML
          .replace(/﻿/g, '')
          .replace(/<br/g,'&nbsp;<br');
        html = html.replace(/undo/g, '');
        html = html.replace(/{{.*?}}/g, '');
        html = sanitizeHtml(html, {
          allowedTags: [
            'b',
            'strong',
            'i',
            'u',
            'p',
            'span',
            'br',
            'img',
            'table',
            'font',
            'sup',
            'sub',
            'del',
            'ins',
          ],
          allowedAttributes: {
            '*': ['style'],
            'p': ['class'],
            'input': ['type', 'value'],
          },
          allowedClasses: {
            'p': ['new-paragraph'],
            'del': [
              'diff-item--accepted',
              'diff-item--rejected',
              'diff-item--manual',
              'diff-item--resolved',
            ],
            'ins': [
              'diff-item--accepted',
              'diff-item--rejected',
              'diff-item--manual',
              'diff-item--resolved',
            ],
            'span':[
              'word-indent',
            ],
          },
          selfClosing: ['br'],
          exclusiveFilter: function(frame) {
            return (
              (frame.tag === 'b' ||
                frame.tag === 'i' ||
                frame.tag === 'u' ||
                frame.tag === 'span') &&
              !frame.text.length
            );
          },
        });

        return html;
      }

      return '';
    }

    function setContentEditableState(nodes, newState) {
      let current;

      for (var i = 0; i < nodes.length; i++) {
        current = nodes[i];

        if (current instanceof jQuery) {
          current = current.get(0);
        }

        if (current.getAttribute('contenteditable') !== newState) {
          current.setAttribute('contenteditable', newState);
        }
      }
    }

    function setEditingModeForSegment(el, isEditable) {
      if (!el) {
        return;
      }
      
      window.applyFilterVisible(() => {
        var currentState = isEditable ? 'tempFalse' : 'true';
        var newState = isEditable ? 'true' : 'tempFalse';
        const $elRef = $(el);
        const elRef = $elRef.get(0);

        if ($elRef.attr('contenteditable') === currentState) {
          // First toggle editing for the immediate segment
          $elRef.attr('contenteditable', newState);

          // Then toggle editing for the entire block
          var container = findClosestContainer(el);
          var allContentEditableInContainer = findAllContentEditable(
            container,
          );

          setContentEditableState(allContentEditableInContainer, newState);
        }

        // Either way, toggle editing for all content-editables in the selection
        let direction = null;

        if (isSelecting()) {
          var allContentEditableInSelection = findAllContentEditableInSelection();
          setContentEditableState(allContentEditableInSelection, newState);

          const sel = rangy.getSelection();

          if (sel.isBackwards()) {
            direction = "backward";
          } else {
            direction = "forward";
          }
        }

        // Toggle editing of the surrounding containers
        if (el.nodeType === Node.TEXT_NODE) {
          el = el.parentElement;
        }
        
        if (direction === null || direction === "backward") {
          const prevContainer = findPreviousContainer(el);
          const prevContentEditableList = findAllContentEditable(prevContainer);
          setContentEditableState(prevContentEditableList, newState);
  
          const prev2Container = findPreviousContainer(prevContainer);
          const prev2ContentEditableList = findAllContentEditable(prev2Container);
          setContentEditableState(prev2ContentEditableList, newState);

          const prev3Container = findPreviousContainer(prevContainer);
          const prev3ContentEditableList = findAllContentEditable(prev3Container);
          setContentEditableState(prev3ContentEditableList, newState);
        }

        if (direction === null || direction === "forward") {
          const nextContainer = findNextContainer(el);
          const nextContentEditableList = findAllContentEditable(nextContainer);
          setContentEditableState(nextContentEditableList, newState);
  
          const next2Container = findNextContainer(nextContainer);
          const next2ContentEditableList = findAllContentEditable(next2Container);
          setContentEditableState(next2ContentEditableList, newState);

          const next3Container = findNextContainer(nextContainer);
          const next3ContentEditableList = findAllContentEditable(next3Container);
          setContentEditableState(next3ContentEditableList, newState);
        }
      });
    }

    function enableEditingForSegment(el) {
      setEditingModeForSegment(el, true);
    }

    function disableEditingForSegment(el) {
      setEditingModeForSegment(el, false);
    }

    function setContentEditableColor(color) {
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('foreColor', false, color);
    }

    function flattenSpecialVariables(element) {
      const scopeVar = $rootScope.findAppScope();

      if (!scopeVar.editingEnabled) {
        return;
      }

      if (element) {
        if (typeof element === 'string') {
          element = $('<span>' + element + '</span>');
        } else if (!(element instanceof jQuery)) {
          element = $(element);
        }

        if (!$rootScope.adminMode) {
          // Handle unlocked-variable
          element.find('unlocked-var').each(function() {
            // replace these with their compiled inner text (visible text)
            $(this)
              .closest('span')
              .html(this.textContent || this.innerText || '');
          });

          // Handle building-variables
          element.find('building-variable').each(function() {
            // replace these with their compiled inner html (visible text + styles)
            var innerNode = $(this)
              .children()
              .first()
              .children()
              .first();
            if (innerNode.length > 0) {
              innerNode.addClass('added');
              $(this)
                .closest('span')
                .html($(innerNode).get(0).innerHTML);
            } else {
              this.outerHTML = '';
            }
          });
        }

        if (scopeVar.editingEnabled) {
          if (element) {
            addMarkers(element);
          }
        }

        return $(element).get(0).innerHTML;
      }
    }

    function skipNonWidthChar(dir = 'forward') {
      const sel = rangy.getSelection();
      let node = findClosestContainer(sel.focusNode);
      if (node){
        node = node.get(0);
      } else {
        return;
      }
      const text = rangy.innerText(node);

      let offset = sel.getRangeAt(0).toCharacterRange(node).start;

      if (dir === 'backward') {
        offset--;
      }

      let isModified = false;

      while (
        text[offset] === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE ||
        text[offset] === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE
      ) {
        if (dir === 'forward') {
          offset++;
          sel.nativeSelection.modify('move', 'forward', 'character');
        } else {
          offset--;
          sel.nativeSelection.modify('move', 'backward', 'character');
        }

        isModified = true;
      }

      if (isModified) {
        sel.refresh();
      }
    }

    function skipPageBreak() {
      const sel = rangy.getSelection();
      const focusNode = sel.focusNode;

      if(focusNode.nodeType === Node.TEXT_NODE) {
        let _node = focusNode.nextElementSibling;
        if (_node && _node.nodeName === "HARD-RETURN") {
          _node = _node.nextElementSibling;
          if (_node && _node.classList.contains('new-page-break')) {

            sel.refresh();
          }
        }
      }
    }

    function getCurrentEditedElement() {
      var sel = rangy.getSelection();
      var range = sel.getRangeAt(0);
      var container = range.commonAncestorContainer;
      container =
        container.nodeType === Node.TEXT_NODE
          ? container.parentNode
          : container;
      return container;
    }

    function adjustBulletsColor() {
      var elem = getCurrentEditedElement();
      var text = $(elem).closest('li').text();

      if (
        !((text.length === 0) ||
        (text.length === 1 && (
          text === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || 
          text === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE
        )))
      ) {
        $(elem)
          .closest('li')
          .removeClass('empty-li');
      } else {
        $(elem)
          .closest('li')
          .addClass('empty-li')
          .text(window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE);
      }
    }


    

    function isFirstBullet() {
      var elem = getCurrentEditedElement();
      var sel = rangy.getSelection();

      return (
        $(elem)
          .closest('[bullet="true"]')
          .find('li').length === 1 && (sel.anchorOffset === 0 || (sel.anchorNode.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || sel.anchorNode.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE))
      );
    }

    function getStyle(element) {
      return element.getAttribute('style');
    }


    function isSelfClosing(element) {
      if (element.nodeType === Node.COMMENT_NODE) {
        return false;
      }
      var selfClosingElements = ['br', 'img'];
      return (
        element.tagName &&
        selfClosingElements.indexOf(element.tagName.toLowerCase()) !== -1
      );
    }

    function isNodeWordStyle(node) {
      var txt = node.text().trim();
      return (
        _.startsWith(txt, '!!SET_LEVEL_') &&
        txt.lastIndexOf('!!') == txt.length - 2
      );
    };

    async function copySelection() {
      if (window.__LEASEPILOT_IS_WORKING__)  {
        return;
      }

      window.__LEASEPILOT_IS_WORKING__ = true;

      try {
        const result = await downloadManager.getSelectionHtml();
        const node = document.createElement("dummy");
        node.innerHTML = result;
        
        const text = node.textContent;
        const html = result;
        const ClipboardItemData = {
          "text/plain": new Blob([text], { type: "text/plain" }),
          "text/html": new Blob([html], { type: "text/html" }),
        }
        const data = [new ClipboardItem(ClipboardItemData)];
  
        await navigator.clipboard.write(data);
      } catch (error) {
        console.error("[clipboard] Error copying to clipboard", error);
        Raven.captureException(error);
      } finally {
        window.__LEASEPILOT_IS_WORKING__ = false;
      }
    }
  },
]);
