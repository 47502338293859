angular.module('LeasePilot').directive('order', [
  '$timeout',
  function($timeout) {
    return {
      restrict: 'AE',
      scope: {
        config: '=',
        category: '@',
        order: '@',
      },
      link($scope, element) {
        $timeout(() => {
          if (_.get($scope.config, `fields.orderFields.${$scope.category}`)) {
            var camelCaseOrder = _.camelCase($scope.order);
            var orderFields = $scope.config.fields.orderFields[$scope.category];
            var order = _.has(orderFields, $scope.order) ? orderFields[$scope.order] : orderFields[camelCaseOrder];
            element[0].style.order = order;
          }
        }, 0);
      },
    };
  },
]);
