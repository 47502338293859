angular.module('LeasePilot').service('ShareService', [
  'LeaseEditorService',
  '$rootScope',
  '$mdDialog',
  function (LeaseEditorService, $rootScope, $mdDialog) {

    var openDialog = function (event) {
      $mdDialog.show({
        template: '<md-dialog><share-modal></share-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: false,
        fullscreen: false,
      });
    }


    var showShareDialog = function (event, data) {
      if ($rootScope.lease && $rootScope.lease.id === data.leaseId) {
        openDialog(event);
      } else {
        LeaseEditorService.setLease(
          data.leaseId,
          data.type.toLowerCase(),
        ).then(function (lease) {
          LeaseEditorService.setLeaseTitle();
          openDialog(event);
        });
      }
    };

    window.showShareDialog = showShareDialog;

    return {
      showShareDialog: showShareDialog,
    };
  }
]);
