angular.module('LeasePilot').service('DownloadService', function() {
  var downloadFile = function(data, type, fileName) {
    var file = new Blob([data], {
      type: type,
    });
    var fileName = fileName;
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, fileName);
    else {
      // Others
      var url = URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };

  return {
    download: downloadFile,
  };
});
