angular.module('LeasePilot').controller('manageEditorController', [
  '$scope',
  '$rootScope',
  '$timeout',
  'TemplateService',
  'LabelsService',
  'EditorService',
  '$mdDialog',
  'DownloadService',
  function(
    $scope,
    $rootScope,
    $timeout,
    TemplateService,
    LabelsService,
    EditorService,
    $mdDialog,
    DownloadService,
  ) {
    $rootScope.editingEditorMode = true;
    $scope.currentCategory = 0;
    $scope.templates = {};
    $scope.categories = [];
    $scope.activeTemplates = [];
    $scope.activeTemplate; // FIX: no assignment
    $scope.hideSelectedTemplates = false;

    $scope.setSections = function() {
      $scope.sections = TemplateService.getSections(
        $scope.editor.isMultipleTerms,
      );
      $scope.multipleTermsSection = TemplateService.getMultipleTermsSection();
      $scope.basicTermsSection = TemplateService.getBasicTermsSection();
      $scope.defaultSection = _.last($scope.sections);
      TemplateService.setSectionsForCards($scope.sections, $scope.editor);
    };

    function setTemplatesForAdditionalTermsCard(additionalTermsCard) {
      var additionalTermsCardId = TemplateService.getMultipleTermsCards()[1];
      var additionalTermsCard = _.find($scope.editor.cards, function(card) {
        return card.id == additionalTermsCardId;
      });

      var initialTermsCardId = TemplateService.getMultipleTermsCards()[0];
      var initialTermsCard = _.find($scope.editor.cards, function(card) {
        return card.id == initialTermsCardId;
      });

      _.each(initialTermsCard.categories, function(category) {
        _.each(category.templates, function(template) {
          var tpl = _.chain(additionalTermsCard.categories)
            .flatMap('templates')
            .find(function(t) {
              return t.id == template.id;
            })
            .value();
          if (tpl) template.config = tpl.config;
        });
      });
    }

    function initAdditionalTermsCard() {
      var additionalTermsCardId = TemplateService.getMultipleTermsCards()[1];
      var additionalTermsCard = _.find($scope.editor.cards, function(card) {
        return card.id == additionalTermsCardId;
      });
      if (!additionalTermsCard) {
        additionalTermsCard = $scope.addEditorCard(
          additionalTermsCardId,
          _.startCase(additionalTermsCardId),
          $scope.multipleTermsSection,
        );
      }
      setTemplatesForAdditionalTermsCard(additionalTermsCard);
      $scope.additionalTermsCard = additionalTermsCard;
    }

    function moveTemplatesBetweenCards() {
      if ($scope.editor.isMultipleTerms) {
        // find or create the new card - combination of term & rent
        var initialTermsCardId = TemplateService.getMultipleTermsCards()[0];
        var initialTermsCard = _.find($scope.editor.cards, function(card) {
          return card.id == initialTermsCardId;
        });
        if (!initialTermsCard) {
          initialTermsCard = $scope.addEditorCard(
            initialTermsCardId,
            _.startCase(initialTermsCardId),
            $scope.multipleTermsSection,
          );
        }
        initAdditionalTermsCard();
        // copy templates from both - term & rent to the new card
        var termAndRentCategories = _.chain($scope.editor.cards)
          .filter(function(card) {
            return card.id == 'term' || card.id == 'base-rent';
          })
          .flatMap('categories')
          .cloneDeep()
          .value();

        var initialTermsCategotries = _.cloneDeep(termAndRentCategories);
        _.each(initialTermsCategotries, function(v, k) {
          v.templates = _.filter(v.templates, function(t) {
            return $scope.templates[t.id].isGeneralTerms;
          });
        });
        initialTermsCard.categories = initialTermsCategotries;
      }
    }

    $scope.toggleMultipleTerms = function() {
      $scope.setSections();
      moveTemplatesBetweenCards();
      $scope.bindCollapseEvent();
    };

    $scope.init = function(formId) {
      EditorService.getEditorCards(formId).then(function(editor) {
        editor.formId = formId;
        $scope.editor = editor;
        $scope.setEditorOfEditor();
        $scope.setSections();
        if ($scope.editor.isMultipleTerms) {
          initAdditionalTermsCard();
        }
        $scope.setLabels();
      });
    };

    $scope.setEditorOfEditor = function() {
      $scope.setTemplates();
      $scope.mergeEditorTemplates();
    };

    $scope.setTemplates = function() {
      $scope.templates = TemplateService.getTemplates();
      $scope.categories = TemplateService.getCategories();
      $scope.categories.push('all');
      $scope.activateCategory($scope.currentCategory);
      $scope.activateCard();
    };

    $scope.mergeEditorTemplates = function() {
      _.mapValues($scope.templates, function(val) {
        val.isSelected = false;
        return val;
      });
      _.each($scope.editor.cards, function(card) {
        _.each(card.categories, function(category) {
          _.each(category.templates, function(template) {
            $scope.templates[template.id].defaults = template.config;
            $scope.templates[template.id].isSelected = true;
          });
        });
      });
    };

    $scope.prevCategory = function() {
      $scope.activateCategory(--$scope.currentCategory);
      $scope.activateCard();
    };

    $scope.nextCategory = function() {
      $scope.activateCategory(++$scope.currentCategory);
      $scope.activateCard();
    };

    $scope.activateCategory = function(categoryNumber) {
      $scope.currentCategory = _.clamp(
        categoryNumber,
        0,
        $scope.categories.length - 1,
      );
      if ($scope.categories[$scope.currentCategory] === 'all') {
        $scope.activeTemplates = $scope.templates;
      } else {
        $scope.activeTemplates = _.pickBy($scope.templates, function(tpl, key) {
          return tpl.category === $scope.categories[$scope.currentCategory];
        });
        $scope.showMultipleTerms =
          $scope.categories[$scope.currentCategory] == 'premises';
      }
      _.each($scope.activeTemplates, function(v, k){
        v.id = k;
      })
    };

    //edit templates
    $scope.activateTemplate = function(template) {
      $scope.activateCategory(
        _.indexOf($scope.categories, $scope.templates[template].category),
      );
      $scope.activateTemplateConfig(template);
      //TODO: scroll to the template
    };

    $scope.activateTemplateConfig = function(template) {
      $scope.activeTemplate = template;
    };

    $scope.selectTemplate = function(templateId, template) {
      if (template.isSelected) {
        $scope.addTemplateToDefaultCard(templateId, template);
      } else {
        $scope.removeEditorTemplate(templateId);
      }
    };

    $scope.removeEditorTemplate = function(templateId) {
      $scope.templates[templateId].isSelected = false;
      _.each($scope.editor.cards, function(card) {
        _.each(card.categories, function(category) {
          _.remove(category.templates, function(tpl) {
            return tpl.id == templateId;
          });
        });
      });
    };

    $scope.addTemplateToDefaultCard = function(templateId, template) {
      var newTemplate = {
        id: templateId,
        config: template.defaults,
      };
      var category =
        $scope.editor.isMultipleTerms && template.category == 'term'
          ? 'initial-commencement-premises'
          : template.category;
      var defaultCard = _.find($scope.editor.cards, function(card) {
        return card.id == category;
      });
      if (!defaultCard) {
        defaultCard = $scope.addEditorCard(category, _.startCase(category));
      }
      _.last(defaultCard.categories).templates.push(newTemplate);
      $scope.activateCard(defaultCard.id);
      if (category == 'additional-commencement-premises') {
        var initialTermsCard = _.find($scope.editor.cards, function(card) {
          return card.id == 'initial-commencement-premises';
        });

        if (initialTermsCard) {
          let lastCategory = _.last(initialTermsCard.categories);
          if (lastCategory) {
            lastCategory.templates.push(newTemplate);
          }
        }
      }
      //TODO: scroll to the new template
    };

    //edit categories
    function getCategoryIndex(card, category) {
      return _.findIndex(card.categories, function(c) {
        return c == category;
      });
    };

    $scope.deleteSubCategory = function(card, category) {
      var categoryIndex = getCategoryIndex(card, category);
      if (categoryIndex == 0) {
        category.caption = '';
      } else {
        var prevCategory = card.categories[categoryIndex - 1];
        //move templates to prev category
        prevCategory.templates = prevCategory.templates.concat(
          category.templates,
        );
        //remove selected category
        card.categories.splice(categoryIndex, 1);
      }
    };

    $scope.addSubCategory = function(card, category, templateIndex) {
      if (templateIndex == 0 && _.isEmpty(category.caption)) {
        category.caption = 'sub category';
      } else {
        var templatesToMove = category.templates.splice(
          templateIndex,
          category.templates.length,
        );
        var categoryIndex = getCategoryIndex(card, category);
        var newCategory = {
          caption: 'sub category',
          templates: templatesToMove,
        };
        card.categories.splice(categoryIndex + 1, 0, newCategory);
      }
    };

    //edit labels
    $scope.setLabels = function() {
      $rootScope.labels = $scope.shortLabels = LabelsService.getMergedLabels(
        $scope.editor.labels,
        false,
      );
      $scope.fullLabels = LabelsService.getMergedLabels(
        $scope.editor.labels,
        true,
      );
    };

    //bind labels events
    $scope.editLabel = function(name) {
      $mdDialog.show({
        controller:
          ('DialogController', ['$scope', '$mdDialog', DialogController]),
        templateUrl: 'edit_label_modal.html',
        clickOutsideToClose: true,
        skipHide: true,
        fullscreen: false,
        hasBackdrop: false,
      });

      function DialogController(scope, $mdDialog) {
        scope.for = name;
        scope.isTitle = name.startsWith('title.');
        scope.long = $scope.fullLabels[name];
        scope.short = $scope.shortLabels[name];
        scope.dialog = $mdDialog;
        scope.longAsShort = _.isEqual(scope.long, scope.short);
        scope.copyShortToLong = function() {
          if (scope.longAsShort) scope.long = scope.short;
        };
        scope.save = function() {
          scope.copyShortToLong();
          var label = {
            key: name,
            shortDesc: scope.short,
            fullDesc: scope.long,
          };
          $scope.editor.labels = _.unionBy(
            [label],
            $scope.editor.labels,
            'key',
          );
          $scope.setLabels();
          scope.dialog.cancel();
        };
      }
    };

    $(document).on('click', '.manage-editor editor-label[for]', function(e) {
      $scope.editLabel($(this).attr('for'));
    });

    //edit cards
    $scope.addEditorCard = function(id, caption, section) {
      var newCard = {
        id: id || '',
        caption: caption || '',
        categories: [
          {
            templates: [],
          },
        ],
        section: section || $scope.defaultSection,
      };
      $scope.editor.cards.push(newCard);
      $scope.bindCollapseEvent();
      $scope.activateCard(newCard.id);
      return newCard;
    };

    $scope.removeEditorCard = function(card) {
      var confirm = $mdDialog.confirm({
        skipHide: true,
        template:
          '<div class="md-dialog-content confirm-modal">' +
          '<p class="title">Delete Card</p>' +
          '<p class="message">Are you sure you want to delete this card? <br/>' +
          'Deleting a card removes all information contained within it.</p>' +
          '<div class="modal-actions">' +
          '<button class="btn blue" ng-click="dialog.hide()">Remove</button>' +
          '<button class="btn blue-outline" ng-click="dialog.abort()">Cancel</buttom>' +
          '</div>' +
          '</div>',
        clickOutsideToClose: true,
      });
      $mdDialog.show(confirm).then(function() {
        _.remove($scope.editor.cards, function(c) {
          return c == card;
        });
      });
    };

    $scope.activateCard = function(id) {
      if (id === undefined) id = $scope.categories[$scope.currentCategory];
      $timeout(function() {
        $('.collapse-card.active')
          .not('.collapse-card[data-id="' + id + '"]')
          .find('.collapse-card__heading')
          .each(function(index) {
            $(this).trigger('click');
          });
        $('.collapse-card[data-id="' + id + '"]')
          .not('.active')
          .last()
          .find('.collapse-card__heading')
          .trigger('click');
      });
    };

    $scope.dropCardCallback = function(index, item, external, type) {
      $scope.bindCollapseEvent();
      return item;
    };

    $scope.bindCollapseEvent = function() {
      $timeout(function() {
        $('.collapse-card__heading').off('click');
        $('.collapse-card').paperCollapse();
        $('.collapse-card input').on('click', function(e) {
          e.stopPropagation();
        });
      });
    };

    //actions
    $scope.setDefault = function(path) {
      window.open(path, '_blank');
    };

    $scope.saveDefault = function(path) {
      $scope.editor.publish = false;
      $scope.editor.defaults = true;
      if ($scope.lease.tenantInfo) $scope.lease.tenantInfo.name = null;
      $scope.editor.defaultFields = _.pickBy($scope.lease, function(value) {
        return value !== null && value !== undefined && value !== '';
      });
      $scope.editor.update().then(function() {
        window.location = path;
      });
    };

    $scope.discard = function(path) {
      window.location = path;
    };

    $scope.save = function(path) {
      $scope.editor.publish = false;
      $scope.editor.update().then(function() {
        window.location = path;
      });
    };

    $scope.publish = function(path) {
      $scope.editor.publish = true;
      $scope.editor.update().then(function() {
        window.location = path;
      });
    };

    $scope.exportEditor = function() {
      var fileName =
        $scope.lease.form.company.name +
        '-' +
        $scope.lease.form.flavor +
        ' editor';
      DownloadService.download(
        JSON.stringify($scope.editor),
        'application/json',
        fileName,
      );
    };

    $scope.importEditor = function(ele) {
      var $file = ele.files[0];
      if (!$file) return;
      var reader = new FileReader();
      reader.onload = function(event) {
        var newEditor = JSON.parse(event.target.result);
        $scope.editor.cards = [];
        $scope.$apply();
        $scope.editor.cards = newEditor.cards;
        $scope.editor.labels = newEditor.labels;
        $scope.editor.defaultFields = newEditor.defaultFields;
        $scope.editor.isMultipleTerms = newEditor.isMultipleTerms;
        $scope.editor.sections = newEditor.sections;
        $scope.setLabels();
        $scope.setSections();
        if ($scope.editor.isMultipleTerms) {
          initAdditionalTermsCard();
        }
        $scope.mergeEditorTemplates();
        $scope.$apply();
        $scope.bindCollapseEvent();
        ele.value = null;
      };
      reader.readAsText($file, 'UTF-8');
    };

    //bind drag-and-drop events
    $(document).on('dragover', '.collapse-card__heading', function(e) {
      var card = $(this).closest('.collapse-card');
      if (!$(card).hasClass('active')) {
        $(this).trigger('click');
      }
    });
  },
]);
