angular.module('LeasePilot').service('VersionControlService', [
  '$http',
  '$rootScope',
  function($http, $rootScope) {
    var report = function(leaseFile, diffgramFile, leaseFileName, diffgramFileName, userEmail) {
      var formData = new FormData();
      formData.append('lease_file', leaseFile);
      formData.append('diffgram_file', diffgramFile);
      formData.append('user_email', userEmail);
      formData.append('env_name', window.location.origin);
      formData.append('lease_file_name', leaseFileName);
      formData.append('diffgram_file_name', diffgramFileName);

      return $http({
        method: 'POST',
        url: '/api/version_control_unsupported_report',
        headers: {
          'Content-Type': undefined,
        },
        data: formData,
      });
    };

    var usage = function(url, email, zipFile, zipFileName, status) {
      var formData = new FormData();
      formData.append('url', url);
      formData.append('email', email);
      formData.append('zip_file', zipFile);
      formData.append('zip_file_name', zipFileName);
      formData.append('status', JSON.stringify(status));

      return $http({
        method: 'POST',
        url: '/api/version_control_usage_report',
        headers: {
          'Content-Type': undefined,
        },
        data: formData,
      });
    };

    return {
      report: report,
      usage: usage,
    };
  },
]);
