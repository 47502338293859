angular.module('LeasePilot').directive('downloadBlankLease', [
  'ApiService',
  'BlankLeaseService',
  function(ApiService, BlankLeaseService) {
    return {
      restrict: 'E',
      templateUrl: 'download_blank_lease.html',
      scope: {},
      link: function($scope, element, ettrs) {
        $scope.processing = false;
        $scope.eventName = 'downloadBlankLease';
        var type =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        $scope.downloadErrorMessage =
          'Oops... something went wrong with the download process, please wait a couple of seconds and try again.';

        // const sessionBlankLeases = sessionStorage.getItem('blankLeases');
        // if (sessionBlankLeases) {
        //   $scope.blankLeases = JSON.parse(sessionBlankLeases);
        // } else {
        //   BlankLeaseService.get().then((response) => {
        //     $scope.blankLeases = response;
        //     sessionStorage.setItem('blankLeases', JSON.stringify($scope.blankLeases));
        //   }, () => {
        //     $scope.errors.blankLeaseDoc = 'error in loading blank leases.';
        //     $scope.blankLeases = [];
        //   });
        // }

        BlankLeaseService.get().then(
          function(blankLeases) {
            $scope.blankLeases = blankLeases;
          },
          function(error) {
            $scope.errors.blankLeaseDoc = 'error in loading blank leases.';
            $scope.blankLeases = [];
          },
        );

        $scope.openDownloadMenu = function($mdOpenMenu, ev) {
          $mdOpenMenu(ev);
        };

        $scope.downloadBlank = function(blankId, blankName) {
          $scope.blankName = blankName;
          $scope.processing = true;
          var iframe = $('iframe[name="download"]');
          var iframeSrc = '/blank_leases/' + blankId;
          iframe.attr('src', iframeSrc);

          window.track.event(new DownloadBlankFormEvent({
            context: 'dashboard',
          }));
        };

        $scope.$on($scope.eventName, function(event, data) {
          console.log(new Date().getTime() + ' on event: ', $scope.eventName);
          console.log(new Date().getTime() + ' data ', data);
          ApiService.htmlToWord(data.file, window.ASSETS_DESIRED_STYLES)
            .then(function successCallback(response) {
              $scope.processing = false;
              $scope.downloadFile(
                response.data,
                $scope.blankName +
                  moment().format(' YYYY_M_D H_mm_ss') +
                  '.docx',
                type,
              );
              $scope.$apply();
              console.log(
                new Date().getTime() +
                  ' download blankLease finished successfully',
              );
            },
            function errorCallback(response) {
              $scope.processing = false;
              console.log(response);
              alert($scope.downloadErrorMessage);
            },
          );
        });

        $scope.downloadFile = function(data, filename, type) {
          var file = new Blob([data], { type: type });
          if (window.navigator.msSaveOrOpenBlob)
            // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
          else {
            // Others
            var url = URL.createObjectURL(file);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            }, 0);
          }
        };
      },
    };
  },
]);
