angular.module('LeasePilot').directive('newAbstractModal', [
  '$http',
  '$mdDialog',
  'FormService',
  '$rootScope',
  function($http, $mdDialog, FormService, $rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'new_abstract_modal.html',
      link: function($scope, element, attrs) {
        $scope.form = {
          flavor: 'Abstract for ' + $scope.parentForm.flavor,
          companyId: $scope.parentForm.companyId,
          dealType: $scope.parentForm.dealType,
          name: 'Abstract',
          parentFormId: $scope.parentForm.id,
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.create = function() {
          var newForm = new FormService($scope.form).create().then(function() {
            window.location.reload();
          });
        };
      },
    };
  },
]);
