angular.module('LeasePilot').factory('FreeTextsBulkService', ['railsResourceFactory', 'railsSerializer', 'CleanFreeTextService', function (railsResourceFactory, railsSerializer, CleanFreeTextService) {
  var FreeTextsBulk = railsResourceFactory({
    url: '/api/free_texts/create_bulk',
    name: 'freeTextsBulk',
    serializer: railsSerializer(function () {
      this.resource('freeTexts', 'FreeTextService');
    }),
  });
  FreeTextsBulk.beforeRequest(function (data) {
    if (data) {
      let clone = {};

      const keys = Object.keys(data);
      
      for (let i = 0; i < keys.length; i++) {
        let freeText = data[keys[i]];
        let cloneFreeText = {
          lease_id: freeText.lease_id,
          amendment_id: freeText.amendment_id,
          section_id: freeText.section_id,
          text: freeText.text,
          timestamp: new Date().getTime(),
        }

        clone[i] = cloneFreeText;
      }

      clone = CleanFreeTextService.cleanBulk(clone);

      return clone;
    }
  });
  return FreeTextsBulk;
}]);
