angular.module('LeasePilot').directive('lpDatePicker', [
  '$rootScope',
  function($rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'lp_date_picker.html',
      scope: {
        dpId: '@',
        field: '@',
        mode: '@',
        currentUpdatingField: '@',
        onChange: '&',
      },
      link: function(scope, element, attrs) {
        scope.mode = scope.mode || 'day';
        scope.currentUpdatingField =
          scope.currentUpdatingField || 'lease.' + scope.field;

        var settings = {
          month: {
            options: {
              datepickerMode: 'month',
              minMode: 'month',
            },
            placeholder: 'MM/YYYY',
            uibDatepickerPopup: 'MM/yyyy',
            class: 'month-picker',
          },
          day: {
            options: {
              showWeeks: false,
            },
            placeholder: 'mm/dd/yyyy',
            uibDatepickerPopup: 'MM/dd/yyyy',
          },
        };

        scope.settings = settings[scope.mode];

        scope.isOpen = false;
        scope.datePickerOptions = {
          showWeeks: false,
        };
        scope.scopeVar = $rootScope.findAppScope(scope);
      },
    };
  },
]);
