angular.module("LeasePilot").factory("IterationService", [
  "railsResourceFactory",
  function(railsResourceFactory) {
    var Iteration = railsResourceFactory({
      url: "/api/iterations",
      name: "iteration"
    });
    return Iteration;
  }
]);
