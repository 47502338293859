angular.module('LeasePilot').directive('newBlankFormModal', [
  '$mdDialog',
  '$http',
  'BlankLeaseService',
  'BuildingService',
  function($mdDialog, $http, BlankLeaseService, BuildingService) {
    return {
      restrict: 'E',
      templateUrl: 'new_blank_form_modal.html',
      link($scope, element, attrs) {
        $scope.blankForm = {
          formId: attrs.formId,
          companyId: attrs.companyId,
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        BuildingService.query({
          companyId: attrs.companyId,
          includeBlanks: true,
        }).then(response => {
          $scope.buildings = response;
          $scope.filterBuildings();
        });

        $scope.filterBuildings = function() {
          $scope.blankForm.building = null;
          $scope.filteredBuildings = _.filter($scope.buildings, building =>
            $scope.blankForm.buildingType == 'existing-empty-building'
              ? building.isBlank
              : !building.isBlank,
          );
        };

        $scope.create = function() {
          const newForm = new BlankLeaseService($scope.blankForm)
            .create()
            .then(() => {
              window.location.reload();
            });
        };
      },
    };
  },
]);
