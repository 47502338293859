/* global JSZip */
angular.module('LeasePilot').directive('compareModal', [
  '$rootScope',
  '$mdDialog',
  'BlankLeaseService',
  'DocumentService',
  'ApiService',
  'LeaseEditorService',
  function(
    $rootScope,
    $mdDialog,
    BlankLeaseService,
    DocumentService,
    ApiService,
    LeaseEditorService,
  ) {
    return {
      restrict: 'E',
      templateUrl: 'compare_modal.html',
      scope: {
        download: '=',
      },
      link: function($scope, element, attrs) {
        $scope.processing = false;
        $scope.showMask = false;
        $scope.compareTo = 'blankLeaseDoc';
        $scope.blankLeases = [];
        $scope.blankLeaseDoc = null;
        $scope.prevLeaseDoc = null;
        $scope.wordDoc = null;
        $scope.errors = { wordDoc: 'No file selected' };
        $scope.downloadErrorMessage =
          'Oops... something went wrong with the download process, please wait a couple of seconds and try again.';
        $scope.eventName1 = 'compareModified';
        $scope.eventName2 = 'compareOriginal';
        $scope.isOppositeDirection = false;
        var path = '/' + pluralize($rootScope.lease.type).toLowerCase() + '/';
        var type =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        BlankLeaseService.get().then(
          blankLeases => {
            const blankLeasesByType = blankLeases.filter(blank => {
              const source = blank.objectType.toLowerCase();
              const target = $rootScope.lease.type.toLowerCase();
              return source === target;
            });

            $scope.blankLeases = blankLeasesByType;
            if (blankLeasesByType.length == 1) {
              $scope.blankLeaseDoc = blankLeasesByType[0];
            }
          },
          () => {
            $scope.errors.blankLeaseDoc = 'error in loading blank leases.';
            $scope.blankLeases = [];
          },
        );

        DocumentService.query(
          { lease_id: $rootScope.lease.id },
          { type: $rootScope.lease.type.toLowerCase() },
        ).then(
          function(leaseVersions) {
            $scope.leaseVersions = leaseVersions;
          },
          function(error) {
            $scope.errors.prevLeaseDoc = 'error in loading lease versions.';
            $scope.leaseVersions = [];
          },
        );

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.uploadFile = function(file) {
          $scope.wordDoc = file;
          delete $scope.errors.wordDoc;
        };

        function downloadFile(data) {
          var a = document.createElement('a');
          var file = new Blob([data], { type: type });
          var url = URL.createObjectURL(file);
          a.href = url;
          a.download = comparisonFileName();
          document.body.appendChild(a);
          a.click();
        }

        function fileName() {
          if (!$rootScope.lease.isConformedDeal) {
            return (
              $rootScope.lease.building.dashboardName +
              ' - ' +
              LeaseEditorService.getLeaseTenantName() +
              ' ' +
              $rootScope.title.version.major +
              '.docx'
            );
          } else {
            return (
              '[Conformed Deal] - ' +
              LeaseEditorService.getLeaseTenantName() +
              ' ' +
              $rootScope.title.version.major +
              '.docx'
            );
          }
        }

        function comparisonFileName() {
          if (!$rootScope.lease.isConformedDeal) {
            return (
              'Comparison - ' +
              $rootScope.lease.building.dashboardName +
              ' - ' +
              LeaseEditorService.getLeaseTenantName() +
              ' ' +
              $rootScope.title.version.major +
              ' against ' +
              $scope.originalFileName +
              '.docx'
            );
          } else {
            // TODO:
          }
        }

        function getRedlineFile(zipFile) {
          /////////////////////////////////////////////////////////////////////////////////////////////
          // Kept for debugging purposes uncomment to get the zip file before it's sent to the service
          // The Zip file is downloaded as a *.docx file, hence, needs to be renamed
          // downloadFile(zipFile);
          /////////////////////////////////////////////////////////////////////////////////////////////

          ApiService.zipToRedline(zipFile).then(
            function(response) {
              $scope.showMask = false;
              $scope.processing = false;
              if ($scope.download === false) {
                var file = new Blob([response.data], { type: type });

                // When you share a document with a comparison attached to it,
                // you should get two documents:
                //   1. The actual comparison
                //   2. The document you're at
                // Even if the direction was changed, we should always get the right file
                var modifiedFile = $scope.isOppositeDirection
                  ? $scope.originalFile
                  : $scope.modifiedFile;

                var data = {
                  data: {
                    file: file,
                    name: comparisonFileName(),
                    type: 'redLine',
                  },
                  modified: {
                    file: modifiedFile,
                    name: fileName(),
                    type: 'share',
                  },
                };
                $rootScope.$emit('getFile', data);
              } else {
                downloadFile(response.data);
              }
              console.log(
                new Date().getTime() +
                  ' download redline doc finished successfully',
              );
              $scope.cancel();
            },
            function(error) {
              $scope.showMask = false;
              $scope.processing = false;
              console.log('error ', error);
              alert($scope.downloadErrorMessage);
              $scope.cancel();
            },
          );
        }

        function zipFiles(original, modified, callback) {
          var zip = new JSZip();
          zip.file('original.docx', original);
          zip.file('modified.docx', modified);
          zip.generateAsync({ type: 'blob' }).then(function(zipFile) {
            callback(zipFile);
          });
        }

        function setModifiedFile() {
          if (window.lease) {
            downloadManager.prepareDownload($scope.eventName1, $scope);
          } else {
            var modifiedIframe = $('iframe[name="modified"]');
            var modifiedIframeSrc = path + $rootScope.lease.id;
            modifiedIframe.attr("src", modifiedIframeSrc);
          }
        }

        function setOriginalFile() {
          var originalIframe = $('iframe[name="original"]');
          var originalIframeSrc;
          switch ($scope.compareTo) {
            case 'blankLeaseDoc':
              originalIframeSrc = '/blank_leases/' + $scope.blankLeaseDoc.id;
              originalIframe.attr('src', originalIframeSrc);
              $scope.originalFileName = $scope.blankLeaseDoc.name;
              break;
            case 'prevLeaseDoc':
              originalIframeSrc = path + $scope.prevLeaseDoc.id;
              originalIframe.attr('src', originalIframeSrc);
              $scope.originalFileName =
                $scope.prevLeaseDoc.building.dashboardName +
                ' - ' +
                ($scope.prevLeaseDoc.customFields?.fileName || $scope.prevLeaseDoc.tenantInfo?.tradeName || $scope.prevLeaseDoc.tenantInfo?.name || '') +
                ' V.' +
                $scope.prevLeaseDoc.instanceVersion;
              break;
            case 'wordDoc':
              $scope.originalFile = $scope.wordDoc;
              $scope.originalFileName =
                'file ' + $scope.wordDoc.name.replace(/\.[^/.]+$/, '');
              break;
            default:
              break;
          }
        }

        function singleFileConverted(data) {
          if ($scope.originalFile && $scope.modifiedFile) {
            // When the comparison direction is opposite
            if ($scope.isOppositeDirection) {
              var tmp = $scope.originalFile;
              $scope.originalFile = $scope.modifiedFile;
              $scope.modifiedFile = tmp;
            }
            zipFiles($scope.originalFile, $scope.modifiedFile, getRedlineFile);
          }
        }

        function convert(data, callback) {
          ApiService.htmlToWord(data.file, window.ASSETS_DESIRED_STYLES)
            .then(function successCallback(response) {
              var file = new Blob([response.data], { type: type });
              callback(file);
            },
            function errorCallback(error) {
              console.log(error);
            },
          );
        }

        $scope.$on($scope.eventName1, function(event, data) {
          console.log(new Date().getTime() + ' on event: ', $scope.eventName1);
          console.log(new Date().getTime() + ' data ', data);
          convert(data, function(file) {
            $scope.modifiedFile = file;
            singleFileConverted(data);
          });
        });

        $scope.$on($scope.eventName2, function(event, data) {
          console.log(new Date().getTime() + ' on event: ', $scope.eventName2);
          console.log(new Date().getTime() + ' data ', data);
          convert(data, function(file) {
            $scope.originalFile = file;
            singleFileConverted(data);
          });
        });

        $scope.compare = function() {
          $scope.showMask = true;
          $scope.processing = true;
          setModifiedFile();
          setOriginalFile();

          window.track.event(new CompareDocumentsEvent({
            context: $rootScope.getContext(),
          }));
        };
      },
    };
  },
]);
