
if (!NodeList.prototype.lastItem) {
  NodeList.prototype.lastItem = function() {
    if (this.length === 0) {
      return null;
    }
    return this[this.length - 1];
  };
}


if (!NodeList.prototype.indexOf) {
  NodeList.prototype.indexOf = function(item) {
    return Array.prototype.indexOf.call(this,item);
  };
}



