angular.module('LeasePilot').factory('DocumentService', [
  'railsResourceFactory',
  'railsSerializer',
  function(railsResourceFactory, railsSerializer) {
    var resource = railsResourceFactory({
      url: '/api/{{type}}/{{id}}',
      name: 'document',
      serializer: railsSerializer(function() {
        this.resource('freeTexts', 'FreeTextService');
      }),
    });

    resource.buildUrl = function(context) {
      var params = _.clone(context);
      if (params.type) {
        params.type = _.lowerCase(params.type);
      } else {
        params.type = 'lease';
      }
      params.type = pluralize(params.type);

      return this.config.urlBuilder(params);
    };

    resource.beforeRequest(function (data) {
      if (data) {
        let clone = JSON.parse(JSON.stringify(data));

        Object.keys(clone).forEach(function(key) {
          if (clone[key] == undefined) {
            clone[key] = null;
          }
        });

        return clone;
      }
    });

    return resource;
  },
]);
