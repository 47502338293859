angular.module('LeasePilot').directive('buildingOverride', [
  '$compile',
  function($compile) {
    return {
      restrict: 'AE',
      require: '?ngModel',
      link: function(scope, element, attrs, ngModel) {
        if (ngModel != null) {
          ngModel.$render = function() {
            element.html(ngModel.$viewValue.text || '');

            var contents = element.contents().each(function() {
              if (this.nodeType === Node.COMMENT_NODE) {
                $(this).remove();
              }
            });

            $compile(contents)(scope);
          };
        }
      },
    };
  },
]);
