angular.module("LeasePilot").factory("LeaseVarService", [
  "$filter",
  "$sanitize",
  "$rootScope",
  "$timeout",
  function ($filter, $sanitize, $rootScope, $timeout) {

    const generateLine = function (l, s = "_") {
      if (l && l > 0) {
        return new Array(l + 1).join(s);
      } else {
        return new Array(11).join(s);
      }
    };

    const leaseVarClick = (event, scope, element, attrs) => {
      if (
        !$(event.target)
        .closest(".lease")
        .hasClass("lease--original")
      ) {
        return;
      }

      var scopeVar = $rootScope.findAppScope(scope);

      if (scopeVar.inline.on) {
        var relateds = scopeVar.inline.findNestedControls(element);

        // remove yourself from array and reposition at beginning
        var myIdx = relateds.indexOf(attrs.name);
        if (myIdx > -1) {
          relateds.splice(myIdx, 1);
        }
        relateds.unshift(attrs.name);

        relateds = scopeVar.inline.util.checkRelateds(relateds, element);
        scopeVar.inline.mouseX = event.clientX;
        scopeVar.inline.mouseY = event.clientY;
        $("#active-element-anchor").remove();
        scopeVar.inline.openInline(element, relateds);
        event.stopPropagation(); // Stop so that the inline isn't opened by the lease-if that it is within
      } else if (leftSideEditorSearch) {
        scopeVar.leftPane = "deal-terms";
        leftSideEditorSearch.searchByLeaseVarElement(element);
      }

      window.track.event(new ClickOnALeaseVarEvent({
        name: attrs.name
      }));
    };

    const leaseVarChangeWatch = (newval, scope, element, attrs) => {
      if (
        (attrs["calc"] &&
          scope.calc !== null &&
          scope.calc !== undefined &&
          scope.calc !== "" &&
          scope.calc !== Infinity &&
          scope.calc !== "NaN" &&
          !Number.isNaN(scope.calc)) ||
        (!attrs["calc"] &&
          scope.name !== null &&
          scope.name !== undefined &&
          scope.name !== "" &&
          scope.name !== Infinity &&
          !Number.isNaN(scope.name))
      ) {
        var processedVal = attrs["calc"] ? scope.calc : scope.name;

        if (scope.f1) {
          processedVal = $filter(scope.f1)(processedVal, scope.f1Options);
        }
        if (scope.f2) {
          processedVal = $filter(scope.f2)(processedVal, scope.f2Options);
        }
        if (scope.f3) {
          processedVal = $filter(scope.f3)(processedVal, scope.f3Options);
        }

        try {
          processedVal = $sanitize(processedVal);
        } catch (ex) {
          processedVal = "";
        }

        if (scope.isChanging) {
          if (element.closest('lease-var[hl]').length === 0) {
            element.html(
              "<span contenteditable='false' class='adding'>" +
              processedVal +
              "</span>"
            );
          } else {
            element.html(
              "<span contenteditable='false' class='added'>" +
              processedVal +
              "</span>"
            );
          }
        } else {
          element.html(
            "<span contenteditable='false' class='added'>" +
            processedVal +
            "</span>"
          );
        }
      } else if (!scope.ll && typeof scope.ev !== "undefined") {
        var ev = scope.ev;
        if (!isNaN(scope.ev)) {
          // if ev is a number, create a string of spaces with that number as length
          ev = generateLine(ev * 1, "&nbsp;");
        }
        element.html(
          "<span contenteditable='false'" +
          (scope.isChanging ? " class='adding'" : "") +
          ">" +
          ev +
          "</span>"
        );
      } else {
        element.html(
          "<span contenteditable='false'" +
          (scope.isChanging ? " class='adding'" : "") +
          ">" +
          generateLine(scope.ll) +
          "</span>"
        );
      }
    };

    const setTitle = (scope, element, LabelsService) => {

      if ($rootScope.findAppScope(scope).inline.on) {
        element.attr("title", "");
      } else if (scope.varName && $rootScope.fullLabels) {
        element.attr(
          "title",
          $rootScope.fullLabels[LabelsService.normalize(scope.varName)]
        );
      }
    };

    const setTitles = (scope, LabelsService) => {
      document.querySelectorAll("lease-var").forEach(node => {
        setTitle(scope, $(node), LabelsService);
      });
    };

    const isChangeStructure = (map, name) => {
      return !!map[name];
    }

    return {
      leaseVarClick: leaseVarClick,
      leaseVarChangeWatch: leaseVarChangeWatch,
      setTitles: setTitles,
      setTitle: setTitle,
      isChangeStructure: isChangeStructure,
    };
  }
]);
