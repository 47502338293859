angular.module('LeasePilot').directive('dualRentTable', function() {
  return {
    restrict: 'E',
    replace: true,
    template: function(element, attrs) {
      var tableId = attrs.tableId ? attrs.tableId : '';
      var tableStyle = attrs.tableStyle ? attrs.tableStyle : '';
      var terms = attrs.terms ? attrs.terms : 'lease.rentPeriods';
      var years = attrs.years ? attrs.years : 'lease.leaseYears';
      var hlTag = attrs.hlTag ? attrs.hlTag : 'rentValues';
      var valueTitle = attrs.valueTitle
        ? attrs.valueTitle
        : 'Amount Per Square Foot of Floor Area';
      var valueFirstHalf = attrs.valueFirstHalf
        ? attrs.valueFirstHalf
        : "{{ rentPeriod.rentPerSqFoot | currency:'$':2 }}";
      var valueSecondHalf = attrs.valueSecondHalf
        ? attrs.valueSecondHalf
        : '{{ ' +
          terms +
          '[Math.ceil(' +
          terms +
          ".length/2) + $index].rentPerSqFoot | currency:'$':2 }}";
      var fontSize = attrs.fontSize ? attrs.fontSize : '11pt';
      var freeRentText = attrs.freeRentText
        ? attrs.freeRentText
        : 'Commencement Date - Day Before Base Rent Commencement Date';
      var freeRentFirstYearText = attrs.freeRentFirstYearText
        ? attrs.freeRentFirstYearText
        : 'Base Rent Commencement Date - End of Lease Year 1';
      var addFreeRentRow = terms === 'termsIncludingFreeRent';

      var table =
        "<table table-id='" +
        tableId +
        '\' class="fs-hide" cellspacing="0" cellpadding="0" style="margin-right:auto; margin-left:auto; border-collapse:collapse; '+ tableStyle +'">' +
        '<tr>' +
        '<td style="width:88.55pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">Lease Year</span>' +
        '</p>' +
        '</td>' +
        '<td style="width:109.9pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">' +
        valueTitle +
        '</span>' +
        '</p>' +
        '</td>' +
        '<td style="width:21.5pt; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">&#xa0;</span>' +
        '</p>' +
        '</td>' +
        '<td style="width:76.6pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">Lease Year</span>' +
        '</p>' +
        '</td>' +
        '<td style="width:118.15pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">' +
        valueTitle +
        '</span>' +
        '</p>' +
        '</td>' +
        '</tr>' +
        /*-------------------------------------------------------
                   Dual table rows
                   -------------------------------------------------------*/
        '<tbody class="added" ng-if=\'' +
        years +
        "' ng-class=\"('" +
        hlTag +
        "'==currentlyChanging || '" +
        years +
        "'==currentlyChanging) ? 'adding-para' : ''\">" +
        "<tr ng-repeat='rentPeriod in getHalfTerm(" +
        terms +
        ") track by $index'>" +
        // -------------------------------------------------------------------------
        // ++++++++++++++++++++++++++    First Column   ++++++++++++++++++++++++++++
        // -------------------------------------------------------------------------
        (!addFreeRentRow
          ? // without free rent
            '<td style="width:88.55pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
            '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
            fontSize +
            '">' +
            '<span style="font-family:\'Times New Roman\'">{{ $index + 1 }}</span>' +
            '</p>' +
            '</td>'
          : // With free rent
            '<td style="width:88.55pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
            '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
            fontSize +
            '">' +
            "<span style=\"font-family:'Times New Roman'\">{{ $index==0 && lease.freeRent ? '" +
            freeRentText +
            "' : $index==1 && lease.freeRent ? '" +
            freeRentFirstYearText +
            "' : $index + (lease.freeRent ? 0 : 1) }}</span>" +
            '</p>' +
            '</td>') +
        '<td style="width:109.9pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">' +
        valueFirstHalf +
        '</span>' +
        '</p>' +
        '</td>' +
        // -------------------------------------------------------------------------
        // ++++++++++++++++++++++++++    Second Column     +++++++++++++++++++++++++
        // -------------------------------------------------------------------------
        '<td style="width:21.5pt; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">&#xa0;</span>' +
        '</p>' +
        '</td>' +
        (!addFreeRentRow
          ? // without free rent
            '<td style="width:76.6pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
            '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
            fontSize +
            '">' +
            '<span style="font-family:\'Times New Roman\'">{{ Math.ceil(' +
            terms +
            '.length/2) + $index < ' +
            terms +
            '.length ? Math.ceil(' +
            terms +
            ".length/2) + $index + 1 : '' }}</span>" +
            '</p>' +
            '</td>'
          : // With free rent
            '<td style="width:76.6pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
            '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
            fontSize +
            '">' +
            '<span style="font-family:\'Times New Roman\'">{{ Math.ceil(' +
            terms +
            '.length/2) + $index < ' +
            terms +
            '.length ? Math.ceil(' +
            terms +
            ".length/2) + $index + (lease.freeRent ? 0 : 1) : '' }}</span>" +
            '</p>' +
            '</td>') +
        '<td style="width:118.15pt; border:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:bottom">' +
        '<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:' +
        fontSize +
        '">' +
        '<span style="font-family:\'Times New Roman\'">' +
        valueSecondHalf +
        '</span>' +
        '</p>' +
        '</td>' +
        '</tr>';

      return table + '</table>';
    },
  };
});
