import React from 'react';
import ReactDOM from 'react-dom';
import Search from '../../react/document/Search';

const navigation = angular.module('LeasePilot').directive('navigation', [
  '$rootScope',
  function($rootScope) {
    return {
      template: '<div id="search"></div>',
      link: function(scope, el, attrs) {
        const placeholder = document.getElementById('search');

        scope.searchIsOpen = false;
        scope.searchIsPaused = false;

        scope.toggleSearch = function(status) {
          if (typeof status === 'undefined') {
            status = !scope.searchIsOpen;
          }

          scope.safeApply(() => {
            scope.searchIsOpen = status;
            scope.searchIsPaused = false;
            scope.render();
          });
        };

        scope.pauseSearch = function() {
          if (!scope.searchIsOpen) {
            return;
          }
          var savedSelection = rangy.saveSelection();

          scope.safeApply(() => {
            scope.searchIsPaused = true;
            scope.render();
          });
          rangy.restoreSelection(savedSelection, true);
        };

        scope.render = function() {
          ReactDOM.render(
            <Search
              scope=".lease.lease--original"
              scrollFn={scope.scrollToElement}
              toggleSearchFn={scope.toggleSearch}
              isOpen={scope.searchIsOpen}
              isPaused={scope.searchIsPaused}
            />,
            placeholder,
          );
        };

        scope.render();
      },
    };
  },
]);

export default navigation;
