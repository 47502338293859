angular.module('LeasePilot').directive('priorAmend', [
  '$filter',
  function($filter) {
    return {
      restrict: 'E',
      scope: {
        lease: '=',
        changing: '=',
        changed: '=',
      },
      link: function(scope, element, attrs) {
        function getPreviousAmendmentDate(scope, i, date) {
          if (!date) {
            return '__________';
          } else {
            return (
              '<span class=' +
              ('lease.previousAmendment[' + i + '].date' == scope.changing
                ? '"adding"'
                : '"added"') +
              '>' +
              moment(date).format('MMMM D, YYYY') +
              '</span>'
            );
          }
        }

        function getPriorAssignmentDate(scope, i, date) {
          if (!date) {
            return '__________';
          } else {
            return (
              '<span class=' +
              ('lease.priorAssignments[' + i + '].date' == scope.changing
                ? '"adding"'
                : '"added"') +
              '>' +
              moment(date).format('MMMM D, YYYY') +
              '</span>'
            );
          }
        }

        function createJoindArray(
          hasPreviousAmendments,
          previousAmendments,
          hasPriorAssignments,
          priorAssignments,
        ) {
          var list;

          if (hasPreviousAmendments && !hasPriorAssignments) {
            list = _.orderBy(previousAmendments, ['date'], ['asc']);
          } else if (!hasPreviousAmendments && hasPriorAssignments) {
            list = _.orderBy(priorAssignments, ['date'], ['asc']);
          } else {
            list = _.sortBy(
              _.concat(previousAmendments, priorAssignments),
              function(value) {
                if (value.date) {
                  return new Date(value.date);
                }
                if (value.date) {
                  return new Date(value.date);
                }
              },
            );
          }

          return list;
        }

        function createLanguageByType(settings, type, scope, i, date) {
          var result;

          if (type === 'amendment') {
            result = _.string.sprintf(
              'that certain %s Lease Modification Agreement, dated %s',
              $filter('capitalize')(
                $filter('ordinalWords')(settings.numberOfAmendments + 1),
              ),
              getPreviousAmendmentDate(
                scope,
                settings.numberOfAmendments,
                date,
              ),
            );
            settings.numberOfAmendments++;
          } else if (type === 'assignment') {
            result = _.string.sprintf(
              'that certain Assignment and Assumption of Lease Agreement, dated %s',
              getPriorAssignmentDate(scope, settings.numberOfAssignments, date),
            );
            settings.numberOfAssignments++;
          }

          return result;
        }

        scope.$watch(
          '[changed, changing, lease.previousAmendment, lease.priorAssignments, lease.hasPriorAssignments]',
          function(newValue, oldValue) {
            if (!scope.lease) {
              return;
            }

            var lease = scope.lease;
            var result = '';
            var hasPreviousAmendments =
              lease.previousAmendment && lease.previousAmendment.length > 0;
            var previousAmendments = lease.previousAmendment;
            var hasPriorAssignments = lease.hasPriorAssignments;
            var priorAssignments = lease.priorAssignments;
            var list;
            var sentences = [];
            var settings = {
              numberOfAmendments: 0,
              numberOfAssignments: 0,
            };

            if (!hasPreviousAmendments && !hasPriorAssignments) {
              element.html(result);
              return;
            }

            // Before concatenating the  two arrays, lets indicate where each item came from
            _.forEach(previousAmendments, function(item) {
              item.type = 'amendment';
            });
            _.forEach(priorAssignments, function(item) {
              item.type = 'assignment';
            });

            // Create the joined array
            list = createJoindArray(
              hasPreviousAmendments,
              previousAmendments,
              hasPriorAssignments,
              priorAssignments,
            );

            // Since we're creating an array of consecutive items of same type
            // we need to set the first item so we'll have some reference
            sentences.push({
              type: list[0].type,
              sentences: [
                createLanguageByType(
                  settings,
                  list[0].type,
                  scope,
                  0,
                  list[0].date,
                ),
              ],
            });

            // Start iterating from the second item since we already took care of the first one
            var curr;
            var group;
            for (var i = 1; i < list.length; i++) {
              curr = list[i];

              // Starting a new sequence since the type is different than the previous one
              if (list[i - 1].type !== curr.type) {
                sentences.push({ type: curr.type, sentences: [] });
              }

              group = sentences[sentences.length - 1];
              group.sentences.push(
                createLanguageByType(settings, curr.type, scope, i, curr.date),
              );
            }

            var isLast = false;
            for (i = 0; i < sentences.length; i++) {
              isLast = i === sentences.length - 1;

              if (sentences[i].type === 'amendment') {
                result += _.string.sprintf(
                  'as modified by %s',
                  _.string.toSentence(sentences[i].sentences, ', ', ' and by '),
                );
              } else if (sentences[i].type === 'assignment') {
                result += _.string.sprintf(
                  'as assigned by %s',
                  _.string.toSentence(sentences[i].sentences, ', ', ' and by '),
                );
              }

              if (!isLast) {
                result = result + ' and ';
              } else {
                result = ', ' + result;
              }
            }

            // Wrap the result with the contenteditable markup
            result = _.string.sprintf(
              "<span contenteditable='false'>%s</span>",
              result,
            );
            element.html(result);
          },
          true,
        );
      },
    };
  },
]);
