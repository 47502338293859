angular.module('LeasePilot').directive('unlockedVar', [
  '$filter',
  '$rootScope',
  function($filter, $rootScope) {
    var generateLine = function(l) {
      if (l) {
        return new Array(l + 1).join('_');
      } else {
        return '__________';
      }
    };
    
    const unlockedVarLink = function(scope, element, attrs) {
      const appScope = $rootScope.findAppScope();

      // Disable editing 'building-variable' in admin mode
      scope.disable = function() {
        element.attr('contenteditable', false);
        element.attr('title', "Building Language can't be edited");
      };
    
      scope.enable = function() {
        element.attr('contenteditable', true);
        element.attr('title', '');
        element.addClass('editable');
      };
    
      $rootScope.adminMode || ($rootScope.lease && $rootScope.lease.isLocked)
        ? scope.disable()
        : scope.enable();
    
      scope.$watch('name', function(newval) {
        
        if (newval) {
          if (attrs.name === "building.name") {
            if (appScope.building.displayName) {
              newval = appScope.building.displayName;
            }
          }
          
          var processedVal = newval;
          if (scope.f1) {
            processedVal = $filter(scope.f1)(processedVal, scope.f1Options);
          }
          if (scope.f2) {
            processedVal = $filter(scope.f2)(processedVal, scope.f2Options);
          }
          element.html(
            "<span lease-section='" +
              scope.inputLs +
              "'>" +
              processedVal +
              '</span>',
          );
        } else if (scope.ev) {
          element.html('<span>' + scope.ev + '</span>');
        } else {
          element.html('<span>' + generateLine(scope.ll) + '</span>');
        }
      });
    
      $rootScope.$on('leaseStatusChanged', function(event, status) {
        if (status.lock) {
          scope.disable();
        } else {
          scope.enable();
        }
      });
    };
    
    return {
      restrict: 'E',
      scope: {
        inputLs: '@',
        ll: '=',
        ev: '@',
        name: '=',
        f1: '@',
        f1Options: '@',
        f2: '@',
        f2Options: '@',
      },
      link: unlockedVarLink,
    };
  },
]);
