angular.module('LeasePilot').filter('toWordsHyphenated', function() {
  return function(input) {
    if (input === 0) {
      return 'zero';
    }
    if (!input) {
      return null;
    }
    var numberInWords = toWords(input).trim();
    var realDigits = [
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
    ];
    var twoDigitsNumbers = [
      'twenty',
      'thirty',
      'forty',
      'fifty',
      'sixty',
      'seventy',
      'eighty',
      'ninety',
    ];
    var numberInArray = numberInWords.split(' ');
    var newNumberInWords = '';

    for (var i = 0; i < numberInArray.length; i++) {
      if (
        i + 1 < numberInArray.length &&
        twoDigitsNumbers.indexOf(numberInArray[i]) > -1 &&
        realDigits.indexOf(numberInArray[i + 1]) > -1
      ) {
        newNumberInWords += numberInArray[i] + '-';
      } else {
        newNumberInWords += numberInArray[i] + ' ';
      }
    }

    return newNumberInWords.trim();
  };
});
