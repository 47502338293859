angular.module('LeasePilot').directive('abatementDates', [
  '$filter',
  function($filter) {
    return {
      restrict: 'E',
      scope: {
        dates: '=',
        changing: '=',
        changed: '=',
      },
      link: function(scope, element, attrs) {
        function getAbatementDate(scope, i, date) {
          if (!date) {
            return '__________';
          } else {
            return (
              '<span class=' +
              ('lease.abatementDates[' + i + '].date' == scope.changing
                ? '"adding"'
                : '"added"') +
              '>' +
              moment(date).format('MMMM YYYY') +
              '</span>'
            );
          }
        }

        scope.$watch(
          '[changed, changing, dates]',
          function(newValue, oldValue) {
            var dates = scope.dates;
            var result = '';
            var isConsecutiveDates;
            var sentences = [];

            if (!dates) {
              return;
            }

            if (dates.length >= 3) {
              isConsecutiveDates = true;
            } else {
              isConsecutiveDates = false;
            }

            _.forEach(dates, function(date, i) {
              sentences.push(getAbatementDate(scope, i, date.date));

              if (
                i > 0 &&
                moment(date.date).diff(dates[i - 1].date, 'month') !== 1
              ) {
                isConsecutiveDates = false;
              }
            });

            if (isConsecutiveDates) {
              result = _.string.sprintf(
                '%s through %s',
                sentences[0],
                sentences[sentences.length - 1],
              );
            } else {
              result = _.string.toSentence(sentences, ', ', ' and ');
            }

            // Wrap the result with the contenteditable markup
            result = _.string.sprintf(
              "<span contenteditable='false'>%s</span>",
              result,
            );
            element.html(result);
          },
          true,
        );
      },
    };
  },
]);
