angular.module("LeasePilot").factory("EventService", [
  "railsResourceFactory",
  function(railsResourceFactory) {
    var LogEvent = railsResourceFactory({
      url: "/api/events",
      name: "event"
    });
    return LogEvent;
  }
]);
