angular.module('LeasePilot').filter('ordinalWordsHyphenated', [
  '$filter',
  function($filter) {
    return function(input) {
      if (!input) return null;

      var number;
      var ordinal;
      var i = 1;

      if (input % 100 <= 20) {
        i *= 10;
      }

      do {
        i *= 10;
        number = parseInt(input / i) * i;
        ordinal = input % i;
      } while (ordinal === 0);

      if (number === 0) {
        return $filter('ordinalWords')(ordinal);
      } else if (i === 10) {
        return (
          $filter('toWordsHyphenated')(number) +
          '-' +
          $filter('ordinalWords')(ordinal)
        );
      }
      return (
        $filter('toWordsHyphenated')(number) +
        ' ' +
        $filter('ordinalWords')(ordinal)
      );
    };
  },
]);
