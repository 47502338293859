/* global JSZip */

angular.module('LeasePilot').directive('expressAbstractModal', [
  '$rootScope',
  '$mdDialog',
  function (
    $rootScope,
    $mdDialog,
  ) {
    return {
      restrict: 'E',
      templateUrl: 'express_abstract_modal.html',
      scope: {
        download: '=',
      },
      link: function ($scope, element, attrs) {
        $scope.isDownloading = false;
        $scope.processing = false;
        const MIDDLE_OF_REPEAT = 'in ';

        let originalLeftEditor = document.querySelector('left-editor');
        let allNgModel = originalLeftEditor.querySelectorAll('[ng-model]');
        allNgModel.forEach((ngModel) =>{
          let id = `${Math.floor(Math.random() * 100000000000)}`;
          ngModel.setAttribute('data-unique-id', id);
        });

        let editorElement = originalLeftEditor.cloneNode(true);
        let cardElements = editorElement.querySelectorAll('.card');
        let body = document.createElement('div');

        for (let index = 0; index < cardElements.length; ++index) {
          let card = document.createElement('div');
          let cardName = cardElements[index].querySelector('.collapse-card__title');
          cardName = cardName.textContent.trim();
          let headerName = document.createElement('h2');
          headerName.textContent = cardName;

          if (index < cardElements.length - 1) {
            card.style.pageBreakAfter = "always";
          }
          
          card.appendChild(headerName);

          let cardBody = cardElements[index].querySelector('.collapse-card__body');
          cardBody.classList.remove('collapse-card__body');
          cardBody.removeAttribute('style');
          prepareCard(cardBody);
          card.appendChild(cardBody);
          body.appendChild(card);
        }

        cleanHtml(body);
        let htmlBody = document.querySelector('.abstract_body');
        htmlBody.appendChild(body);

        $scope.cancel = function () {
          $mdDialog.cancel();
        };

        $scope.downloadExpressAbstract = function () {
          $scope.isDownloading = true;
          $scope.processing = true;
          $scope.safeApply();

          setTimeout(() => {
            let filename = lease.company.name + '_' + lease.type.toLowerCase() + '_abstract_' +
              moment().format('YYYY-MM-DD HH:mm:ss') + '.pdf';

            let body = document.getElementsByClassName('abstract_body')[0];
            var opt = {
              margin: 1,
              filename: filename,
              jsPDF: {
                unit: 'in',
                format: 'letter',
                orientation: 'portrait'
              }
            };
            var worker = html2pdf().set(opt).from(body).save();
            worker.then(function () {
              $scope.isDownloading = false;
              $scope.processing = false;
              $scope.safeApply();
            })
          }, 100);
        }


        function prepareCard(card) {
          let labels = card.querySelectorAll('editor-label:not(.control-title)');
          let textAreaIndicator = false;

          labels.forEach(function(label) {

            let attr = document.createElement('div');
            let attrKey = label.textContent;
            let attrValue = "";
            if (attrKey === "") {
              return;
            }
            if (label.closest('md-radio-button')) {
              return;
            }

            attr.classList.add('abstract-item')
            if (attrKey.endsWith(':')) {
              attrKey = attrKey + ' ';
            } else {
              attrKey = attrKey + ': ';
            }

            let control = label.closest('editor-control');
            if (!control) {
              let parent = label.parentElement;
              control = parent.querySelector('editor-control');
            }
            if (control) {
              let checkbox = control.querySelector('md-checkbox');
              let input = control.querySelector('input');
              let select = control.querySelector('select');
              let radio = control.querySelector('md-radio-button[aria-checked="true"]');
              let textArea = control.querySelector('textarea');

              control.classList.remove("disabled");

              if (checkbox) {
                let state = checkbox.getAttribute('aria-checked');
                attrValue = state === 'true' ? 'Yes' : 'No';
              } else if (input) {
                attrValue = input.value;
                if (input.closest('lp-date-picker')) {
                  if (attrValue != "")  {
                    let newDate = moment(attrValue);
                    attrValue = newDate.format('MM/DD/YYYY');
                  }
                } else if (input.closest('.upload-file')) {
                  control.innerHTML = "";
                  return;
                }

                let inputWithUnit = input.closest('.input-with-unit');
                if (inputWithUnit) {
                  attrValue = getInputWithUnit(control, inputWithUnit, attrValue);
                }
              } else if (select) {
                let allSelect = control.parentElement.querySelectorAll('select');
                let selectValues = [];
                let selectIndex = 0;
                let selValue;

                // This repeat handles two and more select elements that are under the same label.
                allSelect.forEach(function(sel) {
                  selectIndex++;
                  let selectParent = sel.parentElement;
                  if (selectParent.querySelector('label') && selectIndex > 1) {
                    return;
                  }
                  let uniqueId = sel.getAttribute('data-unique-id');
                  if (uniqueId) {
                    let originalSelect = originalLeftEditor.querySelector(`select[data-unique-id="${uniqueId}"]`);
                    selValue = originalSelect.options[originalSelect.selectedIndex];
                    selValue = $(selValue).text();
                  } else {
                    selValue = sel.value;
                  }
                  selValue = selValue.replace('number:', '').replace('? undefined:undefined ?', '');
                  let inputWithUnit = sel.closest('.input-with-unit');
                  if (inputWithUnit) {
                    selValue = getInputWithUnit(control, inputWithUnit, selValue);
                  }
                  selectValues.push(selValue);
                })
                attrValue = selectValues.join(', ');
              } else if (radio) {
                attrValue = radio.textContent.trim();
              } else if (textArea) {
                attrValue = textArea.value.trim();
                textAreaIndicator = true;
              } else {
                if (!control.classList.contains('cleared')) {
                  control.innerHTML = "";
                  control.classList.add('cleared');
                }
                return;
              }
            } else {
              return;
            }

            attrValue = prepareValue(control, attrValue);
            let element = document.createElement('div');
            let attrKeyElement;
            let attrValueElement;
            if (textAreaIndicator) {
              attr.classList.add('text-area-flex');
              attrKeyElement = document.createElement('div');
              attrValueElement = document.createElement('div');
              attrValueElement.classList.add('value-item-flex');
            } else {
              attrKeyElement = document.createElement('span');
              attrValueElement = document.createElement('span');
            }
            attrKeyElement.textContent = attrKey;
            attrKeyElement.style.fontWeight = 'bold';
            attrValueElement.textContent = attrValue;
            attrValueElement.classList.add('value-item');
            attr.appendChild(attrKeyElement);
            attr.appendChild(attrValueElement);
            element.appendChild(attr);
            if (!control.classList.contains('cleared')) {
              control.innerHTML = "";
              control.classList.add('cleared');
            }
            control.appendChild(element);
          });
        }

        function getArrayName(attr) {
          let arrayName = attr.slice(attr.indexOf(MIDDLE_OF_REPEAT) + 3);
          return arrayName.split(' ')[0];
        }

        function cleanHtml(element) {
          let ignoreTags = element.querySelectorAll('button, md-icon, select, .left');
          ignoreTags.forEach(function(tag) {
            let control = tag.closest('editor-control');
            if (control) {
              control.remove();
            } else {
              tag.remove();
            }
          });
          let repeats = element.querySelectorAll('[ng-repeat]');
          prepareRepeats(repeats);

          let labels = Array.from(element.querySelectorAll('editor-label[for]'));
          labels.map(function(label) {
            let f = label.getAttribute('for');
            if (f.startsWith('title.') && label.textContent != ""){
                let b = document.createElement('b');
                let span = label.querySelector('span');
                b.append(span);
                label.appendChild(b);
            }
          });

          let buttons = element.querySelectorAll('[role="button"]');
          buttons.forEach((btn) => {
            btn.remove();
          });
        }

        function prepareRepeats(repeats) {
          repeats.forEach(function(repeat){
            if (repeat.closest('table')) {
              return;
            }
            let arrayName = getArrayName(repeat.getAttribute('ng-repeat'));
            if (arrayName === 'card.categories' || arrayName === 'category.templates') {
              return;
            }
            let parent = repeat.parentElement;
            if (parent) {
              repeat.classList.add('group-item');
              if (parent.classList.contains('group')) {
                return;
              }
              parent.classList.add('group');
              let first = parent.firstElementChild;
              if (!first.getAttribute('ng-repeat')) {
                first.classList.add('group-item-head');
              }
              let titles = parent.querySelectorAll('.title, .guarantor-list-title');
              titles.forEach(function(title) {
                title.remove();
              })

            }
          });
        }

        function getInputWithUnit(control, inputWithUnit, originValue) {
          let unit = inputWithUnit.querySelector('.unit');
          if (unit && originValue !== "") {
            unit = unit.textContent;
            originValue = prepareValue(control, originValue);
            if (unit.contains('/')) {
              let splitUnit = unit.split('/');
              let result = splitUnit[0] + originValue;
              for (let i = 1; i < splitUnit.length; ++i) {
                result = result + '/' + splitUnit[i];
              }
              return result;
            } else if (unit === '$') {
              return unit + originValue;
            } else if (unit === '%') {
              return originValue + unit;
            }
            return originValue + " " + unit;
          } else {
            return originValue;
          }
        }

        function prepareValue(control, originValue) {
          const tempNumberValue = Number(originValue);
          const input = control.querySelector("input");
          // Only format as a number if the value is a number AND the control is an input field of type number
          if (!isNaN(tempNumberValue) && originValue != 0) {
            if (input) {
              const inputType  = input.getAttribute("type");
              if (inputType === "number") {
                return tempNumberValue.toLocaleString();
              } else {
                return originValue;
              }
            }
          }

          // Only format as a number if the value is a number AND the control is NOT an input field
          if (!isNaN(tempNumberValue) && originValue != 0) {
            return tempNumberValue.toLocaleString();
          }

          return originValue;
        }
      }
    }
  },
]);
