angular.module('LeasePilot').directive('versionControlUnsupportedModal', [
  '$rootScope',
  '$mdDialog',
  function($rootScope, $mdDialog) {
    return {
      restrict: 'E',
      templateUrl: 'version_control_unsupported_modal.html',
      scope: {
        count: '=',
      },
      link: function($scope, element, attrs) {
        $scope.close = () => {
          $mdDialog.cancel();
        };
        $scope.exit = () => {
          $mdDialog.cancel();
          var scope = $rootScope.findAppScope();
          scope.annotationsHelper.exitReviewMode();
        };
      },
    };
  },
]);
