window.splitVariable = function(condition) {
  let result = [];

  if (condition) {
    result = _.uniq(
      condition
        .split(/[&&,||,!,+,*,/,<,>,==,*(,),-]/)
        .map(result => result.trim())
        .filter(result => result.startsWith("lease."))
    );
  }

  return result;
}