angular.module('LeasePilot').factory('EditorService', [
  'railsResourceFactory',
  '$q',
  '$rootScope',
  'TemplateService',
  function(railsResourceFactory, $q, $rootScope, TemplateService) {
    var resource = railsResourceFactory({
      url: '/api/forms/{{formId}}/editor',
      name: 'editor',
    });

    resource.getEditorCards = function(formId) {
      return $q(function(resolve) {
        resource.get({ formId: formId }).then(function(editor) {
          mergeDefaultsTemplates(editor);
          $rootScope.$broadcast('editorUpdated', { editor: editor });
          resolve(editor);
        });
      });
    };

    resource.setEditorCards = function(editor) {
      mergeDefaultsTemplates(editor);
      $rootScope.$broadcast('editorUpdated', { editor: editor });
    };

    var mergeDefaultsTemplates = function(editor) {
      _.each(editor.cards, function(card) {
        _.each(card.categories, function(category) {
          _.remove(category.templates, function(tpl) {
            return !TemplateService.isValidTemplate(tpl.id);
          });
          _.each(category.templates, function(template) {
            _.defaults(
              template.config,
              TemplateService.getDefaults(template.id),
            );
          });
        });
      });
    };

    return resource;
  },
]);
