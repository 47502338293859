angular.module('LeasePilot').directive('crossRef', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      crossRefId: '@',
      afterText: '@',
    },
    link: function(scope, element) {
      var $selectedSection = $('#' + scope.crossRefId);
      var $selectedParent = $selectedSection.parent().parent();

      var updateXRefs = function() {
        var sectionIndex =
          $selectedParent
            .parent()
            .children('li:not(.ng-hide)')
            .index($selectedParent) + 1;
        var subsectionIndex =
          $selectedSection
            .parent()
            .children('li:not(.ng-hide)')
            .index($selectedSection) + 1;
        element.html(
          '<span>Section ' +
            sectionIndex +
            '.' +
            subsectionIndex +
            (scope.afterText || '') +
            '</span>',
        );
      };

      updateXRefs();

      scope.$watch(
        function() {
          return $selectedSection.parent().children('li:not(.ng-hide)').length;
        },

        function(oldLength, newLength) {
          if (oldLength !== newLength) {
            updateXRefs();
          }
        },
      );
    },
  };
});
