angular.module('LeasePilot').factory('FreeTextService', ['railsResourceFactory', 'CleanFreeTextService', function (railsResourceFactory, CleanFreeTextService) {
  var FreeText = railsResourceFactory({
    url: '/api/free_texts',
    name: 'freeText'
  });
  FreeText.beforeRequest(function (data) {
    
    if (data) {
      let clone = {
        id: data.id,
        lease_id: data.lease_id,
        amendment_id: data.amendment_id,
        section_id: data.section_id,
        text: data.text,
        timestamp: data.timestamp,
        user_id: data.user_id,
      }

      if (data.timestamp) {
        clone.timestamp = data.timestamp;
      } else {
        clone.timestamp = new Date().getTime();
      }
      
      clone = CleanFreeTextService.cleanVersionControlTags(clone);

      return clone;
    }
  });
  return FreeText;
}]);
