angular.module('LeasePilot').filter('toTemplate', function() {
  return function(id, format, data) {
    switch (format) {
      case 'leftEditor':
        return 'tpl-' + _.kebabCase(id);
      case 'editorOfEditor':
        return 'tpl-' + _.kebabCase(id) + '-config';
      case 'config':
        return _.kebabCase(id) + 'Config';
      default:
        return 'tpl-' + _.kebabCase(id);
    }
  };
});
