angular.module('LeasePilot').directive('newCompanyModal', [
  'CompanyService',
  '$mdDialog',
  function(CompanyService, $mdDialog) {
    return {
      restrict: 'E',
      templateUrl: 'new_company_modal.html',
      link: function($scope) {
        document.getElementById('company-name').focus();

        $scope.companyName;
        $scope.disabled = function() {
          var isValid =
            $scope.form != null &&
            $scope.form.company_name != null &&
            $scope.form.company_name.$valid;
          return !$scope.companyName || !isValid;
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.newCompany = function() {
          new CompanyService({
            name: $scope.companyName,
          })
            .create()
            .then(
              function(response) {
                window.location.reload();
              },
              function(error) {
                $scope.error = error.data.error;
              },
            );
        };
      },
    };
  },
]);
