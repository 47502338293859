


Object.defineProperty(HTMLSpanElement.prototype,'getSectionId',{
  value : function(){
    const sectionElement = this.closest('[section-id]');
    if(sectionElement){
      return sectionElement.getAttribute('section-id');
    }
    return null;
  }
})

// Object.defineProperty(HTMLSpanElement.prototype,'uniteCloseTextNodes',{
//   value : function(){
//     const sectionElement = this.closest('[section-id]');
//     if(sectionElement){
//       return sectionElement.getAttribute('section-id');
//     }
//     return null;
//   }
// })

// Object.defineProperty(HTMLElement.prototype, "getBlockData", {
//   value: function() {
//     return listItemsManager.getListItemObjectFromNode(this);
//   },
//   enumerable: false
// });

// Object.defineProperty(HTMLElement.prototype, "toJson", {
//   value: function() {
//     let json = new ElementJson(this);
//     return json;
//   },
//   enumerable: false
// });

// HTMLElement.fromJson = function(json) {
//   if (typeof json === "ElementJson") {
//     return ElementJson.fromJson(json);
//   } else {
//     return null;
//   }
// };

// function Attribute(key, value) {
//   this.key = key;
//   this.value = value;
// }

// function ElementJson(node) {
//   this.tagName = node.tagName;
//   this.nodeType = node.nodeType;
//   this.attributes = [];
//   this.childNodes = [];
//   this.nodeText = null;

//   if (node.attributes) {
//     for (let attIndex = 0; attIndex < node.attributes.length; attIndex++) {
//       this.attributes.push(
//         new Attribute(
//           node.attributes[attIndex].name,
//           node.attributes[attIndex].value
//         )
//       );
//     }
//   }

//   if (node.childNodes) {
//     for (let cIndex = 0; cIndex < node.childNodes.length; cIndex++) {
//       let cNode = node.childNodes[cIndex];
//       this.childNodes.push(new ElementJson(cNode));
//     }
//   }

//   if (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.COMMENT_NODE) {
//     this.nodeText = node.textContent;
//   }
// }

// Object.defineProperty(ElementJson.prototype, "toElement", {
//   value: function() {
//     return ElementJson.fromJson(this);
//   },
//   enumerable: false
// });

// ElementJson.fromJson = function(json) {
//   let retElement = null;
//   if(typeof json === 'string'){
//     json = JSON.parse(json);
//   }

//   if (json.tagName) {
//     retElement = document.createElement(json.tagName);

//     json.attributes.forEach(att => {
//       retElement.setAttribute(att.key, att.value);
//     });

//     json.childNodes.forEach(child => {
//       retElement.appendChild(ElementJson.fromJson(child));
//     });
//   } else {
//     if (json.nodeType === Node.TEXT_NODE) {
//       retElement = document.createTextNode(json.nodeText);
//     } else {
//       if (json.nodeType === Node.COMMENT_NODE) {
//         retElement = document.createComment(json.nodeText);
//       }
//     }
//   }

//   return retElement;
// };

// window.ElementJson = ElementJson;
