angular.module('LeasePilot').filter('capitalizeAll', function() {
  return function(input) {
    if (!input) {
      return;
    }
    
    if (input.indexOf(' ') !== -1 || input.indexOf('-') !== -1) {
      var inputPieces, i;

      input = input.toLowerCase();

      if (input.indexOf(' ') !== -1) {
        inputPieces = input.split(' ');

        for (i = 0; i < inputPieces.length; i++) {
          inputPieces[i] = capitalizeString(inputPieces[i]);
        }

        input = inputPieces.join(' ');
      }

      if (input.indexOf('-') !== -1) {
        inputPieces = input.split('-');

        for (i = 0; i < inputPieces.length; i++) {
          inputPieces[i] = capitalizeString(inputPieces[i]);
        }

        input = inputPieces.join('-');
      }

      return input;
    } else {
      input = input.toLowerCase();
      return capitalizeString(input);
    }

    function capitalizeString(inputString) {
      return (
        inputString.substring(0, 1).toUpperCase() + inputString.substring(1)
      );
    }
  };
});
