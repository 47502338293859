angular.module('LeasePilot').factory('BuildingOverridesService', [
  'railsResourceFactory',
  'railsSerializer',
  function(railsResourceFactory, railsSerializer) {
    return railsResourceFactory({
      url: '/api/building_overrides',
      name: 'buildingOverride',
    });
  },
]);
