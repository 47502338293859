

var listOutline = [
  "$animate",
  "$compile",
  function($animate, $compile) {
    return {
      restrict: "E",
      link: function(scope, element, attr) {
        let currentOutlineIndex = -1;
        let selectedOutlineIndex = -1;
        scope.showOutlinePickerForPaste = attr.fromPaste ? true : false;

        scope.initOutlineListMenu = function() {
          outlineSelected = false;
          selectedOutlineIndex = -1;
          element[0].classList.remove("outline-selected");
          let outlines = element[0].querySelectorAll(".outline");

          outlines.forEach(node => {
            node.classList.remove("selected");
          });

          selectedOutlineIndex = -1;
          scope.isSelectedOutlineItem = false;
        };

        scope.selectOutlineItemChanged = function(index) {
          if (!Number.isInteger(index)) {
            index = currentOutlineIndex;
          }

          if (index !== selectedOutlineIndex) {
            selectedOutlineIndex = index;
            element[0].querySelectorAll(".outline.selected").forEach(el => {
              el.classList.remove("selected");
            });
            let el = element[0].querySelectorAll(".outline");
            el[index].classList.add("selected");
            selectedOutlineIndex = index;

            if (index !== -1) {
              scope.isSelectedOutlineItem = true;
              scope.safeApply();
            }
          }

          if (scope.showOutlinePickerForPaste) {
            scope.selectOutlineForPaste();
          } else {
            scope.selectOutlineMenuBtn();
            document.querySelector(".lp-menu list-outline-menu").parentElement.classList.toggle("ng-hide");
          }
        };

        scope.selectOutlineForPaste = () => {
          let outlineElement = element[0].querySelector(
            '[data-outline-index="' + selectedOutlineIndex + '"]'
          );
          let outlineName = outlineElement.getAttribute("data-outline-name");
          clipboardManager.continuePasteWithOutline(outlineName);
        };

        scope.cancelPaste = () => {
          clipboardManager.cancelPaste();
        };

        scope.selectOutlineMenuBtn = function() {
          let outlineElement = element[0].querySelector(
            '[data-outline-index="' + selectedOutlineIndex + '"]'
          );

          let outlineName = outlineElement.getAttribute("data-outline-name");

          setTimeout(() => {
            scope.toggleListItemDropdown(outlineName);
          });
        };
      },
      templateUrl: "list_outline_menu.html",
    };
  }
];

angular.module("LeasePilot").directive("listOutlineMenu", listOutline);

