angular.module('LeasePilot').directive('smlCoverageReport', [
  '$mdDialog',
  '$rootScope',
  'LabelsService',
  '$timeout',
  '$q',
  ($mdDialog, $rootScope, LabelsService, $timeout, $q) => ({
    restrict: 'E',
    templateUrl: 'sml_coverage_report_menu_item.html',
    link: function($scope, element, ettrs) {
      $scope.SMLLeaseFields = _.chain(window.SMLFields)
        .pickBy(val => val.type === 'lease-variable')
        .mapValues(val => val.instances)
        .value();

      $scope.SMLBuildingFields = _.chain(window.SMLFields)
        .pickBy(val => val.type !== 'lease-variable')
        .mapValues(val => val.instances)
        .value();

      function getEditorFields() {
        const repeatedVars = [];
        const deferred = $q.defer();
        const editorFields = {};

        // find repeated variables
        $('left-editor')
          .find('*')
          .contents()
          .each(function() {
            if (this.nodeType === Node.COMMENT_NODE) {
              const comment = this.nodeValue.trim();
              if (
                comment.startsWith('ngRepeat') &&
                _.includes(comment, 'in lease.')
              ) {
                let variable = comment.trim().split(' ')[3];
                variable = variable.replace('lease.', '');
                repeatedVars.push(variable);
              }
            }
          });

        // init repeated variables
        for (let i = 0; i < repeatedVars.length; i++) {
          const leaseVar = _.get($rootScope.lease, repeatedVars[i]);
          if (leaseVar) {
            leaseVar.push({});
          }
        }

        $timeout(() => {
          $('left-editor editor-control').each(function() {
            const attr = $(this).attr('for');
            let key = LabelsService.normalize(attr);
            let longLabel = $rootScope.fullLabels[LabelsService.normalize(key)];
            let shortLabel = $rootScope.shortLabels[LabelsService.normalize(key)];

            if (!longLabel && !shortLabel) {
              key = key
                .replace('lease.reduction.', 'lease.')
                .replace('lease.relocation.', 'lease.')
                .replace('lease.expansion.', 'lease.')
                .replace('lease.termination.', 'lease.')
                .replace('lease.renewalRentBumps.', 'lease.')
                .replace('lease.terms.', 'lease.');
              longLabel = $rootScope.fullLabels[LabelsService.normalize(key)];
              shortLabel = $rootScope.shortLabels[LabelsService.normalize(key)];
            }

            if (!longLabel) {
              longLabel = shortLabel;
            }

            if (!shortLabel) {
              shortLabel = longLabel;
            }

            editorFields[attr] = {
              long: longLabel,
              short: shortLabel,
            };
          });

          // rename combined fields
          _.forEach(editorFields, (val, key) => {
            if (_.endsWith(key, 'Other') && editorFields[key.slice(0, -5)]) {
              var newKey = _.replace(key, 'Other', 'Combined');
              editorFields[newKey] = val;
              delete editorFields[key];
              delete editorFields[key.slice(0, -5)];
            }
          });

          // remove repeated variables
          for (let i = 0; i < repeatedVars.length; i++) {
            const leaseVar = _.get($rootScope.lease, repeatedVars[i]);
            if (leaseVar) {
              leaseVar.splice(-1, 1);
            }
          }

          deferred.resolve(editorFields);
        });

        return deferred.promise;
      }

      $scope.showReport = () => {
        getEditorFields().then(editorFields => {
          _.forEach(editorFields, (val, key) => {
            val.instances = $scope.SMLLeaseFields[key];
          });

          var percentage = getPercentage(editorFields);

          const missingFields = _.pickBy(
            $scope.SMLLeaseFields,
            (val, key) => {
              key = key.replace(/lease.terms\[.*?\]/, 'lease.terms[termIndex]');
              return !editorFields[key];
            },
          );

          _.forEach(missingFields, function(value, key) {
            if (key.startsWith('$')) {
              delete missingFields[key];
            }

            //change '[0]' to '[$index]'.
            var newKey = _.replace(key, '[0]', '[$index]');
            if (newKey !== key && editorFields[newKey]) {
              delete missingFields[key];
            }
            
            //when the field ends with '.length', check if there are any items in the array.
            var startWith = _.replace(key, '.length', '[$index]');
            if (
              startWith !== key &&
              Object.keys(editorFields).some(function(k) {
                return ~k.indexOf(startWith);
              })
            ) {
              delete missingFields[key];
            }
          });

          const confirm = $mdDialog.confirm({
            skipHide: true,
            clickOutsideToClose: true,
            templateUrl: 'sml_coverage_report.html',
            controller: ('DialogController', ['scope', DialogController]),
          });

          function DialogController(dialogScope) {
            dialogScope.report = {
              editorFields,
              percentage,
              missingFields,
              missingFieldsCount: Object.keys(missingFields).length,
              buildingFields: $scope.SMLBuildingFields,
            };

            dialogScope.cancel = () => {
              $mdDialog.cancel();
            };
          }

          $mdDialog.show(confirm);
        });
      };

      var getPercentage = function(editorFields) {
        var values = Object.values(editorFields);
        var allItems = values.length;
        var coveredItems = _.filter(values, 'instances').length;
        var result = (coveredItems / allItems) * 100;
        return ({
          allItems,
          coveredItems,
          result: Math.round(result, 2),
        });
      };
    },
  }),
]);
