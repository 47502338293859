angular.module('LeasePilot').directive('downloadAbstract', [
  'ApiService',
  'DownloadService',
  'ProcessStatusService',
  'FreeTextToSmlService',
  '$rootScope',
  'LeaseEditorService',
  function(
    ApiService,
    DownloadService,
    ProcessStatusService,
    FreeTextToSmlService,
    $rootScope,
    LeaseEditorService,
  ) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'download_abstract.html',
      scope: {
        leaseId: '=',
        modifiedFreeTexts: '=',
      },
      link: function($scope) {
        var type =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        $scope.eventName = 'downloadAbstract';
        $scope.downloadErrorMessage =
          'Oops... something went wrong with the abstract generation process, please wait a couple of seconds and try again.';

        function getFileName() {
          if (!$rootScope.lease.isConformedDeal) {
            return (
              $rootScope.lease.building.dashboardName +
              ' - ' +
              LeaseEditorService.getLeaseTenantName() +
              ' - (' +
              $rootScope.lease.type.toLowerCase() +
              ') v' +
              $rootScope.lease.instanceVersion +
              '-abstract.pdf'
            );
          } else {
            return (
              '[Conformed Deal] - ' +
              LeaseEditorService.getLeaseTenantName() +
              ' - (' +
              $rootScope.lease.type.toLowerCase() +
              ') v' +
              $rootScope.lease.instanceVersion +
              '-abstract.pdf'
            );
          }
        }

        function normalize(text) {
          if (typeof text === 'string') {
            return text
              .replace(/&nbsp;/g, '')
              .replace(/&#xFEFF;/g, '')
              .trim();
          } else {
            return text;
          }
        }

        function getConceptData(concept) {
          concept = concept.clone();
          var data = {};

          //get concept section
          var section = concept
            .find('[list]')
            .first()
            .removeAttr('list');
          data.section = section[0] ? normalize(section[0].outerHTML) : null;

          // clean list-items markers to prevent word listing
          concept.find('[style*="display:none"]').remove();
          concept.find('[list]').removeAttr('list');

          // clean font style
          concept.find('[style*="font-family"]').css('font-family', '');
          concept.find('[style*="font-size"]').css('font-size', '');

          // get concept text
          data.text = concept.html();

          //get concept title
          var title = concept.find('concept-title');
          data.title = normalize(title.html());

          //get concept body
          title.remove();
          section.remove();
          data.body = normalize(concept.html());

          // get concept hasModified
          var freeTextsInConcept = concept.find('[free-text]');
          data.hasModified = _.some(freeTextsInConcept, ft => {
            return _.has($scope.modifiedFreeTexts, $(ft).attr('free-text'));
          });

          return data;
        }

        function prepareAbstractData() {
          var abstract = {};

          const root = document.querySelector('.lease--original').cloneNode(true);
          const elementsToRemove = root.querySelectorAll('.lp-hide');
          for (let removeIndex = 0; removeIndex < elementsToRemove.length; removeIndex++) {
            elementsToRemove[removeIndex].remove();
          }

          _.each($(root).find('concept[var]'), function(el) {
            var concept = $(el)
              .attr('var')
              .replace('lease.', '');
            if (!abstract[concept]) {
              abstract[concept] = [];
            }
            var data = getConceptData($(el));
            abstract[concept].push(data);
          });

          window.abstractData = abstract;
        }

        $scope.downloadAbstract = function() {
          $scope.processing = true;
          ProcessStatusService.start('downloadAbstract');
          prepareAbstractData();

          var iframe = angular.element('<iframe/>', {
            id: 'download-abstract',
            name: 'download-abstract',
            style:
              'position: absolute; top: 0; left: 0; width: 1px; height: 1px; z-index: -1; opacity: 0;',
            'event-name': $scope.eventName,
          });

          angular
            .element('iframe[event-name="' + $scope.eventName + '"]')
            .remove();
          var iframeSrc = '/abstracts/' + $scope.leaseId;
          iframe.attr('src', iframeSrc);
          angular.element('body').append(iframe);
        };

        $scope.$on($scope.eventName, function(event, data) {
          console.log(new Date().getTime() + ' on event: ', $scope.eventName);
          console.log(new Date().getTime() + ' data ', data);
          ApiService.htmlToPdf(data.file, window.ASSETS_DESIRED_STYLES).then(
            function successCallback(response) {
              $scope.processing = false;
              ProcessStatusService.end('downloadAbstract');
              $scope.safeApply();
              DownloadService.download(response.data, type, getFileName());
              console.log(
                new Date().getTime() +
                  ' download abstract finished successfully',
              );
            },
            function errorCallback(response) {
              $scope.processing = false;
              ProcessStatusService.end('downloadAbstract');
              $scope.safeApply();
              console.log(response);
              alert($scope.downloadErrorMessage);
            },
          );
        });
      },
    };
  },
]);
