angular.module('LeasePilot').directive('editorLabel', [
  '$rootScope',
  '$mdDialog',
  function($rootScope, $mdDialog) {
    return {
      restrict: 'E',
      scope: {
        for: '@',
        index: '=',
      },
      template:
        '<span title=\'{{editingMode ? for : ""}}\' >{{ $root.labels[for] | toLabel:index }}</span>',
      link: function($scope, element, attr) {
        $scope.editingMode = $rootScope.editingEditorMode;

        $scope.cancel = function() {
          $mdDialog.cancel();
        };
      },
    };
  },
]);
