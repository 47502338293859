angular.module("LeasePilot").directive("comments", [
  "$rootScope",
  function($rootScope) {
    var _pidMap = {};
    var _leaseEditorElement = null;
    var _addBtnElement = null;
    var _mutationObserverConfig = {
      characterData: true,
      subtree: true,
      childList: true,
      attributes: true
    };
    var _observer = null;
    var $scope = null;

    var getElementAbsoluteOffset = el => {
      if (!el) {
        return 0;
      }

      var ret = el.offsetTop;
      while (el.offsetParent) {
        el = el.offsetParent;
        ret += el.offsetTop;
      }

      return ret;
    };

    var getElementOffset = el => {
      if (!el) {
        return 0;
      }

      var ret = el.offsetTop;
      if (el.offsetParent === _leaseEditorElement) return ret;
      else {
        while (el.offsetParent && el.offsetParent !== _leaseEditorElement) {
          el = el.offsetParent;
          ret += el.offsetTop;
        }
      }
      return ret;
    };

    var getMouseMoveTargetPid = evt => {
      var el = evt.srcElement;
      if (el) {
        while (el.parentElement && !el.getAttribute("pid")) {
          el = el.parentElement;
        }
        if (el.getAttribute("pid")) {
          return el;
        }
      }
      return null;
    };

    var updateExistingCommentsMarkersPosition = () => {
      var markers = document.querySelectorAll(".comments-marker");
      if (markers && markers.length > 0) {
        for (var i = 0; i < markers.length; i++) {
          var marker = markers[i];
          var pidel = _pidMap[marker.getAttribute("commentpid")];
          marker.style.top = `${getElementOffset(pidel) + 5}px`;
          marker.style.display = "block";
        }
        updateCommentsDisplayAndEditorPosition();
      }
      updateAcceptRejectItemsPosition();
    };

    var updateCommentsDisplayAndEditorPosition = () => {
      if ($scope.currentCommentPid && _pidMap[$scope.currentCommentPid]) {
        var el = document.querySelector("#comments-display-warper");
        el.style.top = `${getElementOffset(
          _pidMap[$scope.currentCommentPid]
        )}px`;
      }
    };

    var updateAddCommentBtnPosition = evt => {
      if (_addBtnElement === null)
        _addBtnElement = document.querySelector(".addCommentBtn");
      if (_addBtnElement === null) return;
      var target = getMouseMoveTargetPid(evt);
      if (
        target &&
        $scope.comments &&
        !$scope.comments[target.getAttribute("pid")]
      ) {
        _addBtnElement.style.display = "block";
        _addBtnElement.setAttribute("commentpid", target.getAttribute("pid"));
        _addBtnElement.style.top = `${getElementOffset(target) + 3}px`;
      } else if (target) {
        _addBtnElement.style.display = "none";
      }
    };

    var updateAcceptRejectItemsPosition = () => {
      stopObserving();
      var elements = document.querySelectorAll(".annotation");
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        element.classList.remove("annotation--add-space");
        var dataItem = $scope.annotations[elements[i].getAttribute("data-pid")];

        if (dataItem) {
          var anchorElementTop = getElementAbsoluteOffset(dataItem.container);
          if (anchorElementTop <= 0) {
            let container = document.querySelector(
              '[pid="' + dataItem.container.getAttribute("pid") + '"]'
            );
            anchorElementTop = getElementAbsoluteOffset(container);
          }
          var currentTop = getElementAbsoluteOffset(element);
          if (currentTop !== anchorElementTop) {
            var containerTop = getElementAbsoluteOffset(element.parentElement);
            element.style.top = `${anchorElementTop - containerTop}px`;
            currentTop = getElementAbsoluteOffset(element);
          }
          if (currentTop < 150) {
            element.classList.add("annotation--add-space");
          }
        }
      }
      startObserving();
    };

    var startObserving = () => {
      _observer.observe(document.body, _mutationObserverConfig);
    };

    var stopObserving = () => {
      _observer.disconnect();
    };

    function getDiffItem(index) {
      return document.querySelector(`.diff-item[diff-index="${index}"]`);
    }

    function findLeaseVar(diffItemNodes) {
      let leaseVarNode;

      for (let node of diffItemNodes) {
        const parent = node.closest(
          '.transformed--lease-var:not([non-click="true"])'
        );

        if (parent) {
          leaseVarNode = parent;
          break;
        }
      }

      return leaseVarNode;
    }

    var acceptAll = async annotation => {
      const appScope = $rootScope.findAppScope();
      await appScope.annotationsHelper.actions.acceptAndSave(annotation);
      annotation.accepted = true;
      annotation.rejected = false;
      annotation.status = "resolved";

      window.track.event(
        new AcceptAllTenantRequestsEvent({
          context: $rootScope.getContext()
        })
      );
    };

    var acceptRequest = async (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const diffItem = getDiffItem(request._index);

      const requestIndex = annotation.items.indexOf(request);
      if (requestIndex !== annotation.items.length - 1) {
        appScope.annotationsHelper.jumpToRequest(
          annotation.items[requestIndex + 1]._index
        );
      }

      await appScope.annotationsHelper.actions.acceptAndSave(diffItem);

      const resolvedItems = annotation.items.filter(
        item => item._status !== "unresolved"
      );
      if (resolvedItems.length === annotation.items.length) {
        const accepted = resolvedItems.filter(
          item => item._status === "accepted"
        );
        const rejected = resolvedItems.filter(
          item => item._status === "rejected"
        );

        if (accepted.length === annotation.items.length) {
          annotation.accepted = true;
        } else if (rejected.length === annotation.items.length) {
          annotation.rejected = true;
        } else {
          annotation.manual = true;
        }

        annotation.status = "resolved";

        window.track.event(
          new AcceptIndividualTenantRequestEvent({
            context: $rootScope.getContext()
          })
        );
      }
    };

    var rejectAll = async annotation => {
      const appScope = $rootScope.findAppScope();
      await appScope.annotationsHelper.actions.rejectAndSave(annotation);

      annotation.rejected = true;
      annotation.accepted = false;
      annotation.status = "resolved";

      window.track.event(
        new RejectAllTenantRequestsEvent({
          context: $rootScope.getContext()
        })
      );
    };

    var rejectRequest = async (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const diffItem = getDiffItem(request._index);

      const requestIndex = annotation.items.indexOf(request);
      if (requestIndex !== annotation.items.length - 1) {
        appScope.annotationsHelper.jumpToRequest(
          annotation.items[requestIndex + 1]._index
        );
      }

      await appScope.annotationsHelper.actions.rejectAndSave(diffItem);

      const resolvedItems = annotation.items.filter(
        item => item._status !== "unresolved"
      );
      if (resolvedItems.length === annotation.items.length) {
        const accepted = resolvedItems.filter(
          item => item._status === "accepted"
        );
        const rejected = resolvedItems.filter(
          item => item._status === "rejected"
        );

        if (accepted.length === annotation.items.length) {
          annotation.accepted = true;
        } else if (rejected.length === annotation.items.length) {
          annotation.rejected = true;
        } else {
          annotation.manual = true;
        }

        annotation.status = "resolved";

        window.track.event(
          new RejectIndividualTenantRequestEvent({
            context: $rootScope.getContext()
          })
        );
      }
    };

    var adjustManually = annotation => {
      const appScope = $rootScope.findAppScope();
      const diffItemNodes = document.querySelectorAll(
        `.lease.lease--modified .annotation[data-pid="${annotation.pid}"] .diff-item`
      );
      const element = findLeaseVar(diffItemNodes);

      appScope.leftPane = "deal-terms";
      appScope.safeApply();

      setTimeout(() => {
        leftSideEditorSearch.searchByLeaseVarElement(element);
      });
    };

    var adjustRequestManually = (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const diffItemNodes = document.querySelectorAll(
        `.lease.lease--modified .diff-item[diff-index="${request._index}"]`
      );
      const element = findLeaseVar(diffItemNodes);

      appScope.leftPane = "deal-terms";
      appScope.safeApply();

      setTimeout(() => {
        leftSideEditorSearch.searchByLeaseVarElement(element);
      });
    };

    var markAsResolved = annotation => {
      const appScope = $rootScope.findAppScope();

      let currentRequest;
      let currentAnnotationItem;
      for (let i = 0; i < annotation.items.length; i++) {
        currentRequest = annotation.items[i];
        currentAnnotationItem = getDiffItem(currentRequest._index);
        appScope.annotationsHelper.resolveAnnotation(
          currentAnnotationItem,
          "manual"
        );
      }

      annotation.manual = true;
      annotation.status = "resolved";

      window.track.event(
        new MarkAllTenantRequestsAsResolvedEvent({
          context: $rootScope.getContext()
        })
      );
    };

    var markRequestAsResolved = (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const currentAnnotationItem = getDiffItem(request._index);

      const requestIndex = annotation.items.indexOf(request);
      if (requestIndex !== annotation.items.length - 1) {
        appScope.annotationsHelper.jumpToRequest(
          annotation.items[requestIndex + 1]._index
        );
      }

      appScope.annotationsHelper.resolveAnnotation(
        currentAnnotationItem,
        "manual"
      );

      const resolvedItems = annotation.items.filter(
        item => item._status !== "unresolved"
      );
      if (resolvedItems.length === annotation.items.length) {
        const accepted = resolvedItems.filter(
          item => item._status === "accepted"
        );
        const rejected = resolvedItems.filter(
          item => item._status === "rejected"
        );

        if (accepted.length === annotation.items.length) {
          annotation.accepted = true;
        } else if (rejected.length === annotation.items.length) {
          annotation.rejected = true;
        } else {
          annotation.manual = true;
        }

        annotation.status = "resolved";

        window.track.event(
          new MarkIndividualTenantRequestAsResolvedEvent({
            context: $rootScope.getContext()
          })
        );
      }
    };

    var preview = () => {
      const appScope = $rootScope.findAppScope();
      appScope.annotationsHelper.preview();

      window.track.event(
        new PreviewTenantRequestsEvent({
          context: $rootScope.getContext()
        })
      );
    };

    function comments(scope, element, attr) {
      $scope = scope;
      $scope.getElementAbsoluteOffset = getElementAbsoluteOffset;
      $scope.acceptAll = acceptAll;
      $scope.acceptRequest = acceptRequest;
      $scope.rejectAll = rejectAll;
      $scope.rejectRequest = rejectRequest;
      $scope.adjustManually = adjustManually;
      $scope.adjustRequestManually = adjustRequestManually;
      $scope.markAsResolved = markAsResolved;
      $scope.markRequestAsResolved = markRequestAsResolved;
      $scope.preview = preview;

      let _leaseEditorElement = document.querySelector(".lease.lease--modified");

      _observer = new MutationObserver(
        _.throttle(updateExistingCommentsMarkersPosition, 250, {
          leading: false,
          trailing: true
        })
      );

      startObserving();

      window.addEventListener(
        "resize",
        _.throttle(updateExistingCommentsMarkersPosition, 250, {
          leading: false,
          trailing: true
        })
      );
      
      _leaseEditorElement.addEventListener(
        "mouseover",
        _.throttle(updateAddCommentBtnPosition, 250, {
          leading: false,
          trailing: true
        })
      );
    }

    return {
      link: comments,
      restrict: "E",
      templateUrl: "comments.html"
    };
  }
]);
