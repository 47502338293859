angular.module("LeasePilot").directive("leaseVar", [
  "$filter",
  "$rootScope",
  "$sanitize",
  "$timeout",
  "LeaseVarService",
  "LabelsService",
  function(
    $filter,
    $rootScope,
    $sanitize,
    $timeout,
    LeaseVarService,
    LabelsService
  ) {
    const leaseVarLink = function(scope, element, attrs) {

      if (!window.isLeaseIfDeprecated) {
        element.on("click", function(e) {
          return LeaseVarService.leaseVarClick(e, scope, element, attrs);
        });
      } else {
        if (attrs.name && LeaseVarService.isChangeStructure($rootScope.leaseVarStructureChangeMap, attrs.name))  {
          element[0].setAttribute('non-click', true);
        } else {
          element.on("click", function(e) {
            return LeaseVarService.leaseVarClick(e, scope, element, attrs);
          });
        }
      }
    
      scope.$watch("[name, calc, isChanging]", function(newval) {
        return LeaseVarService.leaseVarChangeWatch(
          newval,
          scope,
          element,
          attrs
        );
      });
    
      $timeout(function() {
        LeaseVarService.setTitle(scope, element, LabelsService);
      });
    };
    
    return {
      restrict: "E",
      scope: {
        inputLs: "@",
        ll: "=",
        ev: "@",
        name: "=",
        varName: "@name",
        calc: "=",
        f1: "@",
        f1Options: "@",
        f2: "@",
        f2Options: "@",
        f3: "@",
        f3Options: "@",
        isChanging: "="
      },
      link: leaseVarLink,
    };
  }
]);
