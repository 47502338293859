angular.module('LeasePilot').directive('previewClause', [
  '$mdDialog',
  function($mdDialog) {
    return {
      restrict: 'E',
      templateUrl: 'preview_clause.html',
      link: function(scope, element, attrs) {
        scope.isDescriptionExpanded = false;
        scope.clause = window.__CLAUSE_PREVIEW__;
        
        if (scope.clause.revisions) {
          scope.revision = scope.clause.revisions.at(-1);

          if (scope.revision) {
            scope.revision.createdAt = new Date(scope.revision.createdAt);
            scope.isFirstRevision = scope.clause.revisions.length === 1;
          }
        }

        const previewHtml = document.querySelector(".clause__preview");
        previewHtml.innerHTML = scope.clause.html;
      
        scope.cancel = function() {
          window.__CLAUSE_PREVIEW__ = null;
          
          $mdDialog.cancel();
        };
      },
    };
  },
]);
