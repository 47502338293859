angular.module('LeasePilot').directive('lpPopoutModal', [
  '$rootScope',
  '$mdDialog',
  function($rootScope, $mdDialog) {
    return {
      restrict: 'A',
      transclude: true,
      link: function(scope, element, attrs) {
        const appScope = $rootScope.findAppScope();

        element.bind('click', function() {
          var model = attrs.lpPopoutModal;

          if (appScope.editingEnabled) {
            popoutModal(model);
          }
        });

        const popoutModal = function(model) {
          $mdDialog.show({
            controller: ('DialogController', ['scope', DialogController]),
            templateUrl: 'lp_popout_modal.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: false,
            hasBackdrop: false,
          });

          function DialogController(scopeDialog) {
            scopeDialog.scopeVar = $rootScope.findAppScope(scope);
            scopeDialog.model = 'scopeVar.' + model;
            scopeDialog.label = $rootScope.labels[model];
            scopeDialog.cancel = function() {
              $mdDialog.cancel();
            };
          }
        };
      },
    };
  },
]);
