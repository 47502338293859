/* global JSZip */
angular.module('LeasePilot').directive('importChangesNotSupportedModal', [
    '$mdDialog',
    function(
      $mdDialog,
    ) {
      return {
        restrict: 'E',
        templateUrl: 'import_changes_not_supported_modal.html',
        link: function($scope, element, attrs) {
          $scope.close = function() {
            $mdDialog.cancel();
          };
        },
      };
    },
  ]);
  