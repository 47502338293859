angular.module('LeasePilot').directive('imageControls', ['$rootScope', 'LeaseEditorService',
  function($rootScope, LeaseEditorService) {
    return {
      restrict: 'E',
      replace: false,
      templateUrl: 'image_controls.html',
      link: function($scope) {

        function resizeAndRotate(img, deg, desiredWidth) {
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          let ratio;
          if (deg == 90 || deg == 270) {
            ratio = img.width / img.height;
            canvas.height = desiredWidth;
            canvas.width = canvas.height * ratio;
          } else {
            ratio = img.height / img.width;
            canvas.width = desiredWidth;
            canvas.height = canvas.width * ratio;
          }
          let width = canvas.width;
          let height = canvas.height;

          if (deg === 90) {
            // swap canvas width and height.
            canvas.width = height;
            canvas.height = width;

            // move to the center of the canvas
            ctx.translate(canvas.width / 2, canvas.height / 2);
            // rotate the canvas to the specified degrees
            ctx.rotate(0.5 * Math.PI);
            // since the context is rotated, the image will be rotated also
            ctx.drawImage(img, -width / 2, -height / 2, width, height);
          } else if (deg === 180) {
            ctx.translate(canvas.width, canvas.height);
            ctx.rotate(Math.PI);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          } else if (deg === 270) {
            canvas.width = height;
            canvas.height = width;
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(-0.5 * Math.PI);
            ctx.drawImage(img, -width / 2, -height / 2, width, height);
          } else {
            ctx.drawImage(img, 0, 0, width, height);
          }

          // return base64 data.
          let base64 = canvas.toDataURL("image/png");
          return base64;
        }

        function save(img) {
          var CE = LeaseEditorService.findClosestContentEditable(img);
          CE.trigger('change');
        }

        $scope.resize = function(e) {
          var img = e.currentTarget.closest('.image-container').querySelector('img');
          var size = e.currentTarget.getAttribute('data-size');
          img.setAttribute('data-size', size);
          var currentSelected = img.closest('.image-container').querySelector('.selected');
          if (currentSelected) {
            currentSelected.classList.remove('selected');
          }
          e.currentTarget.classList.add('selected');
          // we resize the *original* image to prevent downscale, and loss of data
          var src = img.getAttribute('data-original-src');
          var container = img.closest('.image-container');
          var width = container.offsetWidth * size;
          var height = container.offsetHeight * size;
          var deg = parseInt(img.getAttribute('data-rotate') || 0);
          var dummyImg = new Image();
          dummyImg.onload = function() {
            img.src = resizeAndRotate(dummyImg, deg, width);
            img.width = width;
            img.height = height;
            img.onload = function() {
              save(img);
            };
          }
          dummyImg.src = src;

          window.track.event(new ResizeImageEvent({
            size: size,
            context: $rootScope.getContext(),
          }));
        };

        $scope.rotate = function(e) {
          var img = e.currentTarget.closest('.image-container').querySelector('img');
          var src = img.getAttribute('data-original-src');
          var size = parseFloat(img.getAttribute('data-size'));
          var width;
          if (size) {
            var container = img.closest('.image-container');
            width = container.offsetWidth * size;
          } else {
            width = img.height;
          }
          var deg = parseInt(img.getAttribute('data-rotate') || 0) + 90;
          deg = deg % 360;
          img.setAttribute('data-rotate', deg);

          var dummyImg = new Image();
          var src = img.getAttribute('data-original-src');
          dummyImg.onload = function() {
            img.src = resizeAndRotate(dummyImg, deg, width);
            img.onload = function() {
              save(img);
            };
          }
          dummyImg.src = src;

          window.track.event(new RotateImageEvent({
            context: $rootScope.getContext(),
          }));
        };

        $scope.delete = function(e) {
          const img = document.querySelector("img.active");

          if (img) {
            LeaseEditorService.deleteImg(img);

            window.track.event(
              new DeleteImageEvent({
                context: $rootScope.getContext()
              })
            );

            return;
          }
        };

        $scope.resetSize = function(e) {
          var img = e.currentTarget.closest('.image-container').querySelector('img');
          img.setAttribute('data-size', 'original');
          var currentSelected = img.closest('.image-container').querySelector('.selected');
          if (currentSelected) {
            currentSelected.classList.remove('selected');
          }
          img.closest('.image-container').querySelector('[data-size="original"]').classList.add('selected');

          var deg = parseInt(img.getAttribute('data-rotate') || 0);
          var dummyImg = new Image();
          dummyImg.onload = function() {
            var width;
            if (deg == 90 || deg == 270) {
              width = dummyImg.height;
            } else {
              width = dummyImg.width;
            }
            img.src = resizeAndRotate(dummyImg, deg, width);
            img.onload = function() {
              save(img);
            };
          }
          dummyImg.src = img.getAttribute('data-original-src');

          window.track.event(new ResetImageToOriginalSizeEvent({
            context: $rootScope.getContext(),
          }));
        }
      },
    };
  },
]);
