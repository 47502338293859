angular.module('LeasePilot').directive('importSalesforceOpportunitiesModal', [
  '$rootScope',
  '$mdDialog',
  'SalesforceOpportunitiesService',
  function($rootScope, $mdDialog, SalesforceOpportunitiesService) {
    return {
      restrict: 'E',
      templateUrl: 'import_salesforce_opportunities_modal.html',
      link: function($scope, _element, _attrs) {
        $scope.isSalesforceOpportunitiesLoading = false;
        $scope.isFailed = false;
        $scope.opportunities = [];

        $scope.getOpportunities = function() {
          $scope.setLoadingState();

          return new SalesforceOpportunitiesService().get().then(
            function success(response) {
              $scope.opportunities = response.opportunities;
              $scope.opportunity_type = 'All Opportunity Types';
              $scope.opportunity_types = [
                'All Opportunity Types',
                'Lease',
                'Specialty Leasing',
              ];
              $scope.isSalesforceOpportunitiesLoading = false;
            },
            function error() {
              $scope.setFailedState();
            },
          );
        };

        $scope.selectOpportunity = function(opportunity) {
          $rootScope.$emit('salesforceOpportunitySelected', opportunity);
          $mdDialog.cancel();
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.setFailedState = function() {
          $scope.isFailed = true;
          $scope.opportunities = []; // TODO: maybe this is not needed
          $scope.isSalesforceOpportunitiesLoading = false;
        };

        $scope.setLoadingState = function() {
          $scope.isFailed = false;
          $scope.isSalesforceOpportunitiesLoading = true;
          $scope.opportunities = [];
        };

        $scope.filterOpportunities = function(opportunity_type) {
          $scope.opportunity_type = opportunity_type;
        };

        // Load all opportunities instantly
        $scope.getOpportunities();
      },
    };
  },
]);
