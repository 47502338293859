angular.module('LeasePilot').directive('guarantorConsent', [
  function() {
    return {
      restrict: 'E',
      scope: {
        lease: '=',
        changing: '=',
        changed: '=',
      },
      link: function(scope, element, attrs) {
        function getIndex(guarantorType, item) {
          return _.indexOf(
            scope.lease.existingGuarantorInfo[guarantorType],
            item,
          );
        }

        function getPlainText(scope, model, text) {
          if (!text) {
            return '__________';
          } else {
            return (
              '<span class=' +
              (model == scope.changing ? '"adding"' : '"added"') +
              '>' +
              text +
              '</span>'
            );
          }
        }

        function createJoindArray(individuals, marriedCouples, entities) {
          return _.filter(_.union(individuals, marriedCouples, entities), {
            hasBeenReleased: false,
          });
        }

        function createLanguageByType(scope, item) {
          var result;

          if (item.type === 'individual') {
            result = _.string.sprintf(
              'the %s Guaranty',
              getPlainText(
                scope,
                'lease.existingGuarantorInfo.individuals[' +
                  getIndex('individuals', item) +
                  '].nickname',
                item.nickname,
              ),
            );
          } else if (item.type === 'married') {
            result = _.string.sprintf(
              'the %s Guaranty',
              getPlainText(
                scope,
                'lease.existingGuarantorInfo.marriedCouples[' +
                  getIndex('marriedCouples', item) +
                  '].nickname',
                item.nickname,
              ),
            );
          } else if (item.type === 'entity') {
            result = _.string.sprintf(
              'the %s Guaranty',
              getPlainText(
                scope,
                'lease.existingGuarantorInfo.entities[' +
                  getIndex('entities', item) +
                  '].nickname',
                item.nickname,
              ),
            );
          }

          return result;
        }

        scope.$watch(
          '[changed, changing, lease.existingGuarantorInfo]',
          function(newValue, oldValue) {
            var lease = scope.lease;
            var result = '';
            var list;
            var guarantorsSequence = [];

            if (!lease) {
              return;
            }

            var hasExistingIndividualGuarantors =
              lease.existingGuarantorInfo.individuals.length > 0;
            var hasExistingMarriedCouplesGuarantors =
              lease.existingGuarantorInfo.marriedCouples.length > 0;
            var hasExistingEntitiesGuarantors =
              lease.existingGuarantorInfo.entities.length > 0;
            var hasExistingGuarantors =
              hasExistingIndividualGuarantors ||
              hasExistingMarriedCouplesGuarantors ||
              hasExistingEntitiesGuarantors;
            var individuals = lease.existingGuarantorInfo.individuals;
            var marriedCouples = lease.existingGuarantorInfo.marriedCouples;
            var entities = lease.existingGuarantorInfo.entities;

            if (!hasExistingGuarantors) {
              element.html(result);
              return;
            }

            // Before concatenating the  two arrays, lets indicate where each item came from
            _.forEach(individuals, function(item) {
              item.type = 'individual';
            });
            _.forEach(marriedCouples, function(item) {
              item.type = 'married';
            });
            _.forEach(entities, function(item) {
              item.type = 'entity';
            });

            // Create the joined array
            list = createJoindArray(individuals, marriedCouples, entities);

            // Start building each sentence seperately
            var curr;
            for (var i = 0; i < list.length; i++) {
              curr = list[i];
              guarantorsSequence.push(createLanguageByType(scope, curr));
            }

            // This is the result language
            var sequence = _.string.toSentence(
              guarantorsSequence,
              ', ',
              ' and ',
            );
            result = _.string.sprintf(
              '%s, executed in connection with the Lease, and further acknowledge and reaffirm the undersigned’s continued obligations under %s',
              sequence,
              sequence,
            );

            if (guarantorsSequence.length > 1) {
              result += ', respectively';
            }

            // Wrap the result with the contenteditable markup
            result = _.string.sprintf(
              "<span contenteditable='false'>%s</span>",
              result,
            );
            element.html(result);
          },
          true,
        );
      },
    };
  },
]);
