angular.module('LeasePilot').factory('AllOverrideService', [
  'railsResourceFactory',
  'railsSerializer',
  function(railsResourceFactory, railsSerializer) {
    return railsResourceFactory({
      url: '/api/overrides',
      name: 'allOverrides',
      serializer: railsSerializer(function() {
        this.resource('buildingOverrides', 'BuildingOverridesService');
        this.resource('overrides', 'OverridesService');
      }),
    });
  },
]);
