angular.module('LeasePilot').directive('importVtsDealsModal', [
  '$rootScope',
  '$mdDialog',
  '$http',
  '$timeout',
  'ImportTaskService',
  function($rootScope, $mdDialog, $http, $timeout, ImportTaskService) {
    return {
      restrict: 'E',
      templateUrl: 'import_vts_deals_modal.html',
      link: function($scope, _element, _attrs) {
        $scope.isVTSDealsLoading = false;
        $scope.isFailed = false;
        $scope.importTask = { deals: [] };

        $scope.getCachedDeals = function() {
          $scope.setLoadingState();

          return new ImportTaskService().getCachedDeals().then(
            function success(response) {
              // Uncomment to fallback to immediately refresh data when no deals returned
              // NOTE: This may take long time for some clients!
              // if (response.deals.length > 0) {
              $scope.importTask = response;
              $scope.isVTSDealsLoading = false;
              // } else {
              //   $scope.reloadDeals();
              // }
            },
            function error() {
              $scope.setFailedState();
            },
          );
        };

        $scope.reloadDeals = function() {
          $scope.setLoadingState();

          new ImportTaskService().create().then(
            function success(response) {
              $scope.importTask = response;
              $scope.performImportTask();
            },
            function error() {
              $scope.setFailedState();
            },
          );
        };

        $scope.selectDeal = function(deal) {
          $rootScope.$emit('vtsDealSelected', $scope.importTask, deal);
          $mdDialog.cancel();
        };

        $scope.cancel = function() {
          $mdDialog.cancel();
          $timeout.cancel($scope.performImportTaskTimer);
        };

        $scope.setFailedState = function() {
          $scope.isFailed = true;
          $scope.importTask.deals = [];
          $scope.isVTSDealsLoading = false;
        };

        $scope.setLoadingState = function() {
          $scope.isFailed = false;
          $scope.isVTSDealsLoading = true;
          $scope.importTask = { deals: [] };
        };

        // TODO: add some general timeout to cancel operation if there are no updates in N mins
        $scope.performImportTask = function() {
          switch ($scope.importTask.status) {
            // NOTE: this status is updated in VTS::DownloadDeals service
            case 'exported':
              $scope.getCachedDeals().then(
                function success() {
                  $scope.isVTSDealsLoading = false;
                },
                function error() {
                  $scope.setFailedState();
                },
              );
              break;
            case 'failed':
              $scope.setFailedState();
              break;
            default:
              // TODO: move this to a more general place. Keeping this var in scope has no sense
              $scope.performImportTaskTimer = $timeout(
                function() {
                  $scope.checkImportTask();
                },
                1000,
                true,
              );
          }
        };

        $scope.checkImportTask = function() {
          $scope.importTask.get().then(function() {
            $scope.performImportTask();
          });
        };

        // Reload all deals instantly
        $scope.reloadDeals();
        //$scope.getCachedDeals();
      },
    };
  },
]);
