function humanize(str) {
  return str
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
}

angular.module('LeasePilot').directive('newFormModal', [
  '$mdDialog',
  '$http',
  'FormService',
  function($mdDialog, $http, FormService) {
    return {
      restrict: 'E',
      templateUrl: 'new_form_modal.html',
      link: function($scope, element, attrs) {
        $scope.form = { companyId: attrs.companyId };

        $scope.documentTypes = ['Lease', 'Amendment', 'LOI'];
        $scope.dealTypes = ['retail', 'office', 'industrial'];

        $scope.cancel = function() {
          $mdDialog.cancel();
        };

        $scope.updateDisplayName = function() {
          const control = document.querySelector("[ng-model='form.displayName']");

          if (!control.classList.contains("ng-dirty")) {
            $scope.form.displayName = humanize($scope.form.flavor);
          }
        }

        $scope.create = function() {
          var newForm = new FormService($scope.form).create().then(function() {
            window.location.reload();
          });
        };
      },
    };
  },
]);
