angular.module('LeasePilot').service('mriExportService', [
  '$q',
  '$http',
  function($q, $http) {
    const createFormData = function(leaseId, unstructuredData) {
      let formData = new FormData();
      formData.append('export_task[lease_id]', leaseId);
      formData.append('export_task[from_status]', window.lease_previous_status.id);
      formData.append('export_task[to_status]', window.lease.status.id);
      formData.append(
        'export_task[unstructured_data]',
        JSON.stringify(unstructuredData),
      );
      return formData;
    };

    const createExportTask = function({ leaseId, unstructuredData }) {
      const formData = createFormData(leaseId, unstructuredData)

      return $http({
        method: 'POST',
        url: '/api/export_tasks',
        data: formData,
        headers: { 'Content-Type': undefined },
        transformRequest: angular.identity,
      })
        .then(function(response) {
          return response.data.export_task;
        })
        .catch(function(errorResponse) {
          return Promise.reject({
            errorType: 'task_creation_failed',
            error: _.get(errorResponse, 'data.errors', undefined),
          });
        });
    };

    return {
      createExportTask: createExportTask,
    };
  },
]);
