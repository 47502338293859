angular.module('LeasePilot').directive('leftEditor', [
  '$timeout',
  '$rootScope',
  '$filter',
  '$compile',
  'EditorService',
  'LabelsService',
  'TemplateService',
  'LeaseVarService',
  function(
    $timeout,
    $rootScope,
    $filter,
    $compile,
    EditorService,
    LabelsService,
    TemplateService,
    LeaseVarService,
  ) {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'left_editor.html',
      link: function($scope, element, attrs) {
        $scope.formId = attrs.formId;
        $scope.editingMode = attrs.editingMode;

        $scope.initializeTemplate = function(template) {
          $rootScope[_.camelCase(template.id) + 'Config'] = {
            fields: template.config,
          };
        };

        $scope.setSections = function() {
          $scope.sections = TemplateService.getSections(
            $scope.editor.isMultipleTerms,
          );
          $scope.multipleTermsSection = TemplateService.getMultipleTermsSection();
          $scope.basicTermsSection = TemplateService.getBasicTermsSection();
          TemplateService.setSectionsForCards(
            $scope.sections,
            $scope.editor,
          );
        };

        $scope.bindCollapseEvent = function() {
          $timeout(function() {
            $('.collapse-card__heading').off('click');
            $('.collapse-card').paperCollapse();
            $('.collapse-card input').on('click', function(e) {
              e.stopPropagation();
            });
          });
        };

        function initAdditionalTermsCard() {
          var additionalTermsCardId = TemplateService.getMultipleTermsCards()[1];
          var additionalTermsCard = _.find($scope.editor.cards, function(card) {
            return card.id == additionalTermsCardId;
          });
          $scope.additionalTermsCard = additionalTermsCard;
        }

        $scope.setLabels = function() {
          $rootScope.fullLabels = LabelsService.getMergedLabels(
            $scope.editor.labels,
            true,
          );
          $rootScope.shortLabels = LabelsService.getMergedLabels(
            $scope.editor.labels,
            false,
          );
          $rootScope.labels = $rootScope.shortLabels;
          LeaseVarService.setTitles($scope,LabelsService);
        };

        $scope.$on('inlineModeChanged', function() {
          $rootScope.labels = $scope.inline.on
            ? $rootScope.fullLabels
            : $rootScope.shortLabels;
        });

        $scope.$on('editorUpdated', function() {
          $timeout(function() {
            // can be removed when all editors are `new-design`
            // (delete paperCollapse() from application.js)
            $('.collapse-card__heading').off('click');
            $('.collapse-card').paperCollapse();
            $('.collapse-card input').on('click', function(e) {
              e.stopPropagation();
            });
          }, 0);
        });

        $scope.init = (function() {
          if (Object.keys(window?.lease?.form?.customEditor || {}).length === 0)  {
            if (!$scope.editingMode) {
              if (window.lease) {
                const editor = window.lease.form.editor;
                if (!editor) {
                  throw `This form (id: ${window.lease.form.id}) does not have a left editor. Please add it in the admin view.`;
                }
                EditorService.setEditorCards(editor);
                $scope.editor = editor;
                $scope.setLabels(editor.labels);
                $scope.setSections(editor);
                initAdditionalTermsCard();
              }
            }
          } else {
            const scope = $scope.findAppScope();
            $rootScope.labels = [];
            $rootScope.fullLabels = [];
            $rootScope.shortLabels = [];
            customEditorManager.init(scope, $filter, $compile);
          }
        })();
      },
    };
  },
]);
