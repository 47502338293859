angular.module('LeasePilot').directive('leaseUpdate', [
  '$compile',
  function($compile) {
    return function(scope, element, attrs) {
      let value = scope.$eval(attrs.leaseUpdate);

      //remove free-text element
      if ($(value).attr('free-text')) value = $(value).html();

      element.html(value);

      $compile(element.contents())(scope);

      $(element)
        .find('lease-var')
        .each(function() {
          var leaseVar = $(this).attr('ng-attr-name');
          $(this).attr('title', 'lease variable: ' + leaseVar);
        });

      $(element)
        .find('building-variable')
        .each(function() {
          var buildingVar = $(this).attr('bs-name');
          $(this).attr('title', 'building section: ' + buildingVar);
        });
    };
  },
]);
