angular.module("LeasePilot").service("UserHelperService", [
  "$http",
  function($http) {
    function randomizeString(inputString) {
      const characters = inputString.split("");

      for (let i = characters.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [characters[i], characters[randomIndex]] = [
          characters[randomIndex],
          characters[i]
        ];
      }

      const randomizedString = characters.join("");

      return randomizedString;
    }

    async function available(email) {
      const result = await $http({
        method: "post",
        url: `/api/users/available`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email,
        }
      })

      if (result.status === 200) {
        return result.data.available;
      } else {
        return false;
      }
    }

    function resetPassword(id, password) {
      return $http({
        method: "put",
        url: `/api/users/reset_password`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          id,
          password
        }
      });
    }

    function activate(id) {
      return $http({
        method: "put",
        url: `/api/users/activate`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          id,
        }
      });
    }

    function deactivate(id) {
      return $http({
        method: "put",
        url: `/api/users/deactivate`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          id,
        }
      });
    }

    function create({ firstName, lastName, email, role, password }) {
      return $http({
        method: "post",
        url: `/api/users`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          firstName,
          lastName,
          email,
          role,
          password
        }
      });
    }

    function update({ id, firstName, lastName, email, role }) {
      return $http({
        method: "put",
        url: `/api/users/${id}`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          id,
          firstName,
          lastName,
          email,
          role
        }
      });
    }

    function generatePassword() {
      const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
      const numberChars = "0123456789";
      const specialChars = "!@#$%^&*()_+~`|}{[]\\:;?><,./-";
      const uppercaseCount = 4; // Number of uppercase letters
      const lowercaseCount = 4; // Number of lowercase letters
      const numberCount = 4; // Number of numbers
      const specialCharCount = 2; // Number of special characters

      let password = "";

      // Generate uppercase letters
      for (let i = 0; i < uppercaseCount; i++) {
        const randomIndex = Math.floor(Math.random() * uppercaseChars.length);
        password += uppercaseChars[randomIndex];
      }

      // Generate lowercase letters
      for (let i = 0; i < lowercaseCount; i++) {
        const randomIndex = Math.floor(Math.random() * lowercaseChars.length);
        password += lowercaseChars[randomIndex];
      }

      // Generate numbers
      for (let i = 0; i < numberCount; i++) {
        const randomIndex = Math.floor(Math.random() * numberChars.length);
        password += numberChars[randomIndex];
      }

      // Generate special characters
      for (let i = 0; i < specialCharCount; i++) {
        const randomIndex = Math.floor(Math.random() * specialChars.length);
        password += specialChars[randomIndex];
      }

      // Generate remaining random characters
      const remainingChars =
        16 - (uppercaseCount + lowercaseCount + numberCount + specialCharCount);
      for (let i = 0; i < remainingChars; i++) {
        const randomIndex = Math.floor(
          Math.random() *
            (uppercaseChars.length +
              lowercaseChars.length +
              numberChars.length +
              specialChars.length)
        );
        if (randomIndex < uppercaseChars.length) {
          password += uppercaseChars[randomIndex];
        } else if (
          randomIndex <
          uppercaseChars.length + lowercaseChars.length
        ) {
          password += lowercaseChars[randomIndex - uppercaseChars.length];
        } else if (
          randomIndex <
          uppercaseChars.length + lowercaseChars.length + numberChars.length
        ) {
          password +=
            numberChars[
              randomIndex - uppercaseChars.length - lowercaseChars.length
            ];
        } else {
          password +=
            specialChars[
              randomIndex -
                uppercaseChars.length -
                lowercaseChars.length -
                numberChars.length
            ];
        }
      }

      return randomizeString(password);
    }

    function validatePassword(password) {
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
      const specialCharRegex = /[!@#$%^&*()_+~`|}{\[\]:;?><,./-]/;

      const hasUppercase = uppercaseRegex.test(password);
      const hasLowercase = lowercaseRegex.test(password);
      const hasNumber = numberRegex.test(password);
      const hasSpecialChar = specialCharRegex.test(password);

      const isValid = {
        uppercase: hasUppercase,
        lowercase: hasLowercase,
        numbers: hasNumber,
        special: hasSpecialChar,
        length: !!password && password.length >= 8
      };

      isValid.all =
        isValid.uppercase &&
        isValid.lowercase &&
        isValid.numbers &&
        isValid.special &&
        isValid.length;

      return isValid;
    }

    function validateEmail(email) {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return !!email && emailRegex.test(email);
    }

    function validateRole(role) {
      return !!role && (role === "user" || role === "form_manager" || role === "it_admin");
    }

    async function validate({
      type = null,
      firstName = null,
      lastName = null,
      email = null,
      role = null,
      password = null
    }) {
      const isValid = {
        all: true
      };

      if (firstName !== null) {
        isValid.firstName = !!firstName;
        isValid.all &&= !!isValid.firstName;
      }

      if (lastName !== null) {
        isValid.lastName = !!lastName;
        isValid.all &&= !!isValid.lastName;
      }

      if (email !== null) {
        isValid.email = validateEmail(email);
        isValid.all &&= isValid.email;
        
        if (type === "create") {
          isValid.emailAvailable = await available(email);
          isValid.all &&= isValid.emailAvailable;
        }
      }

      if (role !== null) {
        isValid.role = validateRole(role);
        isValid.all &&= isValid.role;
      }

      if (password !== null) {
        isValid.password = validatePassword(password);
        isValid.all &&= isValid.password.all;
      }

      return isValid;
    }

    return {
      randomizeString,
      available,
      resetPassword,
      activate,
      deactivate,
      create,
      update,
      generatePassword,
      validatePassword,
      validateEmail,
      validateRole,
      validate
    };
  }
]);
