angular.module('LeasePilot').service('RolesService', function() {
  var _role;

  this.isAdmin = function() {
    return _role === 'admin';
  };

  this.isFormAdmin = function() {
    return _role === 'form_manager';
  };

  this.setRole = function(role) {
    _role = role;
  };
});
