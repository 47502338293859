if (!Node.prototype.cleanAllText) {
  Node.prototype.cleanAllText = function() {
    if (this.nodeType === Node.TEXT_NODE) {
      this.textContent = "";
    } else {
      this.childNodes.forEach(node => {
        node.cleanAllText();
      });
    }
  };
}
if (!Node.prototype.cleanEmptyTextNodes) {
  Node.prototype.cleanEmptyTextNodes = function() {
    if (
      this.nodeType === Node.TEXT_NODE &&
      this.textContent.trim() === "" &&
      (
        this.parentElement.hasAttribute("data-custom-style") && 
        this.parentElement.getAttribute("data-custom-style").indexOf("-aw-import: spaces;") === -1
      ) &&
      this.parentElement.innerHTML.indexOf("&nbsp") === -1
    ) {
      this.remove();
    } else {
      this.childNodes.forEach(node => {
        node.cleanEmptyTextNodes();
      });
    }
  };
}

const NodeAppendChildOriginal = Object.getOwnPropertyDescriptor(
  Node.prototype,
  "appendChild"
);

Object.defineProperty(Node.prototype, "appendChild", {
  ...NodeAppendChildOriginal,
  value: function(value) {
    const returnValue = NodeAppendChildOriginal.value.call(this, value);
    if (
      value &&
      value.classList &&
      value.classList.contains("deleted-paragraph")
    ) {
      if (this && this.getAttribute && this.getAttribute("section-id")) {
        contentEditableManager.updateContainerVisibility(
          this.getAttribute("section-id")
        );
      }
    }
    return returnValue;
  }
});

if (!Node.prototype.getListLevelNumber) {
  Node.prototype.getListLevelNumber = function() {
    const levelAttributeNode =
      this.closest(`[data-list-set-level]`) ||
      this.querySelector(`[data-list-set-level]`);
    if (levelAttributeNode) {
      const value = levelAttributeNode
        .getAttribute("data-list-set-level")
        .replace(/SET_LEVEL_/g, "")
        .trim();
      return parseInt(value);
    } else {
      return 999;
    }
  };
}

if (!Node.prototype.getNodeOccurrenceIndexByAttribute) {
  Node.prototype.getNodeOccurrenceIndexByAttribute = function(attributeName) {
    let index = -1;

    if (this.getAttribute(attributeName) && this.tagName) {
      const query = `${this.tagName}[${attributeName}="${this.getAttribute(
        attributeName
      )}"]`;
      const nodes = document.querySelectorAll(query);
      index = nodes.indexOf(this);
    }

    return index;
  };
}

function isInline(node) {
  if (
    node.tagName === "SPAN" ||
    node.tagName === "B" ||
    node.tagName === "I" ||
    node.tagName === "U" ||
    node.tagName === "A" ||
    node.tagName === "STRONG" ||
    node.tagName === "EM" ||
    node.tagName === "SUP" ||
    node.tagName === "SUB" ||
    node.tagName === "FONT"
  ) {
    return true;
  }

  return false;
}

if (!Node.prototype.getAllTextNodes) {
  Node.prototype.getAllTextNodes = function() {
    let arr = [];
    this.childNodes.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
        arr.push(node);
      } else {
        node.getAllTextNodes().forEach(n => {
          arr.push(n);
        });
      }
    });
    return arr;
  };
}

if (!Node.prototype.getAllCommentNodes) {
  Node.prototype.getAllCommentNodes = function() {
    let arr = [];
    this.childNodes.forEach(node => {
      if (node.nodeType === Node.COMMENT_NODE) {
        arr.push(node);
      } else {
        node.getAllCommentNodes().forEach(n => {
          arr.push(n);
        });
      }
    });
    return arr;
  };
}

if (!Node.prototype.removeWhitespace) {
  Node.prototype.removeWhitespace = function(removeComments = true) {
    let textNodes = this.getAllTextNodes();
    textNodes.forEach(childNode => {
      const isWhitespace = !/[^\t\n\r ]/.test(childNode.textContent);
      if (!isInline(childNode.parentElement) && isWhitespace) {
        childNode.remove();
      }
    });
    if (removeComments) {
      let commentNodes = this.getAllCommentNodes();
      commentNodes.forEach(node => {
        node.remove();
      });
    }
  };
}

if (!Node.prototype.fixSubSupDecorations) {
  Node.prototype.fixSubSupDecorations = function() {
    const root = this;
      
    if (root) {
      const sup = root.querySelectorAll("[style*='vertical-align: super']");
      sup.forEach((item) => {
        item.style.fontSize = "75%";

        const span = document.createElement("span");

        if (item.style.textDecoration === "underline") {
          span.style.textDecoration = "underline";
          item.style.textDecoration = "none";
          item.parentElement.insertBefore(span, item);
          span.appendChild(item);
        }
      });

      const sub = root.querySelectorAll("[style*='vertical-align: sub']");
      sub.forEach((item) => {
        item.style.fontSize = "75%";

        const span = document.createElement("span");

        if (item.style.textDecoration === "underline") {
          span.style.textDecoration = "underline";
          item.style.textDecoration = "none";
          item.parentElement.insertBefore(span, item);
          span.appendChild(item);
        }
      });
    }
  }
}

if (!Node.prototype.fixEmptyParagraphs) {
  Node.prototype.fixEmptyParagraphs = function() {
    const root = this;
    const paragraphs = Array.from(root.querySelectorAll("p, h1, h2, h3, h4, h5, h6"));
    let paragraph;
    let placeholder;
    let clone;

    for (let i = 0; i < paragraphs.length; i++) {
      paragraph = paragraphs[i];
      clone = document.createElement("dummy");
      clone.innerHTML = paragraph.outerHTML;
      clone.removeWhitespace();

      // This checks if the paragraph only consists of a &nbsp;
      if (clone.textContent === ' ') {
        placeholder = paragraphs[i].querySelector(".PLACEHOLDER");

        if  (placeholder) {
          placeholder.textContent = window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE;
        }
      }
    }
  }
}

if (!Node.prototype.getNodeOutline) {
  Node.prototype.getNodeOutline = function() {
    const list = document.querySelectorAll(
      ".lease.lease--original outline-end,lease-end,lease-start,outline," +
        this.tagName
    );
    let index = list.indexOf(this);
    let found = false;
    while (index >= 0 && !found) {
      if (list[index].tagName === this.tagName) {
        index--;
      } else {
        found = true;
      }
    }

    if (list[index]) {
      if (list[index].tagName === "OUTLINE") {
        return list[index].getAttribute("name");
      } else if (list[index].tagName === "LEASE-START") {
        return "main";
      }
    }
    return null;
  };
}

//Object.getOwnPropertyDescriptor(Node.prototype,`insertBefore`);

// const NodeInsertBeforeOriginal =   Object.getOwnPropertyDescriptor(
//   Node.prototype,
//   "insertBefore"
// );

// Object.defineProperty(Node.prototype, "insertBefore", {
//   ...NodeInsertBeforeOriginal,
//   value: function(a,b) {
//     if(window.isDebug === true){
//       debugger;
//     }
//     const returnValue = NodeInsertBeforeOriginal.value.call(this, a,b);

//     return returnValue;
//   }
// });

// const NodeRemoveChildOriginal = Object.getOwnPropertyDescriptor(
//   Node.prototype,
//   "removeChild"
// );

// debugger;
// Object.defineProperty(Node.prototype, "removeChild", {
//   ...NodeRemoveChildOriginal,
//   value: function(value) {
//     console.log('remove',value);
//     return NodeRemoveChildOriginal.value.call(this, value);
//   }
// });
