angular.module('LeasePilot').service('kiteExportService', [
  'leaseProcessorService',
  '$q',
  '$http',
  '$rootScope',
  'ApiService',
  function(leaseProcessorService, $q, $http, $rootScope, ApiService) {
    const prepareData = (lease, documentType, docxFile) => {
      const doc = docxFile;
      return renderLease(lease.id, documentType)
        .then((htmlFile) => {
          const docxFile = doc
            ? new Blob([doc], {
              type:
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              })
            : convertHtmlToWord(htmlFile);

          const unstructuredData = leaseProcessorService.parseUnstructuredData(lease);
          return $q.all({
            leaseId: lease.id,
            docxFile: docxFile,
            unstructuredData: unstructuredData,
          });
        });
    };

    const createLeaseFrame = function(id, documentType) {
      const iframe = angular.element('<iframe/>', {
        name: 'export',
        style:
          'position: absolute; top: 0; left: 0; width: 1px; height: 1px; z-index: -1; opacity: 0;',
        'event-name': 'export',
      });

      const src = '/' + pluralize(documentType).toLowerCase() + '/' + id;

      angular.element('iframe[event-name="export"]').remove();
      angular.element(iframe).attr('src', src);
      angular.element('body').append(iframe);
    };

    const renderLease = function(id, documentType) {
      const deferred = $q.defer();

      createLeaseFrame(id, documentType);
      $rootScope.$on('export', function(event, data) {
        deferred.resolve(data.file);
      });

      return deferred.promise;
    };

    const convertHtmlToWord = function(htmlFile) {
      return new Promise(function(resolve, reject) {
        ApiService.htmlToWord(htmlFile, window.ASSETS_DESIRED_STYLES).then(
          function success(response) {
            const docxFile = new Blob([response.data], {
              type:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            return resolve(docxFile);
          },
          function error(error) {
            return reject({
              errorType: 'html_to_word_conversion_failed',
              error: error,
            });
          },
        );
      });
    };

    const createFormData = function(leaseId, docxFile, unstructuredData) {
      let formData = new FormData();
      formData.append('export_task[lease_id]', leaseId);
      formData.append('export_task[file]', docxFile);
      formData.append('export_task[from_status]', window.lease_previous_status.id);
      formData.append('export_task[to_status]', window.lease.status.id);
      formData.append(
        'export_task[unstructured_data]',
        JSON.stringify(unstructuredData),
      );
      return formData;
    };

    const createExportTask = ({ leaseId, docxFile, unstructuredData }) => {
      const formData = createFormData(leaseId, docxFile, unstructuredData);
      return $http({
        method: 'POST',
        url: '/api/export_tasks',
        data: formData,
        headers: { 'Content-Type': undefined },
        transformRequest: angular.identity,
      })
        .then(function(response) {
          return response.data.export_task;
        })
        .catch(function(errorResponse) {
          return Promise.reject({
            errorType: 'task_creation_failed',
            error: _.get(errorResponse, 'data.errors', undefined),
          });
        });
    };

    return {
      createExportTask: createExportTask,
      prepareData: prepareData
    };
  },
]);
