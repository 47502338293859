Object.defineProperty(HTMLElement.prototype, "getBlockData", {
  value: function() {
    return listItemsManager.getListItemObjectFromNode(this);
  },
  enumerable: false
});

Object.defineProperty(HTMLElement.prototype, "toJson", {
  value: function() {
    let json = new ElementJson(this);
    return json;
  },
  enumerable: false
});


Object.defineProperty(HTMLElement.prototype, "getVisibleElements", {
  value: function() {
    const clone = this.cloneNode(true);
    clone.querySelectorAll('.lp-hide-element,.lp-hide').forEach((node)=>{
      node.remove();
    });
    return clone;
  },
  enumerable: false
});

Object.defineProperty(HTMLElement.prototype, "findIndexOfContainingElement", {
  value: function(Block) {
    let index = Array.prototype.indexOf.call(this.childNodes, Block);
    if (index < 0) {
      index = 0;
      while (
        this.childNodes[index] &&
        !this.childNodes[index].contains(Block)
      ) {
        index++;
      }
    }
    return index;
  },
  writable: true,
  enumerable: false
});

HTMLElement.fromJson = function(json,force) {
  if (typeof json === "ElementJson" || force) {
    return ElementJson.fromJson(json);
  } else {
    return null;
  }
};

function Attribute(key, value) {
  this.key = key;
  this.value = value;
}

function ElementJson(node) {
  this.tagName = node.tagName;
  this.nodeType = node.nodeType;
  this.attributes = [];
  this.childNodes = [];
  this.nodeText = null;

  if (node.attributes) {
    for (let attIndex = 0; attIndex < node.attributes.length; attIndex++) {
      this.attributes.push(
        new Attribute(
          node.attributes[attIndex].name,
          node.attributes[attIndex].value
        )
      );
    }
  }

  if (node.childNodes) {
    for (let cIndex = 0; cIndex < node.childNodes.length; cIndex++) {
      let cNode = node.childNodes[cIndex];
      this.childNodes.push(new ElementJson(cNode));
    }
  }

  if (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.COMMENT_NODE) {
    this.nodeText = node.textContent;
  }
}

Object.defineProperty(ElementJson.prototype, "toElement", {
  value: function() {
    return ElementJson.fromJson(this);
  },
  enumerable: false
});

ElementJson.fromJson = function(json) {
  let retElement = null;
  if(typeof json === 'string'){
    json = JSON.parse(json);
  }

  if (json.tagName) {
    retElement = document.createElement(json.tagName);

    json.attributes.forEach(att => {
      retElement.setAttribute(att.key, att.value);
    });

    json.childNodes.forEach(child => {
      retElement.appendChild(ElementJson.fromJson(child));
    });
  } else {
    if (json.nodeType === Node.TEXT_NODE) {
      retElement = document.createTextNode(json.nodeText);
    } else {
      if (json.nodeType === Node.COMMENT_NODE) {
        retElement = document.createComment(json.nodeText);
      }
    }
  }

  return retElement;
};

window.ElementJson = ElementJson;


Object.defineProperty(HTMLElement.prototype, "getAllTextNodes", {
  value: function(arr) {
    arr = arr ? arr : [];
    this.childNodes.forEach((child)=>{
      if(child.nodeType === Node.TEXT_NODE){
        arr.push(child);
      }
      else{
        if(child.nodeType !== Node.COMMENT_NODE){
          child.getAllTextNodes(arr);
        }
      }
    });
    return arr;
  },
  enumerable: false
});






Object.defineProperty(HTMLElement.prototype, "areAllTextNodesMarkedForDelete", {
  value: function() {
    const nodes = this.getAllTextNodes();
    const filteredNodes = nodes.filter(item => {
      return (
        (item.parentElement.closest("[free-text]") && item.parentElement.offsetParent &&
        !item.parentElement.closest("del")) &&  ( item.parentElement.closest("[free-text]") && !item.parentElement.closest(".SKIP"))
      );
    });
    
    return filteredNodes.length === 0;

  },
  enumerable: false
});


Object.defineProperty(HTMLElement.prototype, "insertInsElement", {
  value: function(index, html, splitSpan = true) {
    const tagName = "INS";
    let returnValue = null;
    if (this.textContent.length < index) {
      throw new Error(
        `Can't insert ${html} in index: ${index} index is greater then length of ${this}`
      );
    } else {
      const textNodes = this.getAllTextNodes();
      let countIndex = index;
      let nodeIdex = 0;
      let currentTextNode = textNodes[0];

      while (currentTextNode && currentTextNode.length <= countIndex) {
        nodeIdex++;
        countIndex = countIndex - currentTextNode.length;
        currentTextNode = textNodes[nodeIdex];
      }

      if (!currentTextNode && countIndex === 0) {
        currentTextNode = textNodes[textNodes.length - 1];
        countIndex = currentTextNode.textContent.length;
      }

      if (!currentTextNode) {
        throw new Error(
          `Can't insert ${html} in index: ${index} index is greater then length of ${this}`
        );
      } else {
        currentTextNode.insertData(countIndex, "!@#$|||!@#$");
        const texts = currentTextNode.textContent.split("!@#$"); 
        texts.forEach(text => {
          if (text !== "") {
            if (text === "|||") {
              const newElement = document.createElement(tagName);
              newElement.innerHTML = html;
              currentTextNode.parentElement.insertBefore(
                newElement,
                currentTextNode
              );
              returnValue = newElement;
            } else {
              const span = document.createElement("span");
              span.setAttribute("style", this.getAttribute("style"));
              const textNode = document.createTextNode(text);
              span.appendChild(textNode);
              if (splitSpan) {
                currentTextNode.parentElement.insertBefore(
                  span,
                  currentTextNode
                );
              } else {
                currentTextNode.parentElement.insertBefore(
                  textNode,
                  currentTextNode
                );
              }
            }
          }
        });
        currentTextNode.remove();
        if (splitSpan) {
          this.setAttribute("style", "");
        }
      }
    }
    return returnValue;
  }
});

Object.defineProperty(HTMLElement.prototype, "insertDelElement", {
  value: function(index, length, splitSpan = false) {
    if(length == 0){
      return null;
      
    }
    const tagName = "DEL";

    let returnValue = null;

    let delTextNode = null;
    const spans = [];

    if (this.textContent.length < index) {
      throw new Error(
        `Can't insert ${html} in index: ${index} index is greater then length of ${this}`
      );
    } else {
      const textNodes = this.getAllTextNodes();
      let countIndex = index;
      let nodeIdex = 0;
      let currentTextNode = textNodes[0];
      while (currentTextNode && currentTextNode.length <= countIndex) {
        nodeIdex++;
        countIndex = countIndex - currentTextNode.length;
        currentTextNode = textNodes[nodeIdex];
      }

      if (!currentTextNode) {
        throw new Error(
          `Can't insert ${html} in index: ${index} index is greater then length of ${this}`
        );
      } else {
        currentTextNode.insertData(countIndex, "!@#$|||!@#$");
        const texts = currentTextNode.textContent.split("!@#$");

        texts.forEach(text => {
          if (text !== "") {
            const span = document.createElement("span");
            const textNode = document.createTextNode(text);

            if (splitSpan) {
              spans.push(span);
            }

            if (text === "|||") {
              const delElement = document.createElement(tagName);
              currentTextNode.parentElement.insertBefore(
                delElement,
                currentTextNode
              );
              if (splitSpan) {
                delElement.appendChild(span);
                span.appendChild(textNode);
              } else {
                delElement.appendChild(textNode);
              }

              delTextNode = textNode;
              returnValue = delElement;
            } else {
              if (splitSpan) {
                currentTextNode.parentElement.insertBefore(
                  span,
                  currentTextNode
                );
                span.appendChild(textNode);
              } else {
                currentTextNode.parentElement.insertBefore(
                  textNode,
                  currentTextNode
                );
              }
            }
          }
        });

        currentTextNode.remove();
      }
    }

    if (returnValue && delTextNode) {
      const nextTextNode = delTextNode.getNextTextNodeInBlock();

      if (nextTextNode) {
        delTextNode.parentElement.appendChild(
          document.createTextNode(nextTextNode.textContent.substr(0, length))
        );
        nextTextNode.deleteData(0, length);
        delTextNode.remove();
        if (nextTextNode.length === 0) {
          nextTextNode.remove();
        }
      }
    }

    return returnValue;
  }
});




