var NG_HIDE_CLASS = 'ng-hide';
var NG_SHOW_CLASS = 'ng-show';

angular.module('LeasePilot').directive('editorShow', [
  '$animate',
  '$location',
  function ($animate, $location) {
    const editorShowLink = function (scope, element, attr) {
      var editorMode = $location.search().editor_mode;
    
      scope.$watch(attr.editorShow, function ngShowWatchAction(value) {
        // we're adding a temporary, animation-specific class for ng-hide since this way
        // we can control when the element is actually displayed on screen without having
        // to have a global/greedy CSS selector that breaks when other animations are run.
        // Read: https://github.com/angular/angular.js/issues/9103#issuecomment-58335845
    
        if (value || editorMode === 'testing') {
          element.removeClass(NG_HIDE_CLASS).addClass(NG_SHOW_CLASS);
        } else {
          element.removeClass(NG_SHOW_CLASS).addClass(NG_HIDE_CLASS);
        }
      });
    };
    
    return {
      restrict: 'A',
      multiElement: true,
      link: editorShowLink,
    };
  },
]);
