angular.module('LeasePilot').service('TemplateService', function() {
  var templates = {};
  var categories = [];
  var dedicatedCards = [
    'term',
    'base-rent',
    'initial-commencement-premises',
    'additional-commencement-premises',
  ];

  var registerTemplate = function(key, data) {
    if (!_.includes(categories, data.category)) {
      categories.push(data.category);
    }
    templates[key] = data;
  };

  var getTemplateDefaults = function(templateId) {
    return templates[templateId].defaults;
  };

  var isValidTemplate = function(templateId) {
    if (templates[templateId]) {
      return true;
    } else {
      console.error('unknown template requested: ' + templateId);
      return false;
    }
  };

  var getTemplates = function() {
    return templates;
  };

  var getCategories = function() {
    var sort = [
      'tenant',
      'premises',
      'permitted-uses',
      'premises-improvements',
      'term',
      'base-rent',
      'additional-commencement-premises',
      'percentage-and-additional-rent',
      'tenant-special-rights',
      'credit-support',
      'event',
    ];
    return _.sortBy(categories, function(category) {
      return _.includes(sort, category) ? sort.indexOf(category) : 100;
    });
  };

  var getSections = function(isMultiTermEditor) {
    var multipleTermSections = [
      'TENANT INFO',
      'TERMS AND BASE RENT',
      'DEAL TERMS',
    ];
    var singleTermSections = ['BASIC TERMS'];
    return isMultiTermEditor ? multipleTermSections : singleTermSections;
  };

  var getSectionsCardsMap = function() {
      return {
        'TENANT INFO': ['tenant', 'premises'],
        'TERMS AND BASE RENT': [
          'initial-commencement-premises',
          'additional-commencement-premises',
        ],
        'DEAL TERMS': [
          'permitted-uses',
          'premises-improvements',
          'percentage-and-additional-rent',
          'tenant-special-rights',
          'credit-support',
          'insurance-and-casualty',
          'miscellaneous',
          'existing-lease-information',
          'extended-term-information',
          'extended-term-rent',
        ],
        'BASIC TERMS': [
          'tenant',
          'premises',
          'permitted-uses',
          'premises-improvements',
          'term',
          'base-rent',
          'percentage-and-additional-rent',
          'tenant-special-rights',
          'credit-support',
          'insurance-and-casualty',
          'miscellaneous',
          'existing-lease-information',
          'extended-term-information',
          'extended-term-rent',
        ],
      };
  };

  var getMultipleTermsCards = function() {
    return getSectionsCardsMap()['TERMS AND BASE RENT'];
  };

  var getMultipleTermsSection = function() {
    return 'TERMS AND BASE RENT';
  };

  var getBasicTermsSection = function() {
    return 'BASIC TERMS';
  };

  var setSectionsForCards = function(sections, editor) {
    var defaultSection = _.last(sections);
    var sectionsCardsMap;
    if (!editor.sections || editor.sections.length == 0) {
      sectionsCardsMap = getSectionsCardsMap();
    } else {
      sectionsCardsMap = editor.sections;
    }
    var currSectionsCards = _.pick(sectionsCardsMap, sections);
    _.each(editor.cards, function(card) {
      var section = _.chain(currSectionsCards)
        .pickBy(function(s) {
          return s.includes(card.id);
        })
        .keys()
        .first()
        .value();
      if (!section && !dedicatedCards.includes(card.id)) {
        section = defaultSection;
      }
      card.section = section;
    });
  };

  return {
    registerTemplate: registerTemplate,
    isValidTemplate: isValidTemplate,
    getDefaults: getTemplateDefaults,
    getTemplates: getTemplates,
    getCategories: getCategories,
    getSections: getSections,
    getMultipleTermsCards: getMultipleTermsCards,
    setSectionsForCards: setSectionsForCards,
    getMultipleTermsSection: getMultipleTermsSection,
    getBasicTermsSection: getBasicTermsSection,
  };
});
