angular.module("LeasePilot").service("PartnerHelperService", [
  "$http",
  function($http) {
    function update({ companyId, partnerId, assets }) {
      return $http({
        method: "put",
        url: `/api/partners/`+partnerId,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          companyId,
          partnerId,
          assets
        }
      });
    }

    return {
      update,
    };
  }
]);
