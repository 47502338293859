angular.module('LeasePilot').directive('note', [
  '$rootScope',
  '$timeout',
  function($rootScope, $timeout) {
    return {
      restrict: 'E',
      scope: {
        category: '=',
      },
      templateUrl: 'note.html',
      link: function($scope, element, attrs) {
        const scopeVar = $rootScope.findAppScope($scope);
        $scope.isPopupOpened = false;
        $scope.notes = _.get(scopeVar, 'lease.notes', []).filter(note =>
          (_.get(note, 'category', '') || '')
            .split('_')
            .includes(attrs.category),
        );

        function changeTooltipPosition() {
          const offset = element.find('md-icon').offset();
          let tooltipX = offset.left + 20;
          let tooltipY = offset.top - 15;
          $(`.note-tooltip[data-for="${attrs.category}"]`).css({
            top: tooltipY,
            left: tooltipX,
          });
        }

        function showTooltip() {
          let notesMarkup = $scope.notes.map(note => {
            return `<div class="note-content">${note.text}</div>`;
          });
          $(
            `<div class="note-tooltip" data-for="${
              attrs.category
            }">${notesMarkup.join('<hr/>')}</div>`,
          ).appendTo('body');
          changeTooltipPosition();
        }

        function hideTooltip() {
          $(`.note-tooltip[data-for="${attrs.category}"]`).remove();
          $scope.isPopupOpened = false;
        }

        function hideAllTooltips() {
          $('.note-tooltip').remove();
          $('.show-description-button').removeClass('active');
        }

        $('.editor-wrapper').on('scroll', _.debounce(() => {
          if (element.is(':visible')) {
            changeTooltipPosition();
          } else {
            hideTooltip();
          }
        }, window.CONSTS.WAIT * 8));

        $scope.$on('cardTriggered', () => {
          $timeout(() => {
            if (element.is(':visible')) {
              changeTooltipPosition();
            } else {
              hideTooltip();
            }
          }, 500);
        });

        $scope.togglePopup = () => {
          $scope.isPopupOpened = !$scope.isPopupOpened;

          if ($scope.isPopupOpened) {
            hideAllTooltips();
            showTooltip();
          } else {
            hideTooltip();
          }
        };
      },
    };
  },
]);
