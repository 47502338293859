angular.module('LeasePilot').directive('breakpointTable', function() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, element, attrs) {
      var generate = attrs.generate || null;
      var watch = attrs.watch ? JSON.parse(attrs.watch) : [];
      var config = attrs.config ? JSON.parse(attrs.config) : {};
      config = {
        consolidate: true,
        format: {
          date: 'MM/DD/YYYY',
        },
        ...config,
      };
      watch.push('currentlyChanging');
      watch.push('changeIsBeingMade');

      // Create the breakpoint-table
      scope[generate](config);

      if (generate) {
        for (var i = 0; i < watch.length; i++) {
          scope.$watchCollection(watch[i], function(newValue) {
            if (newValue) {
              if (_.includes(watch, scope.currentlyChanging)) {
                scope[generate](config);
              } else {
                for (var j = 0; j < watch.length; j++) {
                  if (_.startsWith(scope.currentlyChanging, watch[j])) {
                    scope[generate](config);
                    break;
                  }
                }
              }
            }
          });
        }
      }
    },
    template: function(element, attrs) {
      var tableId = attrs.tableId || '';
      var table;
      var tableColumns = '';
      var tableRows;
      var tableCells = '';
      var tableStyle = attrs.style ? attrs.style : '';
      var rowStyle = attrs.rowStyle ? attrs.rowStyle : '';
      var headRowStyle = attrs.headRowStyle ? attrs.headRowStyle : '';
      var headCellStyle = attrs.headCellStyle ? attrs.headCellStyle : '';
      var headTextStyle = attrs.headTextStyle ? attrs.headTextStyle : '';
      var cellStyle = attrs.cellStyle ? attrs.cellStyle : '';
      var collection = attrs.collection;
      var columns = JSON.parse(attrs.columns) || [];
      var cells = JSON.parse(attrs.cells) || [];
      var watch = JSON.parse(attrs.watch) || [];

      var tableTemplate =
        "<table table-id-'" +
        tableId +
        '\' class="fs-hide" style="%s" ng-class="{\'adding-para\': isWatchedCollectionBeingChanged([\'' +
        watch.join("','") +
        '\'])}">' +
        '<tbody>' +
        '<tr style="%s">%s</tr>' +
        '%s' +
        '</tbody>' +
        '</table>';

      var tableColumnTemplate =
        '<td style="%s"><span style="%s">%s</span></td>';

      var tableRowTemplate =
        '<tr style="%s" ng-repeat="row in %s track by $index">%s</tr>';

      var tableCellTemplate = '<td style="%s">%s</td>';

      // Generating the columns
      for (var i = 0; i < columns.length; i++) {
        tableColumns += _.string.sprintf(
          tableColumnTemplate,
          headCellStyle,
          headTextStyle,
          columns[i],
        );
      }

      // Generating the cells
      for (var j = 0; j < cells.length; j++) {
        tableCells += _.string.sprintf(tableCellTemplate, cellStyle, cells[j]);
      }

      // Generating the row
      tableRows = _.string.sprintf(
        tableRowTemplate,
        rowStyle,
        collection,
        tableCells,
      );

      // Generating the table
      table = _.string.sprintf(
        tableTemplate,
        tableStyle,
        headRowStyle,
        tableColumns,
        tableRows,
      );

      return table;
    },
  };
});
