angular.module('LeasePilot').service('ProcessStatusService', function() {
  var processes = [];

  this.start = function(process) {
    processes.push({ name: process });
  };

  this.end = function(process) {
    _.remove(processes, function(p) {
      return p.name == process;
    });
  };

  this.getCurrent = function() {
    return _.last(processes) || {};
  };

  this.startDefault = function(process) {
    processes.unshift({ name: process });
  };
});
