const hiddenClassName = ".lp-hide";
const windowParamName = "applyFilterVisibleState";

const elementQuerySelectorAllOriginal = Object.getOwnPropertyDescriptor(
  Element.prototype,
  "querySelectorAll"
);
Object.defineProperty(Element.prototype, "querySelectorAll", {
  ...elementQuerySelectorAllOriginal,
  value: function(query, onlyVisibleElements = false) {
    onlyVisibleElements = onlyVisibleElements || window[windowParamName];
    let list = elementQuerySelectorAllOriginal.value.call(this, query);
    if (onlyVisibleElements) {
      return filterVisible(list);
    } else {
      return list;
    }
  }
});

if (!Element.prototype.getTopAnchor) {
  Element.prototype.getTopAnchor = function() {
    let container = this;
    let parent;

    while (
      container.parentElement &&
      (parent = container.parentElement.closest("[pid]")) &&
      parent &&
      (parent.tagName !== "DIV" ||
        (parent.tagName === "DIV" &&
          parent.firstElementChild.hasAttribute("advanced-edit")))
    ) {
      container = parent;
    }

    return container;
  };
}


Element.prototype.getFirstBlockChildContaining = function(node) {
  if (!this.contains(node)) {
    return null;
  } else {
    let scan = this.children[0];
    while (scan && !scan.contains(node)) {
      scan = scan.nextElementSibling;
    }
    if (scan && scan.contains(node)) {
      if(scan.tagName == "DIV"){
        return scan.getFirstBlockChildContaining(node);
      }
      else{
        return scan;
      }
    } else {
      return null;
    }
  }
};

Element.prototype.getFirstChildContaining = function(node) {
  if (!this.contains(node)) {
    return null;
  } else {
    let scan = this.children[0];
    while (scan && !scan.contains(node)) {
      scan = scan.nextElementSibling;
    }
    if (scan && scan.contains(node)) {
      return scan;
    } else {
      return null;
    }
  }
};

const ElementQuerySelectorOriginal = Object.getOwnPropertyDescriptor(
  Element.prototype,
  "querySelector"
);
Object.defineProperty(Element.prototype, "querySelector", {
  ...ElementQuerySelectorOriginal,
  value: function(query, onlyVisibleElements = false) {
    onlyVisibleElements = onlyVisibleElements || window[windowParamName];
    if (onlyVisibleElements) {
      let list = this.querySelectorAll(query, true);
      if (list.length !== 0) {
        return list[0];
      } else {
        return null;
      }
    } else {
      return ElementQuerySelectorOriginal.value.call(this, query);
    }
  }
});

const ElementInnerHTMLOriginal = Object.getOwnPropertyDescriptor(
  Element.prototype,
  "innerHTML"
);

Object.defineProperty(Element.prototype, "innerHTML", {
  ...ElementInnerHTMLOriginal,
  set: function(value) {
    let sectionId = null;
    if (
      this &&
      this.innerHTML &&
      this.innerHTML.indexOf("deleted-paragraph") !== -1
    ) {
      sectionId = this.getAttribute("section-id");
    }

    if (
      value &&
      typeof value.indexOf === "function" &&
      value.indexOf("deleted-paragraph") !== -1
    ) {
      if (this && this.getAttribute && this.getAttribute("section-id")) {
        sectionId = this.getAttribute("section-id");
      }
    }
    const returnValue = ElementInnerHTMLOriginal.set.call(this, value);
    if (sectionId) {
      contentEditableManager.updateContainerVisibility(sectionId);
    }

    return returnValue;
  }
});

const ElementInnerTextOriginal = Object.getOwnPropertyDescriptor(
  HTMLElement.prototype,
  "innerText"
);

Object.defineProperty(HTMLElement.prototype, "innerText", {
  ...ElementInnerTextOriginal,
  set: function(value) {
    const returnValue = ElementInnerTextOriginal.set.call(this, value);
    return returnValue;
  }
});

const ElementSetAttributeOriginal = Object.getOwnPropertyDescriptor(
  Element.prototype,
  "setAttribute"
);

Object.defineProperty(Element.prototype, "setAttribute", {
  ...ElementSetAttributeOriginal,
  value: function(attribute, value) {
    if (
      this.tagName === "INS" &&
      this.hasAttribute("data-paste-id") &&
      attribute.toLowerCase() === "pid"
    ) {
      return;
    }

    return ElementSetAttributeOriginal.value.call(this, attribute, value);
  }
});


// const ElementRemoveOriginal = Object.getOwnPropertyDescriptor(
//   Element.prototype,
//   "remove"
// );

// Object.defineProperty(Element.prototype, "remove", {
//     ...ElementRemoveOriginal,
//     value: function(attribute) {
//       if(this == $0 || this.contains($0) ){
//         debugger;
//       }
//       let returnValue = ElementRemoveOriginal.value.call(this, attribute);
      
//       return returnValue;
//     }
//   });


//   const NodeOrgRemoveChild = Object.getOwnPropertyDescriptor(
//     Node.prototype,
//     "removeChild"
//   );

//   Object.defineProperty(Element.prototype, "removeChild", {
//     ...NodeOrgRemoveChild,
//     value: function(child) {
//       if(window.isDebug){
//         debugger;
//       }
//       let returnValue = NodeOrgRemoveChild.value.call(this, child);
      
//       return returnValue;
//     }
//   });

// remove
// const ElementGetAttributeOriginal =  Object.getOwnPropertyDescriptor(
//   Element.prototype,
//   "getAttribute"
// );

// Object.defineProperty(Element.prototype, "getAttribute", {
//   ...ElementGetAttributeOriginal,
//   value: function(attribute) {
//     let returnValue = ElementGetAttributeOriginal.value.call(this, attribute);
//     const attrList = ["pid","free-text","section-id"];

//     if (returnValue == "100" && attrList.indexOf(attribute) !== -1) {
//       debugger;
//     }
//     return returnValue;
//   }
// });
