angular.module('LeasePilot').directive('ongoingCoTenancyNamedTenantsModal', [
  '$window',
  function($window) {
    return {
      restrict: 'E',
      templateUrl: 'ongoing_co_tenancy_named_tenants_modal.html',
      link: function(scope, attrs) {
        scope.label = attrs.context.attributes.label.value;

        scope.scopeVar = scope.$root.findAppScope(scope);
      },
    };
  },
]);
